// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '414b217f-802c-4509-a6dd-429be87fd869',
    title: 'Introduction To MLP',
    body: '',
    keyLearning: 'Learning',
    passScore: 2,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title: 'What customers might want to purchase a management liability policy?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Limited companies, sole traders and charitable organisations',
            body: '',
            score: 1.0
          },
          {
            title: 'Only limited companies',
            body: '',
            score: 0
          },
          {
            title: 'Only sole traders',
            body: '',
            score: 0
          },
          {
            title: 'Only charitable organisations',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'Why might a customer purchase a management liability policy?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'To protect the Company/ Directors against discrimination claims OR  in case of regulatory investigations',
            body: '',
            score: 1.0
          },
          {
            title: 'To protect the building in case of a flood',
            body: '',
            score: 0
          },
          {
            title: 'To protect loss because of  fire',
            body: '',
            score: 0
          },
          {
            title: 'None of the answers shown here',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const MlpIntroductionToMlp = {
  questionnaire
};
