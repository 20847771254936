<template>
  <div>
    <div v-for="(item, index) in paragraphs" v-bind:key="index">
      <h4 v-if="item.title != ''">{{ item.title }}</h4>
      <p v-html="item.body"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['paragraphs'],
};
</script>