// real estate module 5
const module = [
  {
    id: 'b46f51b2-5afc-40d7-8f32-730a517216ab',
    slug: 'real-estate-additional-policies-to-consider-for-the-real-estate-sector',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'b46f51b2-5afc-40d7-8f32-730a517216ab|f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    template: 'video-template',
    title: 'Additional policies to consider for the real estate sector',
    description:
      'Understand the liability exposures for property owners, why residents’ associations need D&O cover and how specialist policies can meet clients’ needs',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    questionnaireId: 'bbdc75f3-df2e-4d9b-a6fc-8b1176b0fb8e',
    contents: {
      id: '09b1f6e6-fe02-482e-b453-5fd60acd81e3',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the liability exposures for property owners, why residents’ associations need D&O cover and how specialist policies can meet clients’ needs' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the liability exposures for property owners</li>' +
        '<li>Learn why D&O cover is needed for residents’ associations, including key cover issues and claims examples</li>' +
        '<li>Be aware of the policies that should be considered for real estate clients and understand how specialist policies can meet their needs</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Additional policies to consider for the real estate sector',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/592688382',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Additional policies to consider for the real estate sector',
          button: 'Download Slides',
          fileSize: '1858481',
          url: 'real-estate-additional-policies-to-consider-for-the-real-estate-sector.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 5
    },
    statistics: {
      level: 2,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['real estate', 'products', 'sales', 'advanced']
  }
];

export const RealEstateAdditionalPoliciesToConsiderForTheRealEstateSector = {
  module
};
