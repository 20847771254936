const module = [
  {
    id: 'd0ea0901-b7d5-4167-a047-a242649925aa',
    slug: 'adapting-to-a-hardening-market-and-strategic-selling',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1d903ecd-b454-426a-8d43-a44307fd4ab7|d0ea0901-b7d5-4167-a047-a242649925aa',
    template: 'video-template',
    title: 'Adapting to a hardening market and strategic selling',
    description:
      'Understand the effects of a hard market on client relationships and utilise best practice methodology to achieve sales success, such as strategic selling',
    categoryId: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    questionnaireId: '',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    contents: {
      id: '11da06c0-90fa-480e-b730-78d399006455',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the importance of consistent processes and disciplines to successful sales outcomes, including sales planning and review and the role of a CRM.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the effects of a hard market on sales approach and client relationships</li>' +
        '<li>Help create strategies to address these challenges</li>' +
        '<li>Understand the key factors of sales success in a hard market</li>' +
        '<li>Utilise best practice methodology to achieve sales success, such as strategic selling</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title:
            'Sales in a Changing Environment - Session Two: A Hardening Market and Best Practice Methodologies',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505197759',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title:
            'Sales in a Changing Environment - Session Two: A Hardening Market and Best Practice Methodologies',
          button: 'Download Slides',
          fileSize: 941811,
          url: 'adapting-to-a-hardening-market-and-stratigic-selling.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www.axaconnect.co.uk/siteassets/coronavirus-hub/business-support/sales-in-a-changing-environment/sales-in-a-changing-environment-week-2/'
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 6
    },
    statistics: {
      level: 1,
      timeEstimate: '65'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'intermediate']
  }
];

export const AdaptingToAHardeningMarketAndStrategicSelling = {
  module
};
