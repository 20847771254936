<template>
  <div id="app" class="bw">
    <navbar></navbar>
    <div class="container-fluid" style="margin-top: 100px">
      <div v-cloak>
        <router-view></router-view>
      </div>
    </div>
    <footer-panel></footer-panel>
    <cookie-notice></cookie-notice>
  </div>
</template>

<script>
import CookieNotice from '@/components/CookieNotice';
import CssAuth from '@/content/tenants/brokerwise-pro/design/CssAuth';
import CssCurriculum from '@/content/tenants/brokerwise-pro/design/CssCurriculum';
import CssCookieNotice from '@/content/tenants/brokerwise-pro/design/CssCookieNotice';
import CssFooter from '@/content/tenants/brokerwise-pro/design/CssFooter';
import CssHomePage from '@/content/tenants/brokerwise-pro/design/CssHomePage';
import CssIcons from '@/content/tenants/brokerwise-pro/design/CssIcons';
import CssModuleCard from '@/content/tenants/brokerwise-pro/design/CssModuleCard';
import CssModulesCore from '@/content/tenants/brokerwise-pro/design/CssModulesCore';
import CssNavbar from '@/content/tenants/brokerwise-pro/design/CssNavbar';
import CssSiteCore from '@/content/tenants/brokerwise-pro/design/CssSiteCore';
import CssTags from '@/content/tenants/brokerwise-pro/design/CssTags';
import FooterPanel from '@/content/tenants/brokerwise-pro/FooterPanel';
import Navbar from '@/content/tenants/brokerwise-pro/Navbar';

export default {
  components: {
    CookieNotice,
    CssAuth,
    CssCurriculum,
    CssCookieNotice,
    CssFooter,
    CssHomePage,
    CssIcons,
    CssModuleCard,
    CssModulesCore,
    CssNavbar,
    CssSiteCore,
    CssTags,
    FooterPanel,
    Navbar,
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Brokerwise Bite-sized knowledge for brokers ',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Brokerwise is an online personal development platform for Brokers. Work your way through self-contained training modules',
      },
      {
        name: 'msapplication-config',
        content: '/icons/brokerwise-pro/tiles.xml',
      },
      {
        name: 'application-name',
        content: 'Brokerwise',
      },
    ],
    link: [
      {
        rel: 'shortcut icon',
        href: '/icons/brokerwise-pro/favicon.ico',
      },
      {
        rel: 'manifest',
        href: '/icons/brokerwise-pro/manifest.json',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/icons/brokerwise-pro/apple-icon-57x57.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60x',
        href: '/icons/brokerwise-pro/apple-icon-60x60.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/icons/brokerwise-pro/apple-icon-72x72.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/icons/brokerwise-pro/apple-icon-76x76.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/icons/brokerwise-pro/apple-icon-114x114.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/icons/brokerwise-pro/apple-icon-120x120.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/icons/brokerwise-pro/apple-icon-144x144.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/icons/brokerwise-pro/apple-icon-152x152.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/icons/brokerwise-pro/apple-icon-180x180.png',
      },
      {
        rel: 'icon',
        sizes: '192x192',
        href: '/icons/brokerwise-pro/android-icon-192x192.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        href: '/icons/brokerwise-pro/favicon-16x16.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        href: '/icons/brokerwise-pro/favicon-32x32.png',
      },
      {
        rel: 'icon',
        sizes: '96x96',
        href: '/icons/brokerwise-pro/favicon-96x96.png',
      },
    ],
  },
};
</script>
