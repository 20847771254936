const module = [
  {
    id: 'f15ee027-10c1-4584-a78d-c7ce932a348d',
    slug: 'professional-indemnity-vs-cyber',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f15ee027-10c1-4584-a78d-c7ce932a348d|33c63463-c631-4478-9115-ae51dda1931d',
    template: 'video-template',
    title: 'Professional Indemnity or Cyber – which cover is needed?',
    description:
      'You’ll learn the extent of cover in Professional Indemnity and Cyber cover and examples of where both covers are needed.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '33c63463-c631-4478-9115-ae51dda1931d',
    questionnaireId: '0452d74c-e33c-43b0-b3dd-dfdd249b33ba',
    contents: {
      id: '9672c99a-0a0d-47e7-8083-3b9156189449',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module we’ll explore what cyber risks are faced by professionals that may overlap with a cyber policy and which professions my have specific issues they need to consider.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Cyber risks faced by professionals</li>' +
        '<li>Extent of cyber cover in a PI policy</li>' +
        '<li>Triggers - PI v Cyber</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Professional Indemnity or Cyber?',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/704098006',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Professional Indemnity or Cyber?',
          button: 'Download Slides',
          fileSize: '299474',
          url: 'professional-indemnity-vs-cyber.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '23'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'legal', 'intermediate']
  }
];

export const ProfessionalIndemnityVsCyber = {
  module
};
