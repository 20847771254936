// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
    {
        id: 'd617a0c8-8ae8-40eb-858a-f308f15bfa59',
        title: 'How Liability Arises',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Why was Caparo Industries Plc v Dickman & Others (1990) an important case for a professional\'s liability?',
                body: '',
                keyLearning: 'Duty of care owed by professionals',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'It established (via precedent) what is needed to succeed in a legal action against a professional when there is no contractual relationship between the claimant and the professional',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It established (via precedent) that accountants can be liable to any third party',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It established (via precedent) that a contractual relationship is always needed to be able to sue a professional',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It established (via precedent) the level of damages that professionals must pay if an action against them is successful',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'Q2',
                body: 'What is the \'Bolam\' test?',
                keyLearning: 'Duty of care owed by professionals',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'It is a test used about the level of skill a professional needs to demonstrate to avoid liability',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It is an exam that doctors need to pass to be regarded as competent professionals',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is a test used to assess the extent of liability owed by a professional',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is a test used to establish proximity of relationship between a professional and a third party',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'Which of the following is an example of an implied contract term in a professional services contract?',
                body: '',
                keyLearning: 'How professional liability might arise under common law, contract and statute',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The professional will use reasonable skill and care in the provision of the services',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The professional will provide the advice within the agreed time limits',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The professional will be paid expenses',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The advice will generate additional income of at least £1million',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'Which of the following type of professional firm  is <strong>required</strong> by its regulatory body to buy professional indemnity insurance?',
                body: '',
                keyLearning: 'How professional liability might arise under common law, contract and statute',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Architects',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Funeral directors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Recruitment consultants',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'IT consultants',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'A claim was made alleging that the professional failed to carry out a proper appraisal and valuation of an item - what type of professional business is most likely to face this type of claim?',
                body: '',
                keyLearning: 'Know the types of claims different professionals may face',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Auctioneers',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Accountants',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Auditors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Architects',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'Why is South Australian Asset Management Corporation v York Montague Ltd (1997) (SAAMCO) and important case for professionals?',
                body: '',
                keyLearning: 'Duty of care owed by professionals',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The case established (via precedent) that there different consequences for professionals that provide information rather than advice',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The case established (via precedent) that there are NOT different consequences for professionals that provide information rather than advice',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The case established (via precedent) that there is no liability attaching to the provision of negligent information',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The case established (via precedent) that professionals are only liable for the provision of negligent information',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'What are the four \'steps\' needed for a liability  claim to succeed in negligence?',
                body: '',
                keyLearning: 'Duty of care owed by professionals',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The four \'step\'s are:' +
                            '<ol>' +
                            '<li>that a duty of care is owed;</li>' +
                            '<li>that duty has been breached;</li>' +
                            '<li>the breach caused the loss;</li>' +
                            '<li>the loss was foreseeable.</li>' +
                            '</ol>',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The four \'step\'s are:' +
                            '<ol>' +
                            '<li>that a duty of care is owed;</li>' +
                            '<li>the breach caused the loss;</li>' +
                            '<li>the loss was foreseeable.</li>' +
                            '<li>there is a contract between the claimant and professional.</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The four \'step\'s are:' +
                            '<ol>' +
                            '<li>the duty of care has been breached;</li>' +
                            '<li>the breach caused the loss;</li>' +
                            '<li>the loss was foreseeable;</li>' +
                            '<li>the loss exceeds a minimum size.</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The four \'step\'s are:' +
                            '<ol>' +
                            '<li>that a duty of care is owed;</li>' +
                            '<li>that duty has been breached;</li>' +
                            '<li>the breach caused the loss;</li>' +
                            '<li>there is a contract between the claimant and professional.</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Which of the following type of client are most likely to incur a professional liability?',
                body: '',
                keyLearning: 'Know the types of claims different professionals may face',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Graphic designers',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Manufacturers of ready meals',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Retailers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Hospitality',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'To whom do professionals owe a duty of care?',
                body: '',
                keyLearning: 'Duty of care owed by professionals',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Anyone - if it is fair, reasonable and foreseeable that the third party would be affected by the advice provided',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Only third parties in a contractual relationship with the professional',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Anyone - professionals owe a duty of care to everyone',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Anyone that pays a professional for advice',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What is the significance of Hedley Byrne v Heller & Partners (1963)in professional liability case law?',
                body: '',
                keyLearning: 'How professional liability might arise under common law, contract and statute',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The case established that a professional may owe a duty of care to a party with whom they are not in contract for financial loss.',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The case set out guidance on quantum for financial losses',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The case established that a professional does NOT owe a duty of care to a party with whom they are not in contract for financial loss.',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The case confirmed that professionals owe a duty of care to anybody',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Which of the following statements is true?',
                body: '',
                keyLearning: 'How professional liability might arise under common law, contract and statute',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Libel and slander are types of defamation',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Libel and slander are the same ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Professionals cannot be liable for defamation',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Libel is a defamatory statement in a temporary form',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Which of the following is an example of a claim that has been made against a solicitor? ',
                body: '',
                keyLearning: 'Know the types of claims different professionals may face',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Failure to correctly word terms of lease',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Failure to identify a structural defect in a building',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Failure to renew an insurance policy on time',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Failure to provide a product within the contracted period',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Are professionals personally liable for their activities?',
                body: '',
                keyLearning: 'Duty of care owed by professionals',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Yes',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Sometimes',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only when outside the UK',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'Which of the following is NOT a type of tort in the UK?',
                body: '',
                keyLearning: 'How professional liability might arise under common law, contract and statute',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Contractual liability',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Negligence',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Defamation',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Privacy',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'From where does professional liability arise?',
                body: '',
                keyLearning: 'How professional liability might arise under common law, contract and statute',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Civil law and statute/legislation',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Civil law only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Statute/legislation only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only from contracts signed',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const HowLiabilityArises = {
    questionnaire
};