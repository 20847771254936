const module = [
  {
    id: 'f879eee4-9990-4ed6-999b-9dbeb30607ca',
    slug: 'typical-challenges-you-need-to-overcome-to-help-meet-existing-clients-needs',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f879eee4-9990-4ed6-999b-9dbeb30607ca|3187c558-5836-4721-a158-776c11b266ee',
    template: 'video-template',
    title: 'Challenges to overcome to meet existing clients needs',
    description:
      'In this session we look at some typical challenges and how to overcome any fears and objections.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '3187c558-5836-4721-a158-776c11b266ee',
    questionnaireId: '',
    contents: {
      id: 'be93c81b-4f76-43f4-afe4-7db94bcf2e20',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'We look at some typical challenges to achieving successful results and suggest some strategies to overcome them.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Why many businesses fail to realise the opportunity of growing existing client accounts</li>' +
        '<li>Understand what the common challenges are</li>' +
        '<li>What attitudes you need to adopt to be successful</li>' +
        '<li>How to overcome any fears and objections</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Challenges to overcome to meet existing clients needs}',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/798325896',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Challenges to overcome to meet existing clients needs',
          button: 'Download Slides',
          fileSize: '1200754',
          url: 'addressing_the_needs_of_existing_clients.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '5'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'sales', 'skills', 'intermediate']
  }
];

export const ChallengesToAchievingSuccessfulResultsAndStrategiesToOvercomeThem = {
  module
};
