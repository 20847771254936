// real estate module 1
const module = [
  {
    id: '13167efc-a419-46ef-bb74-635fcd50ba30',
    slug: 'real-estate-market-segmentation-and-key-issues',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '13167efc-a419-46ef-bb74-635fcd50ba30|f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    template: 'video-template',
    title: 'Market segmentation and key issues',
    description:
      'Understand how the market is segmented, the role of brokers, management agents and residents’ associations and the key issues affecting the sector',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    questionnaireId: '4628da0b-2721-4281-af58-5e1e68ae2450',
    contents: {
      id: '1baa8262-340f-42fb-b4fb-c2f6d764a5c8',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand how the market is segmented, the role of brokers, management agents and residents’ associations and the key issues affecting the sector' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand what is meant by the real estate sector and how the market is segmented by insurers</li>' +
        '<li>Be aware of the different products issued by insurers within the real estate sector</li>' +
        '<li>Know the key issues affecting the insurance requirements of the real estate sector</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Real Estate - Market segmentation and key issues',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/582143549',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Real Estate - Market segmentation and key issues',
          button: 'Download Slides',
          fileSize: '2030149',
          url: 'real-estate-market-segmentation-and-key-issues.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['real estate', 'products', 'sales', 'marketing', 'intermediate']
  }
];

export const RealEstateMarketSegmentationAndKeyIssues = {
  module
};
