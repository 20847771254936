<template>
  <div id="app" class="be">
    <navbar></navbar>
    <div class="container-fluid" style="margin-top: 100px">
      <div v-cloak>
        <router-view></router-view>
      </div>
    </div>
    <footer-panel></footer-panel>
    <cookie-notice></cookie-notice>
  </div>
</template>

<script>
import CookieNotice from '@/components/CookieNotice';
import CssAuth from '@/content/tenants/brokerelevate-co-uk/design/CssAuth';
import CssBrokerwisePage from '@/content/tenants/brokerelevate-co-uk/design/CssBrokerwisePage';
import CssCurriculum from '@/content/tenants/brokerelevate-co-uk/design/CssCurriculum';
import CssContactsPage from '@/content/tenants/brokerelevate-co-uk/design/CssContactsPage';
import CssContactPage from '@/content/tenants/brokerelevate-co-uk/design/CssContactPage';
import CssCookieNotice from '@/content/tenants/brokerelevate-co-uk/design/CssCookieNotice';
import CssFooter from '@/content/tenants/brokerelevate-co-uk/design/CssFooter';
import CssHomePage from '@/content/tenants/brokerelevate-co-uk/design/CssHomePage';
import CssIcons from '@/content/tenants/brokerelevate-co-uk/design/CssIcons';
import CssModuleCard from '@/content/tenants/brokerelevate-co-uk/design/CssModuleCard';
import CssModulesCore from '@/content/tenants/brokerelevate-co-uk/design/CssModulesCore';
import CssPropositionPage from '@/content/tenants/brokerelevate-co-uk/design/CssPropositionPage';
import CssNavbar from '@/content/tenants/brokerelevate-co-uk/design/CssNavbar';
import CssSiteCore from '@/content/tenants/brokerelevate-co-uk/design/CssSiteCore';
import CssTags from '@/content/tenants/brokerelevate-co-uk/design/CssTags';
import FooterPanel from './FooterPanel';
import Navbar from './Navbar';

export default {
  components: {
    CookieNotice,
    CssAuth,
    CssBrokerwisePage,
    CssCurriculum,
    CssContactPage,
    CssContactsPage,
    CssCookieNotice,
    CssFooter,
    CssHomePage,
    CssIcons,
    CssModuleCard,
    CssModulesCore,
    CssPropositionPage,
    CssNavbar,
    CssSiteCore,
    CssTags,
    FooterPanel,
    Navbar,
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Broker Elevate - We’re on your side and by your side ',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Broker Elevate is our proposition, born out of the AXA National Trading Centre (NTC)',
      },
      {
        name: 'application-name',
        content: 'Broker Elevate',
      },
      {
        name: 'msapplication-config',
        content: '/icons/brokerelevate-co-uk/tiles.xml',
      },
    ],
    link: [
      {
        rel: 'shortcut icon',
        href: '/icons/brokerelevate-co-uk/favicon.ico',
      },
      {
        rel: 'manifest',
        href: '/icons/brokerelevate-co-uk/manifest.json',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/icons/brokerelevate-co-uk/apple-icon-57x57.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60x',
        href: '/icons/brokerelevate-co-uk/apple-icon-60x60.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/icons/brokerelevate-co-uk/apple-icon-72x72.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/icons/brokerelevate-co-uk/apple-icon-76x76.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/icons/brokerelevate-co-uk/apple-icon-114x114.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/icons/brokerelevate-co-uk/apple-icon-120x120.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/icons/brokerelevate-co-uk/apple-icon-144x144.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/icons/brokerelevate-co-uk/apple-icon-152x152.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/icons/brokerelevate-co-uk/apple-icon-180x180.png',
      },
      {
        rel: 'icon',
        sizes: '192x192',
        href: '/icons/brokerelevate-co-uk/android-icon-192x192.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        href: '/icons/brokerelevate-co-uk/favicon-16x16.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        href: '/icons/brokerelevate-co-uk/favicon-32x32.png',
      },
      {
        rel: 'icon',
        sizes: '96x96',
        href: '/icons/brokerelevate-co-uk/favicon-96x96.png',
      },
    ],
  },
};
</script>
