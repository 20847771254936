const module = [
  {
    id: '4ab5a337-e63a-493a-9093-b046b3aafefb',
    slug: 'communication-essentials',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '4ab5a337-e63a-493a-9093-b046b3aafefb|349708b6-4bdc-49a5-a851-fbd4d574b56d',
    template: 'zing365-iframed-template',
    title: 'Communication Essentials',
    description:
      "Great communication skills are key to your professional success. In this course, you'll learn the characteristics that define a great communicator and how these qualities can help you excel at work.",
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
    questionnaireId: '',
    contents: {
      id: '4774df68-84a9-4b39-b300-e22de907831b',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        "Great communication skills are key to your professional success. In this course, you'll learn the characteristics that define a great communicator and how these qualities can help you excel at work." +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand why communication matters</li>' +
        '<li>Identify the right time to use different communication types</li>' +
        '<li>Understand the 4 steps to active listening</li>' +
        '<li>Improve your business writing skills</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/4ab5a337-e63a-493a-9093-b046b3aafefb/media/zing365-soft-skills-communication-essentials/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['skills', 'foundation']
  }
];

export const CommunicationEssentials = {
  module
};
