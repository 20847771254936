export default function (Vue) {
  const collectionItem = {
    type: '',
    id: null,
    status: '',
    data: '',
  };

  Vue.user = {
    collection: [],
    addToCollection(type, module, data = {}) {
      let results = this.findInCollection(type, module.id)
      if (Object.keys(results).length > 0) {
        // already in collection

        return false;
      }

      if (type === 'module') {
        var newCollectionItem = Object.create(collectionItem);
        newCollectionItem.type = type
        newCollectionItem.id = module.id;
        newCollectionItem.data = {
          ref: '',
          date: ''
        };
      } else {
        // don't know this type (yet)
        return false;
      }

      // add it
      this.collection.push(newCollectionItem);
      this.storeCollection()

      return true
    },
    fetchCollection() {
      if (!Vue.prototype.$localStorage.retrieveDecrypted('user')) {
        this.collection = [];
        this.storeCollection();
      } else {
        this.collection = Vue.prototype.$localStorage.retrieveDecrypted('user');
      }
      return true;
    },
    storeCollection() {
      return Vue.prototype.$localStorage.storeEncrypted('user', this.collection)
    },
    findTypeInCollection(type) {
      var results = [];
      for (let i = 0; i < this.collection.length; i++) {
        if (this.collection[i].type === type) {
          results.push(this.collection[i]);
        }
      }

      return results
    },
    findInCollection(type, id) {
      var found = this.collection.findIndex(function (collectionItem, index) {
        if (type === 'module') {
          if (collectionItem.id === id) {
            return true;
          }
        }

        return false
      });

      if (found === -1 || found === undefined) {

        return false
      }

      return this.collection[found];
    }
  }

  Object.defineProperties(Vue.prototype, {
    $user: {
      get() {
        return Vue.user
      },
    }
  });
}