// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
    {
        id: '92624976-eac0-4c6b-b2df-2b629d731245',
        title: 'Professional Indemnity Policy Cover',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'If the claims conditions are conditions precedents, what remedies do insurers have if the conditions are breached?',
                body: '',
                keyLearning: 'Claims conditions – importance of timely notifications',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The insurer is entitled to deny liability for the claim without the need to establish prejudice as a result of the non-compliance with the claims conditions',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The insurer is entitled to avoid the policy in its entirety',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insurer can sue for damages for any prejudice that has been suffered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'None',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'When might run-off professional indemnity cover be required?',
                body: '',
                keyLearning: 'Claims made considerations ',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'When cover is not renewed as claims can be made several years after the professional work has been carried out',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'After each renewal as claims can be made several years after the professional work has been carried out',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'When a professional changes jobs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'When an insurer requires it',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'Which extension would provide cover for a scenario where an employee of an auditor takes a bribe from client to overlook creative accounting and the auditor is subsequently sued?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Fraudulent/dishonest acts of employees',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Mitigation Costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Automatic Acquisitions',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Ombudsman awards',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'What is a jurisdiction exclusion?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'An exclusion that excludes cover for claims made in specific countries',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'An exclusion that excludes cover for claims made for specific types of work undertaken',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An exclusion that excludes cover for claims involving work done in specific countries',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An exclusion that excludes cover for claims made against overseas subsidiaries on a global policy',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'What does it mean for the insured if the claims condition says claims must be \'immediately\' notified? ',
                body: '',
                keyLearning: 'Claims conditions – importance of timely notifications',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The insured is generally expected to act with all reasonable speed in the circumstances',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The insured is expected to notify claims within 24 hours of becoming aware of them.',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured is expected to notify claims within 24 hours of all directors becoming aware of them.',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured is expected to notify claims before the end of the policy period',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'Which of the following statements is true for professional indemnity insurance?',
                body: '',
                keyLearning: 'Claims conditions – importance of timely notifications',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The insured has to notify the insurers of any incident, occurrence, fact, matter or act that they become aware of that might reasonably give rise to a claim',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The insured only has to notify the insurers of any claim made against them by a third party\'s legal adviser',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured does not have to notify the insurers of any incident, occurrence, fact, matter or act that they become aware of that might reasonably give rise to a claim',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured only has to notify the insurers when they are sure that any incident, occurrence, fact, matter or act will give rise to a claim',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'When must claims be notified under a professional indemnity policy?',
                body: '',
                keyLearning: 'Claims made considerations ',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'During the period of insurance, or any additional claims notification period included in the wording',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'During the period of insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Within 2 years of the policy expiry',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Anytime',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Who, in addition to the insured and their employees, is usually automatically covered under a professional indemnity policy?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Retired partners who act as consultants',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Any sub-contractors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Anyone with whom the insured has a contractual relationship',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Suppliers to the insured',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'Which policy extension provides a fixed sum per day if any employee, director or partner has to attend court with respect to a claim under the policy.',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Court Attendance Costs',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Loss of Documents',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Automatic Acquisitions',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Disputed Fees',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'The mitigation costs extension provides what additional cover?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Insurers will pay the cost of preventing or mitigating a loss',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Insurers will pay fees owed to insured ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurers will pay awards stipulated by any ombudsman ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurers will pay defence costs in criminal prosecutions ',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'What is the difference between a civil liability wording and an errors and omissions wording?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'A civil liability wording covers all civil liability unless excluded; an errors and omissions wording is based on negligence and adds cover for other torts as extensions',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A civil liability wording  is based on negligence and adds cover for other torts as extensions; an errors and omissions wording covers all civil liability unless excluded ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A civil liability wording  has no exclusions, an errors and omissions wording does have exclusions',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Nothing - they are the same',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Which of the following professional bodies insists its members take out professional indemnity cover on a civil liability basis?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'The Law Society',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'BIBA',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'AIRMIC',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'ABI',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Which of the following is NOT a common exclusion found on a professional indemnity policy?',
                body: '',
                keyLearning: 'Key elements of cover under a professional indemnity policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Loss of documents',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Asbestos',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pollution',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cyber',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'What is the effect of a retroactive date on a professional indemnity policy?',
                body: '',
                keyLearning: 'Claims made considerations ',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Claims arising from work done before the retroactive date will not be covered',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The claim must be made before the retroactive date',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Claims arising from work done after the retroactive date will not be covered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'None',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'What is the largest reason for insurers declining professional indemnity claims?',
                body: '',
                keyLearning: 'Claims conditions – importance of timely notifications',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Late notification of the claim or circumstance',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Cover is excluded',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Premiums not paid',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Failure to make a fair presentation',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ProfessionalIndemnityPolicyCover = {
    questionnaire
};