const module = [
  {
    id: 'ddacc2cf-c989-4776-9f3a-435e97d94e1f',
    slug: 'telephone-etiquette',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'ddacc2cf-c989-4776-9f3a-435e97d94e1f|349708b6-4bdc-49a5-a851-fbd4d574b56d',
    template: 'zing365-iframed-template',
    title: 'Telephone Etiquette ',
    description:
      'Telephone etiquette is the way you conduct yourself and represent your company during business calls.',
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
    questionnaireId: '',
    contents: {
      id: '3181f0f4-650e-4601-b180-e8cd5bca2781',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Telephone etiquette is the way you conduct yourself and represent your company during business calls. It involves demonstrating professionalism, courtesy, and competence through your tone of voice, word choice, active listening skills, and body language. During this module we will look at how you can identify and improve those skills.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the importance and impact of telephone etiquette</li>' +
        '<li>Be aware of the tips and benefits for guiding a conversation</li>' +
        '<li>Introduce you to the telephone etiquette top tips</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/ddacc2cf-c989-4776-9f3a-435e97d94e1f/media/zing365-soft-skills-telephone-etiquette/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['skills', 'foundation']
  }
];

export const TelephoneEtiquette = {
  module
};
