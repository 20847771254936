const module = [
  {
    id: 'cc482d4a-1691-41e7-93f6-d8a644897a6e',
    slug: 'mergers-and-acquisitions-insurance-issues',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'cc482d4a-1691-41e7-93f6-d8a644897a6e|cb299f5f-2a2e-48a7-a555-df263578556c',
    template: 'video-template',
    title: 'Mergers And Acquisitions Insurance Issues',
    description:
      'This advanced module covers the insurance issues with Mergers & Acquisitions, the types of mergers/acquisitions, the key insurance considerations & any additional insurance products available to cover the risks',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'cb299f5f-2a2e-48a7-a555-df263578556c',
    questionnaireId: '70c65d83-5c63-4a35-aa4c-e96c83168de8',
    contents: {
      id: '0ea868af-7553-4f91-9d03-9041633541d0',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This advanced module covers the insurance issues with Mergers & Acquisitions describing the types of mergers/acquisitions, the key insurance considerations during a client merger or acquisition and the additional insurance products available to deal with the risk' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the various types of M&A clients may be involved with</li>' +
        '<li>Common insurance issues seen in a M&A and potential solutions</li>' +
        '<li>Insurance products that may be used in an M&A</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Mergers And Acquisitions Insurance Issues',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/764477325',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Mergers And Acquisitions Insurance Issues',
          button: 'Download Slides',
          fileSize: '501567',
          url: 'mergers_and_acquisitions_insurance_issues.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 2,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'advanced']
  }
];

export const MergersAndAcquisitionsInsuranceIssues = {
  module
};
