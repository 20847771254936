const module = [
  {
    id: 'be433dc4-2fa0-4bf7-b24b-7aed4d64f6e8',
    slug: 'environmental-risk-management',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: false,
    concatenatedID: 'be433dc4-2fa0-4bf7-b24b-7aed4d64f6e8|f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    template: 'zing365-iframed-template',
    title: 'Environmental Risk Management',
    description:
      'Explore environmental liability insurance in a webinar with case studies. Learn coverage, conditions, legislation, and disaster recovery tips.',
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    questionnaireId: '',
    contents: {
      id: 'b879a62c-4b4d-4273-9f26-c36db9e1f650',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This live-online webinar aims to Introduce the delegate to environmental liability insurance. Supported by case studies, we will look at the type of business that could benefit from this cover together with the extent of cover available, conditions and exclusions that an underwriter may apply. We will also take a quick look at the legislation relating to environmental risks together with some tips on disaster recovery planning.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Have a better understanding of environmental liability insurance</li>' +
        '<li>Understand the extent of cover available to clients and also help with the client’s understanding of this insurance</li>' +
        '<li>Be better able to recognise when a client may need this type of insurance</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url: '/modules/be433dc4-2fa0-4bf7-b24b-7aed4d64f6e8/media/environmental-risk-management/index.html',
          caption: {
            title: '',
            description: '',
          },
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 1,
    },
    statistics: {
      level: 1,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['intermediate', 'esg', 'technical', 'legal', 'environment', 'cover', 'risk'],
  },
];

export const EnvironmentalRiskManagement = {
  module,
};
