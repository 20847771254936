const module = [
  {
    id: 'bb61b0dc-eb2d-409f-b550-2baf9b0071d5',
    slug: 'esg-environmental-social-and-corporate-governance',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'bb61b0dc-eb2d-409f-b550-2baf9b0071d5|614ab504-1537-40f3-a114-72250a217d3f',
    template: 'video-template',
    title: 'ESG Environmental Social and Corporate Governance',
    description:
      '??? ESG Environmental Social and Corporate Governance - Dougie Barnett and Nick Tilley',
    completedDescription: 'TODO',
    supplier: 'AXA',
    categoryId: '614ab504-1537-40f3-a114-72250a217d3f',
    questionnaireId: '',
    contents: {
      id: '1d1f00ae-3753-4fbc-88f0-921ef1eed808',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Thing_1</li>' +
        '<li>Thing_2</li>' +
        '<li>Thing_3</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Environmental Social and Corporate Governance (ESG)',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/{{video id}}',
          caption: {
            title: 'ESG Underwriting Update',
            description: '<p>Delivered by: ???</p>' + '<p>Brought to you by: AXA Insurance</p>'
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'intermediate']
  }
];

export const EsgEnvironmentalSocialAndCorporateGovernance = {
  module
};
