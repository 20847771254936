const module = [
  {
    id: '15a314fd-d637-4514-a6ca-245cc62ab413',
    slug: 'conduct-risk-partner-meeting-our-customers-needs',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '15a314fd-d637-4514-a6ca-245cc62ab413|f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    template: 'video-template',
    title: "Conduct and Risk partner webinar:  meeting our customers' needs together",
    description:
      'You’ll discover more about the evolving regulatory landscape alongside practical guidance on managing conduct risk for your clients.',
    completedDescription: 'TODO',
    supplier: 'AXA',
    categoryId: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    questionnaireId: 'e07d9177-f646-42fe-bb90-ed27195a024d',
    contents: {
      id: 'd0323774-e275-4798-affb-9462ad39c7f7',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'The accompanying webinar provides practical guidance on managing Conduct Risk and within this how the regulatory landscape is evolving and touches upon pricing practices as well as Consumer Duty' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understanding the evolving regulatory landscape</li>' +
        '<li>General Insurance Pricing Practices</li>' +
        '<li>The Consumer Duty</li>' +
        '<li>Practical guidance on managing Conduct Risk</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: "Conduct and Risk partner webinar:  meeting our customers' needs together",
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/741903137',
          caption: {
            title: '',
            description:
              '<p>Prepared and delivered by Nick Cataldo and Tom Dennis from AXA’s Governance, Risk and Conduct team</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: "Conduct and Risk partner webinar:  meeting our customers' needs together",
          button: 'Download Slides',
          fileSize: '673737',
          url: 'axa-broker-event-conduct-and-risk-partner.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '20'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['intermediate','esg','legal', 'sales'],
    questionnaireOverride: {
      passScore: 4,
      mix: {
        easy: 0,
        medium: 3,
        hard: 3
      }
    }
  }
];

export const ConductRiskPartnerMeetingOurCustomersNeeds = {
  module
};
