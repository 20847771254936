const module = [
  {
    id: 'b4a6575e-6898-4358-989c-fec51804f2b7',
    slug: 'electric-vehicles-technical-training',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'b4a6575e-6898-4358-989c-fec51804f2b7|8221d7be-45de-47ee-aba9-33124c54d4a8',
    template: 'video-template',
    title: 'Electric Vehicles technical training',
    description:
      'Learn why electric vehicles are now a viable option for fleet managers and the considerations you need to think about when choosing Electric Vehicles.',
    completedDescription: 'TODO',
    supplier: 'AXA CL',
    categoryId: '8221d7be-45de-47ee-aba9-33124c54d4a8',
    questionnaireId: 'dab36884-a2ec-4f9f-a3af-4456df7b026d',
    contents: {
      id: 'ebaa996c-339e-475a-987f-71593c867883',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn why electric vehicles are now a viable option for fleet managers and the considerations you need to think about when choosing Electric Vehicles.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Why Electric Vehicles are becoming a viable option for fleet managers</li>' +
        '<li>Types of Electric Vehicles</li>' +
        '<li>Range and charging considerations</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Electric Vehicles technical training',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/691162280',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Doug Jenkins - Motor Technical Risk Manager at AXA Insurance UK</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Electric Vehicles technical training',
          button: 'Download Slides',
          fileSize: '8082811',
          url: 'electric_vehicles_technical_training.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '12'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['motor', 'sales', 'foundation']
  }
];

export const ElectricVehiclesTechnicalTraining = {
  module
};
