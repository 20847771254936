const module = [
  {
    id: '04f568ff-a6e2-494c-8640-7bf1ef6f378b',
    slug: 'lithium-ion-batteries',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '04f568ff-a6e2-494c-8640-7bf1ef6f378b|efba1b84-412d-46e7-bea0-05adf97e06af',
    template: 'video-template',
    title: 'Lithium-ion Batteries',
    description:
      'This module will give you an understanding of the use of lithium batteries and associated risks.',
    completedDescription: 'TODO',
    supplier: 'AXA',
    categoryId: 'efba1b84-412d-46e7-bea0-05adf97e06af',
    questionnaireId: '',
    contents: {
      id: 'dd3d6a9e-24a6-4fc3-be66-be7989677555',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This module will give you an understanding of the use of lithium batteries and associated risks' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Explore the reasons for increased use of lithium-ion batteries, applications and technology.</li>' +
        '<li>Raise awareness of battery failure causes and associated fire risks. </li>' +
        '<li>Look at Risk Management Considerations. </li>' +
        '<li>Explains features and applications of Battery Energy Storage Systems.  </li>' +
        '<li>Consider fire protection options.</li>' +
        '<li>Specifically look at fire safety considerations for charging of electric vehicles. </li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Lithium-ion Batteries',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/782492243',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Rob Dakin, Head of Business Resilience Management Delivery AXA Insurance</p>' +
              '<p>Brought to you by: AXA Insurance</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Lithium-ion Batteries',
          button: 'Download Slides',
          fileSize: '1368532',
          url: 'axa-technical-training-lithium-ion-batteries.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'sales', 'motor', 'intermediate']
  }
];

export const LithiumIonBatteries = {
  module
};
