const module = [
  {
    id: '9f3e3fd8-f9bc-4fe7-a4ac-e57a84092204',
    slug: 'professional-Indemnity-policy-cover',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '9f3e3fd8-f9bc-4fe7-a4ac-e57a84092204|33c63463-c631-4478-9115-ae51dda1931d',
    template: 'video-template',
    title: 'Professional Indemnity Policy Cover',
    description:
      'You’ll learn about the policy cover, wordings, typical extensions and exclusions of Professional Indemnity cover.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '33c63463-c631-4478-9115-ae51dda1931d',
    questionnaireId: '92624976-eac0-4c6b-b2df-2b629d731245',
    contents: {
      id: '9a4577cb-522e-418e-b323-c0dfdeefcb73',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In the module you’ll explore what makes up a typical Professional Indemnity cover, including specific wordings for certain professions as well as types of extensions and exclusions to look out for.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Key elements of cover under a professional indemnity policy</li>' +
        '<li>Claims made considerations </li>' +
        '<li>Claims conditions</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Professional Indemnity Policy Cover',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/704096445',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Professional Indemnity Policy Cover',
          button: 'Download Slides',
          fileSize: '495767',
          url: 'professional-Indemnity-policy-cover.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '34'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'legal', 'foundation']
  }
];

export const ProfessionalIndemnityPolicyCover = {
  module
};
