const module = [
  {
    id: 'd5e5802c-97ae-4925-939d-0c179a5190e6',
    slug: 'sales-needs-discovery-one',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'd5e5802c-97ae-4925-939d-0c179a5190e6|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Needs Discovery - One',
    description:
      'In the first of two modules on needs discovery you’ll learn the importance of building rapport and trust in sales.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: '72ae08d1-fdef-443e-a5a0-affc26fdfd52',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Now you have contacted your prospects you need to establish their needs and build a relationship based on trust.  This module looks at how to build that relationship.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>The importance of building rapport and trust in sales</li>' +
        '<li>The keys to building rapport</li>' +
        '<li>Identifying the components of trust</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Needs Discovery One',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755030856',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Needs Discovery One',
          button: 'Download Slides',
          fileSize: '1063383',
          url: 'introduction-to-sales-needs-discovery-one.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 6,
      maximum: 10
    },
    statistics: {
      level: 1,
      timeEstimate: '6'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const IntroductionToSalesNeedsDiscoveryOne = {
  module
};
