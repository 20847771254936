const module = [
  {
    id: 'ba69bfed-e48e-4856-bc49-7798e672a609',
    slug: 'change-management-managing-change',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'ba69bfed-e48e-4856-bc49-7798e672a609|76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    template: 'actus-iframed-template',
    title: 'Managing change',
    description:
      'You’ll learn how to effectively manage change, how to influence others and what are the key traits needed to lead a team through the change curve.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    questionnaireId: '',
    contents: {
      id: '1aca206c-dfd0-4416-aafe-e6bf475e5986',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Being able to manage change effectively is essential for any organisation.  You’ll learn what are the key traits needed for anyone leading the change agenda as well as how to influence others and successfully take your team through the change curve.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your learning outcomes for this module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the key traits of a ‘Change Superhero’ and how to develop them</li>' +
        '<li>How to influence others to buy in to change</li>' +
        '<li>Discover how to build a collaborative change team </li>' +
        '<li>Understand how to manage people through the change curve </li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/ba69bfed-e48e-4856-bc49-7798e672a609/media/actus-change-management-series-managing-change-web-v1/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const ManagingChange = {
  module
};
