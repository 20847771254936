<template>
  <div v-if="this.userHasCompletedThisModule(this.module)" class="row">
    <div class="col all-ready-finished">
      <div class="icon-finished">
        <div class="icon-label">You have already completed this module.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['module'],
  methods: {
    userHasCompletedThisModule(module) {
      if (this.$user.findInCollection('module', module.id)) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style>
.all-ready-finished {
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-ready-finished .icon-label {
  padding-left: 40px;
  font-size: 1.2em;
  text-align: left;
  white-space: normal;
  height: 60px;
  margin-bottom: 10px;
}

.icon-finished {
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 630 1000' style='enable-background:new 0 0 630 1000;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EB5C3C;%7D .st1%7Bfill:%23FABC78;%7D .st2%7Bfill:%23FFE67A;%7D%0A%3C/style%3E%3Cg%3E%3Cpolygon class='st0' points='169.84,489.26 169.84,1000 313.26,888.02 464.81,994.54 467.54,489.26 '/%3E%3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -130.3268 314.6352)' class='st1' cx='314.63' cy='314.64' rx='314.63' ry='314.64'/%3E%3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -130.3268 314.6352)' class='st2' cx='314.63' cy='314.64' rx='254.96' ry='254.96'/%3E%3Cpolygon class='st1' points='318.81,154.56 367.88,253.99 477.6,269.93 398.21,347.32 416.95,456.6 318.81,405.01 220.68,456.6 239.42,347.32 160.02,269.93 269.74,253.99 '/%3E%3C/g%3E%3C/svg%3E%0A");
}
</style>