const module = [
  {
    id: '3b33b107-5c3d-4f96-a280-fb0bda181bb7',
    slug: 'construction-other-cover-options-for-the-construction-industry',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '3b33b107-5c3d-4f96-a280-fb0bda181bb7|07ceadc8-32da-4dab-9bef-da023c3388eb',
    template: 'video-template',
    title: 'Other cover options for the construction industry',
    description:
      'Understand the types of bonds found in the construction industry, the key cyber exposures faced and the additional risks/cover that contractors may need',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '07ceadc8-32da-4dab-9bef-da023c3388eb',
    questionnaireId: '49dbb0f0-b453-47ab-8870-19322d57bd20',
    contents: {
      id: 'c64e9199-4d46-44f2-9ff3-314e87c6c182',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the types of bonds found in the construction industry, the key cyber exposures faced and the additional risks/cover that contractors may need' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Enhance your understanding of the types of bonds found in the constructions industry</li>' +
        '<li>Be able to identify key cyber risks found in the construction industry</li>' +
        '<li>Understand the main additional risks and other cover that a contractor may need, including D&O, Latent Defects and Legal Indemnities</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Other cover options for the construction industry',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/545520030',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Other cover options for the construction industry',
          button: 'Download Slides',
          fileSize: 802326,
          url: 'construction-other-cover-options-for-the-construction-industry.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 5,
      maximum: 5
    },
    statistics: {
      level: 2,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['construction', 'products', 'sales', 'advanced']
  }
];

export const ConstructionOtherCoverOptionsForTheConstructionIndustry = {
  module
};
