const module = [
  {
    id: '92c8b8a8-a900-4353-9a67-23f88a39ccc1',
    slug: 'introduction-to-underinsurance-and-inflation',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '92c8b8a8-a900-4353-9a67-23f88a39ccc1|b6e92936-69ae-4393-b518-3d5b8d4f3dfd',
    template: 'video-template',
    title: 'Introduction to Underinsurance and Inflation',
    description:
      "This learning module introduces you to ‘Underinsurance and Inflation and it's General effect of inflation on insurance, cover considerations and claims issues",
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'b6e92936-69ae-4393-b518-3d5b8d4f3dfd',
    questionnaireId: '4efa5940-84c9-43c3-822d-580f31b4ee9d',
    contents: {
      id: '2765474e-e0fe-4817-a385-0ccb6d9d5fd0',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This learning module introduces you to ‘Underinsurance and Inflation and how inflation causes underinsurance, how policy wordings protect against underinsurance and claims issues caused by underinsurance.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Be aware of the affect that inflation has on insurance</li>' +
        '<li>Know which covers help provide clients with protection against underinsurance</li>' +
        '<li>Be able to identify claims issues caused by inflation and underinsurance</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction to Underinsurance and Inflation',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755043728',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction to Underinsurance and Inflation',
          button: 'Download Slides',
          fileSize: '656952',
          url: 'underinsurance-and-inflation-training.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction to Underinsurance and Inflation',
          button: 'Download AXA making claims clear - underinsurance and Average',
          fileSize: '1020504',
          url: 'axa_making_claims_clear_underinsurance_and_average.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'intermediate']
  }
];

export const introductionToUnderinsuranceAndInflation = {
  module
};
