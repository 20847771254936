const module = [
  {
    id: 'fa49c144-e4be-48d2-b5c8-c8ecaf26d361',
    slug: 'hybrid-management-deliver-feedback-for-positive-outcomes',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'fa49c144-e4be-48d2-b5c8-c8ecaf26d361|9ddda366-d724-4ef0-8a75-248eda696817',
    template: 'actus-iframed-template',
    title: 'Deliver feedback for positive outcomes',
    description:
      'Delivering effective feedback is important when managing remote workers.  You’ll learn to adapt your style to make remote feedback more effective.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '9ddda366-d724-4ef0-8a75-248eda696817',
    questionnaireId: '',
    contents: {
      id: '40286aa8-d429-490c-a7aa-067488b7bf19',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Being able to deliver effective feedback is so important when managing remote workers.  In this module we’ll show you how to adapt your style to make remote feedback even more effective as well as how to avoid any pitfalls when delivering feedback remotely.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes for this learning module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the importance of good feedback when managing remote workers</li>' +
        '<li>Be familiar with different types and levels of feedback</li>' +
        '<li>Feel able to adapt your style to make feedback more effective </li>' +
        '<li>Understand how to prepare for and follow up after feedback</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/fa49c144-e4be-48d2-b5c8-c8ecaf26d361/media/delivering-feedback-for-positive-outcomes-web-v2/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const DeliverFeedbackForPositiveOutcomes = {
  module
};
