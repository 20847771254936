<template>
  <div class="video-section">
    <div class="row">
      <div class="col">
        <div class="videoContainer">
          <iframe
            :src="module.contents.media[0].url + '#t=1'"
            width="100%"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            class="videoEmbed"
          ></iframe>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col caption">
        <h6 v-if="module.contents.media[0].caption.title != ''">
          {{ module.contents.media[0].caption.title }}
        </h6>
        <div v-html="module.contents.media[0].caption.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Player from '@vimeo/player';
import { MetricsMixin } from '@/mixins/metrics.mixin';

export default {
  mixins: [MetricsMixin],
  props: ['module'],
  data() {
    return {
      player: null,
    };
  },
  methods: {
    hasModuleStarted(data) {
      if (this.module.status.state != 'finished') {
        this.$eventBus.$emit('set-module-started');
      }
    },
    hasModuleFinished(data) {
      if (
        data.percent > 0.95 &&
        this.module.status.state != 'finished' &&
        this.module.questionnaireId === ''
      ) {
        this.module.status.state = 'finished';
        this.player.off('timeupdate');
        this.$eventBus.$emit('set-module-finished');
        this.$eventBus.$emit('show-module-complete-message');
      }
    },
  },
  mounted() {
    // see vimeo player docs here https://github.com/vimeo/player.js

    var iframe = document.querySelector('iframe');
    this.player = new Player(iframe);

    // Events
    this.player.on('play', (data) => {
      this.metricRecordPlayerAction('play');
      this.hasModuleStarted(data);
    });

    this.player.on('pause', (data) => {
      this.metricRecordPlayerAction('pause');
    });

    this.player.on('ended', (data) => {
      this.metricRecordPlayerAction('end');
      this.hasModuleFinished(data);
    });

    this.player.on('timeupdate', (data) => {
      this.hasModuleFinished(data);
    });
  },
};
</script>

<style>
.videoContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.videoEmbed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player {
  max-width: 100% !important;
}
</style>