// real estate module 3
const module = [
  {
    id: '268902a6-97d1-4b7f-be83-af44371b9102',
    slug: 'real-estate-property-insurance-key-cover-issues-in-property-owners-policies',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '268902a6-97d1-4b7f-be83-af44371b9102|f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    template: 'video-template',
    title: 'Property insurance: key cover issues in property owners’ policies',
    description:
      'Understand the key clauses in property owners’ policies, including the issues around unoccupancy and the causes and solutions for underinsurance',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    questionnaireId: 'ef78a951-1c8c-436c-8b04-28d48c7a6504',
    contents: {
      id: '{{content-uuid}}',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the key clauses in property owners’ policies, including the issues around unoccupancy and the causes and solutions for underinsurance ' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the issues around vacant properties and property insurance </li>' +
        '<li>Understand the information brokers need to provide to get the best terms</li>' +
        '<li>Be aware of the key clauses in residential and commercial property owner’s insurance policies</li>' +
        '<li>Understand the causes of and solutions for underinsurance</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: '',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/591662635',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Property insurance: key cover issues in property owners’ policies',
          button: 'Download Slides',
          fileSize: '2249097',
          url: 'real-estate-property-insurance-key-cover-issues-in-property-owners-policies.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['real estate', 'products', 'sales', 'intermediate']
  }
];

export const RealEstatePropertyInsuranceKeyCoverIssuesInPropertyOwnersPolicies = {
  module
};
