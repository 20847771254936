const module = [
  {
    id: '6073f6b6-f786-420b-8bc1-ba12b415b8e3',
    slug: 'mlp-introduction-to-mlp',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '6073f6b6-f786-420b-8bc1-ba12b415b8e3|f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    template: 'video-template',
    title: 'Introduction To MLP',
    description: 'Introduction to Management Liability Insurance MLP, need for cover & the market',
    completedDescription: 'TODO',
    supplier: 'AXA Broker Development',
    categoryId: 'f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    questionnaireId: '414b217f-802c-4509-a6dd-429be87fd869',
    contents: {
      id: 'e6f7a69d-1747-46ad-b915-1d133a379a5c',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Introduction to Management Liability Insurance MLP, need for cover & the market' +
        // '<h2 class="subtitle">Objectives</h2>' +
        // '<ul style="margin-top:10px">' +
        // '<li>Thing_1</li>' +
        // '<li>Thing_2</li>' +
        // '<li>Thing_3</li>' +
        // '</ul>',
        '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction To MLP',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/774193979',
          caption: {
            title: '',
            description:
              '<p>Delivered by: AXA Commercial</p>' + '<p>Brought to you by: AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction To MLP',
          button: 'Download Slides',
          fileSize: '3410470',
          url: 'introduction-to-mlp-need-for-cover-the-market.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '10'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'products', 'foundation']
  }
];

export const MlpIntroductionToMlp = {
  module
};
