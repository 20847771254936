// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: 'fe8fb93c-72ce-4877-8344-cda911e4559a',
    title: 'Introduction to Presenting employers’ liability risks',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'Why should comments on disease claims be included in an EL risk presentation?',
        body: '',
        keyLearning: 'Understand why insurers need information',
        difficulty: 'hard',
        answers: [
          {
            title: 'To enable insurers to better understand the causes of existing claims',
            body: '',
            score: 1.0,
          },
          {
            title: 'To enable insurers to exclude cover for known diseases',
            body: '',
            score: 0,
          },
          {
            title: 'To enable insurers to provide more accurate reserves',
            body: '',
            score: 0,
          },
          {
            title: 'To provide insurers with additional rating factors',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 2,
        title: 'Why should a history of prior insurers be included in an EL risk presentation?',
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'hard',
        answers: [
          {
            title: 'Insurers differ in their methods of reserving',
            body: '',
            score: 1.0,
          },
          {
            title: 'Insurers differ in their methods of rating',
            body: '',
            score: 0,
          },
          {
            title: 'Insurers differ in their methods of premium allocation',
            body: '',
            score: 0,
          },
          {
            title: 'Insurers know who they are competing against',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 3,
        title:
          'What is the main benefit to an <strong>insured</strong> of providing a detailed business description?',
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'hard',
        answers: [
          {
            title: 'It ensures that all the activities undertaken are included in the EL cover',
            body: '',
            score: 1.0,
          },
          {
            title: 'It ensures that all the activities undertaken are included premium calculation',
            body: '',
            score: 0,
          },
          {
            title: 'It allows specific activities to be insured under a different policy',
            body: '',
            score: 0,
          },
          {
            title: 'There is no benefit',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 4,
        title: 'Why should changes made to the business be included in an EL risk presentation? ',
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'hard',
        answers: [
          {
            title: 'So that insurers have ‘as is’ view of the risk',
            body: '',
            score: 1.0,
          },
          {
            title: 'So that insurers can ignore claims from closed subsidiary companies',
            body: '',
            score: 0,
          },
          {
            title: 'To make the claims experience look better',
            body: '',
            score: 0,
          },
          {
            title: 'To enable insurers to track disease claims',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 5,
        title: 'What are IBNR claims?',
        body: '',
        keyLearning: 'Importance of claims triangulations',
        difficulty: 'hard',
        answers: [
          {
            title:
              'Incidents that have occurred and are likely to give rise to a claim, but a claim has not been reported to insurers',
            body: '',
            score: 1.0,
          },
          {
            title: 'Incidents that have occurred and are not likely to give rise to a claim',
            body: '',
            score: 0,
          },
          {
            title:
              'Incidents that have occurred, and are likely to give rise to a claims so a claim has been reported to insurers',
            body: '',
            score: 0,
          },
          {
            title: 'Claims that should have been reported to a different insurer',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 6,
        title:
          'For a typical risk, what percentage of total claims are reported in the current policy period?',
        body: '',
        keyLearning: 'Importance of claims triangulations',
        difficulty: 'medium',
        answers: [
          {
            title: '40%',
            body: '',
            score: 1.0,
          },
          {
            title: '100%',
            body: '',
            score: 0,
          },
          {
            title: '10%',
            body: '',
            score: 0,
          },
          {
            title: '60%',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 7,
        title: 'Why do EL insurers need a split in wages paid by activity of the employee?',
        body: '',
        keyLearning: 'Understand why insurers need information',
        difficulty: 'medium',
        answers: [
          {
            title: 'Some activities are riskier and so will be charged at a higher rate',
            body: '',
            score: 1.0,
          },
          {
            title: 'Some activities are riskier and so will be excluded from the cover',
            body: '',
            score: 0,
          },
          {
            title: 'So claims can be reviewed against activity of injured employee',
            body: '',
            score: 0,
          },
          {
            title: 'So claim reserves can be more accurate',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 8,
        title:
          "Why do the names of all the Insured's subsidiary companies need to be included in an EL risk presentation?",
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'medium',
        answers: [
          {
            title: 'So all the employing companies insured can be identified',
            body: '',
            score: 1.0,
          },
          {
            title: 'So appropriate premiums can be charged',
            body: '',
            score: 0,
          },
          {
            title: 'So the premium can be divided between them',
            body: '',
            score: 0,
          },
          {
            title: 'For information only',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 9,
        title:
          'Why do insurers want a five year claims history to be provided in an EL risk presentation?',
        body: '',
        keyLearning: 'Understand why insurers need information',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Claims take several years to be paid so a longer claims history gives a more accurate view of the risk',
            body: '',
            score: 1.0,
          },
          {
            title: 'FCA rules require a five year claims history for EL risks',
            body: '',
            score: 0,
          },
          {
            title:
              'The compulsory insurance legislation requires a five year claims history be provided to insurers',
            body: '',
            score: 0,
          },
          {
            title: 'To ensure all possible claims are included',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 10,
        title: 'What is a HSE risk assessment?',
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'medium',
        answers: [
          {
            title:
              'A formal review of the workplace hazards, who might be harmed and how /what the business is doing to control the risks',
            body: '',
            score: 1.0,
          },
          {
            title: 'A formal review of the risk of not having EL cover in place',
            body: '',
            score: 0,
          },
          {
            title: 'A formal review of the hazards faced by employees',
            body: '',
            score: 0,
          },
          {
            title: 'A formal review of the hazards faced by visitors',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 11,
        title: 'What is a claims triangulation?',
        body: '',
        keyLearning: 'Importance of claims triangulations',
        difficulty: 'easy',
        answers: [
          {
            title: 'A method of showing the pattern of claims development over time ',
            body: '',
            score: 1.0,
          },
          {
            title: 'A method of assessing risks',
            body: '',
            score: 0,
          },
          {
            title: 'A method of rating based on claims',
            body: '',
            score: 0,
          },
          {
            title: 'A method of allocating claims by size',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 12,
        title:
          'Which of the following categories of information would NOT be needed by EL insurers?',
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'easy',
        answers: [
          {
            title: 'Construction of the main workplace building',
            body: '',
            score: 1.0,
          },
          {
            title: 'EL claims information',
            body: '',
            score: 0,
          },
          {
            title: 'Wage roll information',
            body: '',
            score: 0,
          },
          {
            title: 'Information about the activities undertaken by the business',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 13,
        title: 'Why is the Employer Reference Number (ERN) needed by insurers?',
        body: '',
        keyLearning: 'Understand why insurers need information',
        difficulty: 'easy',
        answers: [
          {
            title:
              'The Employers’ Liability Tracing Office (ELTO) uses the ERN as the  unique employer identifier, allowing employees to trace EL insurers',
            body: '',
            score: 1.0,
          },
          {
            title:
              'The HSE uses the ERN as the  unique employer identifier, allowing them to fine companies after a health and safety breach',
            body: '',
            score: 0,
          },
          {
            title:
              'The FCA uses the ERN to check insurers have issued an EL policy that complies with the compulsory insurance legislation',
            body: '',
            score: 0,
          },
          {
            title: 'The insurers use the ERN in their records to identify the insured',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 14,
        title: 'Which of the following should be included in an EL risk presentation?',
        body: '',
        keyLearning: 'Information that should be included in an EL presentation',
        difficulty: 'easy',
        answers: [
          {
            title: 'Detailed business description',
            body: '',
            score: 1.0,
          },
          {
            title: 'Detailed description of building construction',
            body: '',
            score: 0,
          },
          {
            title: 'Details of where products sold',
            body: '',
            score: 0,
          },
          {
            title: 'Details of business costs of production',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 15,
        title: 'What is usually used as the basis of calculating the premium for an EL policy?',
        body: '',
        keyLearning: 'Understand why insurers need information',
        difficulty: 'easy',
        answers: [
          {
            title: 'Estimated wages',
            body: '',
            score: 1.0,
          },
          {
            title: 'Estimated turnover',
            body: '',
            score: 0,
          },
          {
            title: 'Estimated number of employees',
            body: '',
            score: 0,
          },
          {
            title: 'Estimated building sum insured',
            body: '',
            score: 0,
          },
        ],
      },
    ],
  },
];

export const IntroductionToPresentingEmployersLiabilityRisks = {
  questionnaire,
};
