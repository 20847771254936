// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '188ac66c-07de-4a66-98c0-3156262b6886',
    title: 'Demystifying Alternative Risk Transfer (ART)',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title: 'Who must provide the capital required to support the captive’s business plan?',
        body: '',
        keyLearning: 'Advantages and disadvantages of the main types of alternative risk transfer',
        difficulty: 'hard',
        answers: [
          {
            title: 'The parent company',
            body: '',
            score: 1.0
          },
          {
            title: 'The regulator in the chosen captive domicile',
            body: '',
            score: 0
          },
          {
            title: 'The insurer that issues any fronting policies',
            body: '',
            score: 0
          },
          {
            title: 'The captive reinsurer',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'What is an aggregate loss cap in self insurance?',
        body: '',
        keyLearning: 'Advantages and disadvantages of the main types of alternative risk transfer',
        difficulty: 'hard',
        answers: [
          {
            title: 'A limit on the total cost of claims paid in the period by the insured',
            body: '',
            score: 1.0
          },
          {
            title: 'A limit on the total cost of claims paid in the period by the insurer',
            body: '',
            score: 0
          },
          {
            title: 'The maximum amount per claim that is self -insured ',
            body: '',
            score: 0
          },
          {
            title: 'A limit of indemnity',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'Why are D&O policies rarely issued via a captive insurance company',
        body: '',
        keyLearning:
          'Identify the key issues when considering the use of alternative risk transfer',
        difficulty: 'hard',
        answers: [
          {
            title: 'There may be a conflict of interest',
            body: '',
            score: 1.0
          },
          {
            title: 'Premiums are insufficient',
            body: '',
            score: 0
          },
          {
            title: 'Reinsurance is unavailable',
            body: '',
            score: 0
          },
          {
            title: 'Captives are not licenced to write D&O',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'What is a derivative?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'hard',
        answers: [
          {
            title: 'A financial product that has a value derived from the value of another product',
            body: '',
            score: 1.0
          },
          {
            title: 'A type of reinsurance',
            body: '',
            score: 0
          },
          {
            title: 'A financial product similar to an insurance policy',
            body: '',
            score: 0
          },
          {
            title: 'A type of deductible',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'What is a portfolio catastrophe swap ?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'medium',
        answers: [
          {
            title:
              'An agreement to exchange a similar portfolio of catastrophe risk exposures with each other for a given period of time',
            body: '',
            score: 1.0
          },
          {
            title: 'An agreement to insure catastrophe risk within a captive',
            body: '',
            score: 0
          },
          {
            title: 'A type of retro-rated insurance policy',
            body: '',
            score: 0
          },
          {
            title: 'A type of reinsurance',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title:
          'How long, should a client and broker usually allow for the setting up of an ART programme?  ',
        body: '',
        keyLearning: 'Advantages and disadvantages of the main types of alternative risk transfer',
        difficulty: 'medium',
        answers: [
          {
            title: '6 months',
            body: '',
            score: 1.0
          },
          {
            title: '6 weeks',
            body: '',
            score: 0
          },
          {
            title: '1 year',
            body: '',
            score: 0
          },
          {
            title: '3 months',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title: 'Which of the following is an advantage of a retro-rated policy?',
        body: '',
        keyLearning: 'Advantages and disadvantages of the main types of alternative risk transfer',
        difficulty: 'medium',
        answers: [
          {
            title: 'The lower initial premium provides a cash flow benefit',
            body: '',
            score: 1.0
          },
          {
            title: 'Few insurers are prepared to offer such policies',
            body: '',
            score: 0
          },
          {
            title: 'A letter of credit in favour of the insurer will be required',
            body: '',
            score: 0
          },
          {
            title: 'A fronting policy will be required',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'What is the minimum premium charged for an ART programme?',
        body: '',
        keyLearning:
          'Identify the key issues when considering the use of alternative risk transfer',
        difficulty: 'medium',
        answers: [
          {
            title: 'There is no minimum premium',
            body: '',
            score: 1.0
          },
          {
            title: '£ 100,000',
            body: '',
            score: 0
          },
          {
            title: '£ 1m',
            body: '',
            score: 0
          },
          {
            title: '£ 50,000',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'What is a retro-rated insurance policy?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'medium',
        answers: [
          {
            title: 'A type of policy where the premium paid is based on the actual cost of claims ',
            body: '',
            score: 1.0
          },
          {
            title:
              'A type of policy where the premium is paid at the end of the period of insurance',
            body: '',
            score: 0
          },
          {
            title: 'A type of policy that is adjustable',
            body: '',
            score: 0
          },
          {
            title: 'A type of policy that is where the premium is paid in instalments',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'What is virtual captive?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'medium',
        answers: [
          {
            title:
              'A centrally managed fund used by a company to finance the cost of self insurance',
            body: '',
            score: 1.0
          },
          {
            title: 'A captive insurer with no physical office',
            body: '',
            score: 0
          },
          {
            title:
              'A subsidiary insurance company formed by a private company to finance its self-insurance ',
            body: '',
            score: 0
          },
          {
            title: 'A captive that only insurers cyber risk',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'What is a deductible?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'easy',
        answers: [
          {
            title: 'An amount paid by the insured that is deducted from the sum claimed ',
            body: '',
            score: 1.0
          },
          {
            title: 'A discount deducted from the premium',
            body: '',
            score: 0
          },
          {
            title: 'An amount paid by the insured that is deducted from the sum insured',
            body: '',
            score: 0
          },
          {
            title: 'A type of insurance policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title:
          "If ART is used to provide employers' liability insurance - what must be issued by a UK licensed insurer?",
        body: '',
        keyLearning: 'Advantages and disadvantages of the main types of alternative risk transfer',
        difficulty: 'easy',
        answers: [
          {
            title: 'A fronting policy to comply with the compulsory insurance legislation',
            body: '',
            score: 1.0
          },
          {
            title: "An employers' liability certificate",
            body: '',
            score: 0
          },
          {
            title: 'A letter of credit',
            body: '',
            score: 0
          },
          {
            title: 'A reinsurance policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'What is a mutual insurance company?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'easy',
        answers: [
          {
            title: 'An insurance company 100% owned by its policyholders ',
            body: '',
            score: 1.0
          },
          {
            title: 'An agreement between the insurer and insured',
            body: '',
            score: 0
          },
          {
            title: 'An insurance company formed to provide a specialist type of insurance',
            body: '',
            score: 0
          },
          {
            title: 'An insurance company located outside the UK',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'What is a captive?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'easy',
        answers: [
          {
            title:
              'A subsidiary insurance company formed by a private company to finance its self-insurance in a formal structure',
            body: '',
            score: 1.0
          },
          {
            title: 'An insured that is tied to an insurer by a long term agreement',
            body: '',
            score: 0
          },
          {
            title:
              'A subsidiary insurance company formed by an insurer to provide alternative risk transfer products to clients',
            body: '',
            score: 0
          },
          {
            title:
              'A subsidiary insurance company formed by an insurance broker to provide alternative risk transfer products to clients',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'What is alternative risk transfer?',
        body: '',
        keyLearning: 'Review the main types of alternative risk transfer ',
        difficulty: 'easy',
        answers: [
          {
            title: 'Alternative to conventional insurance products for managing/ transferring risk',
            body: '',
            score: 1.0
          },
          {
            title: 'Alternative method of paying an insurance premium',
            body: '',
            score: 0
          },
          {
            title: 'Insurance products that are not issued by insurance companies',
            body: '',
            score: 0
          },
          {
            title: 'Conventional insurance products issued by alternative insurers',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const DemystifyingAlternativeRiskTransferArt = {
  questionnaire
};
