const module = [
  {
    id: '877a3e00-c333-45f2-99cf-1f67b11be8d2',
    slug: '4-habits-of-leadership',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '877a3e00-c333-45f2-99cf-1f67b11be8d2|f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    template: 'video-template',
    title: 'The four habits of leadership',
    description:
      'We’ll identify four key habits for leaders to adopt in both an office or hybrid working environment and why communication is critical for a successful leader.',
    completedDescription: 'TODO',
    supplier: 'Fiona Dewar - Pragmatic',
    categoryId: 'f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    questionnaireId: '',
    contents: {
      id: '0a1ed025-9224-4d79-98e3-a1a15a344432',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'We’ll identify four key habits for leaders to adopt in both an office or hybrid working environment and why communication is critical for a successful leader.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Why it’s important to do the small things every day</li>' +
        '<li>How to adopt the habits in an hybrid working environment</li>' +
        '<li>Why building effective communication is key to success</li>' +
        '<li>Why it’s critical to pick up on poor or changing behaviour promptly.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'The four habits of leadership',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730289203',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Fiona Dewar</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Diary Management Top Tips',
          button: 'Download Diary Management Top Tips',
          fileSize: '592454',
          url: 'diary-management-top-tips.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Four Key Habits Handout',
          button: 'Download Four Key Habits Handout',
          fileSize: '559274',
          url: 'four-key-habits-handout.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Gallup Handout Twelve Questions',
          button: 'Download Gallup Handout Twelve Questions',
          fileSize: '521012',
          url: 'gallup-handout-twelve-questions.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Regular Communication Meetings',
          button: 'Download Regular Communication Meetings',
          fileSize: '564444 ',
          url: 'regular-communication-meetings.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 4
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const FourHabitsOfLeadershipPodcasts = {
  module
};
