const module = [
  {
    id: 'b4cb83af-8c23-42d5-a4aa-0d750f5dc6b6',
    slug: 'mlp-company-legal-liability-cover',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'b4cb83af-8c23-42d5-a4aa-0d750f5dc6b6|f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    template: 'video-template',
    title: 'MLP - Company Legal Liability cover',
    description:
      'Learn about MLP Company Legal Liability policy/cover available, key risks and underwriting considerations.',
    completedDescription: 'TODO',
    supplier: 'AXA Broker Development',
    categoryId: 'f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    questionnaireId: 'd1897733-a532-4bdf-b156-03cf1b359a77',
    contents: {
      id: 'e3ced6d5-bf8c-4098-997e-8b99bbd37dae',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn about MLP Company Legal Liability policy/cover available, key risks and underwriting considerations.' +
        // '<h2 class="subtitle">Objectives</h2>' +
        // '<ul style="margin-top:10px">' +
        // '<li>Thing_1</li>' +
        // '<li>Thing_2</li>' +
        // '<li>Thing_3</li>' +
        // '</ul>',
        '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'MLP - Company Legal Liability cover',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/782556335',
          caption: {
            title: '',
            description:
              '<p>Delivered by: AXA Commercial</p>' + '<p>Brought to you by: AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'MLP - Company Legal Liability cover',
          button: 'Download Slides',
          fileSize: '348160',
          url: 'mlp-company-legal-liability-cover.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '6'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'products', 'foundation']
  }
];

export const MlpCompanyLegalLiabilityCover = {
  module
};
