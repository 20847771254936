export const SanitizeMixin = {
  methods: {
    /**
     * sanitizeCollection
     * Takes an object and sanitize each key within the object
     * and return the sanitized object.
     * @param {Array} uncleanData - The collection that needs sanitizing.
     * @param {Array} ignoreTheseKeys - Optional array of key names that shouldn't be sanitized.
     */
    sanitizeCollection(uncleanData, ignoreTheseKeys = []) {
      var santizedCollection = [];

      if (Array.isArray(uncleanData)) {
        for (var i = 0; i < uncleanData.length; i++) {
          santizedCollection[i] = this.sanitizeObject(uncleanData[i], ignoreTheseKeys);
        }
      } else {
        // not an array so process as an object instead
        return this.sanitizeObject(uncleanData, ignoreTheseKeys);
      }

      return santizedCollection;
    },

    /**
     * sanitizeObject
     * Takes an object and sanitize each key within the object
     * and return the sanitized object.
     * @param {object} uncleanData - The Object that needs sanitizing.
     * @param {Array} ignoreTheseKeys - Optional array of key names that shouldn't be sanitized.
     */
    sanitizeObject(uncleanData, ignoreTheseKeys = []) {
      var santizedObject = {};

      for (var key in uncleanData) {
        if (uncleanData.hasOwnProperty(key)) {
          if (this.arrayContains(key, ignoreTheseKeys)) {
            santizedObject[key] = uncleanData[key];
          } else {
            if (Array.isArray(uncleanData[key])) {
              santizedObject[key] = this.sanitizeCollection(uncleanData[key]);
            } else {
              // check if key is object
              if (typeof uncleanData[key] === "object" && uncleanData[key] !== null) {
                santizedObject[key] = this.sanitizeObject(uncleanData[key]);
              } else {
                santizedObject[key] = this.sanitize(uncleanData[key]);
              }
            }
          }
        }
      }

      return santizedObject;
    },
    /**
     * sanitize
     * Sanitizes a string
     * and return the sanitized object.
     * @param {string} value - The string that needs sanitizing.
     */
    sanitize(value) {
      value = this.$sanitize(value);

      if (value == "null") {
        return "";
      }

      // todo: Blanket replacement of &amp; with & probably needs to be a switch one day
      value = value.replace("&amp;", "&");

      return value;
    },
    /**
     * arrayContains
     * Takes a haystack and needle and confirms if
     * the haystack contains the needle.
     * @param {String} needle - The string that will be checked for in the array.
     * @param {Array} haystack - The array of keys that will be checked against.
     */
    arrayContains(needle, haystack) {
      return haystack.indexOf(needle) > -1;
    },
  },
};
