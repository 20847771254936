<template>
  <div v-if="module.contents.media.length > 0" class="row powerpoint text-right">
    <div class="col">
      <div
        v-for="(downloadable, index) in getMedia(this.module, 'file')"
        :key="index"
        style="margin-bottom: 5px"
      >
        <a :href="getMediaPath('module', module.id, downloadable.url)" target="_blank">
          <button type="button" class="btn btn-powerpoint btn-block">
            {{ downloadable.button }}
            <div v-if="showFileSize(downloadable.fileSize)" class="fileSize">
              {{ downloadable.subType + ':' + formatFileSize(downloadable.fileSize) }}
            </div>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaMixin } from '@/mixins/media.mixin';

export default {
  mixins: [MediaMixin],
  props: ['module'],

  mounted() {},
};
</script>

<style>
.module .fileSize {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}

.module .btn-powerpoint {
  background-color: #304a85;
  color: #fff;
}

.module .btn-powerpoint:hover {
  background-color: #214baf;
  color: #fff;
}

.powerpoint {
  margin-bottom: 20px;
}
</style>