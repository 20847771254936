// Manufacturing - Liability and motor insurance issues and implications
const questionnaire = [
    {
        id: 'a8240431-030e-4642-a657-d64d74fd2d60',
        title: 'Manufacturing - Liability and motor insurance issues and implications',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'An electronics manufacturer supplies components to a range of industries. Which of these would merit special attention in arranging Products Liability cover?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Aircraft navigation systems',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Motor vehicle engine management systems',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Marine navigation systems',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Domestic appliance components',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'A customer claims delay penalties under a contract with a manufacturer. How would the manufacturer’s Public/Products Liability policy respond?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'No cover: delay penalties are excluded',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Covered in full ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Covered in full under standard policy, but with higher excess',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cover not needed as claim would not be upheld by the courts',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'A manufacturer has a large number of trailers, many of which are left in various locations uncoupled from a towing vehicle. What cover should be arranged?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Cover for unspecified trailers in the insured\'s custody or control, attached and detached',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Cover for specified trailers whilst attached to the insured\'s vehicle',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cover for unspecified trailers whilst attached to the insured\'s vehicle ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Add the trailers to the MD policy',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'What is the effect of a retroactive date on a cyber policy liability section?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Claims caused prior to the retroactive date will not be covered',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Claims occurring during the policy period will not be covered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Claims caused after the retroactive date will not be covered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only claims occurring during the policy period will be covered',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Which of the following statements about UK Employers’ Liability insurance is TRUE',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'It cannot have a deductible of any kind',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It cannot have a deductible for each individual loss',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It cannot have a deductible for all losses in the aggregate',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It cannot have a deductible of more than £1000',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'What is the most likely trigger for cyber liability section?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'When the claim is made against the insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'When the cause of the claim occurred',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'When the claim was caused',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'When the cyber incident is discovered',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'Which of these would you not expect to find included in the definition of ‘Employee’ in an Employers’ Liability policy?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Bona fide sub-contractors',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Labour only sub-contractors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Temporary staff from an agency',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Work experience students',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Clients’ workmen installing a machine at a customer’s factory carelessly start a fire. Which of these would you not expect to be part of a Public Liability claim?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Damage to the machine being installed',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Damage to the customer’s factory',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The customer’s loss of production during repairs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Injury to the customer’s employees due to smoke inhalation',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'A manufacturer has a number of fork lift trucks which are only used on the road around the factory occasionally, do they need motor insurance?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Yes- because any vehicle used on a road, regardless of how often, must be insured by an authorised motor insurer for at least the minimum statutory requirements  ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No,  because the Road Traffic Act requires only vehicles that are registered for road use to be insured',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No, because the limited road usage means that there is very little chance of the vehicles being involved in an accident.',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes, because fork lift trucks can have high values and should therefore be covered under a motor policy on a Comprehensive basis  ',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'Under a Public and Products Liability policy, which of these would you expect to find excluded from cover?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Advice provided for a fee',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Advice provided free of charge',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Advice provided when a product is supplied',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Advice provided as part of the sales negotiations for a product',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Which of the following covers would you NOT recommend to a manufacturer of cutlery?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Professional Indemnity',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Public Liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Products Liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Employers’ Liability',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'An Employers’ Liability insurance rate is usually applied to which of these to calculate a premium?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Wageroll',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Turnover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Revenue',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Gross Profit',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'What is the normal basis of indemnity for a total loss under the own damage section of a motor policy ',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'The vehicle’s market value ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The purchase price of the vehicle',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The policyholder’s estimated value of the vehicle',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An agreed value',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'In the context of a cyber policy, what are industrial control systems?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Computers that control production machinery',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Computers found in the office of a manufacturing plant',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Compliance requirements in a production process',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Computers that control security in an industrial plant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'A manufacturer is potentially liable for injury to…?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Anyone who is injured by their product',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Anyone who buys their product',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Anyone who owns their product at any time',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Anyone who uses their product',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ManufacturingLiabilityAndMotorInsuranceIssuesAndImplications = {
    questionnaire
};