<template>
  <div class="row">
    <div class="col welcome">
      <h1>{{ course.title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ['course'],
};
</script>