const module = [
  {
    id: 'a8d8f4ee-4f2c-4820-b9b3-dfbca89844c2',
    slug: 'inspiring-high-performance-from-your-team',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'a8d8f4ee-4f2c-4820-b9b3-dfbca89844c2|d68963e3-4b1b-4af9-9d70-c9399c58d809',
    template: 'video-template',
    title: 'Inspiring high performance from your team when the pressure is on',
    description:
      'Learn what are the characteristics you can adopt to be a high performing leader and why displaying vulnerability is a powerful trait for a leader.',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
    questionnaireId: '',
    contents: {
      id: 'e45f8fe1-229a-4ab0-a1ac-58b44293dea9',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn what are the characteristics you can adopt to be a high performing leader and why displaying vulnerability is a powerful trait for a leader.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Discover the characteristics of a high performing leader</li>' +
        '<li>Harnessing the power of vulnerability as a leader</li>' +
        '<li>How leaders can deal with underperformance</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Inspiring high performance from your team when the pressure is on',
          button: '',
          url: 'https://player.vimeo.com/video/742207809',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Emma Matthews</p>'
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '15'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'intermediate']
  }
];

export const InspiringHighPerformanceFromYourTeamWhenThePressureIsOn = {
  module
};
