const module = [
  {
    id: '37ee44b5-faca-43b7-8dc6-db2f82775517',
    slug: 'introduction-to-social-media',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '37ee44b5-faca-43b7-8dc6-db2f82775517|8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    template: 'video-template',
    title: 'Introducing social media for Commercial Brokers',
    description:
      'An overview of how insurance brokers can start to get the most from social media. This module looks at common mistakes brokers make and provides helpful tips on creating relevant and engaging content.',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS Ltd',
    categoryId: '8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    questionnaireId: '',
    contents: {
      id: '45e12a8d-635a-4233-a646-7f1decfe28d9',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'An overview of how insurance brokers can start to get the most from social media. This module looks at common mistakes brokers make with their social media and provides helpful tips on creating relevant and engaging content.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<div class="row"><div class="col">' +
        '<ul style="margin-top:10px">' +
        '<li>What’s stopping insurance brokers from using social media.</li>' +
        '<li>Where Insurance Brokers go wrong with social media.</li>' +
        '<li>How to get your social media content noticed.</li>' +
        '<li>How to create relevant and engaging content.</li>' +
        '</ul>' +
        '</div><div class="col">' +
        '<ul style="margin-top:10px">' +
        '<li>How to get support from colleagues.</li>' +
        '<li>How to keep your social media activity legal and ethical.</li>' +
        '<li>How to set goals and measure success.</li>' +
        '</ul>' +
        '</div></div>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Marketing - Introducing social media for Commercial Brokers',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/516736787',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Andy Cox - Client Services Manager.</p>' +
              '<p>Brought to you by: Ignition NBS Ltd.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Marketing - Introducing social media for Commercial Brokers',
          button: 'Download Slides',
          fileSize: 4156771,
          url: 'marketing-introducing-social-media-for-commercial-broker.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '18'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'foundation']
  }
];

export const IntroductionToSocialMedia = {
  module
};
