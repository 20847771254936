import moment from 'moment';

export const HelpersMixin = {
  methods: {
    getImageUrl: function(type, id, filename) {
      // let path = '/' + type + 's/' + id + '/images/' + filename;
      let path = 'https://media.brokerwise.pro/' + type + 's/' + id + '/images/' + filename;
      return path;
    },

    // deprecated retired now in media.mixin.js
    getMediaPath(type, id, filename) {
      // let path = '/' + type + 's/' + id + '/media/' + filename;
      let path = 'https://media.brokerwise.pro/' + type + 's/' + id + '/media/' + filename;
      return path;
    },
    // deprecated retired now in media.mixin.js
    showFileSize(fileSize) {
      if (fileSize > 0) {
        return true;
      } else {
        return false;
      }
    },
    // deprecated now in media.mixin.js
    formatFileSize(fileSize) {
      if (fileSize < 1000) {
        return fileSize + ' B';
      } else if (fileSize < 1000000) {
        return (fileSize / 1000).toFixed(0) + ' kB';
      } else if (fileSize < 1000000000) {
        return (fileSize / 1000000).toFixed(2) + ' MB';
      } else if (fileSize < 1000000000000) {
        return (fileSize / 1000000000).toFixed(2) + ' GB';
      } else {
        return fileSize;
      }
      return fileSize;
    },
    //
    getModuleUrl(module) {
      return '/modules/' + module.slug;
    },
    //
    getModuleCompletedDescription(module) {
      if (module.completedDescription == 'TODO') {
        return module.description;
      } else {
        return module.completedDescription;
      }
    },
    formatDateTime(jsDate, dateFormat) {
      //  'certified_at' => '2022 11 07 115859',
      if (dateFormat === 'YmdHis') {
        return moment(jsDate).format('YYYYMMDDHHmmss');
      } else if (dateFormat === 'Y-m-d HH:i:s') {
        return moment(jsDate).format('YYYY-MM-DD HH:mm:ss');
      }

      return false;
    }
  }
};
