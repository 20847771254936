const module = [
  // Work Burnout - 1 - What is work burnout
  {
    id: 'cfe0988f-b3ba-4885-94e4-ee59c18b14a5',
    slug: 'what-is-work-burnout',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'cfe0988f-b3ba-4885-94e4-ee59c18b14a5|32f67180-3d5b-4522-82d8-aec3c6be0971',
    template: 'broker-dev-work-burnout',
    title: 'What is work burnout?',
    description:
      'Burnout can destroy a person’s performance at work and their family life when left unmanaged in the long term. This module is an introduction to the course "Defeating Work Burnout"',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: '32f67180-3d5b-4522-82d8-aec3c6be0971',
    questionnaireId: '',
    contents: {
      id: 'c9c6e07f-1677-4e96-a6b6-796c5c3d1d5b',
      longDescription: '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction to work burnout',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/527273023',
          caption: {
            title: 'Introduction to work burnout',
            description:
              '<p>Delivered by: Emma Matthews - Emma Matthews Consulting</p>' +
              '<p>This content does not constitute medical advice. If you are experiencing a severe health condition, then you should consult a medical professional.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: '??',
          button: 'Download Slides',
          fileSize: 0,
          url: '???.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation']
  }
];
export const WhatIsWorkBurnout = {
  module
};
