<template>
  <div class="questionnaire">
    <h1>Knowledge Quiz</h1>
    <div v-if="!showResult">
      <p>
        Please answer the following questions to confirm you've understood this module and add this
        modules card to your collection.
      </p>
      <div
        class="questions"
        :style="{
          'background-color': this.category.emptyCardBackground,
        }"
      >
        <h1 v-if="debugEnabled" class="temporary">Dev/Debug mode enabled</h1>
        <h2>{{ theQuestionnaire.title }}</h2>
        <span v-if="theQuestionnaire.body" v-html="theQuestionnaire.body"></span>
        <span v-if="debugEnabled && this.module.questionnaireOverride !== undefined">
          PassScore (override): {{ this.module.questionnaireOverride.passScore }} <br />
          Mix: (override):
          {{ this.module.questionnaireOverride.mix }}<br /><br
        /></span>
        <div v-for="(question, qIndex) in theQuestions" v-bind:key="question.id" class="question">
          <h3 v-if="rawQuestionnaire.debug.showQuestionId" class="temporary">
            Question: {{ question.id }}
          </h3>
          <h4 style="margin-bottom: 10px" v-html="question.title"></h4>
          <span v-if="question.body" v-html="question.body"></span>
          <ul>
            <div v-for="(answer, aIndex) in question.answers" v-bind:key="aIndex">
              <h6 v-if="rawQuestionnaire.debug.showAnswerInfo" class="temporary">
                Answer: {{ answer }}
              </h6>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :name="qIndex"
                  :id="uniqueRadio(qIndex, aIndex)"
                  :value="aIndex"
                  v-model="responses[qIndex]"
                />
                <label
                  class="form-check-label"
                  :for="uniqueRadio(qIndex, aIndex)"
                  style="margin-bottom: 8px"
                >
                  <span v-html="answer.title"></span>
                </label>
              </div>
            </div>
          </ul>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="!allQuestionsAnswered"
          @click="submitResponses"
        >
          {{ submitButtonLabel }}
        </button>
      </div>
    </div>
    <div v-else>
      <div v-if="this.passed" class="row passed text-center">
        <div class="col">
          Well done, you've passed this module!
          <br />
          <br />
          <router-link :to="{ name: 'Home' }">
            <button type="button" class="btn btn-info">Go To Your Collection</button>
          </router-link>
        </div>
      </div>
      <div v-else class="row failed">
        <div class="col">
          <h5>
            Sorry, you didn't answer all the questions correctly, we've listed your incorrect
            answers below.
          </h5>
          <br />
          <div
            v-for="incorrectResponse in incorrectResponses"
            v-bind:key="incorrectResponse.question"
          >
            <div class="row">
              <div class="col">
                <p class="incorrect-question" v-html="incorrectResponse.question"></p>
                <p class="incorrect-answer">
                  <span class="icon">X</span>&nbsp;<span v-html="incorrectResponse.answer"></span>
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h5 style="margin-top: 20px; margin-bottom: 20px">
                Please review the module information and try again.
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button type="button" class="btn btn-info" @click="resetQuestionnaire">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.rawQuestionnaire.debug.showJson" class="row temporary">
      <div class="col">
        {{ this.rawQuestionnaire }}
      </div>
    </div>
    <h1 v-if="debugEnabled" class="temporary">Dev/Debug mode enabled</h1>
  </div>
</template>

<script>
import { MetricsMixin } from '@/mixins/metrics.mixin';
import { QuestionnaireContent } from '@/content/questionnaires';

export default {
  props: ['module', 'category'],
  mixins: [MetricsMixin],
  data: function () {
    return {
      rawQuestionnaire: {
        debug: {},
        questions: [],
      },
      theQuestionnaire: {},
      theQuestions: [],
      incorrectResponses: [],
      responses: [],
      score: 0,
      showResult: false,
      passed: false,
    };
  },
  computed: {
    allQuestionsAnswered() {
      for (var i = 0; i < this.responses.length; i++) {
        if (this.responses[i] === null) {
          return false;
        }
      }
      return true;
    },
    submitButtonLabel() {
      if (this.allQuestionsAnswered) {
        return 'Submit Responses';
      }
      return 'Please answer all the questions';
    },
    debugEnabled() {
      if (
        this.rawQuestionnaire.debug.showAllQuestions ||
        this.rawQuestionnaire.debug.showQuestionId ||
        this.rawQuestionnaire.debug.showAnswerInfo ||
        this.rawQuestionnaire.debug.showJson
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    fetchQuestionnaire(questionnaires, id) {
      var i = 0;
      for (i = 0; i < questionnaires.length; i++) {
        if (questionnaires[i].id === id) {
          return questionnaires[i];
        }
      }
    },
    uniqueRadio(firstId, secondId) {
      return firstId + '-' + secondId;
    },
    pickSomeQuestions(rawQuestions, type, pickCount) {
      var j = 0;
      var i = 0;
      for (i = 0; i < rawQuestions.length; i++) {
        if (rawQuestions[i].difficulty === type) {
          if (!this.checkForDuplicates(this.theQuestions, this.rawQuestionnaire.questions[i])) {
            this.theQuestions.push(this.rawQuestionnaire.questions[i]);
            j++;
            if (j >= pickCount) {
              break;
            }
          }
        }
      }
    },
    checkForDuplicates(array, newItem) {
      var i = 0;
      for (i = 0; i < array.length; i++) {
        if (array[i] == newItem) {
          return true;
        } else {
          return false;
        }
      }
    },
    shuffleTheAnswers(questions) {
      var i = 0;
      for (i = 0; i < questions.length; i++) {
        questions[i].answers = this.shuffleArray(questions[i].answers);
      }
      return questions;
    },
    populateResponses(theQuestions) {
      this.responses = [];
      for (var i = 0; i < theQuestions.length; i++) {
        this.responses[i] = null;
      }
    },
    calculateScore(questions, responses) {
      let score = 0;
      this.incorrectResponses = [];

      for (var i = 0; i < questions.length; i++) {
        let questionScore = 0;
        let incorrectResponse = {
          question: '',
          keyLearning: '',
          answer: '',
        };
        if (responses[i] !== null) {
          questionScore = questions[i].answers[responses[i]].score;
          score += questionScore;
          if (questionScore === 0) {
            incorrectResponse.question = questions[i].title;
            incorrectResponse.keyLearning = questions[i].keyLearning;
            incorrectResponse.answer = questions[i].answers[responses[i]].title;
            this.incorrectResponses.push(incorrectResponse);
          }
        }
      }

      return score;
    },
    setUpQuestionnaire() {
      this.showResult = false;
      this.rawQuestionnaire = this.fetchQuestionnaire(
        QuestionnaireContent.questionnaires,
        this.module.questionnaireId
      );

      if (this.rawQuestionnaire) {
        this.theQuestionnaire = this.rawQuestionnaire;

        this.theQuestions = [];
        let shuffledQuestions = [];

        if (this.rawQuestionnaire.debug.showAllQuestions) {
          this.theQuestions = this.rawQuestionnaire.questions;
        } else {
          shuffledQuestions = this.shuffleArray(this.rawQuestionnaire.questions);
          // set default question mix
          var questionnaireMix = {
            easy: 2,
            medium: 2,
            hard: 1,
          };

          // override question mix if required (cpd related)
          if (this.module.questionnaireOverride !== undefined) {
            questionnaireMix = this.module.questionnaireOverride.mix;
          }

          this.pickSomeQuestions(shuffledQuestions, 'hard', questionnaireMix.hard);
          this.pickSomeQuestions(shuffledQuestions, 'medium', questionnaireMix.medium);
          this.pickSomeQuestions(shuffledQuestions, 'easy', questionnaireMix.easy);
          this.populateResponses(this.theQuestions);
          this.shuffleArray(this.theQuestions);
          this.theQuestions = this.shuffleTheAnswers(this.theQuestions);
        }
      } else {
        this.theQuestionnaire = {};
        this.theQuestionnaire.title = 'No questionnaire found';
      }
    },
    resetQuestionnaire() {
      this.rawQuestionnaire = {};
      this.theQuestionnaire = {};
      this.theQuestions = [];
      this.responses = [];
      this.score = 0;
      this.passed = false;
      this.showResult = false;
      this.setUpQuestionnaire();
    },
    submitResponses() {
      if (this.allQuestionsAnswered) {
        this.score = this.calculateScore(this.theQuestions, this.responses);
        this.metricWrite('questionnaire', {
          event: 'submit',
          slug: this.module.slug,
          questionnaire: this.rawQuestionnaire.id,
          score: this.score,
        });

        // set default pass score
        var passScore = this.rawQuestionnaire.passScore;

        // override pass score if required (cpd related)
        if (this.module.questionnaireOverride !== undefined) {
          passScore = this.module.questionnaireOverride.passScore;
        }

        if (this.score >= passScore) {
          this.passed = true;
          this.module.status.state = 'finished';
          this.$eventBus.$emit('set-module-finished');
          this.$eventBus.$emit('show-module-complete-message');
          //
          this.metricWrite('questionnaire', {
            event: 'pass',
            slug: this.module.slug,
            questionnaire: this.rawQuestionnaire.id,
            score: this.score,
          });

          this.$gtag.event('submitted_responses_passed', {
            event_category: 'questionnaires',
            event_label: this.rawQuestionnaire.id,
            value: 1,
          });
        } else {
          this.passed = false;
          this.metricWrite('questionnaire', {
            event: 'failed',
            slug: this.module.slug,
            questionnaire: this.rawQuestionnaire.id,
            score: this.score,
          });

          this.$gtag.event('submitted_responses_failed', {
            event_category: 'questionnaires',
            event_label: this.rawQuestionnaire.id,
            value: 1,
          });
        }
        this.showResult = true;
      } else {
        this.showResult = false;
      }
    },
    shuffleArray(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
  },
  mounted() {
    this.setUpQuestionnaire();
  },
};
</script>

<style scoped>
.questionnaire {
}
.questionnaire .temporary {
  background: repeating-linear-gradient(45deg, #fff2f2, #fff2f2 10px, #ffffff 10px, #ffffff 20px);
  padding: 10px;
  font-weight: 400;
  border-color: red;
  border-width: 1px;
  border-style: dashed;
}

.questionnaire h1 {
  margin-top: 20px;
  font-size: 30px;
}

.questionnaire h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.questions {
  padding: 20px;
  border-radius: 10px;
}

.questionnaire .question h4 {
  font-size: 20px;
}

.questionnaire .form-check-label {
  font-size: 16px;
}

.failed {
  margin-top: 20px;
  padding: 30px;
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(248, 214, 220);
  border-radius: 10px;
}
.incorrect-question {
  font-size: 18px;
  margin-bottom: 0px;
}

.incorrect-key-learning {
  font-size: 16px;
  font-weight: 400;
}
.incorrect-answer {
  font-size: 16px;
  padding-left: 20px;
  color: rgb(170, 12, 12);
}

.incorrect-answer .icon {
  font-size: 22px;
  color: red;
  font-weight: 800;
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.passed {
  margin: 20px;
  padding: 30px;
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(218, 253, 218);
  border-radius: 10px;
}

.btn-info {
  background-color: #efefef;
  color: #333;
}

.btn-info:hover {
  background-color: #dfdfdf;
  color: #333;
}
</style>
