// Questionnaire
const questionnaire = [
  {
    id: 'ef78a951-1c8c-436c-8b04-28d48c7a6504',
    title: 'Property insurance: key cover issues in property owners’ policies',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'Why does a broker need to review any inner limits in cover clauses?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'hard',
        answers: [
          {
            title: 'To ensure they are adequate for the client',
            body: '',
            score: 1.0
          },
          {
            title: 'To ensure they are correct on the schedule',
            body: '',
            score: 0
          },
          {
            title: 'To ensure they are not included in the sum insured calculation',
            body: '',
            score: 0
          },
          {
            title: 'To ensure they are included in the sum insured calculation',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'Which of the following perils is the most likely to not be automatically covered in a real estate policy?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'hard',
        answers: [
          {
            title: 'Subsidence',
            body: '',
            score: 1.0
          },
          {
            title: 'Flood',
            body: '',
            score: 0
          },
          {
            title: 'Riot',
            body: '',
            score: 0
          },
          {
            title: 'Earthquake',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'What cover does the Failure of Third Party Insurance clause provide?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'hard',
        answers: [
          {
            title: 'It provides cover for the insured where a third party that has an obligation under the lease to insure the buildings fails to arrange such insurance',
            body: '',
            score: 1.0
          },
          {
            title: 'It provides cover for the insured where a third party fails to arrange their own insurance',
            body: '',
            score: 0
          },
          {
            title: 'It provides cover for the insured where a third party causes damage and has no liability insurance in place',
            body: '',
            score: 0
          },
          {
            title: 'It provides cover for the insured where a third party breaches the policy conditions',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'What is an average waiver condition?',
        body: '',
        keyLearning: 'Causes of and solutions for underinsurance',
        difficulty: 'hard',
        answers: [
          {
            title: 'A condition that waives the application of average if insured has had a regular professional insurance valuation of all premises and increased the sum insured to match the valuations',
            body: '',
            score: 1.0
          },
          {
            title: 'A condition that waives the application of average if insured requests this',
            body: '',
            score: 0
          },
          {
            title: 'A condition that allows the tenant to request a waiver of the condition of average if they agree to pay for an increased sum insured',
            body: '',
            score: 0
          },
          {
            title: 'A condition that waives the application of average if insured regularly increases the sum insured',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'Which of the following is a most likely to be a condition in a real estate property policy relating to unoccupied premises?',
        body: '',
        keyLearning: 'Vacant properties/unoccupancy – cover, issues and risk management',
        difficulty: 'hard',
        answers: [
          {
            title: 'The premises must be inspected at least once a week',
            body: '',
            score: 1.0
          },
          {
            title: 'The stock must be kept at least six inches off the floor',
            body: '',
            score: 0
          },
          {
            title: 'Fire extinguishers must be maintained',
            body: '',
            score: 0
          },
          {
            title: 'The building must be of standard construction',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'What is the purpose of the Illegal cultivation of drugs clause?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'medium',
        answers: [
          {
            title: 'To provide clean up and repair costs if the premises have been used for the manufacture, cultivation, harvesting or processing of illegal drugs',
            body: '',
            score: 1.0
          },
          {
            title: 'To provide costs to amend the  premises so that it can be used for the manufacture, cultivation, harvesting or processing of illegal drugs',
            body: '',
            score: 0
          },
          {
            title: 'To provide cover for  security costs to prevent the premises being used for the manufacture, cultivation, harvesting or processing of illegal drugs',
            body: '',
            score: 0
          },
          {
            title: 'To provide cover for legal liability defence costs if the premises has been used for the manufacture, cultivation, harvesting or processing of illegal drugs',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title: 'What cover does a Munitions of War clause cover?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'medium',
        answers: [
          {
            title: 'Damage to buildings caused by the detonation of weapons and ammunition from war ',
            body: '',
            score: 1.0
          },
          {
            title: 'Damage to buildings caused when the country is at war',
            body: '',
            score: 0
          },
          {
            title: 'An exclusion of damage to buildings caused by the detonation of weapons and ammunition from war ',
            body: '',
            score: 0
          },
          {
            title: 'Damage to buildings caused by terrorism',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'Why is understanding the definition of buildings in the real estate policy important to avoid underinsurance?',
        body: '',
        keyLearning: 'Causes of and solutions for underinsurance',
        difficulty: 'medium',
        answers: [
          {
            title: 'So insured can ensure everything within definition is included in the calculation of the sum insured',
            body: '',
            score: 1.0
          },
          {
            title: 'So insured can ensure building is of standard construction',
            body: '',
            score: 0
          },
          {
            title: 'So insured can identify extensions needed',
            body: '',
            score: 0
          },
          {
            title: 'So insured can ensure correct premium has been charged',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'Why might insurers reduce/delete the automatic Capital Additions limit for unoccupied premises?',
        body: '',
        keyLearning: 'Vacant properties/unoccupancy – cover, issues and risk management',
        difficulty: 'medium',
        answers: [
          {
            title: 'To avoid large empty premises being added to the cover, without their prior agreement',
            body: '',
            score: 1.0
          },
          {
            title: 'To enable a higher additional premium to be charged ',
            body: '',
            score: 0
          },
          {
            title: 'To ensure flood risk is managed',
            body: '',
            score: 0
          },
          {
            title: 'To avoid accumulation of risk in a postcode',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'How long does a premises need to have been empty/unoccupied to trigger unoccupancy conditions?',
        body: '',
        keyLearning: 'Vacant properties/unoccupancy – cover, issues and risk management',
        difficulty: 'medium',
        answers: [
          {
            title: 'It will depend on the policy definition of empty/unoccupied',
            body: '',
            score: 1.0
          },
          {
            title: '15 days',
            body: '',
            score: 0
          },
          {
            title: '5 days',
            body: '',
            score: 0
          },
          {
            title: '60 days',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'What is the purpose of a Capital Additions clause?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'easy',
        answers: [
          {
            title: 'It provides automatic cover for a set period for new premises, alterations, additions and improvements that increase the sum insured ',
            body: '',
            score: 1.0
          },
          {
            title: 'It provides automatic cover for a set period for any new subsidiaries',
            body: '',
            score: 0
          },
          {
            title: 'It provides automatic cover for a set period for premises outside the usual territorial limits',
            body: '',
            score: 0
          },
          {
            title: 'It provides additional capital to fund risk improvements',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title: 'What cover does a Trace and Access Clause provide?',
        body: '',
        keyLearning: 'Key clauses in property sections of property owners\’ policies',
        difficulty: 'easy',
        answers: [
          {
            title: 'The costs that insured incurs in finding the source of certain types of damage and repairing it',
            body: '',
            score: 1.0
          },
          {
            title: 'The costs that insured incurs in tracing third parties that have caused damage',
            body: '',
            score: 0
          },
          {
            title: 'The costs that insured incurs in finding the cause of a fire',
            body: '',
            score: 0
          },
          {
            title: 'The costs the insured incurs in tracing tenants that have left with rent arrears',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'Why might underinsurance issues increase when the insurance market is \'hard\'?',
        body: '',
        keyLearning: 'Causes of and solutions for underinsurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'Insured seeks to reduce costs by not having regular insurance valuations carried out',
            body: '',
            score: 1.0
          },
          {
            title: 'Insured wants to keep tenants happy by not increasing premiums',
            body: '',
            score: 0
          },
          {
            title: 'Tenant insolvencies increase',
            body: '',
            score: 0
          },
          {
            title: 'Insurers\' capacity reduces',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'Which risks are increased in an unoccupied premises?',
        body: '',
        keyLearning: 'Vacant properties/unoccupancy – cover, issues and risk management',
        difficulty: 'easy',
        answers: [
          {
            title: 'Fire (arson), escape of water and malicious damage ',
            body: '',
            score: 1.0
          },
          {
            title: 'Theft of contents',
            body: '',
            score: 0
          },
          {
            title: 'Storm, tempest and flood',
            body: '',
            score: 0
          },
          {
            title: 'Riot',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'Why do brokers need to tell insurers if a property becomes vacant , even if there is no policy condition requiring notification?',
        body: '',
        keyLearning: 'Vacant properties/unoccupancy – cover, issues and risk management',
        difficulty: 'easy',
        answers: [
          {
            title: 'Unoccupancy increases the risk for insurers',
            body: '',
            score: 1.0
          },
          {
            title: 'Unoccupancy decreases the risk for insurers',
            body: '',
            score: 0
          },
          {
            title: 'Unoccupancy means insurers need to increase their reinsurance protection',
            body: '',
            score: 0
          },
          {
            title: 'Unoccupancy means insurers will cancel the policy',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
]

export const RealEstatePropertyInsuranceKeyCoverIssuesInPropertyOwnersPolicies = {
  questionnaire
};