const module = [
  {
    id: '933c8eeb-07cf-41df-93cd-ff16ee3fbaf6',
    slug: 'impactful-communications-and-rapport',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '0634f0ad-b1be-4a8d-8975-dd46dee3742d|933c8eeb-07cf-41df-93cd-ff16ee3fbaf6',
    template: 'video-template',
    title: 'Impactful communications and rapport',
    description:
      'Understand how remote working practices affect traditional communication and how you can adapt your approach to build rapport in a virtual environment',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '0634f0ad-b1be-4a8d-8975-dd46dee3742d',
    questionnaireId: '',
    contents: {
      id: 'f45aa962-16e4-4dd4-8117-d417897d8666',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand how remote working practices affect traditional communication and how you can adapt your approach to build rapport in a virtual environment' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>To enable Brokers to communicate impactfully in the virtual world</li>' +
        '<li>Understand the key aspects of communication and rapport building</li>' +
        '<li>Understand how the shift to remote working practices affects traditional communication</li>' +
        '<li>Understand how to adapt your approach to building rapport in a virtual environment</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Impactful Communication in a Virtual Trading Environment - Session Two',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505202534',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Lorna Reeves & Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Impactful Communication in a Virtual Trading Environment - Session Two',
          button: 'Download Slides',
          fileSize: 6206064,
          url: 'impactful-communications-and-rapport.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://urldefense.com/v3/__https:/eur04.safelinks.protection.outlook.com/?url=https*3A*2F*2Fwww2.axa-insurance.co.uk*2Fe*2F471271*2Fal-Impact---Module-2---V3-pptx*2F3l5tv4*2F427588571*3Fh*3DrQ1a164e3AmUFjXog2TZNGDpcVwMrxcitJXPSAAdVAA&data=04*7C01*7C*7C78df0081bac94b0ab47708d88d4acce7*7C84df9e7fe9f640afb435aaaaaaaaaaaa*7C1*7C0*7C637414699684316219*7CUnknown*7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0*3D*7C1000&sdata=*2F*2FdL*2FLgaid*2BZwzIS7iDV1SiQMXUVYokLT6HQTiDX0Eg*3D&reserved=0__;JSUlJSUlJSUlJSUlJSUlJSUlJSUlJSUlJSU!!BnkV9pdh5V0!TiHdGbe9C2paBi8pDUp7B_5JFCKsmFIyL2jtXgvw0SXN2P_Ei3Zk8OSxymfH4DPnqG4QgfOctc0a$'
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 2
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'sales', 'intermediate']
  }
];

export const ImpactfulCommunicationsAndRapport = {
  module
};
