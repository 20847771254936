<template>
  <div>
    <div v-if="showIframePanel">
      <div class="close-button">
        <button type="button" class="btn btn-light btn-sm" @click="hideModuleIframe">CLOSE</button>
      </div>
      <div class="iframe-panel">
        <div class="row iframe-body">
          <div class="col">
            <iframe :src="module.contents.media[0].url" border="0"> </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['module'],
  data() {
    return {
      showIframePanel: false,
    };
  },
  methods: {
    showModuleIframe() {
      // Hides the main scrollbars to reduce confusion for the users.
      // They are put back on close or if the user navigates away from the page see: App.vue
      document.body.classList.add('hide-scrollbar');
      this.showIframePanel = true;
    },
    hideModuleIframe() {
      document.body.classList.remove('hide-scrollbar');
      this.showIframePanel = false;
    },
  },
  created() {
    this.$eventBus.$on('show-module-iframe', this.showModuleIframe);
    this.$eventBus.$on('hide-module-iframe', this.hideModuleIframe);
  },
  beforeDestroy() {
    this.$eventBus.$off('show-module-iframe', this.showModuleIframe);
    this.$eventBus.$off('hide-module-iframe', this.hideModuleIframe);
  },
};
</script>

<style scoped>
.iframe-panel {
  position: fixed;
  top: 74px;
  left: 0;
  width: 100%;
  z-index: 9998;
  display: table;
  height: 800px;
}

.iframe-body {
  min-height: 100% !important;
}

iframe {
  height: 800px;
  width: 100%;
  box-shadow: none;
  border: none;
  padding: 0px;
  background-color: #fff;
}

.close-button {
  z-index: 9999;
  position: fixed;
  top: 90px;
  right: 30px;
}
</style>