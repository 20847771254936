// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '130e320d-eb1b-46b7-9b0e-2834bce3a359',
    title: 'Income protection: loss of rent and alternative accommodation',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'Why does the rent cessation clause in a lease affect the indemnity period needed in the real estate rental income insurance?',
        body: '',
        keyLearning: 'Factors to consider when assessing the indemnity period',
        difficulty: 'hard',
        answers: [
          {
            title: 'The clause usually states the circumstances and length of time the tenants can avoid paying rent after damage to the premises has occurred',
            body: '',
            score: 1.0
          },
          {
            title: 'The clause details the maximum indemnity period required under the insurance',
            body: '',
            score: 0
          },
          {
            title: 'The clause requires the tenants to continue paying rent even if the premises is uninhabitable by the tenant',
            body: '',
            score: 0
          },
          {
            title: 'The clause has no affect on the indemnity period needed',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'What is a rent cessation clause?',
        body: '',
        keyLearning: 'Factors to consider when assessing the indemnity period',
        difficulty: 'hard',
        answers: [
          {
            title: 'A clause in a lease that allows the tenants to stop paying rent',
            body: '',
            score: 1.0
          },
          {
            title: 'A clause in the real estate policy that deletes rent cover for empty premises',
            body: '',
            score: 0
          },
          {
            title: 'A clause in the lease that means the tenants have to pay rent even when the premises is uninhabitable',
            body: '',
            score: 0
          },
          {
            title: 'A clause in the real estate policy that adds cover for additional rent payable by tenants',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'What is the benefit of the Rent Free Cover clause in the real estate policy?',
        body: '',
        keyLearning: 'Key rent cover extensions available',
        difficulty: 'hard',
        answers: [
          {
            title: 'The clause extends the maximum indemnity period by the unused part of the rent free period',
            body: '',
            score: 1.0
          },
          {
            title: 'The clause increases the sum insured to include the rent that would have been paid had a rent free period not been in place',
            body: '',
            score: 0
          },
          {
            title: 'The clauses reduces the maximum indemnity period by the unused part of the rent free period',
            body: '',
            score: 0
          },
          {
            title: 'The clause allows the insured to offer tenants a rent free period',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'Why is the Boiler Explosion clause needed?',
        body: '',
        keyLearning: 'Key rent cover extensions available',
        difficulty: 'hard',
        answers: [
          {
            title: 'To provide cover for all boiler explosions, not just non-steam and domestic boilers',
            body: '',
            score: 1.0
          },
          {
            title: 'To exclude cover for boiler explosion from the policy',
            body: '',
            score: 0
          },
          {
            title: 'To provide cover for boiler explosions occurring in neighbouring premises',
            body: '',
            score: 0
          },
          {
            title: 'To provide cover for explosions of a tenants\' boiler',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'What is \'stepped rent\'?',
        body: '',
        keyLearning: 'Issues to consider when arranging loss of rent cover',
        difficulty: 'hard',
        answers: [
          {
            title: 'Rent that will increase progressively with specific amounts on pre-determined dates',
            body: '',
            score: 1.0
          },
          {
            title: 'Rent that will increase',
            body: '',
            score: 0
          },
          {
            title: 'Rent for to allow use of common parts of the building',
            body: '',
            score: 0
          },
          {
            title: 'Rent that will increase progressively when leases are renewed',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'Which of the following is a factor to consider when advising on the correct maximum indemnity period under the rental income section of the real estate policy?',
        body: '',
        keyLearning: 'Factors to consider when assessing the indemnity period',
        difficulty: 'medium',
        answers: [
          {
            title: 'Time it would take to regain the full building occupancy rate',
            body: '',
            score: 1.0
          },
          {
            title: 'Time it would take for the tenants\' business to recover from the interruption',
            body: '',
            score: 0
          },
          {
            title: 'The date the policy period begins',
            body: '',
            score: 0
          },
          {
            title: 'Time it would take for the rent free period to end',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title: 'What type of costs are covered under a Buildings Awaiting Sale clause?',
        body: '',
        keyLearning: 'Key rent cover extensions available',
        difficulty: 'medium',
        answers: [
          {
            title: 'Additional costs incurred because a building sale is cancelled or delayed due to occurrence of insured damage at the building',
            body: '',
            score: 1.0
          },
          {
            title: 'Additional debris removal costs incurred to speed up rebuilding',
            body: '',
            score: 0
          },
          {
            title: 'Additional cost incurred to sell a damage property ',
            body: '',
            score: 0
          },
          {
            title: 'Additional costs incurred because a building sale is needed following an occurrence of insured damage at the building',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'Why should the rental income sum insured be higher than the actual rent at the start of the policy period?',
        body: '',
        keyLearning: 'Issues to consider when arranging loss of rent cover',
        difficulty: 'medium',
        answers: [
          {
            title: 'To allow for the rent increases that occur during the policy period ',
            body: '',
            score: 1.0
          },
          {
            title: 'To allow for rent free periods',
            body: '',
            score: 0
          },
          {
            title: 'To allow for turnover rent',
            body: '',
            score: 0
          },
          {
            title: 'To allow for service charges',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'Why is turnover rent more difficult to insure?',
        body: '',
        keyLearning: 'Issues to consider when arranging loss of rent cover',
        difficulty: 'medium',
        answers: [
          {
            title: 'Because it is variable',
            body: '',
            score: 1.0
          },
          {
            title: 'Because it is higher than standard rent',
            body: '',
            score: 0
          },
          {
            title: 'Because it depends on insured\'s turnover',
            body: '',
            score: 0
          },
          {
            title: 'Because it is not included in the standard policy rent definition',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'What types of rent should be insured under a real estate policy?',
        body: '',
        keyLearning: 'Issues to consider when arranging loss of rent cover',
        difficulty: 'medium',
        answers: [
          {
            title: 'Basic rent, service charge rent and insurance premium rent',
            body: '',
            score: 1.0
          },
          {
            title: 'Basic rent only',
            body: '',
            score: 0
          },
          {
            title: 'Service charge rent only',
            body: '',
            score: 0
          },
          {
            title: 'Insurance premium rent and service charge rent',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'When does an indemnity period end?',
        body: '',
        keyLearning: 'Factors to consider when assessing the indemnity period',
        difficulty: 'easy',
        answers: [
          {
            title: 'At the time the business ceases to be affected by the damage or at the end of the maximum indemnity period in the policy',
            body: '',
            score: 1.0
          },
          {
            title: 'At the time the damaged premises have been rebuilt',
            body: '',
            score: 0
          },
          {
            title: 'At the time the damaged premises have been relet',
            body: '',
            score: 0
          },
          {
            title: 'At the end of the maximum indemnity period in the policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title: 'When does an indemnity period start?',
        body: '',
        keyLearning: 'Factors to consider when assessing the indemnity period',
        difficulty: 'easy',
        answers: [
          {
            title: 'At the time the damage that causes the interruption occurs',
            body: '',
            score: 1.0
          },
          {
            title: 'At the time the tenants affected by the damage move out',
            body: '',
            score: 0
          },
          {
            title: 'At the time the rent stops being paid',
            body: '',
            score: 0
          },
          {
            title: 'At inception of the policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'What does the Failure of Public Utility extension cover?',
        body: '',
        keyLearning: 'Key rent cover extensions available',
        difficulty: 'easy',
        answers: [
          {
            title: 'Loss of rental income following interruption to the business caused by insured damage occurring at the premises of the insured\'s utility providers',
            body: '',
            score: 1.0
          },
          {
            title: 'Loss of rental income following interruption to the business caused by any damage occurring at the premises of the insured\'s utility providers',
            body: '',
            score: 0
          },
          {
            title: 'Loss to the tenants following interruption to the utilities caused by any damage occurring at the premises of the insured\'s utility providers',
            body: '',
            score: 0
          },
          {
            title: 'Loss of rental income following interruption to the business caused by any damage occurring at the premises of the tenants\' utility providers',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'What is turnover rent?',
        body: '',
        keyLearning: 'Issues to consider when arranging loss of rent cover',
        difficulty: 'easy',
        answers: [
          {
            title: 'Rent linked to turnover achieved by the tenant\’s business',
            body: '',
            score: 1.0
          },
          {
            title: 'Rent linked to turnover achieved by the property owners\' business',
            body: '',
            score: 0
          },
          {
            title: 'Rent that applies to temporary use of common parts',
            body: '',
            score: 0
          },
          {
            title: 'Rent linked to the retail price index',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'Which of the following expenses might be charged to tenants within the service charge rent?',
        body: '',
        keyLearning: 'Issues to consider when arranging loss of rent cover',
        difficulty: 'easy',
        answers: [
          {
            title: 'Building maintenance charges',
            body: '',
            score: 1.0
          },
          {
            title: 'Insurance premiums for tenant\'s contents',
            body: '',
            score: 0
          },
          {
            title: 'The tenants\' cleaning costs ',
            body: '',
            score: 0
          },
          {
            title: 'The costs tenants incur in fitting out',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
]

export const RealEstateIncomeProtectionLossOfRentAndAlternativeAccommodation = {
  questionnaire
};