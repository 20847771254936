const module = [
  {
    id: 'f84e9463-fc90-48f2-9b06-abd93e50b9fa',
    slug: 'employee-series-effective-1-2-1s-and-appraisals',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f84e9463-fc90-48f2-9b06-abd93e50b9fa|f7673de3-978f-4260-9f29-46f2c6700c0b',
    template: 'actus-iframed-template',
    title: 'Effective 1-2-1s and appraisals',
    description:
      'When working remotely, it’s important to plan your 121’s effectively so you can demonstrate your productivity and performance confidently.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: 'f7673de3-978f-4260-9f29-46f2c6700c0b',
    questionnaireId: '',
    contents: {
      id: 'e490076b-6ba2-430c-980f-a87970607f6e',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>When working remotely it’s important that you plan effectively for your 121’s and appraisals so you can be feel confident that you’re able to demonstrate your productivity and performance whilst working remotely. </p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your learning outcomes for this module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Feel better able to demonstrate your productivity and performance when working remotely</li>' +
        '<li>Have more confidence about your role during a remote appraisal or 1 to 1</li>' +
        '<li>Understand the importance of 1 to 1’s in a remote environment</li>' +
        '<li>Know how to better prepare for remote appraisal success</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/f84e9463-fc90-48f2-9b06-abd93e50b9fa/media/actus-hybrid-employee-series-effective-1-to-1-s-and-appraisals-web-v1/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management', 'intermediate']
  }
];

export const Effective121sAndAppraisals = {
  module
};
