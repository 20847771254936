const module = [
  {
    id: '096bb77e-f692-4cbc-ac2d-625e50fe93a6',
    slug: 'mind-health-and-wellbeing-at-work',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '096bb77e-f692-4cbc-ac2d-625e50fe93a6|3038339a-ac33-42da-bf74-7dd66270aed5',
    template: 'video-template',
    title: 'Mind Health and Wellbeing at work',
    description:
      'You’ll learn what mental health and wellbeing looks like in the workplace and the important role you can play in making sure your clients are well covered.',
    completedDescription: 'TODO',
    supplier: 'AXA Health',
    categoryId: '3038339a-ac33-42da-bf74-7dd66270aed5',
    questionnaireId: '',
    contents: {
      id: '57f7db51-7a8f-4fb8-bbef-90c231ed4773',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Mental health and wellbeing support is important in any organisation and we’ll showcase the ever-changing nature of mental wellbeing in the workplace, how it’s importance can’t be underestimated. You’ll understand what role you can place in making sure your clients are well covered.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>The importance of mental wellbeing support</li>' +
        '<li>What does mental wellbeing at work look like today?</li>' +
        '<li>What role  providers, advisers and employers must play</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Mind Health and Wellbeing at work',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/782513700',
          caption: {
            title: 'Mind Health and Wellbeing at work',
            description: '<p>Delivered by: AXA Health</p>'
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '36'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'skills', 'foundation']
  }
];

export const MindHealthAndWellbeingAtWork = {
  module
};
