const module = [
  {
    id: '3879b79c-87f8-4c9d-9464-947edff9f596',
    slug: 'your-personal-effectiveness-in-a-hard-market',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b|3879b79c-87f8-4c9d-9464-947edff9f596',
    template: 'video-template',
    title: 'Your personal effectiveness in a hard market',
    description:
      'Develop skills and methodologies to enable you to maximise delivery of trading strategies and build your personal effectiveness in a hard market',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b',
    questionnaireId: '',
    contents: {
      id: '583d5a18-0050-40bb-b654-f9ca85ed0f5f',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Develop skills and methodologies to enable you to maximise delivery of trading strategies and build your personal effectiveness in a hard market' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Develop a range of business skills and methodologies to enable you to maximise delivery of hard market trading strategies</li>' +
        '<li>Tailor these skills to address typical hard market challenges</li>' +
        '<li>Build your personal effectiveness in a hard market</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title:
            'Thriving in a Hard Market - Webinar 3 - You personal Effectiveness in a Hard Market',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505200514',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas & Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title:
            'Thriving in a Hard Market - Webinar 3 - You personal Effectiveness in a Hard Market',
          button: 'Download Slides',
          fileSize: 7511134,
          url: 'your-personal-effectiveness-in-a-hard-market.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www2.axa-insurance.co.uk/e/471271/lides-for-Webinar-3-final-pptx/3l2ppz/415253605?h=6V72L71As0d_1j3_ZPWdqBl-4UoIACNbwltRT3kldcA'
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const YourPersonalEffectivenessInAHardMarket = {
  module
};
