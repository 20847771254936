// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '70c65d83-5c63-4a35-aa4c-e96c83168de8',
    title: 'Mergers And Acquisitions Insurance Issues',
    body: '',
    keyLearning: 'Learning Validation',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title: 'What cover does Prospectus Liability Insurance provide?',
        body: '',
        keyLearning: 'Insurance products that may be used in an M&A',
        difficulty: 'hard',
        answers: [
          {
            title:
              'Prospectus Liability Insurance provides cover for the liabilities incurred by directors  in a public offering of shares (IPO)',
            body: '',
            score: 1.0
          },
          {
            title:
              'Prospectus Liability Insurance provides cover for the liabilities incurred by directors in selling a subsidiary',
            body: '',
            score: 0
          },
          {
            title:
              'Prospectus Liability Insurance provides cover for the liabilities incurred by directors in buying a new subsidiary',
            body: '',
            score: 0
          },
          {
            title:
              'Prospectus Liability Insurance provides cover for the liabilities incurred by directors  when selling company assets',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'Which of the following statements is <strong>correct?</strong>',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'hard',
        answers: [
          {
            title:
              'When a new business acquisition is added to a D&O policy its directors will be covered under that policy for their activities after the acquisition only',
            body: '',
            score: 1.0
          },
          {
            title:
              'When a new business acquisition is added to a D&O policy its directors will be covered under that policy for their past activities only',
            body: '',
            score: 0
          },
          {
            title:
              'When a new business is acquired, the purchasing company must cancel and replace its existing D&O policy with a new D&O policy ',
            body: '',
            score: 0
          },
          {
            title:
              'When a new business acquisition is added to a D&O policy its directors will be covered under that policy for their past and future activities ',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: "Why might a retrospective employers' liability extension be required?",
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'hard',
        answers: [
          {
            title:
              'A business has acquired liability for employees transferred to them, but there is no prior EL insurance covering injury/disease caused prior to the transfer',
            body: '',
            score: 1.0
          },
          {
            title: 'Cover for all accidents/disease caused prior to the policy period is needed',
            body: '',
            score: 0
          },
          {
            title: 'A business has acquired liability for employees transferred to them',
            body: '',
            score: 0
          },
          {
            title: 'Employees have previously been exposed to asbestos',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'What does a contracted purchasers’ clause in a property policy do?',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'hard',
        answers: [
          {
            title:
              'It adds the interest of contracted purchasers of buildings  to the insurance of the buildings in the property policy',
            body: '',
            score: 1.0
          },
          {
            title:
              'It adds to the building cover buildings that the business are contracted to purchase',
            body: '',
            score: 0
          },
          {
            title: 'It increases the perils covered to those required by contracting purchasers',
            body: '',
            score: 0
          },
          {
            title:
              'It adds the interest of contracted purchasers of businesses to the property policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title:
          'In an asset only deal, will the insurance policies transfer to the new owner of the asset?',
        body: '',
        keyLearning: 'Understand the various types of M&A clients may be involved with',
        difficulty: 'hard',
        answers: [
          {
            title:
              'No - insurance policies are not assets and are not assignable without insurers’ agreement',
            body: '',
            score: 1.0
          },
          {
            title:
              'Yes - provided the insurers are notified about the change of owner after the transaction',
            body: '',
            score: 0
          },
          {
            title:
              'No - unless the insurers are notified about the change of owner in advance of the transaction',
            body: '',
            score: 0
          },
          {
            title:
              'Yes - provided the insurance policies are listed on the asset register and included in the transaction',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'Which of the following statements is <strong>incorrect?</stong>',
        body: '',
        keyLearning: 'Insurance products that may be used in an M&A',
        difficulty: 'medium',
        answers: [
          {
            title:
              'All warranties in a sale and purchase agreement can be insured under a Warranty and Indemnity Policy',
            body: '',
            score: 1.0
          },
          {
            title:
              'Not all warranties in a sale and purchase agreement can be insured under a Warranty and Indemnity Policy',
            body: '',
            score: 0
          },
          {
            title: 'A Warranty and Indemnity Policy can be bought by either a buyer or a seller ',
            body: '',
            score: 0
          },
          {
            title:
              'A Warranty and Indemnity Policy can be used to increase a warranty cap or extend the time limit on warranty claims provided under a sale and purchase agreement',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title:
          'What costs should be included in the estimated current cost of risk provided to buyers?',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Premiums, estimated cost of retained losses  (including those within excess/retention, uninsured contractual liability, exposure in excess of bought limits), expenses, fees, taxes ',
            body: '',
            score: 1.0
          },
          {
            title: 'Premiums and taxes',
            body: '',
            score: 0
          },
          {
            title: 'Premiums, taxes and broker fees',
            body: '',
            score: 0
          },
          {
            title: 'Premiums and broker fees',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'What is the trigger on most products liability policies?',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'medium',
        answers: [
          {
            title: 'Occurrence - when the product causes injury/damage',
            body: '',
            score: 1.0
          },
          {
            title: 'Occurrence - when the faulty product is made ',
            body: '',
            score: 0
          },
          {
            title: 'Occurrence - when the product that causes injury/damage is sold',
            body: '',
            score: 0
          },
          {
            title: 'Occurrence - when the product that causes the injury or damage is first used',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'What is the effect of a change of control clause in an insurance policy?',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'medium',
        answers: [
          {
            title:
              'A clause in a policy that will automatically cancel cover in the event of a change of control (as defined in policy) of the insured business',
            body: '',
            score: 1.0
          },
          {
            title:
              'A clause in a policy that will automatically provide additional cover in the event of a change of control (as defined in policy) of the insured business',
            body: '',
            score: 0
          },
          {
            title:
              'A clause in a policy that will automatically cancel cover in the event of a business entering into insolvency',
            body: '',
            score: 0
          },
          {
            title:
              'A clause in a policy that will automatically provide cover for businesses acquired by the insured',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title:
          'In what type of deal are all liabilities of the sold entity usually transferred to the new owner?',
        body: '',
        keyLearning: 'Understand the various types of M&A clients may be involved with',
        difficulty: 'medium',
        answers: [
          {
            title: 'A share deal',
            body: '',
            score: 1.0
          },
          {
            title: 'An asset deal',
            body: '',
            score: 0
          },
          {
            title: 'A purchase of a company from an insolvency practitioner',
            body: '',
            score: 0
          },
          {
            title: 'In no type of deal are past liabilities acquired',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'What does a Tax Opinion cover?',
        body: '',
        keyLearning: 'Insurance products that may be used in an M&A',
        difficulty: 'easy',
        answers: [
          {
            title:
              'A Tax Opinion policy covers the liabilities/defence costs incurred if HMRC disagrees with the opinion of the insureds tax advisor on the tax treatment of a specific matter ',
            body: '',
            score: 1.0
          },
          {
            title:
              'A Tax Opinion policy covers any additional tax liabilities incurred by a seller after a transaction',
            body: '',
            score: 0
          },
          {
            title:
              'A Tax Opinion policy covers the liabilities/defence costs incurred in a transaction ',
            body: '',
            score: 0
          },
          {
            title:
              'A Tax Opinion policy covers any additional tax liabilities incurred by a buyer after a transaction',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title:
          'Which of the following extensions on a public liability policy is usually written on a claims made basis?',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'easy',
        answers: [
          {
            title: 'Financial loss',
            body: '',
            score: 1.0
          },
          {
            title: 'Bona Fide Subcontractors',
            body: '',
            score: 0
          },
          {
            title: 'Indemnity to Principal',
            body: '',
            score: 0
          },
          {
            title: 'Contingent Motor',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title:
          'Why is it important that the business descriptions of all past and current policies include everything the business has done/currently does',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'easy',
        answers: [
          {
            title: 'Only activities /businesses included in the business descriptions are insured',
            body: '',
            score: 1.0
          },
          {
            title: 'To check that the correct premium has been and is paid by the insured',
            body: '',
            score: 0
          },
          {
            title: 'So the broker can check all necessary clauses and extensions are included',
            body: '',
            score: 0
          },
          {
            title: 'To ensure that the correct business entity is insured',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'What is TUPE?',
        body: '',
        keyLearning: 'Common insurance issues seen in a M&A and potential solutions',
        difficulty: 'easy',
        answers: [
          {
            title: 'The Transfer of Undertakings (Protection of Employees) Regulations 2006',
            body: '',
            score: 1.0
          },
          {
            title: 'The Transfer of Unidentified Permanent Employees Regulations 2006',
            body: '',
            score: 0
          },
          {
            title: "A clause found in employers' liability insurance policies",
            body: '',
            score: 0
          },
          {
            title: 'A type of merger',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'What is a carve-out deal?',
        body: '',
        keyLearning: 'Understand the various types of M&A clients may be involved with',
        difficulty: 'easy',
        answers: [
          {
            title: 'Sale of part of a company only (often a subsidiary)',
            body: '',
            score: 1.0
          },
          {
            title: 'Sale of all of a company to another company in same industry',
            body: '',
            score: 0
          },
          {
            title: 'Purchase of a company from an insolvency practitioner',
            body: '',
            score: 0
          },
          {
            title: "Refinancing of an existing company's debt",
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const MergersAndAcquisitionsInsuranceIssues = {
  questionnaire
};
