const module = [
  {
    id: '753cd031-f71d-46dd-a552-cd535cc009fe',
    slug: 'sales-prospecting-one',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '753cd031-f71d-46dd-a552-cd535cc009fe|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Prospecting - One',
    description:
      'In the first of two modules on prospecting you’ll learn about targeting the right prospects by identifying your Ideal Client Profile.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: '66b01f8c-ad8f-4378-a5da-b1dde20adfea',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'With any sales process focussing on targeting the right prospects is key to success. You’ll learn how to develop and understand how to build your Ideal Client Profile.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Which leads you should target</li>' +
        '<li>Understanding the importance of your Ideal Client Profile</li>' +
        '<li>How do you establish your Ideal Client Profile and your value proposition? </li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Prospecting - One',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/731359222',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Prospecting - One',
          button: 'Download Ideal Client Profile',
          fileSize: '224935',
          url: 'prospecting-one.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 10
    },
    statistics: {
      level: 0,
      timeEstimate: '5'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'foundation']
  }
];

export const IntroductionToSalesProspectingOne = {
  module
};
