import Axios from '@/packages/axios/Axios';
import { v4 as uuidv4 } from 'uuid';

export const MetricsMixin = {
  methods: {
    areMetricsEnabled() {
      if (window.localStorage.getItem('disable-metrics')) {
        if (window.localStorage.getItem('disable-metrics') == 'true') {
          return false;
        }
        return true;
      }

      return true;
    },
    metricWrite(type, data = {}) {
      if (this.areMetricsEnabled()) {
        data.tenant = this.$tenant.slug;

        // add skin details to tenantslug if it's a variant
        if (this.metricIsIEElevate()) {
          data.tenant = this.$tenant.slug + '-elevate';
        }

        data.ref = this.metricFetchReference();

        Axios.post('api/v1/metrics', {
          type: type,
          data: JSON.stringify(data),
        }).then(() => {});
      }
    },
    metricRecordPlayerAction(event) {
      this.metricWrite('video', {
        event: event,
        slug: this.module.slug,
        url: this.module.contents.media[0].url,
      });
    },
    metricFetchReference() {
      if (
        window.localStorage.getItem('metrics-reference') === null ||
        window.localStorage.getItem('metrics-reference') === 'undefined'
      ) {
        var newReference = this.metricGenerateReference();
        window.localStorage.setItem('metrics-reference', newReference);
        return newReference;
      }

      return window.localStorage.getItem('metrics-reference');
    },
    metricGenerateReference(length = 8) {
      let uuid = uuidv4();
      return uuid.substring(0, length);
    },
    metricUpdateReference(newReference) {
      window.localStorage.setItem('metrics-reference', newReference);
    },
    metricIsIEElevate() {
      if (
        this.$route.name === 'AccessCodeEntryElevate' ||
        this.$localStorage.retrieveDecrypted('view') === 'elevate' ||
        this.$localStorage.retrieveDecrypted('access-code').type === 'ELE'
      ) {
        return true;
      }
      return false;
    },
  },
};
