// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '4efa5940-84c9-43c3-822d-580f31b4ee9d',
    title: 'Introduction to Underinsurance and Inflation',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title: 'Why might rapid economic inflation cause premiums to be inadequate?',
        body: '',
        keyLearning: 'Claims issues caused by underinsurance',
        difficulty: 'hard',
        answers: [
          {
            title: 'Costs of claims might be rising faster than the premiums',
            body: '',
            score: 1.0
          },
          {
            title: 'The frequency of claims might be rising faster than the premiums',
            body: '',
            score: 0
          },
          {
            title: 'Insureds might seek to save money by spending less on insurance',
            body: '',
            score: 0
          },
          {
            title: 'Economic inflation might lead to a soft market and rate reductions',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'What is the benefit to the client of BI extension sub-limits being expressed as a percentage of the sum insured rather than a set limit?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'hard',
        answers: [
          {
            title: 'As the sum insured increased the cover limit will increase',
            body: '',
            score: 1.0
          },
          {
            title: 'It is cheaper',
            body: '',
            score: 0
          },
          {
            title: 'It is easier to calculate the limit',
            body: '',
            score: 0
          },
          {
            title: 'There is no benefit to the client, only to the insurer',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'How might the policy buildings definition affect the buildings sum insured?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'hard',
        answers: [
          {
            title:
              'If the policy definition includes foundations they need to be included in the calculation of the rebuilding cost and sum insured',
            body: '',
            score: 1.0
          },
          {
            title:
              'If the policy definition includes premises built of standard construction only any other materials used will need to be included in the rebuilding cost and sum insured',
            body: '',
            score: 0
          },
          {
            title:
              'If the policy definition <strong>excludes</strong> foundations they need to be included in the calculation of the rebuilding cost and sum insured',
            body: '',
            score: 0
          },
          {
            title:
              'The policy definition needs to be changed to match the definition used by the rebuilding cost valuer to set the sum insured',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title:
          'How does an occurrence liability policy trigger affect the limit of liability required?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'hard',
        answers: [
          {
            title:
              'There may be a long time gap (with a period of high inflation) between the policy limit being set and the claim settlement - this needs to be reflected in the limit of liability chosen.',
            body: '',
            score: 1.0
          },
          {
            title:
              'There may be a short time gap between the policy limit being set and the claim settlement - this can be reflected in the limit of liability chosen.',
            body: '',
            score: 0
          },
          {
            title: 'It has no effect',
            body: '',
            score: 0
          },
          {
            title: 'Limits need to be based on the date of occurrence ',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'Which of the following is a cause of social inflation?',
        body: '',
        keyLearning: 'How inflation causes underinsurance',
        difficulty: 'hard',
        answers: [
          {
            title: 'Increasing litigation',
            body: '',
            score: 1.0
          },
          {
            title: 'Increasing reinsurance costs',
            body: '',
            score: 0
          },
          {
            title: 'Higher insurance staff wages',
            body: '',
            score: 0
          },
          {
            title: 'Higher insurance staff turnover',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'Why does a speedy claims settlement benefit the insured and insurer?',
        body: '',
        keyLearning: 'Claims issues caused by underinsurance',
        difficulty: 'medium',
        answers: [
          {
            title: 'A speedy settlement helps reduce the exposure to the long-term inflation risk',
            body: '',
            score: 1.0
          },
          {
            title: "A speedy settlement helps reduce the insurers' expenses",
            body: '',
            score: 0
          },
          {
            title:
              'A speedy settlement helps reduce the need for loss adjusters to be involved in the claim',
            body: '',
            score: 0
          },
          {
            title: "A speedy settlement helps reduce the insured's expenses",
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title:
          'What is the main  benefit for a client of placing the business interruption policy on a Declaration Link basis?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'medium',
        answers: [
          {
            title: 'Average is deleted by a standard Declaration Link condition',
            body: '',
            score: 1.0
          },
          {
            title: 'The insured will not be underinsured',
            body: '',
            score: 0
          },
          {
            title: 'The indemnity period does not need to be increased',
            body: '',
            score: 0
          },
          {
            title: 'There is no need to adjust the policy at the end of the policy period',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title:
          'What is the main advantage of an obsolete property clause on a Property Insurance Policy?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'medium',
        answers: [
          {
            title:
              'It means obsolete property is not included in the calculation of reinstatement costs for average purposes ',
            body: '',
            score: 1.0
          },
          {
            title: 'It means obsolete property is insured for indemnity rather than reinstatement',
            body: '',
            score: 0
          },
          {
            title:
              'It means damage to obsolete property is not insured under the business interruption policy',
            body: '',
            score: 0
          },
          {
            title: 'It means damage to obsolete property is fully insured ',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title:
          'Which of the following clauses may require a client to increase the property policy stock sum insured?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'medium',
        answers: [
          {
            title: 'Contract Price clause ',
            body: '',
            score: 1.0
          },
          {
            title: 'Drains Clearance clause ',
            body: '',
            score: 0
          },
          {
            title: 'Fire Brigade Charges clause',
            body: '',
            score: 0
          },
          {
            title: 'Pairs and Sets clause',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: "what is 'social' inflation?",
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'How inflation causes underinsurance',
            body: '',
            score: 1.0
          },
          {
            title: "Increases in insurers' costs due to factors other than economic inflation",
            body: '',
            score: 0
          },
          {
            title: 'Increases in the population',
            body: '',
            score: 0
          },
          {
            title: "Increases in insurers' costs to rising prices",
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title:
          'Why are reductions of claims payments for underinsurance more likely in period of high inflation?',
        body: '',
        keyLearning: 'Claims issues caused by underinsurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'The sums insured are more likely to be too low due to inflation',
            body: '',
            score: 1.0
          },
          {
            title:
              'Insurers are more likely to apply larger excesses in a period of high inflation',
            body: '',
            score: 0
          },
          {
            title: 'Insureds are more likely to claim reduced amounts',
            body: '',
            score: 0
          },
          {
            title: 'Inflation often leads to a hard market during which insurers pay fewer claims',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title: 'What is the effect of an average clause  in a property insurance policy?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'easy',
        answers: [
          {
            title:
              'Where property is not insured for the full replacement value, it allows insurers to proportionately reduce the amount that they are liable to pay dependent on the level of underinsurance',
            body: '',
            score: 1.0
          },
          {
            title:
              'Where property is not insured for the full replacement value, it allows insurers to decline a claim for failure to make a fair presentation',
            body: '',
            score: 0
          },
          {
            title: 'It allows insurers to charge an average rate across the insured property',
            body: '',
            score: 0
          },
          {
            title:
              'It allows the insured to claim for average values of the damaged property rather than actual replacement values',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'What is the effect of the Day-One clause?',
        body: '',
        keyLearning: 'How policy wordings protect against underinsurance ',
        difficulty: 'easy',
        answers: [
          {
            title:
              'It helps to protect against underinsurance during the policy and reinstatement periods',
            body: '',
            score: 1.0
          },
          {
            title: 'It ensures the sum insured is correct at Day One of the policy period',
            body: '',
            score: 0
          },
          {
            title: 'It ensures the sum insured is correct at the time of a claim',
            body: '',
            score: 0
          },
          {
            title: 'It ensures the sum insured is correct at the time of reinstatement',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title:
          'Why might insurers claims reserves need to be increased during a period of high inflation?',
        body: '',
        keyLearning: 'How inflation causes underinsurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'The costs of settling the claims that are outstanding will increase rapidly',
            body: '',
            score: 1.0
          },
          {
            title: 'There are more claims during a period of high inflation',
            body: '',
            score: 0
          },
          {
            title: "The insurers'  claims staff costs will increase",
            body: '',
            score: 0
          },
          {
            title: 'Regulation requires claims reserves to be increased in line with inflation',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'Which of the following may be a result high of economic inflation? ',
        body: '',
        keyLearning: 'How inflation causes underinsurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'Underinsurance',
            body: '',
            score: 1.0
          },
          {
            title: 'More claims',
            body: '',
            score: 0
          },
          {
            title: 'More insurance purchased',
            body: '',
            score: 0
          },
          {
            title: 'Less insurance purchased',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const IntroductionToUnderinsuranceAndInflation = {
  questionnaire
};
