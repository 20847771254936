const module = [
  {
    id: '0ef88a1f-c759-4144-9090-b1a8b0486a4f',
    slug: 'introduction-to-terrorism-cover-session-two',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '0ef88a1f-c759-4144-9090-b1a8b0486a4f|e016af10-18a0-4fbd-b48d-0df0e59ede7d',
    template: 'video-template',
    title: 'Introduction to Terrorism cover - Session Two',
    description:
      'In this two-session course you’ll cover the key risks and cover issues associated with Terrorism.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'e016af10-18a0-4fbd-b48d-0df0e59ede7d',
    questionnaireId: '0ce99d11-b025-4d3e-bf86-22411cfefc8b',
    contents: {
      id: 'd5b84d85-3193-4407-b04c-ed07891dcf56',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This course details the key Terrorism risk issues including how and why Pool Re operates, the type of Terrorism covered by Pool Re and what are the key Pool Re rules.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Be aware of Pool Re and how it operates.</li>' +
        '<li>Understand what is treated as a Terrorism incident.</li>' +
        '<li>Know how Pool Re fits with insurance policies.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction to Terrorism cover - Session Two',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/963271553',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>',
          },
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction to Terrorism cover - Session Two',
          button: 'Download Slides',
          fileSize: '808593',
          url: 'introduction-to-terrorism-cover-session-two.pdf',
          caption: {
            title: '',
            description: '',
          },
          axaUrl: '',
        },
      ],
    },
    progress: {
      value: 2,
      maximum: 2,
    },
    statistics: {
      level: 1,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['intermediate', 'sales', 'products', 'terrorism'],
  },
];

export const IntroductionToTerrorismCoverSessionTwo = {
  module,
};
