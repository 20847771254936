const module = [
  {
    id: '1080946a-0508-48a2-8730-002fb14cd5cb',
    slug: 'virtual-meetings',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1d903ecd-b454-426a-8d43-a44307fd4ab7|1080946a-0508-48a2-8730-002fb14cd5cb',
    template: 'video-template',
    title: 'Virtual meetings',
    description:
      'Learn how to build rapport virtually with prospects and clients, use Perspective Selling to gain client engagement and run successful virtual meetings',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    questionnaireId: '',
    contents: {
      id: '994bb585-4acf-4309-96a3-822cca39b9f7',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn how to build rapport virtually with prospects and clients, use Perspective Selling to gain client engagement and run successful virtual meetings' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how rapport building is affected by a remote environment and develop skills to build rapport virtually with prospects and clients</li>' +
        '<li>Understand how to use Perspective Selling to gain client engagement</li>' +
        '<li>Learn how to set up and run successful virtual meetings</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Broker Sales in a Changing Environment - Webinar 6',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505196302',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Broker Sales in a Changing Environment - Webinar 6',
          button: 'Download Slides',
          fileSize: 1411423,
          url: 'virtual-meetings.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www.axaconnect.co.uk/siteassets/coronavirus-hub/business-support/sales-in-a-changing-environment/sales-in-a-changing-environment-week-6/'
        }
      ]
    },
    progress: {
      value: 6,
      maximum: 6
    },
    statistics: {
      level: 1,
      timeEstimate: '70'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const VirtualMeetings = {
  module
};
