const module = [
  {
    id: 'd3e90929-0f83-4492-ae00-4aaf4cb0b781',
    slug: 'construction-liability-issues-in-the-construction-sector-part-1',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'd3e90929-0f83-4492-ae00-4aaf4cb0b781|07ceadc8-32da-4dab-9bef-da023c3388eb',
    template: 'video-template',
    title: 'Liability issues in the construction sector – part 1',
    description:
      'Understand the difference between labour only sub-contractors and bona fide sub-contractors and the key cover extensions needed for both EL and PL',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '07ceadc8-32da-4dab-9bef-da023c3388eb',
    questionnaireId: '71cce13a-4cb0-4b4d-8a3f-31283695cfe4',
    contents: {
      id: 'e9931a44-7b52-402e-813a-7b1f56cb8454',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the difference between labour only sub-contractors and bona fide sub-contractors and the key cover extensions needed for both EL and PL' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the difference between labour only sub-contractors (LOSC) and bona fide sub-contractors</li>' +
        '<li>Enhance your understanding of Employers Liability and Public Liability and the key cover extensions and exclusions</li>' +
        '<li>Understand the issues related to collateral warranties</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Construction - Liability issues in the construction sector – part 1',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/541683938',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Construction - Liability issues in the construction sector – part 1',
          button: 'Download Slides',
          fileSize: 4837078,
          url: 'construction-liability-issues-in-the-construction-sector-part-1.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['construction', 'products', 'sales', 'intermediate']
  }
];

export const ConstructionLiabilityIssuesInTheConstructionSectorPart_1 = {
  module
};
