export const ModuleMixin = {
  computed: {
    userHasCompletedThisModule() {
      if (this.$user.findInCollection('module', this.module.id)) {
        return true;
      }

      return false;
    }
  }
};
