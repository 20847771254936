// Construction - {{Module Name }}
const questionnaire = [
  {
    id: '260a5307-db60-49f9-bdf3-038868810624',
    title: 'Market segmentation and key issues',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title:
          'Which of the following is <strong>NOT</strong> a piece of critical broking information?',
        body: '',
        keyLearning: 'Information needed by brokers to place construction market business',
        difficulty: 'hard',
        answers: [
          {
            title: 'What percentage of work involves brick built construction',
            body: '',
            score: 1.0
          },
          {
            title: 'Details of any work carried out above 10M from ground level ',
            body: '',
            score: 0
          },
          {
            title: 'Details of the types of locations worked at ',
            body: '',
            score: 0
          },
          {
            title: 'Split between work in/on new build and existing buildings',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'Which of the following is a piece of critical broking information when placing a construction public liability risk?',
        body: '',
        keyLearning: 'Information needed by brokers to place construction market business',
        difficulty: 'hard',
        answers: [
          {
            title: 'Payments to bona fide sub-contractors',
            body: '',
            score: 1.0
          },
          {
            title: 'Personal address of main director',
            body: '',
            score: 0
          },
          {
            title: 'Payments to suppliers',
            body: '',
            score: 0
          },
          {
            title: 'Travel patterns for employees working away from office',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title:
          "Why should a broker placing a building contractors' CAR policy, disclose the percentage of turnover  that is derived from contracts where the Employer is responsible for insuring the contract works",
        body: '',
        keyLearning: 'Information needed by brokers to place construction market business',
        difficulty: 'hard',
        answers: [
          {
            title:
              'If the Employer is insuring the works in joint names then a premium saving can be obtained, as the risk to insurers is reduced',
            body: '',
            score: 1.0
          },
          {
            title:
              'If the Employer is insuring the works in joint names then insurers charge a higher rate to the building contractor',
            body: '',
            score: 0
          },
          {
            title: 'To ensure insurers have full subrogation details',
            body: '',
            score: 0
          },
          {
            title: 'So such contracts can be excluded',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'Which party in the construction project may buy a latent defects policy',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'hard',
        answers: [
          {
            title: 'Employer',
            body: '',
            score: 1.0
          },
          {
            title: 'Sub-contractor',
            body: '',
            score: 0
          },
          {
            title: 'Professional consultant',
            body: '',
            score: 0
          },
          {
            title: 'Building contractor',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title:
          'Which of the following type of contractor is likely to be most difficult to arrange cover for?',
        body: '',
        keyLearning: 'Information needed by brokers to place construction market business',
        difficulty: 'hard',
        answers: [
          {
            title: 'Demolition contractors',
            body: '',
            score: 1.0
          },
          {
            title: 'Cladding removal contractors',
            body: '',
            score: 0
          },
          {
            title: 'Electrical contractors',
            body: '',
            score: 0
          },
          {
            title: 'Fencing contractors',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'What is a DSU policy?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'medium',
        answers: [
          {
            title: 'A delay in start-up policy',
            body: '',
            score: 1.0
          },
          {
            title: 'A developers speculative unit policy',
            body: '',
            score: 0
          },
          {
            title: "A distribution suppliers' umbrella policy",
            body: '',
            score: 0
          },
          {
            title: 'A design specification unworthiness policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title:
          'Which of the following is <strong>NOT</strong> a name by which the employer in a construction project may be known?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'medium',
        answers: [
          {
            title: 'Cost consultant',
            body: '',
            score: 1.0
          },
          {
            title: 'Client',
            body: '',
            score: 0
          },
          {
            title: 'Developer',
            body: '',
            score: 0
          },
          {
            title: 'Promotor',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title:
          'Why should brokers supply a split in wages between employees based on the type of work undertaken',
        body: '',
        keyLearning: 'Information needed by brokers to place construction market business',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Allows insurers to accurately apply the right rates rather than apply highest rate to all wages',
            body: '',
            score: 1.0
          },
          {
            title: 'Insurers may seek to exclude certain categories of employees from the policy',
            body: '',
            score: 0
          },
          {
            title: 'To enable insurers to set employee accident claim reserves',
            body: '',
            score: 0
          },
          {
            title: "It is required under the Employers' Liability Compulsory Insurance Act 1969",
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title:
          'Which parties involved in a construction project does the employer usually have a contract with?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'medium',
        answers: [
          {
            title: 'Funder, purchaser, tenant, professional consultants, building contractor',
            body: '',
            score: 1.0
          },
          {
            title: 'The building contractor and funder',
            body: '',
            score: 0
          },
          {
            title: 'The professional consultants and funder',
            body: '',
            score: 0
          },
          {
            title: 'The building contractor and sub-contractors',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title:
          'What is the main difference between construction all risks and erection all risks policies?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'medium',
        answers: [
          {
            title:
              'The CAR policy covers damage to works, the EAR policy covers damage to machinery being installed',
            body: '',
            score: 1.0
          },
          {
            title:
              'The CAR policy covers damage to machinery being installed, the EAR policy covers damage to works',
            body: '',
            score: 0
          },
          {
            title: 'The CAR policy is an annual policy, the EAR policy is a single project policy',
            body: '',
            score: 0
          },
          {
            title: 'The CAR policy is single project policy, the EAR policy is a an annual policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title:
          'Which of the following will <strong>not</strong> have an interest in the outcome of a project?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'easy',
        answers: [
          {
            title: 'Visitors',
            body: '',
            score: 1.0
          },
          {
            title: 'Funders',
            body: '',
            score: 0
          },
          {
            title: 'Purchasers',
            body: '',
            score: 0
          },
          {
            title: 'Tenants',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title:
          'Which of the following best describes the role of the employer in a construction project?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'easy',
        answers: [
          {
            title: 'The employer is the party for whose benefit the project is carried out',
            body: '',
            score: 1.0
          },
          {
            title: 'The employer carries out the design of the project',
            body: '',
            score: 0
          },
          {
            title:
              'The employer carries out the building works in accordance with the drawings and/or specifications',
            body: '',
            score: 0
          },
          {
            title: 'The employer carries out the skilled work of the project',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'Sub-contractors are usually employed by:',
        body: '',
        keyLearning: 'How insurers segment the construction markets',
        difficulty: 'easy',
        answers: [
          {
            title: 'the main building contractor',
            body: '',
            score: 1.0
          },
          {
            title: 'the employer',
            body: '',
            score: 0
          },
          {
            title: 'the funder',
            body: '',
            score: 0
          },
          {
            title: 'the architect',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'Which of these policies will be needed by a building contractor?',
        body: '',
        keyLearning: 'Which type of policy the different parts of the construction industry need',
        difficulty: 'easy',
        answers: [
          {
            title: 'Contractors all risks',
            body: '',
            score: 1.0
          },
          {
            title: 'Professional indemnity as quantity surveyors ',
            body: '',
            score: 0
          },
          {
            title: 'Latent defects',
            body: '',
            score: 0
          },
          {
            title: 'Business Travel',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title:
          'Which of the following is a key segmentation factor for the purchase of a construction insurance policy?',
        body: '',
        keyLearning: 'How insurers segment the construction markets',
        difficulty: 'easy',
        answers: [
          {
            title: 'Turnover of the business',
            body: '',
            score: 1.0
          },
          {
            title: 'Geographical location of the business',
            body: '',
            score: 0
          },
          {
            title: 'Number of commercial vehicles',
            body: '',
            score: 0
          },
          {
            title: 'Office contents sum insured',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const ConstructionMarketSegmentationAndKeyIssues = {
  questionnaire
};
