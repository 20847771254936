import axios from 'axios';
import { AxiosConfig } from '@/config/config';
import Vue from 'vue';

const Axios = new axios.create({
  baseURL: AxiosConfig.baseURL
});

Axios.defaults.headers.common['Accept'] = 'application/json';
Axios.defaults.headers.common['X-Application-ID'] = AxiosConfig.xApplicationId;
/* If you want, you can introduce X-Related-Tenants as a JSON array here as well. */
Axios.defaults.withCredentials = true;

Axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        // alert('You are currently not logged in. Please login in order to view member-only contents.');
      }
    }
    return Promise.reject(error);
  }
);
// Axios.interceptors.response.use(
// 	function(response) {
// 		return response;
// 	},
// 	function(error) {
// 		let result = error;
//
// 		if(error.response) {
// 			let status = error.response.status;
// 			if(status == 403) {
// 				Vue._installedPlugins[5].commit("push_message", {
// 					title: "Unauthorized",
// 					body: "You are not authorized to perform that particular action.",
// 					type: "danger",
// 				});
// 				result = undefined;
// 			} else if(status == 404) {
// 				/*
// 				 * We do not really need to comment on this. We can simply set result to
// 				 * null and return to the caller
// 				 */
// 				result = null;
// 			}
// 		} else if(error.request) {
// 			if(error.message == "Network Error") {
// 				// eslint-disable-next-line
// 				Vue._installedPlugins[5].commit("SHOW_FLASH", {
// 					body:
// 						"It seems that the API server is not responding. Please " +
// 						"contact Ignition NBS Support and tell them that the API Server " +
// 						"for Ignition Training & Development is not responding. Thank you!",
// 					duration: 20,
// 					title: "Network Error",
// 					type: "danger",
// 				});
// 				result = undefined;
// 			}
// 		}
//
// 		if (result === undefined) {
// 			// eslint-disable-next-line
// 			Vue._installedPlugins[5].commit("HIDE_PLEASE_WAIT");
// 			return Promise.reject(result);
// 		} else if (result === null) {
// 			return Promise.resolve({ data: [] });
// 		} else {
// 			return Promise.reject(result);
// 		}
// 	}
// );

export default Axios;
