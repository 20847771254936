const module = [
  {
    id: '8d318909-37b2-4691-b20b-7555a3bf9e46',
    slug: 'hybrid-management-setting-smarter-objectives-remotely',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '8d318909-37b2-4691-b20b-7555a3bf9e46|9ddda366-d724-4ef0-8a75-248eda696817',
    template: 'actus-iframed-template',
    title: 'Setting smarter objectives remotely',
    description:
      'Having clear, SMARTER objectives is crucial when working remotely.  You’ll learn how to develop and write objectives that suit a hybrid working environment.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '9ddda366-d724-4ef0-8a75-248eda696817',
    questionnaireId: '',
    contents: {
      id: '1bdbfed9-a495-47c3-a526-1494df9bd10f',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Having clear, SMARTER objectives is crucial when working remotely.  You’ll learn how to develop and write your objectives that are supportive of working in a hybrid environment.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes for this learning module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand why clear objectives are particularly important in a hybrid environment </li>' +
        '<li>Learn how to make your objectives SMARTER to support you when working in a hybrid setting </li>' +
        '<li>Recognise and overcome common issues when writing SMARTER objectives</li>' +
        '<li>Build confidence and competence around the development of quality objectives</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/8d318909-37b2-4691-b20b-7555a3bf9e46/media/actus-hybrid-management-series-setting-smarter-objectives-web-v2/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const SettingSmarterObjectivesRemotely = {
  module
};
