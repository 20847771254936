const module = [
  {
    id: '3639f37d-09bb-4261-a60b-7447a44d7d65',
    slug: 'employee-series-agreeing-smarter-objectives',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '3639f37d-09bb-4261-a60b-7447a44d7d65|f7673de3-978f-4260-9f29-46f2c6700c0b',
    template: 'actus-iframed-template',
    title: 'Agreeing smarter objectives',
    description:
      'You’ll learn how to set SMARTER objectives to help you and your line managers to define your goals whilst working in hybrid working setting.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: 'f7673de3-978f-4260-9f29-46f2c6700c0b',
    questionnaireId: '',
    contents: {
      id: '6f4591b1-7106-4208-83d8-14d5f2374f5a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Having a set of SMARTER objective helps to set clear goals for you and your line manager especially in a remote working environment.  You’ll discover how to overcome common issues to make you objectives SMARTER and relevant for the changing working environment. </p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your learning outcomes for this module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand why having clear objectives is particularly important in a remote environment</li>' +
        '<li>Learn how to make your objectives SMARTER to support you when working remotely</li>' +
        '<li>Recognise and overcome common issues when agreeing SMARTER objectives</li>' +
        '<li>Build your confidence and competence around the development of quality objectives</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/3639f37d-09bb-4261-a60b-7447a44d7d65/media/actus-hybrid-employee-series-agreeing-smarter-objectives-web-v2/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management', 'foundation']
  }
];

export const AgreeingSmarterObjectives = {
  module
};
