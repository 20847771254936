// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: 'e07d9177-f646-42fe-bb90-ed27195a024d',
    title: "Conduct and Risk partner webinar:  meeting our customers' needs together",
    body: '',
    keyLearning: 'Learning',
    passScore: 3,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title:
          'What date did the Financial Conduct Authority (FCA) and Prudential Regulation Authority (PRA) replace the Financial Services Authority (FSA)?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: '1st April 2013',
            body: '',
            score: 1.0
          },
          {
            title: '1st April 2015',
            body: '',
            score: 0
          },
          {
            title: '1st April 2012',
            body: '',
            score: 0
          },
          {
            title: '1st January 2013',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'When did the Insurance Distribution Directive (IDD) start?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'October 2018',
            body: '',
            score: 1.0
          },
          {
            title: 'Jan 2019',
            body: '',
            score: 0
          },
          {
            title: 'March 2015',
            body: '',
            score: 0
          },
          {
            title: 'November 2018',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title:
          'What workstreams/activities do AXA have in place to ensure we adhere to the General Insurance Pricing Practices (GIPP)?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title:
              'Annual Product reviews, Fair Value Assessments, Assessing the impact distribution arrangements have on the overall value to the end customer',
            body: '',
            score: 1.0
          },
          {
            title: 'Fair Value Assessments, Customer Outcomes Testing (e.g., Call listening)',
            body: '',
            score: 0
          },
          {
            title: 'Gap Analyses, Fair Value Assessments, Meetings with the FCA',
            body: '',
            score: 0
          },
          {
            title: 'Marketing Assessments, Fair Value Assessments, Annual Product Reviews',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title:
          'Fill in the blanks for this sentence: "When it comes to Vulnerable Customers, we respond to customer needs throughout ______ flexible ______ and ______"',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'Product design, customer service, communications ',
            body: '',
            score: 1.0
          },
          {
            title: 'Communications, methods, product design ',
            body: '',
            score: 0
          },
          {
            title: 'Communications, options, product design ',
            body: '',
            score: 0
          },
          {
            title: 'Customer service, monitor, capability ',
            body: '',
            score: 0
          }
        ]
      },

      {
        id: 5,
        title: 'For the GIPP, what are the three key themes specific to distribution value?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'Assess and mitigate customer detriment, use of information, and fair value ',
            body: '',
            score: 1.0
          },
          {
            title: 'Use of information, product value and assurance ',
            body: '',
            score: 0
          },

          {
            title: 'Use of information, quality, assess and mitigate customer detriment ',
            body: '',
            score: 0
          },
          {
            title: 'Fair value, use of information, product damage',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'As per the FCA guidance, for the New Consumer Duty, what does it contain?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Consumer Principle, Consumer Outcomes in 4 specific areas and 3 Cross-Cutting Rules',
            body: '',
            score: 1.0
          },
          {
            title:
              'Consumer Principle, 5 Cross-Cutting Rules and Consumer Outcomes in 3 specific areas ',
            body: '',
            score: 0
          },
          {
            title:
              '3 Cross-Cutting Rules, Consumer Principle and Consumer Outcomes in 5 specified areas ',
            body: '',
            score: 0
          },
          {
            title:
              'Consumer Principle, 4 Cross-Cutting Rules and Consumer Outcomes in 4 specific areas ',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title:
          'What are the missing words to complete this sentence on the Consumer Principle: "A firm must ______ to ______ good ______ for ______ ______"',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Act, Deliver, Outcomes, Retail Customers ',
            body: '',
            score: 1.0
          },
          {
            title: 'Ensure, Provide, Outcomes, Retail Consumers ',
            body: '',
            score: 0
          },
          {
            title: 'Act, Ensure, Outcomes, Retail Customers',
            body: '',
            score: 0
          },
          {
            title: 'Ensure, Deliver, Outcomes, Commercial Customers ',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'Why is good conduct important? ',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Increases the chance that customers will experience good outcomes, putting customers the interests of the customer at the forefront of decision making',
            body: '',
            score: 1.0
          },
          {
            title:
              'Improve and be consistent with providing excellent customer service, our customers are likely to stay with us ',
            body: '',
            score: 0
          },
          {
            title:
              'Our regulators the FCA/PRA/FOS are less likely to fine us, impacting our reputation with current/future customers ',
            body: '',
            score: 0
          },
          {
            title:
              'Allow all of us to adhere to regulatory topics such as treating customers fairly, VIDC, IDD etc',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title:
          'Partners who we have a direct relationship with are required to provide information about services, remuneration, controls, and information about any other parties in the distribution chain. What does AXA have in place to complete this?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Conduct Questionnaire',
            body: '',
            score: 1.0
          },
          {
            title: 'Customer Outcomes Questionnaire',
            body: '',
            score: 0
          },
          {
            title: 'FCA Questionnaire',
            body: '',
            score: 0
          },
          {
            title: 'Commercial Conduct Questionnaire',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'What figure was reported as the total fines handed out by the FCA in 2021?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: '£91m',
            body: '',
            score: 1.0
          },
          {
            title: '£15m',
            body: '',
            score: 0
          },
          {
            title: '£50m',
            body: '',
            score: 0
          },
          {
            title: '£89m',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const ConductRiskPartnerMeetingOurCustomersNeeds = {
  questionnaire
};
