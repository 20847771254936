const module = [
  {
    id: 'a8b83a3a-f74b-4a7a-949d-8e7243ec64a2',
    slug: 'key-skills-and-approaches-for-addressing-clients-needs',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'a8b83a3a-f74b-4a7a-949d-8e7243ec64a2|3187c558-5836-4721-a158-776c11b266ee',
    template: 'video-template',
    title: 'Key skills and approaches for addressing clients needs',
    description:
      'We’ll look at some of the key skills and approaches you need to successfully unearth and address these needs.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '3187c558-5836-4721-a158-776c11b266ee',
    questionnaireId: '',
    contents: {
      id: 'e6b97343-1219-4e3b-bf61-a8c21425f103',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this session we’ll discover what skills will help you in establishing strategies to boost your relationship with your existing clients.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand what combination of skills you need for success</li>' +
        '<li>How to leverage the strength of an account team to best use their individual skills</li>' +
        '<li>Know your client</li>' +
        '<li>How to prioritise and identify the clients most suited to offering multiple products.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Key skills and approaches for addressing clients needs',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/798325551',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>',
          },
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Key skills and approaches for addressing clients needs',
          button: 'Download Slides',
          fileSize: '1200754',
          url: 'addressing_the_needs_of_existing_clients.pdf',
          caption: {
            title: '',
            description: '',
          },
          axaUrl: '',
        },
      ],
    },
    progress: {
      value: 2,
      maximum: 3,
    },
    statistics: {
      level: 1,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['marketing', 'sales', 'skills', 'intermediate'],
  },
];

export const KeySkillsAndApproachesForAddressingClientsNeeds = {
  module,
};
