// Manufacturing - 2 - Business interruption issues and implications [part 2]
const questionnaire = [
    {
        id: '46aeeaef-694b-40dd-9bcb-410518267d83',
        title: 'Business interruption issues and implications - Part 2',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Which of the following statements is TRUE regarding Suppliers extensions?',
                body: '',
                keyLearning: 'What to consider when negotiating cover extensions',
                difficulty: 'hard',
                answers: [
                    {
                        title:
                            'There may be a need to extend the policy Indemnity Period for a specified supplier',
                        body: '',
                        score: 1.0
                    },
                    {
                        title:
                            'Goods held at outside storage locations after manufacture can be treated as being covered within the extension',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only suppliers in the UK can be covered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The extension covers penalties for breach of contract with the supplier',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title:
                    'Which of the following statements is FALSE regarding Research and Development extensions?',
                body: '',
                keyLearning: 'Assessing an indemnity period. Identifying key extensions. ',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The property policy will pay for the costs of reconstituting R & D records',
                        body: '',
                        score: 1.0
                    },
                    {
                        title:
                            'The extension provides cover for re-writing R & D information lost as a result of the insured damage',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'A separate loss limit is necessary for this extension because pure R & D cannot be quantified in terms of turnover',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'Following a loss, most of the normal costs of running an R & D unit will continue',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title:
                    'Which of these is NOT a reason why  a policy might have different indemnity periods within the same policy',
                body: '',
                keyLearning: 'Assessing an indemnity period.',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'One machine needs longer to replace than the others',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The AICOW has a longer indemnity period than the insurable gross profit cover',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'There are different types of income covered (such as loss of rental income) that need a different indemnity period',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The extensions have different (usually shorter) indemnity periods',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'What sort of machinery is most likely to need engineering BI cover?',
                body: '',
                keyLearning:
                    'Identifying key extensions. What to consider when negotiating cover extensions',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Key machines, where breakdown would cause an interruption of production',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Office equipment',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Key machines where there are several of the same type used in production',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Hired in machines',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title:
                    'Which of the following is most likely to influence the required indemnity period for a manufacturing business?',
                body: '',
                keyLearning: 'Assessing an indemnity period.',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Lack of a business continuity plan',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: "The factory managers' holiday plans",
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The type of goods being manufactured',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The fire load',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title:
                    'Which of the following would NOT be admissible as Increased Cost of Working claims under a standard BI policy?',
                body: '',
                keyLearning: 'What to consider when negotiating cover extensions',
                difficulty: 'medium',
                answers: [
                    {
                        title:
                            'Long term extra security costs incurred following damage to the alarm system at the premises',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Overtime payments for clearing up the premises',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Extra rates incurred in leasing alternative premises',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Additional costs incurred in fitting out alternative premises',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title:
                    'A client with a 12 month Indemnity Period should project their estimated Gross Profit how far into the future?',
                body: '',
                keyLearning: 'Assessing an indemnity period.',
                difficulty: 'medium',
                answers: [
                    {
                        title: '24 months',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '12 months',
                        body: '',
                        score: 0
                    },
                    {
                        title: '36 months',
                        body: '',
                        score: 0
                    },
                    {
                        title: '48 months',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title:
                    'A BI policy is incepted on 01.01.20 with an indemnity period of 12 months. A fire occurs on 01.03.20 disrupting the business for the next 18 months. The policy is not renewed at 01.01.21. Over what period is the claim calculated? ',
                body: '',
                keyLearning: 'Assessing an indemnity period.',
                difficulty: 'medium',
                answers: [
                    {
                        title: '01.03.20 to 01.03.21',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '01.01.20 to 01.01.21',
                        body: '',
                        score: 0
                    },
                    {
                        title: '01.03.20 to 01.09.21',
                        body: '',
                        score: 0
                    },
                    {
                        title: '01.03.20 to 01.01.21',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title:
                    'Under a standard engineering BI policy which of the following is not a standard exclusion?',
                body: '',
                keyLearning: 'What to consider when negotiating cover extensions',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Breakdown',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Fire perils',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Wear and tear',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Water perils',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title:
                    'What is the difference between ICOW and AICOW in a insurable gross profit BI policy?',
                body: '',
                keyLearning: 'Identifying key extensions.',
                difficulty: 'medium',
                answers: [
                    {
                        title:
                            'AICOW are not limited by needing to be economic - they just need to reasonable and necessary and incurred with prior permission of insurers',
                        body: '',
                        score: 1.0
                    },
                    {
                        title:
                            'AICOW are not limited by needing to reasonable and necessary and incurred with prior permission of insurers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'There is no difference',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'AICOW are only found in US wordings',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'When does an indemnity period start running?',
                body: '',
                keyLearning: 'Assessing an indemnity period.',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'At the time the damage occurs',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'At the time the rebuilding/repairs begin',
                        body: '',
                        score: 0
                    },
                    {
                        title: "At a time of the Insured's choosing",
                        body: '',
                        score: 0
                    },
                    {
                        title: 'At the time the production is interrupted',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title:
                    'Which of the following considerations is NOT normally taken into account when establishing the correct length of an Indemnity Period for a BI policy?',
                body: '',
                keyLearning: 'Assessing an indemnity period.',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Time taken as holidays',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Time taken to obtain planning consents',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Time taken to install new machinery',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Time taken to recommission plant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title:
                    'If your BI policy has territorial limits of UK, would a supplier based in Italy be automatically covered under an unnamed suppliers extension?',
                body: '',
                keyLearning: 'What to consider when negotiating cover extensions',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'No - only suppliers within the policy territorial limits are covered',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Yes, unless the suppliers extension limited the cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes, territorial limits do not apply to extensions',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No, it is not possible to get cover for EU suppliers after Brexit',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'What are AICOW in an insurable gross profit BI policy?',
                body: '',
                keyLearning: 'Identifying key extensions.',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Additional Increased Costs of Working',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Additional Insured Costs of Working',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Accelerated Insured Costs of Working',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Accelerated Increased Costs of Working',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'What type of damage is usually covered under an engineering BI policy?',
                body: '',
                keyLearning:
                    'Identifying key extensions. What to consider when negotiating cover extensions',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Breakdown of key machinery',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Fire damage to key machinery',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Fire damage to a building',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Loss of goods in transit',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    },
]

export const ManufacturingBusinessInterruptionIssuesAndImplicationsPart_2 = {
    questionnaire
};