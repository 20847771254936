const module = [
  {
    id: '5b46fcd4-c0fc-4432-9055-9d5ea111e1ba',
    slug: 'sales-closing',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '5b46fcd4-c0fc-4432-9055-9d5ea111e1ba|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Closing and Gaining Commitment',
    description:
      'In this module we’ll be focusing on the final stage of the process, closing the deal. ',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: '98d26e0e-bac9-4332-b1d2-b2ae960d20d8',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module we talk about closing the deal and how to become a trusted adviser to your client by working in partnership to develop a profitable, long-term relationship' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>An ethical and prospect-focussed approach to closing</li>' +
        '<li>The importance of timing and gaining commitment</li>' +
        '<li>Prospect focus and proposing the next best step</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Closing and Gaining Commitment',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755032405',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Closing',
          button: 'Download Slides',
          fileSize: '123440',
          url: 'introduction-to-sales-closing.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 10,
      maximum: 10
    },
    statistics: {
      level: 2,
      timeEstimate: '5'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'advanced']
  }
];

export const IntroductionToSalesClosing = {
  module
};
