// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
    {
        id: '10f5df48-8f96-4bbd-8b61-b5932e6104e8',
        title: 'Reviewing contracts for insurance implications',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Why would insurers have an issue with a requirement to add a loss payee clause to a public/products liability policy?',
                body: '',
                keyLearning: 'Identify areas that will cause issues for insurers',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'In a public/products policy payment is made directly to the third party not to the insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'In a public/products policy there is usually a third party property damage excess',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'In a public/products policy there is cross liability clause',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'In a public/products policy there is usually an aggregate limit of indemnity for the products liability section',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'What is the main insurance issue of a client agreeing in a contract to indemnify the other party for all costs,  claims, demands and liabilities',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'No insurance programme will provide cover against all costs, claims, demands and liabilities',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Cover against all costs, claims, demands and liabilities is only available for an additional premium',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Arranging cover against all costs, claims, demands and liabilities will take several weeks to arrange',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'More than one policy is needed to provide cover for all costs, claims, demands and liabilities',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'In a contract condition your client is required to arrange insurance that covers injury to third parties sustained during the period of insurance as a result of your client\'s negligence.  What is the main issue with this requirement? ',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Public liability policies are usually triggered by claims that occur during the period of insurance, not claims sustained during the period of insurance',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No public liability policy will cover the client\'s negligence only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Public liability policies have an any one event/occurrence limit of indemnity',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Public liability policies cover injury to third parties and damage to third party property',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'What does a hold harmless agreement in a contract mean?',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Each party to the contract is responsible for loss/damage to their own employees/property irrespective of which party actually caused the loss/damage',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Neither party to the contract is responsible for loss/damage to their own employees/property ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Each party to the contract is responsible for loss/damage to their own employees/property only if they have caused the loss/damage',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Each party to the contract is responsible for loss/damage to their own employees/property unless the other party caused the loss/damage',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Why should a broker review a contract for insurance implications before the client has signed the contract?',
                body: '',
                keyLearning: 'What advice to give clients (and what not to say)',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'If the broker cannot get cover required to protect client, or get cover at a reasonable price, it is too late to negotiate a change in the contract instead',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Insurance brokers cannot give legal advice after the contract has been signed',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurers will not agree to provide any additional cover if the contract has already been signed',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Brokers cannot charge any additional fee for the review if the contract has already been signed',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'What type of your clients\'  contracts may have insurance implications?',
                body: '',
                keyLearning: 'What advice to give clients (and what not to say)',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'All contracts may have insurance implications',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Only contracts for the purchase of raw materials may have insurance implications',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Bespoke contracts only may have insurance implications',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'All contracts except leases may have insurance implications',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'What cover for a third parties consequential loss will be provided in a public/products liability policy?',
                body: '',
                keyLearning: 'Identify areas that will cause issues for insurers',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The extent of insurance for consequential loss in a public/products liability policy will depend on the wording',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Full consequential loss cover is provided in all public/products liability policies',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Full consequential loss cover is provided in all public/products liability policies unless it is specifically excluded',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No public/products liability policy provides cover for a third parties consequential losses',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Why should a client NOT sign a contract that imposes obligations in respect of their insurers’ before discussing the obligations with the insurers?',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'If the insurers refuse to comply with the obligations, the client will be in breach of contract',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'There may be an additional charge made by the insurers for complying with the obligations',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insurers will only agree to obligations in respect of claims notifications to third parties',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The broker also needs to agree to accept the insurers\' obligations',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'What is the effect for insurers of a client agreeing to a hold harmless agreement in a contract?',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Hold harmless agreements mean the insurer has no rights of recovery against the party causing the insured damage/injury',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The insurer is not affected by the insured agreeing to a hold harmless contract clause',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Hold harmless agreements mean the insurer can only recover against the party causing the insured damage/injury',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insurer is not affected by the insured agreeing to a hold harmless contract clause if they have been informed of its existence',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What advice would you give a client about obtaining cover for financial loss to comply with a contract condition?',
                body: '',
                keyLearning: 'Identify areas that will cause issues for insurers',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Some cover may be available, at an additional premium, but will be subject to exclusions and limitations',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No insurers will provide any financial loss cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cover that matches the contract requirement for financial loss is widely available, for an additional premium',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Financial loss insurance cover is only available for non-UK contracts',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Which of the following is unlikely to be covered by any UK insurer?',
                body: '',
                keyLearning: 'Identify areas that will cause issues for insurers',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Punitive damages',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Defamation',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Defence costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Nuisance',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Why should the insurance broker ask to see the entire contract when reviewing for insurance implications only?',
                body: '',
                keyLearning: 'What advice to give clients (and what not to say)',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Reviewing extracts could be misleading',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'To enable the charge for the review to be higher',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To enable the insurance broker to discuss the  contract with the client\'s legal advisers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'So the insurance broker can send whole contract onto the insurer',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Should insurance brokers suggest alternative/better insurance clause contract language to a client?',
                body: '',
                keyLearning: 'What advice to give clients (and what not to say)',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'No - that is giving legal advice',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No - unless they charge an additional fee',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes - provided they charge an additional fee',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes - it is part of their duty as an insurance broker',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'Why is the part of the contract that sets out responsibilities important when reviewing contracts for insurance implications?',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'The contract might make the client responsible for something that their insurance does not cover',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The contract only makes the client responsible for something that their insurance covers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The contract responsibilities have no bearing on the insurance arranged by the insured',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insurance is limited to what the client is responsible for',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'What is an indemnity in a contract?',
                body: '',
                keyLearning: 'Be aware of the insurance clauses and conditions within normal commercial contracts',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'A promise to pay',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A promise to take out insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The limit of insurance required',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A promise to hold the other party harmless',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ReviewingContractsForInsuranceImplications = {
    questionnaire
};