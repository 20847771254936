// Manufacturing - Business interruption issues and implications [part 1]
const questionnaire = [
    {
        id: '0234ef19-1ca0-4f67-944c-1a199ce559d0',
        title: 'Business interruption issues and implications - Part 1',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'What would normally be excluded from an accountant’s definition of Gross Profit?',
                body: '',
                keyLearning: 'How to identify which costs could be uninsured working expenses',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Wages',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Freight costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Factory overheads',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Salaries',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'What is a Declaration Link basis of sum insured on a BI policy?',
                body: '',
                keyLearning: 'Calculating an insurable gross profit sum insured.',
                difficulty: 'hard',
                answers: [
                    {
                        title:
                            'An estimate of the insurable gross profit at the start of the policy period, with a 33.33% up-lift included as the maximum sum insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'An extension for covering unexpected costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Special BI cover for Solicitors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The US method of insuring BI',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title:
                    'How is depreciation treated when calculating an insurable gross profit sum insured?',
                body: '',
                keyLearning:
                    'How to identify which costs could be uninsured working expenses. Calculating an insurable gross profit sum insured. ',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Usually treated as a fixed (insurable) cost',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Usually treated as an uninsured working expense',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Usually ignored',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Usually treated as income',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title:
                    'Underestimating the estimated Gross Profit on a Declaration Linked wording would have what effect?',
                body: '',
                keyLearning:
                    'How to identify which costs could be uninsured working expenses. Calculating an insurable gross profit sum insured.',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'No effect providing the claim did not exceed 133.3% of the Sum Insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The claim would be repudiated through non disclosure',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The IP would be proportionately reduced',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Average would be applied',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Which of the following type of loss is not covered by a BI policy?',
                body: '',
                keyLearning: 'Basics of BI',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Replacement of Stock',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Increased Cost of Working',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Loss of Profit',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Inability to pay standing charges',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title:
                    'Which of the following best describes the DIFFERENCE BASIS used to calculate the sum insured under a BI policy?',
                body: '',
                keyLearning: 'Calculating an insurable gross profit sum insured',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Turnover less uninsured working expenses',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Net profit plus insured standing charges',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Gross Profit minus insured standing charges',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Turnover minus net profit',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'How would cancelling a Material Damage policy affect a BI policy?',
                body: '',
                keyLearning: 'Basics of BI',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The insured would be in breach of the material damage proviso',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The cost of rebuilding would need to be included in the GP sum insured',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A rating increase would be applied to the BI policy',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No effect as both policies operate independently',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title:
                    'Which of the following is likely to be a true variable cost used to calculate a Insurable Gross Profit Sum Insured?',
                body: '',
                keyLearning:
                    'Calculating an insurable gross profit sum insured. How to identify which costs could be uninsured working expenses',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Purchases',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Advertising costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Wages',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Loan repayments',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title:
                    'What are the main benefits for an insured of having a Declaration Link basis of sum insured on a BI policy?',
                body: '',
                keyLearning: 'Calculating an insurable gross profit sum insured.',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Protection for inflationary growth and deletion of the condition of average',
                        body: '',
                        score: 1.0
                    },
                    {
                        title:
                            'Avoids need to provide adjustment figures at the end of the period of insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Avoids need to calculate insurable gross profit',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'None - a standard sum insured basis is more beneficial for the insured',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What is the standard Limit of Liability on the Declaration Link basis?',
                body: '',
                keyLearning: 'Calculating an insurable gross profit sum insured.',
                difficulty: 'medium',
                answers: [
                    {
                        title: '133.33% of insurable gross profit estimate',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '200% of insurable gross profit estimate',
                        body: '',
                        score: 0
                    },
                    {
                        title: '150% of insurable gross profit estimate',
                        body: '',
                        score: 0
                    },
                    {
                        title: '100% of insurable gross profit estimate',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title:
                    'Why do insurers require there to be a material damage proviso on their BI policies?',
                body: '',
                keyLearning: 'Basics of BI',
                difficulty: 'easy',
                answers: [
                    {
                        title:
                            'To ensure the insured has the funds to repair/replace their property and so mitigate the BI loss',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'To delete the excess',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To change the Indemnity Period',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'To make sure the insured insures both the property and the BI with the same insurer',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'What needs to reduce to trigger a BI policy claim?',
                body: '',
                keyLearning: 'Basics of BI',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Turnover',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Wages',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Profit',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'How is VAT treated when calculating an insurable gross profit sum insured?',
                body: '',
                keyLearning:
                    'Calculating an insurable gross profit sum insured. How to identify which costs could be uninsured working expenses',
                difficulty: 'easy',
                answers: [
                    {
                        title:
                            'It is ignored - income/turnover net of VAT is used when calculating the insurable gross profit sum insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It is treated as income ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is treated as an uninsurable variable expense',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is treated as a fixed cost',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'What are specified working expenses in a BI policy?',
                body: '',
                keyLearning:
                    'Calculating an insurable gross profit sum insured. How to identify which costs could be uninsured working expenses',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Another name for uninsured variable expenses',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Another name for fixed insured expenses',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Costs of clearing up a site after damage',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A list of what is covered under a BI policy',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'Are BI policies adjustable?',
                body: '',
                keyLearning: 'Basics of BI',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Yes - almost always',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No, never',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes,  but only if on a Declaration Link basis',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only if a declaration is especially requested by the insurer',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    },
]

export const ManufacturingBusinessInterruptionIssuesAndImplicationsPart_1 = {
    questionnaire
};