// Manufacturing
import { ManufacturingPropertyInsuranceIssuesAndImplications } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-property-insurance-issues-and-implications/questionnaire.js';

import { ManufacturingBusinessInterruptionIssuesAndImplicationsPart_1 } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-business-interruption-issues-and-implications-part-1/questionnaire.js';

import { ManufacturingBusinessInterruptionIssuesAndImplicationsPart_2 } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-business-interruption-issues-and-implications-part-2/questionnaire.js';

import { ManufacturingLiabilityAndMotorInsuranceIssuesAndImplications } from '@/content/courses/itd-course-technical-manufacturing/manufacturing-liability-and-motor-insurance-issues-and-implications/questionnaire.js';

// // Constructions
import { ConstructionMarketSegmentationAndKeyIssues } from '@/content/courses/itd-course-technical-construction/construction-market-segmentation-and-key-issues/questionnaire.js';

import { ConstructionLiabilityIssuesInTheConstructionSectorPart_1 } from '@/content/courses/itd-course-technical-construction/construction-liability-issues-in-the-construction-sector-part-1/questionnaire.js';

import { ConstructionLiabilityIssuesInTheConstructionSectorPart_2 } from '@/content/courses/itd-course-technical-construction/construction-liability-issues-in-the-construction-sector-part-2/questionnaire.js';

import { ConstructionContractorsAllRisksErectionAllRisksAndBiIssues } from '@/content/courses/itd-course-technical-construction/construction-contractors-all-risks-erection-all-risks-and-bi-issues/questionnaire.js';

import { ConstructionOtherCoverOptionsForTheConstructionIndustry } from '@/content/courses/itd-course-technical-construction/construction-other-cover-options-for-the-construction-industry/questionnaire.js';

// Real Estate
import { RealEstateMarketSegmentationAndKeyIssues } from '@/content/courses/itd-course-technical-real-estate/real-estate-market-segmentation-and-key-issues/questionnaire.js';
import { RealEstateConstructionLeasesAndLendersInTheRealEstateSector } from '@/content/courses//itd-course-technical-real-estate/real-estate-construction-leases-and-lenders-in-the-real-estate-sector/questionnaire.js';
import { RealEstatePropertyInsuranceKeyCoverIssuesInPropertyOwnersPolicies } from '@/content/courses/itd-course-technical-real-estate/real-estate-property-insurance-key-cover-issues-in-property-owners-policies/questionnaire.js';
import { RealEstateIncomeProtectionLossOfRentAndAlternativeAccommodation } from '@/content/courses/itd-course-technical-real-estate/real-estate-income-protection-loss-of-rent-and-alternative-accommodation/questionnaire.js';

import { RealEstateAdditionalPoliciesToConsiderForTheRealEstateSector } from '@/content/courses/itd-course-technical-real-estate/real-estate-additional-policies-to-consider-for-the-real-estate-sector/questionnaire.js';

// axa-motor-fleet
import { MotorFleetElectricVehiclesTechnicalTraining } from '@/content/courses/axa-motor-fleet/electric-vehicles-charging-ahead/questionnaire.js';

// nta-introduction-to-professional-indemnity-insurance
import { HowLiabilityArises } from '@/content/courses/nta-introduction-to-professional-indemnity-insurance/how-liability-arises/questionnaire.js';

import { ProfessionalIndemnityPolicyCover } from '@/content/courses/nta-introduction-to-professional-indemnity-insurance/professional-Indemnity-policy-cover/questionnaire.js';

import { ProfessionalIndemnityOrCyberWhichCoverIsNeeded } from '@/content/courses/nta-introduction-to-professional-indemnity-insurance/professional-indemnity-vs-cyber/questionnaire.js';

import { CourtOperationsDuringCovid19 } from '@/content/courses/rradar-court-operations-during-covid-19/court-operations-during-covid-19/questionnaire.js';

import { IntroductionToPresentingEmployersLiabilityRisks } from '@/content/courses/nta-presenting-employers-liability-risk/presenting-employers-liability-risk/questionnaire.js';

import { ReviewingContractsForInsuranceImplications } from '@/content/courses/nta-reviewing-contracts-for-insurance-implications/reviewing-contracts-for-insurance-implications/questionnaire.js';

import { ConductRiskPartnerMeetingOurCustomersNeeds } from '@/content/courses/axa-conduct-risk-review/conduct-risk-partner-meeting-our-customers-needs/questionnaire.js';

import { DemystifyingAlternativeRiskTransferArt } from '@/content/courses/nta-demystifying-alternative-risk-transfer/questionnaire.js';

import { IntroductionToUnderinsuranceAndInflation } from '@/content/courses/nta-underinsurance-and-inflation/questionnaire.js';

import { PreActionProtocolsInNorthernIreland } from '@/content/courses/rradar-pre-action-protocols/pre-action-protocols-in-northern-ireland/questionnaire.js';

import { MergersAndAcquisitionsInsuranceIssues } from '@/content/courses/nta-mergers-and-acquisitions/mergers-and-acquisitions–insurance-issues/questionnaire.js';

import { MlpIntroductionToMlp } from '@/content/courses/axa-mlp-training/mlp-introduction-to-mlp/questionnaire.js';

import { MlpDirectorsOfficersCover } from '@/content/courses/axa-mlp-training/mlp-directors-officers-cover/questionnaire.js';

import { MlpCompanyLegalLiabilityCover } from '@/content/courses/axa-mlp-training/mlp-company-legal-liability-cover/questionnaire.js';
import { MlpEmploymentPracticesLiabilityCover } from '@/content/courses/axa-mlp-training/mlp-employment-practices-liability-cover/questionnaire.js';
import { MlpClaimsSmeRisks } from '@/content/courses/axa-mlp-training/mlp-claims-sme-risks/questionnaire.js';

import { IntroductionToHospitality } from '@/content/courses/nta-sector-issues-and-insurance/introduction-to-hospitality/questionnaire.js';

import { IntroductionToRetail } from '@/content/courses/nta-sector-issues-and-insurance/introduction-to-retail/questionnaire.js';

import { IntroductionToTerrorismCoverSessionOne } from '@/content/courses/nta-introduction-to-terrorism-cover/introduction-to-terrorism-cover-session-1/questionnaire.js';
import { IntroductionToTerrorismCoverSessionTwo } from '@/content/courses/nta-introduction-to-terrorism-cover/introduction-to-terrorism-cover-session-2/questionnaire.js';

let allQuestionnaires = [];

allQuestionnaires.push.apply(
  allQuestionnaires,
  ManufacturingPropertyInsuranceIssuesAndImplications.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ManufacturingBusinessInterruptionIssuesAndImplicationsPart_1.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ManufacturingBusinessInterruptionIssuesAndImplicationsPart_2.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ManufacturingLiabilityAndMotorInsuranceIssuesAndImplications.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ConstructionMarketSegmentationAndKeyIssues.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ConstructionLiabilityIssuesInTheConstructionSectorPart_1.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ConstructionLiabilityIssuesInTheConstructionSectorPart_2.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ConstructionContractorsAllRisksErectionAllRisksAndBiIssues.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ConstructionOtherCoverOptionsForTheConstructionIndustry.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  RealEstateMarketSegmentationAndKeyIssues.questionnaire
);
allQuestionnaires.push.apply(
  allQuestionnaires,
  RealEstateConstructionLeasesAndLendersInTheRealEstateSector.questionnaire
);
allQuestionnaires.push.apply(
  allQuestionnaires,
  RealEstatePropertyInsuranceKeyCoverIssuesInPropertyOwnersPolicies.questionnaire
);
allQuestionnaires.push.apply(
  allQuestionnaires,
  RealEstateIncomeProtectionLossOfRentAndAlternativeAccommodation.questionnaire
);
allQuestionnaires.push.apply(
  allQuestionnaires,
  RealEstateAdditionalPoliciesToConsiderForTheRealEstateSector.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  MotorFleetElectricVehiclesTechnicalTraining.questionnaire
);

allQuestionnaires.push.apply(allQuestionnaires, HowLiabilityArises.questionnaire);

allQuestionnaires.push.apply(allQuestionnaires, ProfessionalIndemnityPolicyCover.questionnaire);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ProfessionalIndemnityOrCyberWhichCoverIsNeeded.questionnaire
);

allQuestionnaires.push.apply(allQuestionnaires, CourtOperationsDuringCovid19.questionnaire);

allQuestionnaires.push.apply(
  allQuestionnaires,
  IntroductionToPresentingEmployersLiabilityRisks.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ReviewingContractsForInsuranceImplications.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  ConductRiskPartnerMeetingOurCustomersNeeds.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  DemystifyingAlternativeRiskTransferArt.questionnaire
);

allQuestionnaires.push.apply(
  allQuestionnaires,
  IntroductionToUnderinsuranceAndInflation.questionnaire
);
allQuestionnaires.push.apply(allQuestionnaires, PreActionProtocolsInNorthernIreland.questionnaire);

allQuestionnaires.push.apply(
  allQuestionnaires,
  MergersAndAcquisitionsInsuranceIssues.questionnaire
);
allQuestionnaires.push.apply(allQuestionnaires, MlpIntroductionToMlp.questionnaire);

allQuestionnaires.push.apply(allQuestionnaires, MlpDirectorsOfficersCover.questionnaire);

allQuestionnaires.push.apply(allQuestionnaires, MlpCompanyLegalLiabilityCover.questionnaire);

allQuestionnaires.push.apply(allQuestionnaires, MlpEmploymentPracticesLiabilityCover.questionnaire);

allQuestionnaires.push.apply(allQuestionnaires, MlpClaimsSmeRisks.questionnaire);
allQuestionnaires.push.apply(allQuestionnaires, IntroductionToHospitality.questionnaire);
allQuestionnaires.push.apply(allQuestionnaires, IntroductionToRetail.questionnaire);

allQuestionnaires.push.apply(allQuestionnaires, IntroductionToTerrorismCoverSessionOne.questionnaire);
allQuestionnaires.push.apply(allQuestionnaires, IntroductionToTerrorismCoverSessionTwo.questionnaire);

const questionnaires = allQuestionnaires;

export const QuestionnaireContent = {
  questionnaires,
};
