export const AuthMixin = {
  methods: {
    hasRole(requiredRole) {
      if (this.$roles() && this.$roles().includes(requiredRole)) {
        return true;
      }
      return false;
    },
    checkAndAllowAccess() {
      // console.log(this.$route.name);
      if (
        this.$tenant.auth.requiresAccessCode &&
        this.$route.name !== 'AccessCodeEntry' &&
        this.$route.name !== 'AccessCodeEntryElevate'
      ) {
        // console.log('hold up...checking...');
        var storedAccesCode = this.$localStorage.retrieveDecrypted('access-code');
        if (!this.checkAccessCodeValid(storedAccesCode.code)) {
          // console.log('erm I need a valid code please');
          this.$router.push({ name: 'AccessCodeEntry' });
        }
        // console.log('ok looks ok...off you go');
      }
      // console.log('pubic page eh... carry on');
    },
    checkAccessCodeValid(accessCode) {
      if (accessCode) {
        for (var i = 0; i < this.$tenant.auth.accessCodes.length; i++) {
          if (this.$tenant.auth.accessCodes[i].code == accessCode) {
            return this.$tenant.auth.accessCodes[i];
          }
        }
      }

      return false;
    },
  },
};
