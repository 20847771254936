<template>
  <keep-alive>
    <module-0 v-if="showModule === 0" :module="this.module" :category="this.category" />
    <module-1 v-if="showModule === 1" :module="this.module" :category="this.category" />
    <module-2 v-if="showModule === 2" :module="this.module" :category="this.category" />
    <module-3 v-if="showModule === 3" :module="this.module" :category="this.category" />
  </keep-alive>
</template>

<script>
export default {
  props: ['module', 'category'],
  components: {
    Module0: () => import('./what-is-work-burnout/Content'),
    Module1: () => import('./stages-of-burnout/Content'),
    Module2: () => import('./how-to-talk-to-your-boss-about-burnout/Content'),
    Module3: () => import('./myths-about-burnout/Content'),
  },
  data() {
    return {
      player: null,
      showQuestionnaire: true,
      contentSlugs: [
        'what-is-work-burnout',
        'stages-of-burnout',
        'how-to-talk-to-your-boss-about-burnout',
        'myths-about-burnout',
      ],
    };
  },
  computed: {
    showModule() {
      return this.contentSlugs.findIndex((contentSlug) => contentSlug == this.module.slug);
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.burnout {
  font-size: 18px;
}

.burnout p {
  font-weight: 500;
  margin-bottom: 30px;
}

.burnout h1 {
  margin: 20px 0 10px 0;
}

.burnout h2 {
  margin: 30px 0 10px 0;
}

.burnout h3 {
  font-size: 28px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 5px;
}

.burnout h4 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.burnout .slide-title {
  letter-spacing: 1px;
  margin-bottom: 40px;
  margin-top: 20px;
  padding: 20px 20px 60px 20px;
}

.burnout .slide-title h2 {
  color: #fff;
  font-size: 42px;
  font-weight: 800;
  line-height: 48px;
  margin-top: 0px;
}

.burnout .callout {
  background-color: #e5e6dc;
  padding: 30px;
  font-size: 20px;
  text-align: left;
  font-family: 'Roboto Slab', serif;
  margin-bottom: 20px;
}

.burnout .videoContainer {
  margin: 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  box-shadow: 10px 10px 28px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 28px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px -22px rgba(0, 0, 0, 0.75);
}
.burnout .videoEmbed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.burnout .angle-top {
  margin-top: 50px;
  margin-bottom: -1px;
  position: relative;
  padding: 15px 0 0 0;
  margin-right: -14px;
  overflow: visible;
  z-index: 1;
}

.burnout .angle-top:before,
.burnout .angle-top:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: right bottom;
  transform: skewY(3deg);
}

.burnout .angle-bottom {
  margin-top: -1px;
  position: relative;
  padding: 30px 0;
  overflow: visible;
  z-index: 1;
}

.burnout .angle-bottom:before,
.burnout .angle-bottom:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: left top;
  transform: skewY(3deg);
}

.burnout .btn-navigation {
  background-color: #f6edea;
  text-transform: uppercase;
  font-weight: 600;
}

.burnout .btn-navigation:hover {
  background-color: #ddd2ce;
}

@media (min-width: 576px) {
  .burnout .slide-title h2 {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (min-width: 768px) {
  .burnout .slide-title h2 {
    font-size: 52px;
    line-height: 58px;
  }
}

@media (min-width: 992px) {
  .burnout .slide-title h2 {
    font-size: 62px;
    line-height: 72px;
  }
}

@media (min-width: 1200px) {
}
</style>