const module = [
  {
    id: 'b0fbd092-843e-43f4-9f67-c4c43111c23c',
    slug: 'esg-intro-and-real-estate-strategy',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'b0fbd092-843e-43f4-9f67-c4c43111c23c|614ab504-1537-40f3-a114-72250a217d3f',
    template: 'video-template',
    title: 'ESG Intro and Real Estate Strategy',
    description: '??? ESG Intro and Real Estate Strategy - Darren Tanner',
    completedDescription: 'TODO',
    supplier: 'AXA',
    categoryId: '614ab504-1537-40f3-a114-72250a217d3f',
    questionnaireId: '',
    contents: {
      id: '6d113576-05a7-4efb-9364-581650b3793a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Thing_1</li>' +
        '<li>Thing_2</li>' +
        '<li>Thing_3</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Environmental Social and Corporate Governance (ESG)',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/{{video id}}',
          caption: {
            title: 'ESG Intro and Real Estate Strategy',
            description: '<p>Delivered by: ???</p>' + '<p>Brought to you by: AXA Insurance</p>'
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'intermediate']
  }
];

export const EsgIntroAndRealEstateStrategy = {
  module
};
