<template>
  <div class="feedback">
    <template v-if="!showThankYouMessage">
      <div class="row">
        <div class="col">
          <h2>Feedback</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-7">
          <p>
            Your feedback is valuable and important to us. It helps us improve content and features
            throughout this tool and prioritise the next additions. If you'd like to, please select
            the rating that you think is appropriate.
          </p>
        </div>
        <div class="col-12 col-md-5 text-center">
          <ul class="star-rating">
            <li v-for="i in 5" @click="onFeedbackClick(i)" v-bind:key="i" :class="starIcon(i)"></li>
          </ul>
          <div class="row">
            <div class="col">1 star - Not Great</div>
            <div class="col">5 stars - Excellent</div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-12 col-md-7">
          <h4>Got more to say?</h4>
          <p>
            If you'd like to share more details or have suggestions that would improve this content,
            that would be great!
          </p>
          <p>
            Please include your contact details if you are happy for us to get in touch to discuss.
          </p>
        </div>
        <!-- <div class="col-12 col-md-5 text-center">
          <p>More details please (optional)</p>
          <textarea class="form-control" v-model="detailedFeedback" rows="4"></textarea><br />
        </div> -->
      </div>
      <div class="row">
        <div class="col-12 col-md-5 offset-md-7 text-center">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="this.score == 0"
            @click="onSubmitFeedback"
          >
            Submit Feedback
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12 text-center">
          <h2>Thank you for your feedback!</h2>
          <p v-if="this.$tenant.showNewsletterSignup">
            If you'd like to receive email updates when we add new content or features
            <router-link :to="{ name: 'Newsletter' }">Subscribe Here</router-link>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { MetricsMixin } from '@/mixins/metrics.mixin';
import { SanitizeMixin } from '@/mixins/sanitize.mixin';

export default {
  props: ['module'],
  mixins: [MetricsMixin, SanitizeMixin],
  data: function () {
    return {
      score: 0,
      detailedFeedback: '',
      showThankYouMessage: false,
    };
  },
  computed: {
    disabled() {
      return false;
    },
  },
  methods: {
    starIcon(i) {
      if (i <= this.score) {
        return 'icon-filled-star';
      }

      return 'icon-empty-star';
    },
    onFeedbackClick(i) {
      if (i === this.score) {
        this.score = 0;
        return;
      }

      this.score = i;
    },
    onSubmitFeedback() {
      this.metricWrite('feedback', {
        event: 'submitted',
        slug: this.module.slug,
        rating: this.score,
        moreDetails: this.sanitize(this.detailedFeedback),
      });
      this.showThankYouMessage = true;
      this.score = 0;
      this.detailedFeedback = '';
    },
  },
};
</script>

<style scoped>
.feedback {
  margin-top: 100px;
  background-color: rgb(249, 251, 253);
  padding: 20px;
  margin-bottom: 20px;
  border-width: 2px;
  border-color: rgb(170, 200, 229);
  border-style: solid;
  border-radius: 10px;
}

.feedback h2 {
  margin-top: 0px;
}

.feedback h4 {
  margin-top: 0px;
}

ul {
  padding-left: 0px;
  margin-bottom: 30px;
}
ul > li {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.thanks {
  height: 300px;
}

.icon-empty-star {
  width: 48px;
  height: 48px;
  transition: 0.3s;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='Gainsboro' class='bi bi-star' viewBox='0 0 16 16'%3E%3Cpath d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z'/%3E%3C/svg%3E");
}

.icon-filled-star {
  width: 48px;
  height: 48px;
  transition: 0.3s;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='gold' class='bi bi-star-fill' viewBox='0 0 16 16'%3E%3Cpath d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/%3E%3C/svg%3E");
}
</style>