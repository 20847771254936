const module = [
  {
    id: 'a073796c-1b3a-4418-911d-2269e6ea0004',
    slug: 'introduction-to-business-interruption',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'a073796c-1b3a-4418-911d-2269e6ea0004|5d12de43-63e2-4939-b7ab-2f5677f9cc1c',
    template: 'video-template',
    title: 'An introduction to Business Interruption',
    description:
      'Using real life, practical examples you’ll learn why Business Interruption insurance is a policy of indemnity, what it means and how it works.',
    completedDescription: 'TODO',
    supplier: 'LMI Group',
    categoryId: '5d12de43-63e2-4939-b7ab-2f5677f9cc1c',
    questionnaireId: '',
    contents: {
      id: 'c982e59e-c608-4387-91f5-b3af28988602',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this video we’re joined by Steve Manning, CEO of LMI group as he guides us through an introduction to Business Interruption insurance.  He’ll explain what does a policy of indemnity mean and explains the steps you need to go through to compensate your client.' +
        'For more information on business interruption insurance you can check out <a href="https://biexplained.com/" target="blank">biexplained.com</a>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand what a policy of indemnity means</li>' +
        '<li>How does a policy indemnify the insured?</li>' +
        '<li>Understand what expenses are typically covered</li>' +
        '<li>Using a practical example to demonstrate how the cover works in practice.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Business Interruption',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/705694628',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Steve Manning, CEO of LMI group</p>' +
              '<p>Brought to you by: LMI Group, in association with AXA Insurance.</p>'
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '10'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'products', 'sales', 'foundation']
  }
];

export const IntroductionToBusinessInterruption = {
  module
};
