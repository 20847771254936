const module = [
  {
    id: 'f095540c-8180-499f-b4f4-9fab6678cb0d',
    slug: 'sales-solution-proposal',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f095540c-8180-499f-b4f4-9fab6678cb0d|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Solution Proposal',
    description:
      'In this module we’ll be taking a look at how you can build a solution that addresses your prospects needs and requirements.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: 'a869ee1b-1076-4a95-b91f-348c2fc2c71e',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Now that you have contacted your prospect you need to do the groundwork to set yourself up for success.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to set yourself up for success</li>' +
        '<li>How to set up the ‘pre-sell’ to build a vision that addresses their needs</li>' +
        '<li>How to identify decision makers and what are their buying influences</li>' +
        '<li>Understanding how to ‘level the playing field’ for the best chance of success</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Solution Proposal',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755031643',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Solution Proposal',
          button: 'Download Slides',
          fileSize: '132732',
          url: 'introduction-to-sales-solution-proposal.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 8,
      maximum: 10
    },
    statistics: {
      level: 2,
      timeEstimate: '5'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'advanced']
  }
];

export const IntroductionToSalesSolutionProposal = {
  module
};
