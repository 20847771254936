const module = [
  {
    id: 'bbea1ffe-7cb0-4199-a911-a42bb433c5f4',
    slug: 'fraud-and-fraudulent-claims',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: false,
    concatenatedID: 'bbea1ffe-7cb0-4199-a911-a42bb433c5f4|f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    template: 'zing365-iframed-template',
    title: 'Fraud and Fraudulent Claims',
    description: 'To understand what fraud is and how it can be seen in a claims situation.',
    completedDescription: 'TODO',
    supplier: 'Zing 365',
    categoryId: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    questionnaireId: '',
    contents: {
      id: 'f949d8c4-6fa1-4a50-a70c-2e9e00f345c5',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'To understand what fraud is and how it can be seen in a claims situation.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Explain the background to regulation in the general insurance industry.</li>' +
        '<li>Detail the aims of the Financial Conduct Authority</li>' +
        '<li>Understand the outcome of the Office of Fair-Trading investigations into the private motor insurance market</li>' +
        '<li>Demonstrate an understanding of the changes proposed by the Financial Ombudsman Service</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url: '/modules/bbea1ffe-7cb0-4199-a911-a42bb433c5f4/media/fraud-and-fraudulent-claims/index.html',
          caption: {
            title: '',
            description: '',
          },
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 1,
    },
    statistics: {
      level: 0,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['foundation', 'esg', 'legal', 'claims'],
  },
];

export const FraudAndFraudulentClaims = {
  module,
};
