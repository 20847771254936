const module = [
  {
    id: '97df8808-56fa-47b9-87fa-835364226218',
    slug: 'sales-process-overview',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    categoryId: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    questionnaireId: '',
    concatenatedID: '1d903ecd-b454-426a-8d43-a44307fd4ab7|97df8808-56fa-47b9-87fa-835364226218',
    template: 'video-template',
    title: 'Sales process overview',
    description:
      'Understand the importance of consistent processes and disciplines to successful sales outcomes, including sales planning and review and the role of a CRM',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    contents: {
      id: '12c0470e-8ba7-4871-b6a2-39b56c045f2d',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the importance of consistent processes and disciplines to successful sales outcomes, including sales planning and review and the role of a CRM.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Build an understanding of the importance of consistent sales processes and disciplines to successful sales outcomes</li>' +
        '<li>Learn the importance of sales planning and review</li>' +
        '<li>Understand the key role of a CRM / sales MI</li>' +
        '<li>Set the platform for future sessions to overlay key skills and methodologies</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Broker Sales in a Changing Environment - Webinar One: The Sales Process',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505195742',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Broker Sales in a Changing Environment - Webinar One: The Sales Process',
          button: 'Download Slides',
          fileSize: 529356,
          url: 'sales-in-a-changing-environment-sales-process-overview.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www.axaconnect.co.uk/siteassets/coronavirus-hub/business-support/sales-in-a-changing-environment/sales-in-a-changing-environment-week-1/'
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 6
    },
    statistics: {
      level: 0,
      timeEstimate: '70'
    },
    status: {
      // state: 'passed',
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'foundation']
  }
];

export const SalesProcessOverview = {
  module
};
