<template>
  <div>
    <module-complete-modal />
    <!-- {{ module }} -->
    <div v-if="dataLoaded" class="module-page">
      <template-zing365-iframed
        v-if="module.template == 'zing365-iframed-template'"
        :module="module"
        :course="category"
        @showTaggedModules="showTaggedModules"
      ></template-zing365-iframed>

      <template-actus-iframed
        v-else-if="module.template == 'actus-iframed-template'"
        :module="module"
        :course="category"
        @showTaggedModules="showTaggedModules"
      ></template-actus-iframed>

      <template-video
        v-else-if="module.template == 'video-template'"
        :module="module"
        :course="category"
        @showTaggedModules="showTaggedModules"
      ></template-video>

      <template-rradar-iframed
        v-else-if="module.template == 'rradar-iframed-template'"
        :module="module"
        :course="category"
        @showTaggedModules="showTaggedModules"
      ></template-rradar-iframed>

      <template-work-burnout
        v-else-if="module.template == 'broker-dev-work-burnout'"
        :module="module"
        :category="category"
        @showTaggedModules="showTaggedModules"
      ></template-work-burnout>

      <template-rradar-people-management-series
        v-else-if="module.template == 'rradar-people-management-series'"
        :module="module"
        :category="category"
        @showTaggedModules="showTaggedModules"
      ></template-rradar-people-management-series>

      <template-itd-course-your-behaviour-matters
        v-else-if="module.template == 'itd-course-your-behaviour-matters'"
        :module="module"
        :category="category"
        @showTaggedModules="showTaggedModules"
      ></template-itd-course-your-behaviour-matters>

      <div v-else>
        <div class="row temporary">
          <div class="col">
            <h1>{{ module.title }}</h1>
            <p>Module template not found</p>
          </div>
        </div>
      </div>
    </div>
    <feedback-panel
      v-if="this.module.showFeedbackForm && feedbackEnabled"
      :module="module"
    ></feedback-panel>
  </div>
</template>

<script>
import { HelpersMixin } from '@/mixins/helpers.mixin';
import { MetricsMixin } from '@/mixins/metrics.mixin';
import { ModuleContent } from '@/content/modules';
import { Courses } from '@/content/courses';
import CardStatistics from '@/components/card/default/CardStatistics';
import ModuleProgress from '@/components/ModuleProgress.vue';
import ModuleCompleteModal from '@/components/module/ModuleCompleteModal.vue';
import FeedbackPanel from '@/components/FeedbackPanel';
//templates
import TemplateActusIframed from '@/components/module/templates/ActusIFramed.vue';
import TemplateZing365Iframed from '@/components/module/templates/Zing365IFramed.vue';
import TemplateVideo from '@/components/module/templates/Video.vue';
import TemplateRradarIframed from '@/components/module/templates/RradarIFramed.vue';
import TemplateWorkBurnout from '@/content/courses/itd-course-work-burnout/Template.vue';
import TemplateItdCourseYourBehaviourMatters from '@/content/courses/itd-course-your-behaviour-matters/Template.vue';

export default {
  mixins: [HelpersMixin, MetricsMixin],
  props: ['moduleSlug'],
  components: {
    CardStatistics,
    ModuleProgress,
    FeedbackPanel,
    //templates
    TemplateActusIframed,
    TemplateZing365Iframed,
    TemplateVideo,
    TemplateRradarIframed,
    TemplateWorkBurnout,
    TemplateItdCourseYourBehaviourMatters,
    //
    ModuleCompleteModal,
  },
  data: function () {
    return {
      module: {},
      category: {},
      dataLoaded: false,
      progress: {
        visible: true,
        value: 1,
        maximum: 5,
      },
      started: false,
      feedbackEnabled: true,
    };
  },
  metaInfo() {
    return {
      title: this.module.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.module.description,
        },
      ],
    };
  },
  methods: {
    forceAnUpdate() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    showTaggedModules(data) {
      this.$router.push('/r/tags-' + data).catch((err) => {});
    },
    fetchContent: function (slug) {
      this.dataLoaded = false;
      let index = ModuleContent.modules.findIndex((property) => {
        if (property.slug === slug) {
          return true;
        }
      });
      if (index > -1) {
        this.fetchCategory(ModuleContent.modules[index].categoryId);
        this.module = ModuleContent.modules[index];
        this.dataLoaded = true;
      } else {
        this.$router.push('/error/404');
      }
    },
    fetchCategory: function (categoryId) {
      let index = Courses.courses.findIndex((property) => {
        if (property.id === categoryId) {
          return true;
        }
      });
      if (index > -1) {
        this.category = Courses.courses[index];
      } else {
        this.$router.push('/error/404');
      }
      return true;
    },
    finishModule() {
      this.$user.addToCollection('module', this.module);

      this.metricWrite('module', {
        event: 'finish',
        slug: this.moduleSlug,
      });
      this.$eventBus.$emit('show-module-complete-message');
      return true;
    },
    startModule() {
      this.metricWrite('module', {
        event: 'start',
        slug: this.moduleSlug,
      });

      return true;
    },
    disableFeedback() {
      this.feedbackEnabled = false;
    },
    enableFeedback() {
      this.feedbackEnabled = true;
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('loggedIn', this.forceAnUpdate);
    this.$eventBus.$off('loggedOut', this.forceAnUpdate);
  },
  mounted() {
    this.fetchContent(this.moduleSlug);
    this.$eventBus.$on('loggedIn', this.$forceUpdate);
    this.$eventBus.$on('loggedOut', this.$forceUpdate);

    this.metricWrite('module', {
      event: 'enter',
      slug: this.moduleSlug,
    });

    if (this.module.requiresAuth && !this.$isLoggedIn()) {
      this.$router.push('/login?b=' + this.$route.fullPath);
    }

    this.$eventBus.$on('set-module-started', this.startModule);
    this.$eventBus.$on('set-module-finished', this.finishModule);
    this.$eventBus.$on('disable-feedback', this.disableFeedback);
    this.$eventBus.$on('enable-feedback', this.enableFeedback);
    // Shouldn't need the scroll to but for some reason the page loads half way down the content without it,
    // will investigate further after the demo
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    this.$eventBus.$off('set-module-started');
    this.$eventBus.$off('set-module-finished');
    this.$eventBus.$off('disable-feedback');
    this.$eventBus.$off('enable-feedback');

    this.metricWrite('module', {
      event: 'leave',
      slug: this.moduleSlug,
    });
  },
};
</script>

<style src="vue-step-indicator/dist/vue-step-indicator.css"></style>

<style>
.module-page img {
  width: 100%;
}

.progress-steps {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
