const module = [
  {
    id: '00cdb38b-0e55-457d-bf5b-3049d83464c7',
    slug: 'esg-underwriting-update',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '00cdb38b-0e55-457d-bf5b-3049d83464c7|614ab504-1537-40f3-a114-72250a217d3f',
    template: 'video-template',
    title: 'ESG Underwriting Update',
    description: '??? ESG Underwriting Update -  Chris Benstead',
    completedDescription: 'TODO',
    supplier: 'AXA',
    categoryId: '614ab504-1537-40f3-a114-72250a217d3f',
    questionnaireId: '',
    contents: {
      id: '2926fa3d-ffb0-4340-b16f-cdb88a8e5a5a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Thing_1</li>' +
        '<li>Thing_2</li>' +
        '<li>Thing_3</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Environmental Social and Corporate Governance (ESG)',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/{{video id}}',
          caption: {
            title: 'ESG Underwriting Update',
            description: '<p>Delivered by: ???</p>' + '<p>Brought to you by: AXA Insurance</p>'
          }
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'intermediate']
  }
];

export const EsgUnderwritingUpdate = {
  module
};
