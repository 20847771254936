// Questionnaire
// {{ Course }} - {{Module Name }}
const questionnaire = [
    {
        id: '71cce13a-4cb0-4b4d-8a3f-31283695cfe4',
        title: 'Liability issues in the construction sector – part 1',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'What role does the principal contractor have under the Construction (Design and Management) Regulations 2015?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'To plan, manage, monitor and coordinate health and safety during the construction work ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'To plan, manage, monitor and coordinate health and safety during the design work',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To employ all the sub-contractors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To manage the whole construction project',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'What is the main reason for the use of collateral warranties in the construction industry?',
                body: '',
                keyLearning: 'Issues of collateral warranties',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'To create a contractual relationship so claims for pure economic loss not recoverable in tort of negligence can be made directly',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'To create a contractual relationship so claims where there is no negligence can be made directly',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To protect contractors against claims for pure economic loss',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To protect contractors against claims for negligence',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'What does LMA 5396 exclude?',
                body: '',
                keyLearning: 'Key cover, extensions and exclusions',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Claims arising from a Communicable Disease ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Claims arising from a collateral warranty',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Claims arising outside the UK',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Claims arising from contractual liability',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'Under the Construction (Design and Management) Regulations 2015, the party responsible for  eliminating, reducing or controlling foreseeable risks that may arise in the use and maintenance of the building once built is termed the…?',
                body: '',
                keyLearning: 'Key cover, extensions and exclusions',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Principal designer',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Principal contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Principal sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Principal employer',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Under which policy would the contractors\' liability for bodily injury to employees that drive mobile plant be covered?',
                body: '',
                keyLearning: 'Key cover, extensions and exclusions',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Employers\' liability',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Motor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Professional Indemnity',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Contractors All Risks',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'Which of the following is the most likely reason for a contractor to purchase a contractor\'s pollution liability policy?',
                body: '',
                keyLearning: 'Key cover, extensions and exclusions',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'It is a contractual condition that cover is purchased',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The insurance premium budget has not been used up',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The sub-contractors have the cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The site will generate waste',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'Which of the following statements may be true about collateral warranties?',
                body: '',
                keyLearning: 'Issues of collateral warranties',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The collateral warranty may impose extra obligations on the contractor beyond the original contract',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The collateral warranty may be used instead of a contract between the employer and contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The collateral warranty may be used instead of a contract between the  contractor and sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The collateral warranty does not affect the contractors\' contractual liabilities',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Why should a Principal not rely on a sub-contractors\' Indemnity to Principal extension?',
                body: '',
                keyLearning: 'Key cover, extensions and exclusions',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The limit in the sub-contractors\' policy may be insufficient',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Sub-contractors insurer\'s may be based overseas',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The sub-contractors\' brokers may be based overseas',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Principals have several sub-contractors',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'Which of the following statements is <strong>NOT</strong> true?',
                body: '',
                keyLearning: 'Difference between labour only sub-contractors (LOSC) and bona fide sub-contractors',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Bona fide sub-contractors do not have to correct work errors at their own cost',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Bona fide sub- contractors should have their own public liability insurance in place',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Bona fide sub-contractors decide their own work hours',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Bona fide sub-contractors  work for more than one contractor',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What are the Construction (Design and Management) Regulations 2015? ',
                body: '',
                keyLearning: 'Key cover, extensions and exclusions',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Regulations designed to improve health and safety in the construction industry ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Regulations replacing the Health and Safety at Work Act 1974 in the construction industry',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Regulations that apply to sub-contractors only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Regulations that apply only  to professionals involved in project designs',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Which of the following is a common condition found on a contractor\'s PL policy?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Hot work procedures must be followed',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Employees must be provided with relevant personal protective equipment',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Fire alarms must be set whenever site unattended',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Keys for mobile plant equipment must be locked away or kept off-site',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'What is a collateral warranty?',
                body: '',
                keyLearning: 'Issues of collateral warranties',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'A legal undertaking by a party used to create a contractual responsibility where it would not otherwise exist',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A contract between the contractor and sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A contract between the contractor and employer',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A contract between the contractor and professional consultant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Are work at height exclusions in an EL policy permitted under the Employers\' Liability Compulsory Insurance Act 1969?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'No - only clarification of cover endorsements are permitted',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No - only work at dangerous locations  exclusions are permitted',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes - any exclusions can be added to an EL policy',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes - only work at height exclusions are permitted in an EL policy',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'Which of the following statements is <strong>NOT</strong> true?',
                body: '',
                keyLearning: 'Difference between labour only sub-contractors (LOSC) and bona fide sub-contractors',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Labour only sub-contractors usually have their own public liability insurance in place',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Labour only sub-contractors usually have tools and materials supplied by contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Labour only sub-contractors usually are paid directly by the contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Labour only sub-contractors usually have fixed hours set by the contractor',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'What is a key issue when deciding if a sub-contractor is an employee or third party?',
                body: '',
                keyLearning: 'Difference between labour only sub-contractors (LOSC) and bona fide sub-contractors',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Control and supervision ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Amount paid to sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Age of sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'How sub-contractor describes themselves',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ConstructionLiabilityIssuesInTheConstructionSectorPart_1 = {
    questionnaire
};