// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: 'dc8d9c9b-3794-46eb-93db-a0b43d85e859',
    title: 'MLP - Claims & SME Risks',
    body: '',
    keyLearning: 'Learning',
    passScore: 2,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title:
          'In the UK SME market, the main source of claims is usually regulatory investigations and/or prosecutions. What might be some of the key risks for them to consider:',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'The number of decision makers and sign off processes, Clear on the protection provided by other covers purchased or interests of the company and the individual might not be the same',
            body: '',
            score: 1.0
          },
          {
            title: 'Only the number of decision makers and sign off processes',
            body: '',
            score: 0
          },
          {
            title: 'Only clear on the protection provided by other covers purchased',
            body: '',
            score: 0
          },
          {
            title: 'Only interests of the company and the individual might not be the same',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'Directors and officers are open to claims from many sources but not Shareholders of the company ',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'False',
            body: '',
            score: 1.0
          },
          {
            title: 'True',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const MlpClaimsSmeRisks = {
  questionnaire
};
