// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: 'e030abd2-16d0-48ea-9665-7f25037caa0e',
    title: 'Pre-action Protocols in Northern Ireland',
    body: '',
    keyLearning: 'Learning',
    passScore: 3,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title:
          'In what year did the Pre-Action Protocol for Personal Injury Litigation and Damage Only Road Traffic Accident Claims, applicable to the County Court, come into effect?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: '2013',
            body: '',
            score: 1.0
          },
          {
            title: '2008',
            body: '',
            score: 0
          },
          {
            title: '2010',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'In what year did the High Court Pre-Action Protocol for Personal Injury Litigation come into effect? ',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: '2008',
            body: '',
            score: 1.0
          },
          {
            title: '2005',
            body: '',
            score: 0
          },
          {
            title: '2013',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'The Protocols have statutory basis?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'False',
            body: '',
            score: 1.0
          },
          {
            title: 'True',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'Is the obligation on the Court to consider penalties for non-compliance?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Discretionary',
            body: '',
            score: 1.0
          },
          {
            title: 'Obligatory',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'The time limit for acknowledging receipt of a Letter of Claim is:',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: '21 days',
            body: '',
            score: 1.0
          },
          {
            title: '14 days',
            body: '',
            score: 0
          },
          {
            title: '28 days',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title:
          'The time limit for investigating a claim and providing decision in respect of liability is:',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Three months from the date of acknowledgment of the Letter of Claim.',
            body: '',
            score: 1.0
          },
          {
            title: 'Four months from the date of acknowledgement of the Letter of Claim.',
            body: '',
            score: 0
          },
          {
            title: 'Six months from the date of acknowledgement of the Letter of Claim.',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title:
          'If the cause of action occurs outside NI or the Defendant is outside the  jurisdiction the time limit for investigating a claim is:',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Six months from the date of acknowledgement of the Letter of Claim',
            body: '',
            score: 1.0
          },
          {
            title: 'Four months from the date of acknowledgement of the Letter of Claim',
            body: '',
            score: 0
          },
          {
            title: 'Three months from the date of acknowledgment of the Letter of Claim',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const PreActionProtocolsInNorthernIreland = {
  questionnaire
};
