// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
    {
        id: '0452d74c-e33c-43b0-b3dd-dfdd249b33ba',
        title: 'Professional Indemnity or Cyber – which cover is needed?',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Why might a PI insurer provide liability cover for cyber incidents?',
                body: '',
                keyLearning: 'Extent of PI cover in a cyber policy',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'It is required by regulators as part the agreed wording',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It is required by reinsurers as part the agreed wording',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is required by the government as part the agreed wording',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is required by brokers as part the agreed wording',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'Which section of a cyber policy will provide cover with a discovery trigger?',
                body: '',
                keyLearning: 'When cyber cover is needed as well as PI cover',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The section covering data breach first party costs ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The section covering cyber liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The section covering business interruption',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The section covering media liability',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'Why is the definition of professional services important when considering the extent of cyber  cover in a professional indemnity policy?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'PI cover is limited to claims arising from the defined professional services, and information and cyber risks are often not a fundamental part of the professional service being offered',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'PI cover is limited to claims arising from the defined professional services, and information and cyber risks cannot be part of the professional service being offered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'PI cover is limited to claims arising from the defined professional services, and insurers will not include cyber and information services within the definition of professional risks ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The definition of professional services will always specifically exclude any cyber activities',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'If a client bought a professional liability policy, would they be covered for liabilities assumed under a payment card industry (PCI) contract?',
                body: '',
                keyLearning: 'When cyber cover is needed as well as PI cover',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'No - even if there was full contractual liability cover as a PCI contract is not concerned with professional liability',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No - all PI policies include a full contractual liability exclusion',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes -provided the PI policy included contractual liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes- provided the PI policy had a loss of documents extension',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'If a package of cyber and PI is bought what might be an issue?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'A shared aggregate limit',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Duplication of cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cyber should only be bought as part of a management liability package',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Gaps in cover',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'Would the breach notification expenses incurred by a client after a data breach be covered under a professional indemnity policy?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'No - such first party costs would not be covered',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Yes - as part the cover for defence costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No - they are excluded as fines and penalties',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes -  within a common extension found on most PI policies',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'Do the crime extensions in cyber policies cover social engineering crimes? ',
                body: '',
                keyLearning: 'Extent of PI cover in a cyber policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Maybe - it depends on the wording',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Yes - always',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No - never',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No - only liability for crime is covered',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Why might clients need both PI and cyber insurance?',
                body: '',
                keyLearning: 'When cyber cover is needed as well as PI cover',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Only buying both policies gives them all the cover they need',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The limit under the PI policy is insufficient',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The law requires businesses to buy both',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'They are IT consultants',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'Which of the following types of costs would be covered under a PI policy?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'None of them',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Fines and penalties for a data breach',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Costs of notifying data subjects of regulatory breach',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Extra expenses incurred to minimise a business interruption',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What (if any) cyber cover might be found in a professional indemnity policy?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Third party liability only',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'First party costs only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Loss of documents only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'None',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Does any cyber policy provide professional liability cover?',
                body: '',
                keyLearning: 'Extent of PI cover in a cyber policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Maybe - some cyber policies include an optional professional indemnity module',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Yes - they all do',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Never - no cyber policies include an optional professional indemnity module',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes - it is a core part of the cyber liability module',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'What is social engineering?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Crime that uses deception to manipulate individuals ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Persuading a client to buy cyber cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A type of professional liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A type of cyber liability',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'For which of the following type of client would a broker recommend both PI and cyber cover is bought?',
                body: '',
                keyLearning: 'When cyber cover is needed as well as PI cover',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'All of them',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Solicitor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurance Broker',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Building surveyor',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'Which of the following statements is true?',
                body: '',
                keyLearning: 'Extent of PI cover in a cyber policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Most cyber policies have a specific exclusion relating to breach of professional advice or design',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No cyber policies have a specific exclusion relating to breach of professional advice or design',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only global cyber policies have a specific exclusion relating to breach of professional advice or design',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only  cyber policies issued by Lloyd\'s have a specific exclusion relating to breach of professional advice or design',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'Does any professional indemnity policy provide any cover for cyber risks?',
                body: '',
                keyLearning: 'Extent of cyber cover found in a PI policy',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Yes, a few do',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Never',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Always',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Only global policies',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ProfessionalIndemnityOrCyberWhichCoverIsNeeded = {
    questionnaire
};