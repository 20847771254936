const module = [
  {
    id: '9c8799f2-5ead-4e6c-98a7-c054d18e19d6',
    slug: 'introduction-to-hospitality',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '9c8799f2-5ead-4e6c-98a7-c054d18e19d6|06d81203-f996-435c-b246-6b25422c35d6',
    template: 'video-template',
    title: 'Introduction to Hospitality',
    description:
      'In this module you’ll cover the key risks and cover issues affecting the hospitality industry.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '06d81203-f996-435c-b246-6b25422c35d6',
    questionnaireId: '7132f93c-7a26-48e8-8895-dfba175a01cd',
    contents: {
      id: 'd5b84d85-3193-4407-b04c-ed07891dcf56',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This course details the key hospitality risk issues as well as the property/BI, Liability and Motor cover issues affecting the Hospitality sector.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the key issues facing the sector.</li>' +
        '<li>Identify specific insurance solutions for the sector.</li>' +
        '<li>Be aware of insurance market issues for the sector.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction to Hospitality',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/861955793',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>',
          },
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction to Hospitality',
          button: 'Download Slides',
          fileSize: '1,249,069',
          url: 'introduction-to-hospitality.pdf',
          caption: {
            title: '',
            description: '',
          },
          axaUrl: '',
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 2,
    },
    statistics: {
      level: 1,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['intermediate', 'sales', 'products', 'hospitality'],
  },
];

export const IntroductionToHospitality = {
  module,
};
