const module = [
  {
    id: 'ec8fa890-105b-4f90-b6e9-8e4eb93ce216',
    slug: 'manufacturing-business-interruption-issues-and-implications-part-1',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'ec8fa890-105b-4f90-b6e9-8e4eb93ce216|31e36ecf-9b1e-4831-9193-665e8534fe0c',
    template: 'video-template',
    title: 'Business interruption issues and implications - Part 1',
    description:
      'Understand what triggers a business interruption claim, how to calculate insurable gross profit and how to identify uninsured working expenses.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '31e36ecf-9b1e-4831-9193-665e8534fe0c',
    questionnaireId: '0234ef19-1ca0-4f67-944c-1a199ce559d0',
    contents: {
      id: 'a4154fe6-7321-4ded-b6c9-8e082ad38b5a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand what triggers a business interruption claim, how to calculate insurable gross profit and how to identify uninsured working expenses.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Learn the basics of business interruption cover and understand what triggers a claim</li>' +
        '<li>Understand the principles of calculating insurable gross profit</li>' +
        '<li>Learn how to identify which costs could be uninsured working expenses for manufacturing risks</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Manufacturing 2 - Business interruption issues and implications - Part 1',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/526102485',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Manufacturing 2 - Business interruption issues and implications - Part 1',
          button: 'Download Slides',
          fileSize: 7222667,
          url: 'manufacturing-business-interruption-issues-and-implications-part-1.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 4
    },
    statistics: {
      level: 1,
      timeEstimate: '28'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['manufacturing', 'products', 'sales', 'intermediate']
  }
];

export const ManufacturingBusinessInterruptionIssuesAndImplicationsPart_1 = {
  module
};
