<template>
  <nav
    class="bw-ie navbar fixed-top navbar-expand-lg navbar-light"
    v-bind:class="{ elevate: isIEElevate }"
  >
    <router-link :to="{ name: 'Home' }" class="navbar-brand logo">
      <img
        v-if="!this.isIEElevate"
        :src="getImageUrl('tenant', this.$tenant.id, 'logo_sml.png')"
        height="50px"
        alt=""
        loading="lazy"
        style="margin-left: -15px"
      />
      <div v-else>
        <img
          :src="getImageUrl('tenant', this.$tenant.id, this.logoFileName)"
          height="100px"
          alt=""
          loading="lazy"
          style="margin-left: -15px"
        />
      </div>
    </router-link>
    <template
      v-if="this.$route.name !== 'AccessCodeEntry' && this.$route.name !== 'AccessCodeEntryElevate'"
    >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style="color: #fff"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <router-link :to="{ name: 'Home' }" class="nav-link">
              <span v-if="!$isLoggedIn()">Home<span class="sr-only">(current)</span></span>
              <span v-else> Your Collection <span class="sr-only">(current)</span></span>
            </router-link>
          </li>
          <template v-if="this.$tenant.files.length > 0">
            <li class="nav-item active">
              <router-link :to="{ name: 'SpotlightArticles' }" class="nav-link">
                Spotlight Articles <span class="sr-only">(current)</span>
              </router-link>
            </li>
          </template>
          <template v-if="this.isPremierPartner">
            <li class="nav-item active">
              <a href="https://www.axa.ie/premier-partner/" target="_blank" class="nav-link">
                AXA Premier Partner <span class="sr-only">(current)</span>
              </a>
            </li>
          </template>
          <template v-if="this.isIEElevate">
            <li class="nav-item active">
              <a href="https://www.axa.ie/broker/elevate/" target="_blank" class="nav-link">
                AXA Elevate<span class="sr-only">(current)</span>
              </a>
            </li>
          </template>
          <li class="nav-item active">
            <router-link :to="{ name: 'About' }" class="nav-link">
              About <span class="sr-only">(current)</span>
            </router-link>
          </li>
        </ul>
      </div>
    </template>
  </nav>
</template>

<script>
import { HelpersMixin } from '@/mixins/helpers.mixin';

export default {
  props: ['accessCode', 'isIEElevate'],
  mixins: [HelpersMixin],
  beforeDestroy() {
    this.$eventBus.$off('loggedIn', this.forceAnUpdate);
    this.$eventBus.$off('loggedOut', this.forceAnUpdate);
  },
  computed: {
    isPremierPartner() {
      if (this.accessCode.type == 'PP') {
        return true;
      }
      return false;
    },
    logoFileName() {
      if (this.isIEElevate) {
        return 'elevate_logo_sml_mono_200.png';
      }

      return 'logo_sml.png';
    },
  },
  created() {
    this.$eventBus.$on('loggedIn', this.forceAnUpdate);
    this.$eventBus.$on('loggedOut', this.forceAnUpdate);
  },
  methods: {
    forceAnUpdate() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
};
</script>
