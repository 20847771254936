const module = [
  // Work Burnout - 3 - How to talk to your boss about burnout
  {
    id: '010aa87d-695e-432d-995d-5298413ac5c6',
    slug: 'how-to-talk-to-your-boss-about-burnout',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '010aa87d-695e-432d-995d-5298413ac5c6|32f67180-3d5b-4522-82d8-aec3c6be0971',
    template: 'broker-dev-work-burnout',
    title: 'How to talk to your boss about burnout',
    description:
      'One of the hardest things when you’re experiencing burnout is knowing how to talk to your line manager about it. So, how do you talk to your boss about burnout?',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: '32f67180-3d5b-4522-82d8-aec3c6be0971',
    questionnaireId: '',
    contents: {
      id: '7481c56a-9518-4d89-a90c-463369d54196',
      longDescription: '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: '??',
          button: '',
          fileSize: 0,
          url: '???',
          caption: {
            title: '',
            description: '<p>Delivered by: ??</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: '??',
          button: 'Download Slides',
          fileSize: 0,
          url: '???.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation']
  }
];

export const HowToTalkToYourBossAboutBurnout = {
  module
};
