// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '4628da0b-2721-4281-af58-5e1e68ae2450',
    title: 'Market segmentation and key issues',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'How should a broker deal with a request for a high commission rate that means tenants pay a higher gross amount and the property owners takes a share of the commission?',
        body: '',
        keyLearning: 'The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'hard',
        answers: [
          {
            title: 'Advise the client that commission should be justifiable and reasonable, and insurers will not support unreasonable rates of commission',
            body: '',
            score: 1.0
          },
          {
            title: 'Advise the client that any level of commission can be arranged',
            body: '',
            score: 0
          },
          {
            title: 'Advise the clients that the risk will only be marketed to insurers who will support high commission levels',
            body: '',
            score: 0
          },
          {
            title: 'Advise the client that no commission share can be arranged',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'Why does a tenant\'s insolvency affect the property owner\'s building insurance?',
        body: '',
        keyLearning: 'The issues affecting insurance faced by the real estate sector',
        difficulty: 'hard',
        answers: [
          {
            title: 'It means the premises are likely to be unoccupied',
            body: '',
            score: 1.0
          },
          {
            title: 'It means that the property owner will be left with rent arrears',
            body: '',
            score: 0
          },
          {
            title: 'It means the property owner will face additional legal expenses',
            body: '',
            score: 0
          },
          {
            title: 'It means the rent received by the property owner will reduce',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'Why does a tenant letting their flat via AirBnB cause an issue for insurers?',
        body: '',
        keyLearning: 'The issues affecting insurance faced by the real estate sector',
        difficulty: 'hard',
        answers: [
          {
            title: 'Increases the risk as unvetted renters in premises',
            body: '',
            score: 1.0
          },
          {
            title: 'AirBnB would be a an interested party on the policy',
            body: '',
            score: 0
          },
          {
            title: 'Increases rent that needs to be insured',
            body: '',
            score: 0
          },
          {
            title: 'May be dual insurance',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'Which of the following provisions is not usually found in a lease?',
        body: '',
        keyLearning: 'The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'hard',
        answers: [
          {
            title: 'The amount of the premium',
            body: '',
            score: 1.0
          },
          {
            title: 'Who is responsible for arranging insurance',
            body: '',
            score: 0
          },
          {
            title: 'Which perils must be insured against',
            body: '',
            score: 0
          },
          {
            title: 'Who must pay the premium',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'Which of the following is a NOT a common characteristic of the real estate sector?',
        body: '',
        keyLearning: 'How the real estate market is segmented ',
        difficulty: 'hard',
        answers: [
          {
            title: 'The property is of standard construction',
            body: '',
            score: 1.0
          },
          {
            title: 'The property is not occupied by property owner ',
            body: '',
            score: 0
          },
          {
            title: 'The insurance for the buildings is not arranged by the property occupier',
            body: '',
            score: 0
          },
          {
            title: 'The property is used to generate income for the property owner',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'Which of the following services are not usually included in a service charge made by the property owner to the tenant?',
        body: '',
        keyLearning: ' The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'medium',
        answers: [
          {
            title: 'Cleaning of the managing agents\' offices',
            body: '',
            score: 1.0
          },
          {
            title: 'Maintenance of the building',
            body: '',
            score: 0
          },
          {
            title: 'Cleaning of the common parts',
            body: '',
            score: 0
          },
          {
            title: 'Arranging insurance of the building',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title: 'Why does reduction in insurers\' capacity for real estate business cause an issue for brokers?',
        body: '',
        keyLearning: 'The issues affecting insurance faced by the real estate sector',
        difficulty: 'medium',
        answers: [
          {
            title: 'Insurers raise premiums as capacity reduces',
            body: '',
            score: 1.0
          },
          {
            title: 'More brokers chasing same business',
            body: '',
            score: 0
          },
          {
            title: 'Specialist real estate policies cease to be available',
            body: '',
            score: 0
          },
          {
            title: 'Only residential policies can be bought',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'Which of the following services are usually provided by a broker for a real estate client?',
        body: '',
        keyLearning: 'The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'medium',
        answers: [
          {
            title: 'Providing evidence of insurance for tenants',
            body: '',
            score: 1.0
          },
          {
            title: 'Collecting the rent',
            body: '',
            score: 0
          },
          {
            title: 'Collecting the service charge',
            body: '',
            score: 0
          },
          {
            title: 'Arranging routine repairs of the building',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'Which of the following provisions is not usually found in a lease?',
        body: '',
        keyLearning: 'The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'medium',
        answers: [
          {
            title: 'The amount of the premium',
            body: '',
            score: 1.0
          },
          {
            title: 'Who is responsible for arranging insurance',
            body: '',
            score: 0
          },
          {
            title: 'Which perils must be insured against',
            body: '',
            score: 0
          },
          {
            title: 'Who must pay the premium',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'Which of the following would be the most difficult type of real estate business to place?',
        body: '',
        keyLearning: 'How the real estate market is segmented ',
        difficulty: 'medium',
        answers: [
          {
            title: 'A block of student housing',
            body: '',
            score: 1.0
          },
          {
            title: 'Commercial office blocks',
            body: '',
            score: 0
          },
          {
            title: 'A block of flats',
            body: '',
            score: 0
          },
          {
            title: 'Shops with residential flats above',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'Why might insurers increase a flood excess on a real estate policy?',
        body: '',
        keyLearning: 'The issues affecting insurance faced by the real estate sector',
        difficulty: 'easy',
        answers: [
          {
            title: 'Some properties are in flood danger zones',
            body: '',
            score: 1.0
          },
          {
            title: 'Some tenants have asked for a higher excess',
            body: '',
            score: 0
          },
          {
            title: 'The tenant\'s property insurers have imposed a higher excess',
            body: '',
            score: 0
          },
          {
            title: 'The tenants prefer a higher excess to a higher premium',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title: 'Which of the following is NOT a service often undertaken by a managing agent?',
        body: '',
        keyLearning: 'The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'Arranging finance for purchase of a property',
            body: '',
            score: 1.0
          },
          {
            title: 'Collecting rent ',
            body: '',
            score: 0
          },
          {
            title: 'Liaising with tenants',
            body: '',
            score: 0
          },
          {
            title: 'Dealing with insurance claims',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'Which of the following statements is true?',
        body: '',
        keyLearning: 'The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'The managing agents are usually appointed by the property owner or a residents\' association',
            body: '',
            score: 1.0
          },
          {
            title: 'The managing agents are never appointed by the property owner or a residents\' association',
            body: '',
            score: 0
          },
          {
            title: 'The managing agents are only ever appointed by the property owner',
            body: '',
            score: 0
          },
          {
            title: 'The managing agents are only ever appointed by a residents\' association',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'Which of the following best defines a lease?',
        body: '',
        keyLearning: ' The role of brokers, management agents and resident’s associations in arranging insurance',
        difficulty: 'easy',
        answers: [
          {
            title: 'A lease is a legally binding contract that remains in force for a specified period ',
            body: '',
            score: 1.0
          },
          {
            title: 'A lease is not a legally binding contract that remains in force for a specified period',
            body: '',
            score: 0
          },
          {
            title: 'A lease is a legally binding contract that is has no set term',
            body: '',
            score: 0
          },
          {
            title: 'A lease is evidence of ownership of a property',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'Which of the following types of business would NOT fall within the real estate sector?',
        body: '',
        keyLearning: 'How the real estate market is segmented ',
        difficulty: 'easy',
        answers: [
          {
            title: 'Solicitors',
            body: '',
            score: 1.0
          },
          {
            title: 'Managing Agents',
            body: '',
            score: 0
          },
          {
            title: 'Property investors',
            body: '',
            score: 0
          },
          {
            title: 'Housing association',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
]

export const RealEstateMarketSegmentationAndKeyIssues = {
  questionnaire
};