<template>
  <div>
    <div class="row card-statistics">
      <div class="col required-level">
        <div class="center-info">
          <div class="icon" :class="'icon-required-level-' + statistics.level">
            <span class="icon-label">{{ this.levels[statistics.level].name }}</span>
          </div>
        </div>
      </div>
      <div class="col estimated-time">
        <div class="center-info">
          <div class="icon icon-required-time">
            <span class="icon-label">{{ statistics.timeEstimate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['statistics'],
  data: function () {
    return {
      levels: [
        { name: 'Foundation' },
        { name: 'Intermediate' },
        { name: 'Advanced' },
        { name: 'Expert' },
      ],
    };
  },
};
</script>
