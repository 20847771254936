const module = [
  {
    id: '8a0229c9-4d64-4199-8a4c-1fa459f796f9',
    slug: 'effective-communication-in-a-virtual-world',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1d903ecd-b454-426a-8d43-a44307fd4ab7|8a0229c9-4d64-4199-8a4c-1fa459f796f9',
    template: 'video-template',
    title: 'Effective communication in a virtual world',
    description:
      'Understand how to adapt and improve your communication skills in the absence of face to face meetings and develop your ability to close the sale remotely',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    questionnaireId: '',
    contents: {
      id: '3eb04d2b-430a-412a-93bd-5d31eefcdca3',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand how to adapt and improve your communication skills in the absence of face to face meetings and develop your ability to close the sale remotely' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how to adapt and improve your communication skills in the absence of face to face meetings</li>' +
        '<li>Utilise communication skills to improve the way you sound to prospects</li>' +
        '<li>Develop telephone sales questioning techniques</li>' +
        '<li>Build your ability to overcome objections and close the sale remotely</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title:
            'Broker Sales in a Changing Environment - Session Five: Effective Communication in a Virtual World',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505196677',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title:
            'Broker Sales in a Changing Environment - Session Five: Effective Communication in a Virtual World',
          button: 'Download Slides',
          fileSize: 862100,
          url: 'effective-communication-in-a-virtual-world.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www.axaconnect.co.uk/siteassets/coronavirus-hub/business-support/sales-in-a-changing-environment/sales-in-a-changing-environment-week-5/'
        }
      ]
    },
    progress: {
      value: 5,
      maximum: 6
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const EffectiveCommunicationInAVirtualWorld = {
  module
};
