import Vue from 'vue';

// Some validation rules need to be applied uniformly across the app and
// are likely to change over time and as development continues.
Vue.prototype.$VALIDATION = {
  username: 'required|email',
  password: 'required|min:6',
};

// use this to force app to use production API whist in dev.
const useProdApi = false;
const useLocalModuleData = true;

let baseUrl = '';
let domain = window.location.hostname;
let domainTenant = {};

if (domain === 'brokerwise.pro' || domain === 'brokerwise.local') {
  domainTenant = {
    id: 'f8a99a4e-3e42-4b9a-812d-3494f00b5639',
    xApplicationId: 'Brokerwise',
  };
}

if (domain === 'brokerelevate.co.uk' || domain === 'brokerelevate.local') {
  domainTenant = {
    id: '59b9aef5-15cd-4978-9b45-0964261181e3',
    xApplicationId: 'Broker Elevate',
  };
}

if (domain === 'ie.brokerwise.pro' || domain === 'ie.brokerwise.local') {
  domainTenant = {
    id: 'da84b5cd-a95a-415f-802a-3e0bee9346df',
    xApplicationId: 'Brokerwise (IE)',
  };
}

if (domain === 'ni.brokerwise.pro' || domain === 'ni.brokerwise.local') {
  domainTenant = {
    id: '88bf9ebb-8fc8-449c-a6c2-6011aae518f8',
    xApplicationId: 'Brokerwise (NI)',
  };
}

const tenant = domainTenant;

if (process.env.NODE_ENV === 'development' && !useProdApi) {
  baseUrl = 'http://api.brokerwise.local';
}

if (process.env.NODE_ENV === 'production' || useProdApi) {
  baseUrl = 'https://itd.ignapis.com/';
}

export const AppConfig = {
  baseUrl,
  tenant,
  useLocalModuleData,
};

export const AxiosConfig = {
  baseURL: baseUrl,
  xApplicationId: tenant.xApplicationId,
};
