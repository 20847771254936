const module = [
  {
    id: '70291d56-eb93-4627-88ee-6ed4fe53df05',
    slug: 'hybrid-management-effective-1-2-1s-and-appraisals-in-a-hybrid-world',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '70291d56-eb93-4627-88ee-6ed4fe53df05|9ddda366-d724-4ef0-8a75-248eda696817',
    template: 'actus-iframed-template',
    title: 'Effective 1-2-1s and appraisals in a hybrid world',
    description:
      'In this module you’ll learn how to adjust your approach to delivering and evaluating appraisals and 121’s when working remotely.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '9ddda366-d724-4ef0-8a75-248eda696817',
    questionnaireId: '',
    contents: {
      id: 'e29755c5-f360-401b-8945-a4664df181a0',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Delivering appraisals and 121’s can be challenging when working remotely. In this module you’ll learn how to adjust your approach, so you feel more confident in evaluating performance for your team.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes for this learning module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how to deliver quality remote appraisals or 1 to 1’s</li>' +
        '<li>Learn how to adjust your management style when managing performance remotely</li>' +
        '<li>Appreciate the vital importance of regular, effective 1 to 1’s when managing remote staff</li>' +
        '<li>Feel more confident in your ability to evaluate the performance of remote staff in an abnormal year</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/70291d56-eb93-4627-88ee-6ed4fe53df05/media/actus-effective-1-to-1-s-appraisals-web-v1/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const Effective121sAndAppraisalsInAHybridWorld = {
  module
};
