const module = [
  {
    id: '1a682c09-e4f0-4c88-96fe-fb0dab10b300',
    slug: 'introduction-to-presenting-employers-liability-risk',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1a682c09-e4f0-4c88-96fe-fb0dab10b300|96217a28-8620-4869-9b36-ab53bc19cc14',
    template: 'video-template',
    title: 'Introduction to Presenting employers’ liability risks',
    description:
      'You’ll learn about claims triangulations and the key information you need to provide to get the best terms',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '96217a28-8620-4869-9b36-ab53bc19cc14',
    questionnaireId: 'fe8fb93c-72ce-4877-8344-cda911e4559a',
    contents: {
      id: '7f767019-8c50-412b-bef1-a9f14c51922e',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module you’ll discover what important information you need in order to present a comprehensive EL case to your client and  why claims triangulations really matter.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Be aware of key information that should be included in an EL presentation to obtain best terms</li>' +
        '<li>Know why claims triangulations important</li>' +
        '<li>Be able to confidently explain to client what information is needed and why</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction to Presenting employers’ liability risks',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730295625',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction to Presenting employers’ liability risks - Slides',
          button: 'Download Slides',
          fileSize: '538652',
          url: 'presenting-el-risks-slides.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Example EL Risk Presentation',
          button: 'Download Example EL Risk Presentation',
          fileSize: '761273',
          url: 'example-el-risk-presentation.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '23'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'products', 'sales', 'intermediate']
  }
];

export const IntroductionToPresentingEmployersLiabilityRisks = {
  module
};
