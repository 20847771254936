const module = [
  {
    id: 'ac0f1886-4695-4bbf-a5ba-6b81e4b583c1',
    slug: 'manufacturing-business-interruption-issues-and-implications-part-2',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'ac0f1886-4695-4bbf-a5ba-6b81e4b583c1|31e36ecf-9b1e-4831-9193-665e8534fe0c',
    template: 'video-template',
    title: 'Business interruption issues and implications - Part 2',
    description:
      'Understand the key issues to consider when assessing an indemnity period and negotiating cover extensions.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '31e36ecf-9b1e-4831-9193-665e8534fe0c',
    questionnaireId: '46aeeaef-694b-40dd-9bcb-410518267d83',
    contents: {
      id: '5dc9ad12-1b8f-475f-a8b7-9a0cc09595b8',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the key issues to consider when assessing an indemnity period and negotiating cover extensions.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the key issues to consider when assessing an indemnity period</li>' +
        '<li>Learn how to identify key extensions to business interruption cover for manufacturers</li>' +
        '<li>Understand what to consider when negotiating cover extensions</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Manufacturing 3 - Business interruption issues and implications - Part 2',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/526109359',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Manufacturing 3 - Business interruption issues and implications - Part 2',
          button: 'Download Slides',
          fileSize: 8642556,
          url: 'manufacturing-business-interruption-issues-and-implications-part-2.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 4
    },
    statistics: {
      level: 1,
      timeEstimate: '33'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['manufacturing', 'products', 'sales', 'intermediate']
  }
];

export const ManufacturingBusinessInterruptionIssuesAndImplicationsPart_2 = {
  module
};
