<template>
  <div class="bw-ie footer footer-border" v-bind:class="{ elevate: isIEElevate }">
    <div class="">
      <div class="row">
        <div class="col-12 col-lg-4 footer-panel">
          <img
            v-if="this.isIEElevate"
            :src="getImageUrl('tenant', this.$tenant.id, 'logo_sml.png')"
            height="50px"
            alt=""
            loading="lazy"
            style="margin-bottom: 10px"
          />
          <ul class="menu list-inline text-uppercase">
            <li v-for="(item, index) in this.$content.footer.panels[0].list" v-bind:key="index">
              <router-link v-if="item.routeName != ''" :to="{ name: item.routeName }">
                <span v-html="item.title"></span>
              </router-link>
              <span v-else v-html="item.title"></span>
            </li>
          </ul>
          <div class="title">{{ this.$content.footer.panels[0].title }}</div>
          <div class="phone-number">{{ this.$content.footer.panels[0].body }}</div>
        </div>
        <div class="col-12 col-lg-4 footer-panel text-lg-center mb-4 mt-4">
          <template v-if="this.$tenant.showNewsletterSignup">
            <h2>Get Content Updates</h2>
            <p>Subscribe to keep up to date with the latest content and features.</p>
            <router-link :to="{ name: 'Newsletter' }">
              <button class="btn btn-subscribe">Subscribe Here</button>
            </router-link>
          </template>
        </div>
        <div class="col-12 col-lg-4 footer-panel text-lg-right">
          <ul>
            <li v-for="(item, index) in this.$content.footer.panels[1].list" v-bind:key="index">
              <span v-html="item.title"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HelpersMixin } from '@/mixins/helpers.mixin';

export default {
  mixins: [HelpersMixin],
  props: ['isIEElevate'],
};
</script>
