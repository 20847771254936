<template>
  <div>
    <div class="module-page module">
      <course-title :course="course" />
      <div class="row">
        <div class="col-md-9 order-2 order-md-1">
          <module-title-description :module="module" @showTaggedModules="showTaggedModules" />
        </div>
        <div class="col-md-3 order-1 order-md-2 text-center">
          <module-progress :module="module" />
          <module-downloads :module="module" />
          <module-completed :module="module" />
        </div>
      </div>
      <div class="row startModule">
        <div class="col">
          <button v-on:click="this.showModuleIframe" class="btn-lg btn-success">
            <div v-if="this.userHasCompletedThisModule">VIEW THE MODULE</div>
            <div v-else>START THE MODULE</div>
          </button>
        </div>
      </div>
      <module-iframe :module="module" :course="course"></module-iframe>
    </div>
  </div>
</template>

<script>
import { MetricsMixin } from '@/mixins/metrics.mixin';
import { ModuleMixin } from '@/mixins/module.mixin';
import CourseTitle from '@/components/module/CourseTitle';
import ModuleTitleDescription from '@/components/module/TitleDescription';
import ModuleProgress from '@/components/module/Progress';
import ModuleDownloads from '@/components/module/Downloads';
import ModuleCompleted from '@/components/module/Completed';
import ModuleIframe from '@/components/ModuleIframe.vue';

export default {
  mixins: [ModuleMixin, MetricsMixin],
  props: ['module', 'course'],
  components: {
    CourseTitle,
    ModuleTitleDescription,
    ModuleProgress,
    ModuleDownloads,
    ModuleCompleted,
    ModuleIframe,
  },
  data() {
    return {
      player: null,
      timerHandle: null,
    };
  },

  methods: {
    receiveMessageFromIframe(event) {
      if (event.data.message === 'markModuleComplete') {
        this.module.status.state = 'finished';
        this.$eventBus.$emit('hide-module-iframe');
        this.$eventBus.$emit('enable-feedback');
        window.setTimeout(this.markComplete, 100);
      }
    },
    timerMarkComplete() {
      this.module.status.state = 'finished';
      this.markComplete;
    },
    markComplete() {
      // this.$eventBus.$emit('set-module-finished');
      this.$user.addToCollection('module', this.module);

      this.metricWrite('module', {
        event: 'finish',
        slug: this.moduleSlug,
      });
    },
    showModuleIframe() {
      this.$eventBus.$emit('show-module-iframe');

      if (this.module.status.state != 'finished') {
        this.$eventBus.$emit('set-module-started');
        //start the clock!
        this.timerHandle = window.setTimeout(this.markComplete, 1000 * 60 * 15);
      }
    },
    showTaggedModules(tag) {
      this.$emit('showTaggedModules', tag);
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessageFromIframe);
    if (!this.userHasCompletedThisModule) {
      this.$eventBus.$emit('disable-feedback');
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessageFromIframe);
    clearTimeout(this.timerHandle);
  },
};
</script>

<style scoped>
.startModule {
  margin-top: 40px;
  text-align: center;
  background-color: #b1bdb8;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 0px;
}

li {
  margin-bottom: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.module .icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.module .icon-label {
  padding-left: 25px;
  white-space: nowrap;
}

.caption {
  margin-top: 20px;
}

.caption h6 {
  margin-bottom: 5px;
  font-weight: 800;
}

.caption p {
  text-align: justify;
  margin-bottom: 2px;
  font-size: 12px;
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 20px;
}

.player {
  max-width: 100% !important;
}
</style>
