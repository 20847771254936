export const MediaMixin = {
  methods: {
    getMedia(module, mediaType) {
      var downloadables = [];
      if (module.contents.media !== undefined) {
        for (var i = 0; i < module.contents.media.length; i++) {
          if (module.contents.media[i].type === mediaType) {
            downloadables.push(module.contents.media[i]);
          }
        }
      }
      return downloadables;
    },
    getMediaPath(type, id, filename) {
      // let path = '/' + type + 's/' + id + '/media/' + filename;
      let path = 'https://media.brokerwise.pro/' + type + 's/' + id + '/media/' + filename;

      return path;
    },
    showFileSize(fileSize) {
      if (fileSize > 0) {
        return true;
      } else {
        return false;
      }
    },
    formatFileSize(fileSize) {
      if (fileSize < 1000) {
        return fileSize + ' B';
      } else if (fileSize < 1000000) {
        return (fileSize / 1000).toFixed(0) + ' kB';
      } else if (fileSize < 1000000000) {
        return (fileSize / 1000000).toFixed(2) + ' MB';
      } else if (fileSize < 1000000000000) {
        return (fileSize / 1000000000).toFixed(2) + ' GB';
      } else {
        return fileSize;
      }
      return fileSize;
    }
  }
};
