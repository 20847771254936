<template>
  <div>
    <h2>{{ module.title }}</h2>
    <card-tags
      :module="module"
      style="margin-bottom: 10px"
      @showTaggedModules="showTaggedModules"
    />
    <p><span v-html="module.contents.longDescription"></span></p>
  </div>
</template>

<script>
import CardTags from '@/components/card/CardTags';

export default {
  props: ['module'],
  components: {
    CardTags,
  },
  methods: {
    showTaggedModules(tag) {
      this.$emit('showTaggedModules', tag);
    },
  },
};
</script>
