const module = [
  {
    id: '7abf78eb-ebbf-4fc7-9736-2ed3885843cb',
    slug: 'construction-market-segmentation-and-key-issues',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '7abf78eb-ebbf-4fc7-9736-2ed3885843cb|07ceadc8-32da-4dab-9bef-da023c3388eb',
    template: 'video-template',
    title: 'Market segmentation and key issues',
    description:
      'Understand how the construction market is segmented, how policy needs differ between segments and the information required to place business with insurers',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '07ceadc8-32da-4dab-9bef-da023c3388eb',
    questionnaireId: '260a5307-db60-49f9-bdf3-038868810624',
    contents: {
      id: 'dcb76e08-94b9-4ee2-8716-9c9b039a7cb8',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand how the construction market is segmented, how policy needs differ between segments and the information required to place business with insurers' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how insurers segment the construction markets and how the needs of each segment differ</li>' +
        '<li>Learn which types of policy are required by each part of the construction industry</li>' +
        '<li>Understand what broking information is needed in order to place construction market business with an insurer</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Construction - Market segmentation and key issues',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/541683722',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Market segmentation and key issues',
          button: 'Download Slides',
          fileSize: 4229134,
          url: 'construction-market-segmentation-and-key-issues.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['construction', 'products', 'sales', 'intermediate']
  }
];

export const ConstructionMarketSegmentationAndKeyIssues = {
  module
};
