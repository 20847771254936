// Questionnaire
const questionnaire = [
  {
    id: '5a1e3b8a-d315-4b71-a05b-c2b7c9fd9a55',
    title: 'MLP - Employment Practices Liability cover',
    body: '',
    keyLearning: 'Learning',
    passScore: 2,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title:
          'The Employment practices liability section of the Management liability policy covers the insured as an individual and an entity against claims from current and former employees alleging a breach of employment law. Which is not an example of employment Law?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Road traffic act',
            body: '',
            score: 1.0
          },
          {
            title: 'Violation of discrimination laws',
            body: '',
            score: 0
          },
          {
            title: 'Breach of contract ',
            body: '',
            score: 0
          },
          {
            title: 'unfair termination ',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'Key factors for consideration when looking at EPL cover include the quality of the management of the company and whether the company has the following:',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Has any knowledge of any ongoing conflicts in the workplace, is intending to carry out any redundancies in the next twelve months OR has a dedicated Human Resources person/department ',
            body: '',
            score: 1.0
          },
          {
            title: 'Only because it has any knowledge of any ongoing conflicts in the workplace.  ',
            body: '',
            score: 0
          },
          {
            title:
              'only because it is intending to carry out any redundancies in the next twelve months ',
            body: '',
            score: 0
          },
          {
            title: 'Only because it has a dedicated Human Resources person/department ',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const MlpEmploymentPracticesLiabilityCover = {
  questionnaire
};
