const module = [
  {
    id: '49b9cb8a-a18f-4a06-9fc3-1487599480d3',
    slug: 'change-management-leading-change',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '49b9cb8a-a18f-4a06-9fc3-1487599480d3|76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    template: 'actus-iframed-template',
    title: 'Leading change',
    description:
      'You’ll learn how to plan for an effective change, how change might impact on organisational culture and how to communicate change to your team.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    questionnaireId: '',
    contents: {
      id: '57c9029d-3612-4e95-b509-27b778830c87',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Delivering change in an organisation can be a challenge.  In this module you’ll learn how to plan for an effective change, what impact it could have on the culture and how to successfully communicate the change to your team.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your learning outcomes for this module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how to plan for an effective change</li>' +
        '<li>Discover the impact of culture on change</li>' +
        '<li>Recognise the process of large-scale change </li>' +
        '<li>Understand how to build a communication plan</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/49b9cb8a-a18f-4a06-9fc3-1487599480d3/media/actus-change-management-series-leading-change-web-v1/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 2,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'advanced']
  }
];

export const LeadingChange = {
  module
};
