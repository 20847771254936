import { BrokerwisePro } from '@/content/tenants/brokerwise-pro/definition.js'
import { BrokerelevateCoUk } from '@/content/tenants/brokerelevate-co-uk/definition.js'
import { IeBrokerwisePro } from '@/content/tenants/ie-brokerwise-pro/definition.js'
import { NiBrokerwisePro } from '@/content/tenants/ni-brokerwise-pro/definition.js'

import { IeBrokerwiseProCodes } from '@/content/tenants/ie-brokerwise-pro/accessCodes.js'
IeBrokerwisePro.tenant.auth.accessCodes = IeBrokerwiseProCodes.codes.accessCodes;

import { NiBrokerwiseProCodes } from '@/content/tenants/ni-brokerwise-pro/accessCodes.js'
NiBrokerwisePro.tenant.auth.accessCodes = NiBrokerwiseProCodes.codes.accessCodes;

let allTenants = [];

allTenants.push(BrokerwisePro.tenant);
allTenants.push(BrokerelevateCoUk.tenant);
allTenants.push(IeBrokerwisePro.tenant);
allTenants.push(NiBrokerwisePro.tenant);

const tenants = allTenants;

export const TenantContent = {
  tenants
};