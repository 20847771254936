<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light">
    <router-link :to="{ name: 'Home' }" class="navbar-brand logo">
      <img :src="getImageUrl('tenant', this.$tenant.id, 'logo_sml.png')" height="50px" alt="" loading="lazy"
        style="margin-left: -15px" crossorigin="anonymous"/>
      <div></div>
    </router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <router-link :to="{ name: 'Home' }" class="nav-link">
            <span v-if="!$isLoggedIn()">Home<span class="sr-only">(current)</span></span>
            <span v-else> Your Collection <span class="sr-only">(current)</span></span>
          </router-link>
        </li>
        <li class="nav-item active">
          <a href="https://www.axaconnect.co.uk/broker-learning-zone/" target="_blank" class="nav-link">
            AXA Connect <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item active">
          <router-link :to="{ name: 'About' }" class="nav-link">
            About <span class="sr-only">(current)</span>
          </router-link>
        </li>
        <template v-if="!$isLoggedIn()">
          <li class="nav-item active">
            <router-link :to="{ name: 'Register' }" class="nav-link">
              Sign Up <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Login' }" class="nav-link">
              Log In <span class="sr-only">(current)</span>
            </router-link>
          </li>
        </template>
        <template v-if="$isLoggedIn()">
          <li class="nav-item active">
            <router-link :to="{ name: 'Logout' }" class="nav-link">
              Log Out <span class="sr-only">(current)</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import { HelpersMixin } from '@/mixins/helpers.mixin';

export default {
  mixins: [HelpersMixin],
  beforeDestroy() {
    this.$eventBus.$off('loggedIn', this.forceAnUpdate);
    this.$eventBus.$off('loggedOut', this.forceAnUpdate);
  },
  created() {
    this.$eventBus.$on('loggedIn', this.forceAnUpdate);
    this.$eventBus.$on('loggedOut', this.forceAnUpdate);
  },
  methods: {
    forceAnUpdate() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
};
</script>