<template>
  <keep-alive>
    <module-0 v-if="showModule === 0" :module="this.module" :category="this.category" />
    <module-1 v-if="showModule === 1" :module="this.module" :category="this.category" />
    <module-2 v-if="showModule === 2" :module="this.module" :category="this.category" />
    <!-- <module-3 v-if="showModule === 3" :module="this.module" :category="this.category" />  -->
  </keep-alive>
</template>

<script>
export default {
  props: ['module', 'category'],
  components: {
    Module0: () => import('./your-behaviours-matter/Content'),
    Module1: () => import('./flexing-your-approach-to-drive-engagement/Content'),
    Module2: () => import('./motivating-your-team-for-improved-performance/Content'),
  },
  data() {
    return {
      player: null,
      showQuestionnaire: true,
      contentSlugs: [
        'your-behaviours-matter',
        'flexing-your-approach-to-drive-engagement',
        'motivating-your-team-for-improved-performance',
      ],
    };
  },
  computed: {
    showModule() {
      return this.contentSlugs.findIndex((contentSlug) => contentSlug == this.module.slug);
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.behaviours {
  font-size: 18px;
}

.behaviours p {
  font-weight: 500;
  margin-bottom: 30px;
}

.behaviours h1 {
  margin: 20px 0 10px 0;
}

.behaviours h2 {
  margin: 30px 0 10px 0;
}

.behaviours h3 {
  font-size: 28px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 5px;
}

.behaviours h4 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.behaviours h5 {
  /* font-family: 'Roboto Slab', serif; */
  background-color: #5e7d71;
  padding: 20px;
  color: #fff;
  line-height: 26px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.behaviours ul > li,
.behaviours ol > li {
  font-weight: 500;
  margin-bottom: 20px;
}

.behaviours .slide-title {
  letter-spacing: 1px;
  margin-bottom: 40px;
  margin-top: 20px;
  padding: 20px 20px 60px 20px;
}

.behaviours .slide-title h2 {
  color: #fff;
  font-size: 42px;
  font-weight: 800;
  line-height: 48px;
  margin-top: 0px;
}

.behaviours .videoContainer {
  margin: 0;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  box-shadow: 10px 10px 28px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 28px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px -22px rgba(0, 0, 0, 0.75);
}

.behaviours .videoEmbed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.behaviours .angle-top {
  margin-top: 50px;
  margin-bottom: -1px;
  position: relative;
  padding: 15px 0 0 0;
  margin-right: -14px;
  overflow: visible;
  z-index: 1;
}

.behaviours .angle-top:before,
.behaviours .angle-top:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: right bottom;
  transform: skewY(3deg);
}

.behaviours .angle-bottom {
  margin-top: -1px;
  position: relative;
  padding: 30px 0;
  overflow: visible;
  z-index: 1;
}

.behaviours .angle-bottom:before,
.behaviours .angle-bottom:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: left top;
  transform: skewY(3deg);
}

.behaviours .btn-navigation {
  background-color: #ccdcdb;
  text-transform: uppercase;
  font-weight: 600;
}

.behaviours .btn-navigation:hover {
  background-color: rgb(96, 190, 154);
}

.behaviours .cta {
  font-family: 'Roboto Slab', serif;
  font-style: italic;
  font-weight: 600;
}

.behaviours .endButton {
  padding: 20px;
  background-color: #64e76f !important;
}

.behaviours .endButton:hover {
  padding: 20px;
  background-color: #01a70f !important;
}

.behaviours .bottomNavigation {
  margin-top: 30px;
}

.behaviours .callout-angle {
  margin-top: 80px;
  background-color: #d8e2dc;
  padding: 30px 0 30px 0;
}

.behaviours .callout {
  background-color: #ccdcdb;
  padding: 30px;
  font-size: 20px;
  text-align: left;
  font-family: 'Roboto Slab', serif;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .behaviours .slide-title h2 {
    font-size: 52px;
    line-height: 52px;
  }
}

@media (min-width: 768px) {
  .behaviours .slide-title h2 {
    font-size: 52px;
    line-height: 58px;
  }
}

@media (min-width: 992px) {
  .behaviours .slide-title h2 {
    font-size: 62px;
    line-height: 72px;
  }
}

@media (min-width: 1200px) {
}
</style>