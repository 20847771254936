// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
    {
        id: '025915f6-923f-4746-ae7b-4af015144233',
        title: 'Introduction to Terrorism cover - Session One',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Which of the following statements is correct?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: ' BI extensions will include terrorism cover if Pool Re cover is bought, including any damage based covers such as customers and suppliers',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: ' BI extensions will NOT include terrorism cover if Pool Re cover is bought, including any damage based covers such as customers and suppliers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insureds can decide if they want Terrorism cover included in extensions that are bought under the ceding policy',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pool Re do not offer any cover for BI extensions',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'Why does Pool Re not cover terrorism cover for Northern Ireland?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Terrorism damage in Northern Ireland is covered via a government compensation scheme',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The government deemed Terrorism in Northern Ireland too high a risk',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'UK insurers do not provide property cover in Northern Ireland due to Brexit',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cover for Terrorism damage in Northern Ireland is only available via a Lloyd\'s scheme',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'What does the Pool Re Non-Selection Rule mean?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Policyholders must ensure that all of their insurance risks are insured through Pool Re',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Insurers must ensure that all of their Terrorism insurance risks are reinsured through Pool Re',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Policyholders are not permitted to select the extensions that apply to the Terrorism cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurers are not permitted to select the extensions that apply to the Terrorism cover',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'What is the member Threshold?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The Threshold is the retention each Member must first pay before making a recovery from Pool Re',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The Threshold is the number of policies each Member is permitted to offer',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The Threshold is the total amount that each Member can recover from Pool Re',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The Threshold is the total amount of reserves held by Pool Re',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'How long does HM Treasury have to decide if an incident meets the ‘Act of Terrorism’ definition ',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: '21 days',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '10 days',
                        body: '',
                        score: 0
                    },
                    {
                        title: '5 days',
                        body: '',
                        score: 0
                    },
                    {
                        title: '28 days',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'What is Remote Digital Interference cover that is included in the Pool Re terrorism cover',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Physical damage resulting from cyber terrorism',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Damage to data resulting from cyber terrorism',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Physical damage resulting from cyber war',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Damage to data resulting from cyber terrorism or cyber war',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'When was Non-Damage Business Interruption first offered by Pool Re',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: '2019',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '2017',
                        body: '',
                        score: 0
                    },
                    {
                        title: '2020',
                        body: '',
                        score: 0
                    },
                    {
                        title: '2022',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Which of the following describes the Pool Re terrorism cover exclusions?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'War and electronic risks',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'War and nuclear contamination',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Nuclear contamination and chemical attacks',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'War and chemical attacks',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'Can  Pool Re member insurers decline to offer Terrorism cover if cover is requested by an insured?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'No, where a member insurer provides property insurance in Great Britain, they must offer terrorism cover upon request ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No, where a member insurer provides property insurance anywhere in the world, they must offer terrorism cover upon request ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes, a member insurer can decline any terrorism risk',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No,  but a member insurer can add additional exclusions',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'Which insurers can become Pool Re members?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'any UK authorised insurer ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'any EU authorised insurer ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'any insurer',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Lloyd\'s syndicates only',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Which of the following is the definition of terrorism used by Pool Re?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'An Act of Terrorism means: acts of persons acting on behalf of or in connection with any organisation which carries out activities direct towards the overthrowing or influencing by force or violence, of His Majesty’s Government in the United Kingdom or any other government de jure or de facto.',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'An Act of Terrorism means: acts of persons acting on behalf of or in connection with any organisation which carries out activities involving force or violence',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An Act of Terrorism means: acts of any persons that carry out activities involving force or violence',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An Act of Terrorism means: acts of persons acting on behalf of or in connection with any organisation which carries out activities with the intention of  overthrowing or influencing  His Majesty’s Government in the United Kingdom or any other government de jure or de facto.',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Which of the following statements is correct?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Pool Re cover must be a mirror of the insured\'s commercial policy',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Pool Re cover can include extensions not covered by the insured\'s commercial policy',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pool Re cover can include conditions and warranties not included in  the insured\'s commercial policy',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pool Re can decide what cover to offer an insured',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Who decides if there has been a qualifying \'act of terrorism\'',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'HM Treasury ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Pool Re',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Ceding insurers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pool Re Members',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'Who owns Pool Re?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Its members',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The Government',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Shareholders',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A consortium of reinsurers',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'When was Pool Re created?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: '1993',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '2001',
                        body: '',
                        score: 0
                    },
                    {
                        title: '1997',
                        body: '',
                        score: 0
                    },
                    {
                        title: '1990',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const IntroductionToTerrorismCoverSessionOne  = {
    questionnaire
};