const module = [
  {
    id: '4b07c6a5-3a35-4e07-bd58-5b0750305d90',
    slug: 'performance-management',
    type: 'playlist',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '4b07c6a5-3a35-4e07-bd58-5b0750305d90|9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    template: 'rradar-iframed-template',
    title: 'Performance Management',
    description:
      'The second in the series of Rradars Employment Law modules we’re tackling performance management.  From what is meant by performance management through to how to manage performance informally and formally.',
    completedDescription: 'TODO',
    supplier: 'Rradar',
    categoryId: '9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    questionnaireId: '',
    contents: {
      id: '5e799cc9-3c07-4384-b48d-1cce2c419ded',
      longDescription:
        '<div class="row">' +
        '<div class="col-12 col-md-6">' +
        '<h2 class="subtitle">Description</h2>' +
        'You’ll learn how to manage the performance of your team so you can create an environment in which people can succeed, based on setting clear expectations and regular feedback.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>What is meant by ‘managing performance’</li>' +
        '<li>Performance Management Key points – Tips and tricks</li>' +
        '<li>How to manage performance issues  - informally</li>' +
        '<li>Top tips: ‘What should you do if…’</li>' +
        '<li>Formal performance management process</li>' +
        '<li>Key risks and pitfalls</li>' +
        '<li>How to confidently carry out a performance management conversation</li>' +
        '</ul>' +
        '</div>' +
        '<div class="col-12 col-md-6">' +
        '<h2 class="subtitle">Chapters</h2>' +
        "<p>This module is made up of a series of chapters which you will complete them in the order shown below but as your progress will be saved as you go you don't have to do them all in one sitting, just leave the module and the chapter you are on will be shown when you return.</p>" +
        '<ul style="margin-top:10px">' +
        '<li>Chapter 1 - What is performance management</li>' +
        '<li>Chapter 2 - Setting Clear Expectations & Providing Regular Feedback</li>' +
        '<li>Chapter 3 - The formal performance management process</li>' +
        '<li>Chapter 4 - Common Reasons for Employment Claims</li>' +
        '<li>Chapter 5 - Quiz</li>' +
        '</ul>' +
        '</div>' +
        '</div>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/17dfae1f-d43c-479d-839c-a9c7a49c6459/media/managing-sickness-absence-chapter-one/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ],
      playlist: {
        id: 'c311c839-6910-43bd-af9d-b5dcccb6f127',
        title: 'Performance Managment',
        author: 'Rradar',
        tracks: [
          {
            id: 'eb82d210-a53a-4bb6-a687-a35783d1be0f',
            title: 'Chapter 1 - What is performance management',
            slug: 'chapter-1-what-is-performance-management',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '7482b845-dd8f-4173-90e2-6e1e9491278b',
            title: 'Chapter 2 - Setting Clear Expectations & Providing Regular Feedback',
            slug: 'chapter-2-setting-clear-expectations-providing-regular-feedback',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '611126a9-dc33-468b-9b72-05ce4040b757',
            title: 'Chapter 3 - The formal performance management process',
            slug: 'chapter-3-the-formal-performance-management-process',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '09232f33-c5fd-407f-a088-ab3cc6dc9e0e',
            title: 'Chapter 4 - Common Reasons for Employment Claims',
            slug: 'chapter-4-common-reasons-for-employment-claims',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: 'fa675ce2-0854-4ee8-ab34-3dc199740eae',
            title: 'Chapter 5 - Quiz',
            slug: 'chapter-5-quiz',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          }
        ]
      }
    },
    progress: {
      value: 1,
      maximum: 0
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management', 'legal', 'intermediate']
  }
];

export const PerformanceManagement = {
  module
};
