<template>
  <div class="tag-panel">
    <div class="tag-panel-inner">
      <p
        v-for="(tag, index) in module.tags"
        :key="index"
        class="tag"
        :class="'tag-' + tag"
        @click="showTaggedModules(tag)"
      >
      <template v-if="tag.length<=3">
        {{ tag.toUpperCase() }}
      </template>
      <template v-else>
        {{ tag }}
      </template>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['module'],
  methods: {
    showTaggedModules(tag) {
      this.$emit('showTaggedModules', tag);
    },
  },
};
</script>
