const module = [
  {
    id: '645f1dca-bfec-4f33-a299-1d5bf8ffaba8',
    slug: 'the-dynamics-of-the-insurance-market-cycle',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b|645f1dca-bfec-4f33-a299-1d5bf8ffaba8',
    template: 'video-template',
    title: 'The dynamics of the insurance market cycle',
    description:
      'Understand the dynamics of the insurance market cycle and the causes of the current hard market, along with its effect on Insurers, Brokers and clients',
    completedDescription: 'TODO',
    categoryId: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b',
    questionnaireId: '',
    supplier: 'Nick Thomas & Associates',
    contents: {
      id: '49076eff-89fe-4555-991a-6a23cec371b9',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the dynamics of the insurance market cycle and the causes of the current hard market, along with its effect on Insurers, Brokers and clients' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the dynamics of the insurance market cycle</li>' +
        '<li>Develop an understanding of the specific causes of the current hardening market</li>' +
        '<li>Enhance understanding of the characteristics of a hard market and its effect on Insurers, Brokers and clients</li>' +
        "<li>Ability to frame the above in the context of our clients' businesses and/or personal circumstances</li>" +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title:
            'Thriving in the Hard Market - Module One: The Dynamics of the Insurance Market Cycle',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505199847',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas & Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title:
            'Thriving in the Hard Market - Module One: The Dynamics of the Insurance Market Cycle',
          button: 'Download Slides',
          fileSize: 1328218,
          url: 'the-dynamics-of-the-insurance-market-cycle.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www2.axa-insurance.co.uk/e/471271/lides-for-Webinar-1-96dpi-pptx/3l2pq4/415253605?h=6V72L71As0d_1j3_ZPWdqBl-4UoIACNbwltRT3kldcA'
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'passed',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'foundation']
  }
];

export const TheDynamicsOfTheInsuranceMarketCycle = {
  module
};
