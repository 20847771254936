const module = [
  // Work Burnout - 2 - Stages of burnout
  {
    id: '9c1521c2-66b7-4320-aab2-4b04ce7fc5a7',
    slug: 'stages-of-burnout',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '9c1521c2-66b7-4320-aab2-4b04ce7fc5a7|32f67180-3d5b-4522-82d8-aec3c6be0971',
    template: 'broker-dev-work-burnout',
    title: 'Stages of burnout',
    description:
      'The stages of burnout are distinct, each with individual warning signs.' +
      'This module describes the key signs and stages of burnout and how to recognise them.',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: '32f67180-3d5b-4522-82d8-aec3c6be0971',
    questionnaireId: '',
    contents: {
      id: 'c4bc0a0b-8aa8-43f6-b5d4-621b2e2656de',
      longDescription: '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: '??',
          button: '',
          fileSize: 0,
          url: '???',
          caption: {
            title: '',
            description: '<p>Delivered by: ??</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: '??',
          button: 'Download Slides',
          fileSize: 0,
          url: '???.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation']
  }
];

export const StagesOfBurnout = {
  module
};
