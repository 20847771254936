<template>
  <div>
    <step-indicator
      :current="this.progress.value - 1"
      :total="this.progress.maximum"
      :currentColor="'rgb(68, 200, 204)'"
      :defaultColor="'rgb(200, 200, 200)'"
    ></step-indicator>
  </div>
</template>

<script>
import StepIndicator from 'vue-step-indicator';

export default {
  components: {
    StepIndicator,
  },
  props: ['progress'],
};
</script>
