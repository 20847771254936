// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '4515dc3b-0496-4717-8488-629f79311885',
    title: 'Construction, leases and lenders in the real estate sector',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'Why is this statement not correct - "a solution to protect lenders is for insureds to assign the policy to the lenders"?',
        body: '',
        keyLearning: 'Issues around the lenders’ insurance requirements',
        difficulty: 'hard',
        answers: [
          {
            title: 'It is the policy proceeds not the policy that is assigned',
            body: '',
            score: 1.0
          },
          {
            title: 'The solution protects insurers not lenders',
            body: '',
            score: 0
          },
          {
            title: 'Policy assignment is not legal in the UK',
            body: '',
            score: 0
          },
          {
            title: 'The policy is assigned by the insurers not the insured',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'What does adding a non-vitiation clause to a real estate policy achieve for a lender?',
        body: '',
        keyLearning: 'Issues around the lenders’ insurance requirements',
        difficulty: 'hard',
        answers: [
          {
            title: 'A non-vitiation clause prevents the insurer from using any nondisclosure or misrepresentation or breach of policy by the insured to avoid paying the lender',
            body: '',
            score: 1.0
          },
          {
            title: 'A non-vitiation clause prevents the insurer from paying the insured the policy proceeds, instead the proceeds are paid to the lender',
            body: '',
            score: 0
          },
          {
            title: 'A non-vitiation clause makes the lender a co-insured under the policy',
            body: '',
            score: 0
          },
          {
            title: 'A non-vitiation clause automatically notes the interest of the lender in the policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'Why was the case of Mark Rowlands Ltd v Berni Inns Ltd [1985] important for insurers?',
        body: '',
        keyLearning: 'Subrogation issues for landlords and tenants',
        difficulty: 'hard',
        answers: [
          {
            title: 'It means insurers cannot subrogate against tenants causing damage if the tenant pays the premium for covering the damaged part',
            body: '',
            score: 1.0
          },
          {
            title: 'It means insurers can subrogate against any tenant',
            body: '',
            score: 0
          },
          {
            title: 'It means insurers cannot subrogate against any tenants in any circumstances',
            body: '',
            score: 0
          },
          {
            title: 'It means insurers can only subrogate against any tenants in residential premises',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'In which of these buildings are composite panels most likely to be found inside the building?',
        body: '',
        keyLearning: 'Issues with construction for brokers, insurers and lenders',
        difficulty: 'hard',
        answers: [
          {
            title: 'Cold storage warehouse',
            body: '',
            score: 1.0
          },
          {
            title: 'Office block',
            body: '',
            score: 0
          },
          {
            title: 'Retail unit',
            body: '',
            score: 0
          },
          {
            title: 'motor repair garage',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'Which of the following is NOT an issue for Real Estate insurers where volumetric unit construction has been used?',
        body: '',
        keyLearning: 'Issues with construction for brokers, insurers and lenders',
        difficulty: 'hard',
        answers: [
          {
            title: 'The construction period on site is reduced',
            body: '',
            score: 1.0
          },
          {
            title: 'Costs of repair are increased as several modules need to be removed to replace a damage module',
            body: '',
            score: 0
          },
          {
            title: 'Time taken for repairs are in increased as specialist cranes need to remove the damage module',
            body: '',
            score: 0
          },
          {
            title: 'Tracing the cause of insured damage is made more difficult',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'Why is a full waiver of subrogation against tenants required in a real estate policy?',
        body: '',
        keyLearning: 'Subrogation issues for landlords and tenants',
        difficulty: 'medium',
        answers: [
          {
            title: 'Without this tenants can be sued to recover the costs for damage caused to the parts of the building they do not occupy',
            body: '',
            score: 1.0
          },
          {
            title: 'Without this only tenants in the property can be sued to recover the costs for damage caused to the building',
            body: '',
            score: 0
          },
          {
            title: 'Without this no tenants can be sued to recover the costs for causing any damage',
            body: '',
            score: 0
          },
          {
            title: 'Without this tenants can be sued to recover all the costs for causing any damage',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title: 'Why do insurers resist allowing a lender to be a composite insured on the real estate policy?',
        body: '',
        keyLearning: 'Issues around the lenders’ insurance requirements',
        difficulty: 'medium',
        answers: [
          {
            title: 'Lender may not use funds to pay for reinstatement/repairs',
            body: '',
            score: 1.0
          },
          {
            title: 'Lenders may be overseas',
            body: '',
            score: 0
          },
          {
            title: 'Lenders may increase moral hazard ',
            body: '',
            score: 0
          },
          {
            title: 'Lenders will have different insurance brokers',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'Who is most likely to insist on a loss payee clause being added to a real estate policy?',
        body: '',
        keyLearning: 'Issues around the lenders’ insurance requirements',
        difficulty: 'medium',
        answers: [
          {
            title: 'A lender',
            body: '',
            score: 1.0
          },
          {
            title: 'A loss adjuster',
            body: '',
            score: 0
          },
          {
            title: 'An insurer',
            body: '',
            score: 0
          },
          {
            title: 'A broker',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'Which of the following is NOT an issue for Real estate insurers with the use of MMC?',
        body: '',
        keyLearning: 'Issues with construction for brokers, insurers and lenders',
        difficulty: 'medium',
        answers: [
          {
            title: 'Initial construction costs and time may be reduced',
            body: '',
            score: 1.0
          },
          {
            title: 'Potential for fire to spread rapidly',
            body: '',
            score: 0
          },
          {
            title: 'The fire brigade are reluctant to enter buildings to fight fires unless people are at risk',
            body: '',
            score: 0
          },
          {
            title: 'Melting cores of composite panels may fuel a fire',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'Why is use of CLT (Cross Laminated Timber) in a building\'s construction an issue for insurers?',
        body: '',
        keyLearning: 'Issues with construction for brokers, insurers and lenders',
        difficulty: 'medium',
        answers: [
          {
            title: 'The use of CLT  increases the fire risk',
            body: '',
            score: 1.0
          },
          {
            title: 'The use of CLT increases construction costs',
            body: '',
            score: 0
          },
          {
            title: 'The use of CLT increases construction time',
            body: '',
            score: 0
          },
          {
            title: 'The use of CLT reduces construction costs',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'What is subrogation?',
        body: '',
        keyLearning: 'Subrogation issues for landlords and tenants',
        difficulty: 'easy',
        answers: [
          {
            title: 'The right to recover costs from the party that caused the damage',
            body: '',
            score: 1.0
          },
          {
            title: 'The right to avoid liability as a tenant of the insured property',
            body: '',
            score: 0
          },
          {
            title: 'The right of the insured to claim under the insurance policy',
            body: '',
            score: 0
          },
          {
            title: 'The right of the tenant to claim under the insurance policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title: 'Why should brokers be careful if a letter confirming adequate insurance is in place is requested by a lender?',
        body: '',
        keyLearning: 'Issues around the lenders’ insurance requirements',
        difficulty: 'easy',
        answers: [
          {
            title: 'It increases the potential liability of the broker',
            body: '',
            score: 1.0
          },
          {
            title: 'Confirmation of cover to a lender is not included in a standard broker client service agreement',
            body: '',
            score: 0
          },
          {
            title: 'Lenders have tight deadlines',
            body: '',
            score: 0
          },
          {
            title: 'Only ACII qualified brokers may issue such letters',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'What is the effect of the Mortgagees and Other Interest clause on a real estate policy?',
        body: '',
        keyLearning: 'Issues around the lenders’ insurance requirements',
        difficulty: 'easy',
        answers: [
          {
            title: 'It notes the interest of lenders in the policy',
            body: '',
            score: 1.0
          },
          {
            title: 'It makes the lenders co-insured of the policy',
            body: '',
            score: 0
          },
          {
            title: 'It ensures lenders get paid first for any policy claims',
            body: '',
            score: 0
          },
          {
            title: 'It has no effect',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'From the following, what would be the insurer\'s preferred composite panel infill?',
        body: '',
        keyLearning: 'Issues with construction for brokers, insurers and lenders',
        difficulty: 'easy',
        answers: [
          {
            title: 'Mineral wool',
            body: '',
            score: 1.0
          },
          {
            title: 'Expanded polystyrene',
            body: '',
            score: 0
          },
          {
            title: 'Polystyrene polyurethane',
            body: '',
            score: 0
          },
          {
            title: 'Polyisocyanurate',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'Which of the following would NOT usually be regarded as standard construction?',
        body: '',
        keyLearning: 'Issues with construction for brokers, insurers and lenders',
        difficulty: 'easy',
        answers: [
          {
            title: 'Thatched roof',
            body: '',
            score: 1.0
          },
          {
            title: 'Brick walls',
            body: '',
            score: 0
          },
          {
            title: 'Tiled roof',
            body: '',
            score: 0
          },
          {
            title: 'Concrete floors',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
]

export const RealEstateConstructionLeasesAndLendersInTheRealEstateSector = {
  questionnaire
};