const module = [
  {
    id: '464b0624-48f4-451a-8016-f46109d82382',
    slug: 'mlp-claims-sme-risks',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '464b0624-48f4-451a-8016-f46109d82382|f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    template: 'video-template',
    title: 'MLP - Claims & SME Risks',
    description: 'Learn about MLP Claims & SME Risks',
    completedDescription: 'TODO',
    supplier: 'AXA Broker Development',
    categoryId: 'f698ff3b-eb6f-4635-8f7e-a81c6d3f69ae',
    questionnaireId: 'dc8d9c9b-3794-46eb-93db-a0b43d85e859',
    contents: {
      id: 'c3bb0e75-da4e-4ff9-b300-883de886784b',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn about MLP Claims & SME Risks' +
        // '<h2 class="subtitle">Objectives</h2>' +
        // '<ul style="margin-top:10px">' +
        // '<li>Thing_1</li>' +
        // '<li>Thing_2</li>' +
        // '<li>Thing_3</li>' +
        // '</ul>',
        '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'MLP - Claims & SME Risks',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/782594604',
          caption: {
            title: '',
            description:
              '<p>Delivered by: AXA Commercial</p>' + '<p>Brought to you by: AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'MLP - Claims & SME Risks',
          button: 'Download Slides',
          fileSize: '3268682',
          url: 'mlp-claims-sme-risks.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 5,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '5'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'products', 'foundation']
  }
];

export const MlpClaimsSmeRisks = {
  module
};
