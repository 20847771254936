const module = [
  {
    id: '5a1df134-7ab1-428d-8245-a1feaf150a5f',
    slug: 'bringing-your-leaderships-skills-together',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '5a1df134-7ab1-428d-8245-a1feaf150a5f|f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    template: 'video-template',
    title: 'Bringing your leaderships skills together',
    description:
      'Learn how to use the all the tools discussed to lead, build and develop your team to achieve success.',
    completedDescription: 'TODO',
    supplier: 'Fiona Dewar - Pragmatic',
    categoryId: 'f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    questionnaireId: '',
    contents: {
      id: '53df7bee-e082-4aff-b98e-070fd253de6a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn how to use the all the tools discussed to lead, build and develop your team to achieve success.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Recap on the previous sessions to bring them together</li>' +
        '<li>How to adopt the 4 habits of leadership</li>' +
        '<li>How to develop you team</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Bringing your leaderships skills together',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730292074',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Fiona Dewar</p>'
          }
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 4
    },
    statistics: {
      level: 2,
      timeEstimate: '16'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'advanced']
  }
];

export const BringingYourLeadershipsSkillsTogether = {
  module
};
