<template>
  <div>
    <div class="container-fluid auth" style="margin-top: 100px; margin-bottom: 200px">
      <div class="row">
        <div class="col-12">
          <h1>{{ this.$content.accessCodeEntry.title }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p v-if="!isIEElevate">{{ this.$content.accessCodeEntry.body[0].body }}</p>
          <p v-else>{{ this.$content.accessCodeEntry.body[2].body }}</p>
        </div>
        <div class="col-md-6">
          <p>{{ this.$content.accessCodeEntry.body[1].body }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-1 access-code" v-bind:class="{ elevate: isIEElevate }">
          <div v-show="this.showError" class="alert alert-danger fade show">
            <strong>{{ this.$content.accessCodeEntry.form.invalidCodeError.title }}</strong
            ><br />
            {{ this.$content.accessCodeEntry.form.invalidCodeError.body }}
          </div>
          <form v-on:submit.prevent="onClickAccess">
            <div class="form-group">
              <h4>{{ this.$content.accessCodeEntry.form.title }}</h4>
              <input
                id="accessCode"
                v-model="accessCode"
                class="form-control"
                autocomplete="off"
                v-on:keydown="this.onAccessKeyDown"
              />
            </div>
            <div class="form-group">
              <div class="col text-center">
                <button
                  type="button"
                  class="btn btn-access"
                  v-bind:class="{ elevate: isIEElevate }"
                  :disabled="this.buttonDisabled"
                  @click="onClickAccess"
                >
                  {{ this.$content.accessCodeEntry.button }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-4 support">
          <paragraphs :paragraphs="this.$content.accessCodeEntry.support"></paragraphs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CookieNotice from '@/components/CookieNotice';
import Navbar from '@/content/tenants/brokerwise-pro/Navbar';
import Paragraphs from '@/components/Paragraphs.vue';
import { HelpersMixin } from '@/mixins/helpers.mixin';
import { AuthMixin } from '@/mixins/auth.mixin';
import { MetricsMixin } from '@/mixins/metrics.mixin';
import { SanitizeMixin } from '@/mixins/sanitize.mixin';

export default {
  mixins: [HelpersMixin, AuthMixin, MetricsMixin, SanitizeMixin],
  components: {
    CookieNotice,
    Paragraphs,
    Navbar,
  },
  data() {
    return {
      accessCode: '',
      showError: false,
      module: {
        type: module,
        id: '76d97b82-e661-4f58-be08-0bce306f32a4',
        data: {},
      },
    };
  },
  computed: {
    buttonDisabled() {
      if (this.accessCode.length < 9) {
        return true;
      }

      return false;
    },
  },
  methods: {
    onClickAccess() {
      var sanitizedAccessCode = this.sanitize(this.accessCode);
      sanitizedAccessCode = sanitizedAccessCode.toUpperCase();
      var verifiedAccessCode = this.checkAccessCodeValid(sanitizedAccessCode);

      if (verifiedAccessCode !== false) {
        this.$localStorage.storeEncrypted('access-code', verifiedAccessCode);
        this.metricWrite('accessCode', {
          event: 'success',
          code: sanitizedAccessCode,
        });
        this.$localStorage.deleteItem('view');
        this.$router.push({ name: 'Home' });
      } else {
        this.showError = true;
        this.metricWrite('accessCode', {
          event: 'failed',
          code: sanitizedAccessCode,
        });
      }
    },
    onAccessKeyDown() {
      this.showError = false;
    },
  },
  mounted() {
    this.accessCode = '';
    window.scrollTo(0, 0);
  },
  props: ['isIEElevate'],
};
</script>

<style>
.access-code {
  background-color: #96825a;
  padding: 30px;
  border-radius: 5px;
  margin-top: 40px;
}

.elevate.access-code {
  background-color: #027180;
  padding: 30px;
  border-radius: 5px;
  margin-top: 40px;
}

.access-code h4 {
  margin-top: 0px;
  margin-bottom: 30px;
  color: #fff;
  text-transform: capitalize;
}

.support {
  background-color: #96825a63;
  padding: 20px;
  margin-top: 40px;
}

.support h4 {
  margin-top: 0px;
}

.btn-access {
  background-color: #e2b251;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 20px 8px 20px;
  font-size: 26px;
}

.elevate.btn-access {
  background-color: #00adc6 !important;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 20px 8px 20px;
  font-size: 26px;
}

.support a {
  color: #000;
  font-weight: 600;
}

.support a:hover {
  color: rgb(238, 102, 102) !important;
}

.btn-access:hover {
  color: #fff;
}
</style>
