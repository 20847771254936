const module = [
  {
    id: '03bd1c66-a6b9-40c3-9d21-748fec83cdd7',
    slug: 'how-to-create-a-linkedIn-profile',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '03bd1c66-a6b9-40c3-9d21-748fec83cdd7|8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    template: 'video-template',
    title: 'How to create a LinkedIn profile',
    description:
      'The second in a series dedicated to LinkedIn. This module discusses the foundations of a LinkedIn profile, and tips you need to implement to make a great profile',
    completedDescription: 'TODO',
    categoryId: '8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    questionnaireId: '',
    contents: {
      id: '',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'The second in a series dedicated to LinkedIn. This module discusses the foundations of a LinkedIn profile, and tips you need to implement to make a great profile' +
        '<h2 class="subtitle">Objectives / Tips</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Tips for the perfect headshot</li>' +
        '<li>How to make the most out of your bio, including some words to avoid</li>' +
        '<li>How using rich content can help lift your profile to become more than an online CV</li>' +
        '<li>The benefits of adding in your education</li>' +
        '<li>The key factors that will make your profile a success.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'How to create a LinkedIn profile',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/546401178',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Mike McNabb - Broker Marketing Manager.</p>' +
              '<p>Brought to you by: Ignition NBS Ltd.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How to create a LinkedIn profile',
          button: 'Download Slides',
          fileSize: 1365300,
          url: 'how-to-create-a-linkedIn-profile.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '11'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'foundation']
  }
];

export const HowToCreateALinkedInProfile = {
  module
};
