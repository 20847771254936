const module = [
  {
    id: '9823ef3f-0900-43e8-b82d-249e72d0c732',
    slug: 'the-future-of-regulation',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '9823ef3f-0900-43e8-b82d-249e72d0c732|f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    template: 'video-template',
    title: 'The Future of Regulation',
    description:
      'This course is designed to look at possible future regulation within the general insurance industry.',
    completedDescription: 'TODO',
    supplier: 'Zing 365',
    categoryId: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    questionnaireId: '',
    contents: {
      id: '56c8d817-e9b7-47cb-beff-911dc52dcb09',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This course is designed to look at possible future regulation within the general insurance industry.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Explain the background to regulation in the general insurance industry.</li>' +
        '<li>Detail the aims of the Financial Conduct Authority</li>' +
        '<li>Understand the outcome of the Office of Fair-Trading investigations into the private motor insurance market</li>' +
        '<li>Demonstrate an understanding of the changes proposed by the Financial Ombudsman Service</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'The Future of Regulation',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/910476573',
          caption: {
            title: '',
            description: '<p>Brought to you by: Zing365</p>',
          },
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 1,
    },
    statistics: {
      level: 0,
      timeEstimate: '90',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['foundation', 'esg','fca', 'regulation'],
  },
];

export const TheFutureOfRegulation = {
  module,
};
