<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light">
    <router-link :to="{ name: 'Home' }" class="navbar-brand logo">Broker Elevate</router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li v-if="hasRole('ntc_file_writer')" class="nav-item active">
          <router-link :to="{ name: 'PropositionUpdate' }" class="nav-link">
            <span>Upload<span class="sr-only">(current)</span></span>
          </router-link>
        </li>

        <li class="nav-item active">
          <router-link :to="{ name: 'Home' }" class="nav-link">
            <span>Home<span class="sr-only">(current)</span></span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'About' }" class="nav-link">
            About <span class="sr-only">(current)</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Proposition' }" class="nav-link">
            Proposition <span class="sr-only">(current)</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'BrokerwiseModules' }" class="nav-link"
            >Brokerwise Modules<span class="sr-only">(current)</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Contact' }" class="nav-link">
            Contact <span class="sr-only">(current)</span>
          </router-link>
        </li>
        <li v-if="$isLoggedIn() && hasRole('ntc_file_reader')" class="nav-item active">
          <router-link :to="{ name: 'PropositionStatus' }" class="nav-link">
            <span>Deals<span class="sr-only">(current)</span></span>
          </router-link>
        </li>
        <template v-if="!$isLoggedIn()">
          <li class="nav-item">
            <div class="auth">
              <!-- <router-link :to="{ name: 'Register' }" class="nav-link">
                Sign&nbsp;Up<span class="sr-only">(current)</span>
              </router-link> -->

              <router-link :to="{ name: 'Login' }" class="nav-link">
                &nbsp;Log&nbsp;In<span class="sr-only">(current)</span>
              </router-link>
            </div>
          </li>
        </template>
        <template v-if="$isLoggedIn()">
          <li class="nav-item">
            <router-link :to="{ name: 'Logout' }" class="nav-link">
              Log Out <span class="sr-only">(current)</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import { HelpersMixin } from '@/mixins/helpers.mixin';
import { AuthMixin } from '@/mixins/auth.mixin';

export default {
  mixins: [HelpersMixin, AuthMixin],
  beforeDestroy() {
    this.$eventBus.$off('loggedIn', this.forceAnUpdate);
    this.$eventBus.$off('loggedOut', this.forceAnUpdate);
  },
  created() {
    this.$eventBus.$on('loggedIn', this.forceAnUpdate);
    this.$eventBus.$on('loggedOut', this.forceAnUpdate);
  },
  methods: {
    forceAnUpdate() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
};
</script>
