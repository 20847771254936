let codes = {
  accessCodes: [
    // PPs and CPs
    { name: 'Abbeyautoline', code: 'PP-ABBEYA', type: 'PP' },
    { name: 'Brian Mcgurgan Insurance', code: 'PP-BRIANM', type: 'PP' },
    { name: 'Dickson & Co', code: 'PP-DICKSN', type: 'PP' },
    { name: 'Find Insurance Ni Ltd', code: 'PP-FINDIN', type: 'PP' },
    { name: 'Hughes Insurance Services Limited', code: 'PP-HUGHES', type: 'PP' },
    { name: 'J Caulfield & Co Ltd & Caulfield Corporate ', code: 'PP-JCAULF', type: 'PP' },
    { name: 'S Rankin & Co', code: 'PP-SRANKI', type: 'PP' },
    { name: 'William Kerr & Co (Insurance Consultants) Ltd', code: 'PP-WILLIA', type: 'PP' },
    { name: 'Willis & Company (Insurance Brokers) Ltd', code: 'PP-WILLIS', type: 'PP' },
    { name: 'Abbey Bond Lovis Limited ', code: 'CP-ABBEYB', type: 'CP' },
    { name: 'Aon Risk Services (Now Part Of Abl Group)', code: 'CP-AONRIS', type: 'CP' },
    { name: 'Arthur J. Gallagher Holdings (Uk) Ltd', code: 'CP-ARTHUR', type: 'CP' },
    { name: 'Digney Grant Ltd', code: 'CP-DIGNEY', type: 'CP' },
    { name: 'Lockton Companies', code: 'CP-LOCKTO', type: 'CP' },
    { name: 'Marsh Uk Limited', code: 'CP-MARSHU', type: 'CP' },
    { name: 'Mcgrady Limited', code: 'CP-MCGRAD', type: 'CP' },
    { name: 'Oyster Risk Solutions Ltd', code: 'CP-OYSTER', type: 'CP' },
    { name: 'Risk Alliance International Ltd', code: 'CP-RISKAL', type: 'CP' },
    { name: 'Towergate Underwriting Group Ltd', code: 'CP-TOWERG', type: 'CP' },
    { name: 'Willis Towers Watson Insurances (Ireland) Ltd', code: 'CP-WILLIS', type: 'CP' },
    { name: 'Robertson Low Insurances Ltd (Aston Lark)', code: 'CP-ROBERT', type: 'CP' },
    { name: 'Aston Lark (Ni)', code: 'CP-ASTNLNI', type: 'CP' },
    // Small Brokers
    { name: 'ALAN GASTON INSURANCE SERVICES', code: 'BR-ALANGA', type: 'BR' },
    { name: 'ARLENE JARDINE INSURANCE SERVICES', code: 'BR-ARLENE', type: 'BR' },
    { name: "BERNADETTE O'HARE INSURANCE SERVICES", code: 'BR-BERNAD', type: 'BR' },
    { name: 'BYRNE INSURANCE(APRICOT)', code: 'BR-BYRNEI', type: 'BR' },
    { name: 'CITYGATE INSURANCE SERVICES LTD', code: 'BR-CITYGA', type: 'BR' },
    { name: 'DOWN INSURANCES LTD ', code: 'BR-DOWNIN', type: 'BR' },
    { name: 'ERNE INSURANCE SERVICES EDI ACCOUNT', code: 'BR-ERNEIN', type: 'BR' },
    { name: 'G & C INSURANCE SERVICES LTD.', code: 'BR-GCINSU', type: 'BR' },
    { name: 'HUTCHISON & WHITE LTD', code: 'BR-HUTCHI', type: 'BR' },
    { name: 'J F CARBERRY & CO', code: 'BR-JFCARB', type: 'BR' },
    { name: 'J J BALLANTINE & CO ', code: 'BR-JJBALL', type: 'BR' },
    { name: 'JAMES G MCNICHOLL JAMES GERALD', code: 'BR-JAMESG', type: 'BR' },
    { name: 'JOSEPH W BURLEY & PARTNERS (UK) LTD', code: 'BR-JOSEPH', type: 'BR' },
    { name: 'KERR HENDERSON (GEN INS SERV) LTD', code: 'BR-KERRHE', type: 'BR' },
    { name: 'KYLE INSURANCE AND FINANCIAL SERVICE', code: 'BR-KYLEIN', type: 'BR' },
    { name: 'MCCUMISKEY & CO INSURANCES', code: 'BR-MCCUMI', type: 'BR' },
    { name: 'MCL INSURE TECH LTD ', code: 'BR-MCLINS', type: 'BR' },
    { name: 'MICHAEL MCCULLOCH INSURANCES EDI', code: 'BR-MICHAE', type: 'BR' },
    { name: 'MMB INSURANCE BROKERS', code: 'BR-MMBINS', type: 'BR' },
    { name: 'MOMENTUM BROKER SOLUTIONS LTD', code: 'BR-MOMENT', type: 'BR' },
    { name: 'P J MCILROY & SON INSURANCE & INVEST', code: 'BR-PJMCIL', type: 'BR' },
    { name: 'PROPERTY INSURANCE CENTRE', code: 'BR-PROPER', type: 'BR' },
    { name: 'RAVENHALL RISK SOLUTIONS LTD', code: 'BR-RAVENH', type: 'BR' },
    { name: 'SELECT BROKERS LTD T/A SELECT INSCE', code: 'BR-SELECT', type: 'BR' },
    { name: 'STEPHEN MCANEE T/AS H A LESLIE & CO', code: 'BR-STEPHE', type: 'BR' },
    { name: 'STEVENSON ASSOCIATES T/A STEVENSON RISK SOLUTIONS', code: 'BR-STEVEN', type: 'BR' },
    { name: 'T L DALLAS & COMPANY LIMITED', code: 'BR-TLDALL', type: 'BR' },
    { name: 'T OSCAR ROLLINS & COMPANY LTD', code: 'BR-TOSCAR', type: 'BR' },
    { name: 'W TODD & SON LTD', code: 'BR-WTODDS', type: 'BR' },
    { name: 'WALLACE INSURANCE BROKERS', code: 'BR-WALLAC', type: 'BR' },

    //Province wide - in IE and NI
    { name: 'Alan Brown & Co (Ni) Ltd', code: 'PP-ALANBR', type: 'PP' },
    { name: 'Brady Insurance Services Ltd', code: 'PP-BRADYI', type: 'PP' },
    { name: 'Cip Insurance Brokers Ltd', code: 'PP-CIPINS', type: 'PP' },
    { name: 'Curran Connolly & Co', code: 'PP-CURRAN', type: 'PP' },
    { name: 'E A Davies & Co Ltd', code: 'PP-EADAVI', type: 'PP' },
    { name: 'Ivan Bell T/A Insurance Matters General Brokers', code: 'PP-IVANBE', type: 'PP' },
    { name: 'J Hatty & Co', code: 'PP-JHATTY', type: 'PP' },
    { name: 'J N Chambers Ltd', code: 'PP-JNCHAM', type: 'PP' },
    { name: 'Mccarroll Mcconnell & Co Ltd', code: 'PP-MCCARR', type: 'PP' },
    { name: 'Oakland Insurance Services Ltd', code: 'PP-OAKLAN', type: 'PP' },
    { name: 'Peter Mcaleer Ltd ', code: 'PP-PETERM', type: 'PP' },
    { name: 'Provincewide Ltd', code: 'PP-PROVIN', type: 'PP' },
    //AXA Internals
    { name: 'Axa-NI-Internal', code: 'AXA-NI-INT', type: 'AXA' },
    // AXA Elevate
    { name: 'NI Elevate', code: 'NI-ELEVATE', type: 'ELE' },
  ],
};

export const NiBrokerwiseProCodes = {
  codes,
};
