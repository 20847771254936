<template>
  <div>
    <div class="module-page module">
      <course-title :course="course" />
      <div class="row">
        <div class="col-md-9 order-2 order-md-1">
          <module-title-description :module="module" @showTaggedModules="showTaggedModules" />
          <module-more-info v-if="module.questionnaireId != ''">
            <template slot="title">Knowledge Quiz</template>
            <template slot="body"
              >This module includes some randomised questions about the information it contains.
              You'll need to complete the quiz successfully to complete the module and add it to
              your collection.</template
            >
          </module-more-info>
          <module-accreditation
            v-if="module.accreditation !== undefined"
            class="accreditation"
            :module="module"
          >
            <template slot="title">{{ module.accreditation.title }}</template>
            <template slot="body"><span v-html="module.accreditation.body"></span></template>
          </module-accreditation>
        </div>

        <div class="col-md-3 order-1 order-md-2 text-center">
          <module-progress :module="module" />
          <module-downloads :module="module" />
          <module-completed :module="module" />
          <module-send-certificate v-if="module.accreditation !== undefined" :module="module" />
        </div>
      </div>
      <div class="row"></div>
      <div class="row">
        <div class="col">
          <module-vimeo-player :module="module" />
        </div>
      </div>
      <questionnaire
        v-if="module.questionnaireId != '' && showQuestionnaire"
        :module="module"
        :category="course"
      >
      </questionnaire>
    </div>
  </div>
</template>

<script>
import { ModuleMixin } from '@/mixins/module.mixin';
import CourseTitle from '@/components/module/CourseTitle';
import ModuleTitleDescription from '@/components/module/TitleDescription';
import ModuleMoreInfo from '@/components/module/MoreInfo.vue';
import ModuleAccreditation from '@/components/module/Accreditation.vue';
import ModuleVimeoPlayer from '@/components/module/VimeoPlayer';
import ModuleProgress from '@/components/module/Progress';
import ModuleDownloads from '@/components/module/Downloads';
import ModuleCompleted from '@/components/module/Completed';
import ModuleSendCertificate from '@/components/module/SendCertificate';
import Questionnaire from '@/components/module/Questionnaire';

export default {
  mixins: [ModuleMixin],
  props: ['module', 'course'],
  components: {
    CourseTitle,
    ModuleTitleDescription,
    ModuleMoreInfo,
    ModuleAccreditation,
    ModuleVimeoPlayer,
    ModuleProgress,
    ModuleDownloads,
    ModuleCompleted,
    ModuleSendCertificate,
    Questionnaire,
  },
  data() {
    return {
      showQuestionnaire: true,
    };
  },
  computed: {
    userHasCompletedThisModule() {
      if (this.$user.findInCollection('module', this.module.id)) {
        return true;
      }

      return false;
    },
  },
  methods: {
    showTaggedModules(tag) {
      this.$emit('showTaggedModules', tag);
    },
  },
};
</script>
