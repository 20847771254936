const module = [
  {
    id: 'f35cf08c-2d4e-4ac0-af1f-7b0a64c9f9f3',
    slug: 'demystifying-alternative-risk-transfer',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f35cf08c-2d4e-4ac0-af1f-7b0a64c9f9f3|78626287-b0ac-4384-a96c-77d172323d18',
    template: 'video-template',
    title: 'Demystifying Alternative Risk Transfer (ART)',
    description: 'You’ll learn the main types of alternative risk transfer ',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '78626287-b0ac-4384-a96c-77d172323d18',
    questionnaireId: '188ac66c-07de-4a66-98c0-3156262b6886',
    contents: {
      id: '224b01d3-7dee-4ee5-8a69-5f47490b1822',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module you’ll discover the main types of alternative risk transfer, identify key issues when considering the use of alternative risk transfer. You will also learn about the advantages and disadvantages of the main types of alternative risk transfer and be able to identify when it might be viable for a customer.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Be aware of the main types of alternative risk transfer</li>' +
        '<li>Be able to identify when alternative risk transfer might be viable</li>' +
        '<li>Know the key issues when discussing alternative risk transfer with a client</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Demystifying Alternative Risk Transfer (ART)',
          button: '',
          url: 'https://player.vimeo.com/video/742268079',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Demystifying Alternative Risk Transfer (ART) Slides',
          button: 'Download Slides',
          fileSize: '374140',
          url: 'demystifying-alternative-risk-transfer-training-slides.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Demystifying Alternative Risk Transfer (ART)',
          button: 'Download Case Study & Glossary',
          fileSize: '1008552',
          url: 'case-study-and-glossary-demystifying-art.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 2,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'advanced']
  }
];

export const DemystifyingAlternativeRiskTransferArt = {
  module
};
