const module = [
  {
    id: 'd20d8177-83f8-4fb6-9f31-9a7113969f1e',
    slug: 'sales-objection-handling',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'd20d8177-83f8-4fb6-9f31-9a7113969f1e|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Objection Handling',
    description:
      'In this module we’ll be looking at preventing and if necessary, managing prospect objections.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: 'ed141840-7be0-48db-adb8-86d87bb6952c',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Managing objections is a key component in a salesperson’s armoury.  In this module we’ll showcase a model to assist you in overcoming objections.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Objection prevention</li>' +
        '<li>Understanding a 4-step model for objection handling</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Objection Handling',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755032108',
          caption: {
            title: 'Objection Handling',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Objection Handling',
          button: 'Download Slides',
          fileSize: '127316',
          url: 'introduction-to-sales-objection-handling.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 9,
      maximum: 10
    },
    statistics: {
      level: 2,
      timeEstimate: '4'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'advanced']
  }
];

export const IntroductionToSalesObjectionHandling = {
  module
};
