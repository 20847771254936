const module = [
  {
    id: 'be8d85f2-4054-4f56-92fb-0256bc9ce996',
    slug: 'modern-building-techniques',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'be8d85f2-4054-4f56-92fb-0256bc9ce996|<category-uuid}}',
    template: 'video-template',
    title: 'Modern Building Techniques',
    description:
      'This session will explore some contemporary methods of building and the challenges we face with modern structures when affected by perils such as fire, escape of water and flood',
    completedDescription: 'TODO',
    supplier: 'AXA',
    categoryId: 'd139596c-fd9b-4651-815a-6de33d2601ab',
    questionnaireId: '',
    contents: {
      id: 'bc92b377-8c01-4527-945c-0fbbb099c890',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This session will explore some contemporary methods of building and the challenges we face with modern structures and help with the following:' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand some of the most common modern methods of construction and how insurers grade them for Fire risk</li>' +
        '<li>The risks they face during construction phase and as-built</li>' +
        '<li>Understand the issues attached to non fire events such as escape of water, flood and impact</li>' +
        '<li>Be aware of the combustibility of materials and how they are protected</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Modern Building Techniques',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/782470391',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Rob Dakin, Head of Business Resilience Management Delivery AXA Insurance</p>' +
              '<p>Brought to you by: AXA Insurance</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Modern Building Techniques',
          button: 'Download Slides',
          fileSize: '3796518',
          url: 'axa-technical-training-modern-building-techniques.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'real estate', 'intermediate']
  }
];

export const ModernBuildingTechniques = {
  module
};
