const module = [
  {
    id: 'be735670-e3fa-4712-8dac-05bead879e4d',
    slug: 'employee-series-key-communication-skills-and-self-awareness',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'be735670-e3fa-4712-8dac-05bead879e4d|f7673de3-978f-4260-9f29-46f2c6700c0b',
    template: 'actus-iframed-template',
    title: 'Key communication skills and self-awareness',
    description:
      'Effective communication is crucial for business success, especially when working remotely.  You’ll learn how to flex your style to improve relationships and results.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: 'f7673de3-978f-4260-9f29-46f2c6700c0b',
    questionnaireId: '',
    contents: {
      id: '2a56e8e8-da0e-4e28-9b22-c04c3832eda8',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Being able to communicate effectively is crucial for business success and even more so when working remotely.  You’ll understand your own communication preferences and how to flex your style to improve relationships and drive key results. </p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your Learning outcomes for this module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how to communicate effectively when working remotely</li>' +
        '<li>Have explored the behaviours and skills involved when communicating remotely</li>' +
        '<li>Have developed an awareness of your own communication style and preferences</li>' +
        '<li>Understand how to develop flexibility of communication style to improve relationships and key results</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/be735670-e3fa-4712-8dac-05bead879e4d/media/actus-hybrid-employee-series-key-communication-skills-and-self-awareness-web-v2/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management', 'intermediate']
  }
];

export const KeyCommunicationSkillsAndSelfAwareness = {
  module
};
