const module = [
  {
    id: '74be6f3b-1121-46e9-b709-0022b7cdcbab',
    slug: 'how-liability-arises',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '74be6f3b-1121-46e9-b709-0022b7cdcbab|33c63463-c631-4478-9115-ae51dda1931d',
    template: 'video-template',
    title: 'How Liability Arises',
    description: 'You’ll learn how the reasons why liability arises.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '33c63463-c631-4478-9115-ae51dda1931d',
    questionnaireId: 'd617a0c8-8ae8-40eb-858a-f308f15bfa59',
    contents: {
      id: '148d9d6e-9c5f-4e0e-86dc-46ebd921780d',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understanding how liability arises is important when advising your professional clients.   Using landmark cases as examples we’ll explore the 4 steps set out to prove negligent behaviour.  We’ll also discuss which clients need Professional Indemnity cover.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How professional liability arises</li>' +
        '<li>Duty of care owed by professionals</li>' +
        '<li>Who needs professional indemnity insurance?</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'How liability arises',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/704094758',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'How liability arises',
          button: 'Download Slides',
          fileSize: '487285',
          url: 'how-liability-arises.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '28'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['products', 'sales', 'legal', 'foundation']
  }
];

export const HowLiabilityArises = {
  module
};
