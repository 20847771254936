const module = [
  {
    id: 'dc5963bb-ee3c-4460-9c2d-70e6e3a2cd05',
    slug: 'module-1-resilience-and-wellbeing',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: false,
    concatenatedID: 'dc5963bb-ee3c-4460-9c2d-70e6e3a2cd05|80ea6f13-2d75-4b83-9c42-02ef20d57166',
    template: 'actus-iframed-template',
    title: 'Resilience and wellbeing',
    description:
      'Understand more about how to strengthen your resilience and cope with pressure to support your overall health and wellbeing.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '80ea6f13-2d75-4b83-9c42-02ef20d57166',
    questionnaireId: '',
    contents: {
      id: 'd935f54e-8f0a-41f0-bbb7-1bfe53390091',
      longDescription:
        '<h2 class="subtitle">Introduction</h2>' +
        '<p>Prepared by Actus Consulting, this module is designed for anyone who wants to find ways to improve their physical and mental health and wellbeing.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes from this module will include:</p>' +
        '<div class="row"><div class="col">' +
        '<ul style="margin-top:10px">' +
        '<li>Understanding more about wellbeing, pressure and resilience</li>' +
        '<li>Learn some tips to support overall wellbeing</li>' +
        '<li>Know how to manage your physical wellbeing</li>' +
        '</ul>' +
        '</div>' +
        '<div class="col">' +
        '<ul>' +
        '<li>Have insight into positive mental health habits</li>' +
        '<li>Understand pressure versus stress</li>' +
        '</ul>' +
        '</div>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/dc5963bb-ee3c-4460-9c2d-70e6e3a2cd05/media/actus-resilience-and-wellbeing-web-v2/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation']
  }
];

export const ActusEmployeeeWellbeingAndResilience = {
  module
};
