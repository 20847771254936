const module = [
  {
    id: '8ef25070-8ea4-4c43-9c5e-907c4c6dced9',
    slug: 'becoming-a-solutions-provider',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1d903ecd-b454-426a-8d43-a44307fd4ab7|8ef25070-8ea4-4c43-9c5e-907c4c6dced9',
    template: 'video-template',
    title: 'Becoming a solutions provider',
    description:
      'Develop consultative sales skills, learn how to sell product features, advantages and benefits and understand how to use SPIN selling techniques',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    questionnaireId: '',
    contents: {
      id: '508896f1-303c-49b3-ba05-72f6d6091814',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Develop consultative sales skills, learn how to sell product features, advantages and benefits and understand how to use SPIN selling techniques' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Continue to look at best practice methodologies - heightened significance in hard market</li>' +
        '<li>Learn how to sell product features, advantages and benefits</li>' +
        '<li>Develop consultative sales skills and understand how to build rapport, gather research and use listening skills to identify client needs</li>' +
        '<li>Understand how to use SPIN selling techniques</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Sales in a Changing Environment - Session Three: Becoming a Solutions Provider',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505197318',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Sales in a Changing Environment - Session Three: Becoming a Solutions Provider',
          button: 'Download Slides',
          fileSize: 1458588,
          url: 'becoming-a-solutions-provider.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www.axaconnect.co.uk/siteassets/coronavirus-hub/business-support/sales-in-a-changing-environment/week-3-sales-in-a-changing-environment-week-3/'
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 6
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'intermediate']
  }
];

export const BecomingASolutionsProvider = {
  module
};
