const module = [
  {
    id: '2cd9fd2d-7003-46c7-8cf4-aa77e6e4cd7c',
    slug: 'construction-liability-issues-in-the-construction-sector-part-2',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '2cd9fd2d-7003-46c7-8cf4-aa77e6e4cd7c|07ceadc8-32da-4dab-9bef-da023c3388eb',
    template: 'video-template',
    title: 'Liability issues in the construction sector – part 2',
    description:
      'Understand when non-negligence cover and financial loss cover is needed, along with the key cover extensions and exclusions for public liability',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '07ceadc8-32da-4dab-9bef-da023c3388eb',
    questionnaireId: 'c416f322-4404-429d-9450-8c66a66edab1',
    contents: {
      id: '8fb83c36-dabf-4a51-be22-99e84899d768',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand when non-negligence cover and financial loss cover is needed, along with the key cover extensions and exclusions for public liability' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand non-negligence cover (JCT 6.5.1) and when it is needed</li>' +
        '<li>Understand when financial loss cover is needed and what to look for in the cover</li>' +
        '<li>Learn the cover extensions required for Professional Indemnity</li>' +
        '<li>Understand what information is required for comprehensive broking presentations</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Construction - Liability issues in the construction sector – part 2',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/541683489',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Construction - Liability issues in the construction sector – part 2',
          button: 'Download Slides',
          fileSize: 5504670,
          url: 'construction-liability-issues-in-the-construction-sector-part-2.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['construction', 'products', 'sales', 'intermediate']
  }
];

export const ConstructionLiabilityIssuesInTheConstructionSectorPart_2 = {
  module
};
