<template>
  <transition name="fade">
    <div v-if="showCookieMessage">
      <nav class="fixed-bottom cookie-notice">
        <div class="container">
          <div class="row">
            <div class="col">
              <h4>{{ this.$content.cookieNotice.title }}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <div>
                {{ this.$content.cookieNotice.body[0].body }}
              </div>
            </div>
            <div class="col-2">
              <button type="button" class="btn btn-primary" @click="onClickCookieOk">
                {{ this.$content.cookieNotice.body[0].button }}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showCookieMessage: false,
    };
  },
  methods: {
    onClickCookieOk() {
      this.showCookieMessage = false;
      window.localStorage.setItem('cookie-message-clicked', 'true');
    },
  },
  mounted() {
    this.showCookieMessage = !window.localStorage.getItem('cookie-message-clicked');
  },
};
</script>