// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: 'd1897733-a532-4bdf-b156-03cf1b359a77',
    title: 'MLP - Company Legal Liability cover',
    body: '',
    keyLearning: 'Learning',
    passScore: 2,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title:
          'Claims that can be brought against a director can also be brought against a company under the Company Legal Liability Section which of these is an example of a potential claim?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Contract dispute, Breach of restrictive covenant or GDPR',
            body: '',
            score: 1.0
          },
          {
            title: 'Only contract dispute',
            body: '',
            score: 0
          },
          {
            title: 'Only Breach of restrictive covenant',
            body: '',
            score: 0
          },
          {
            title: 'Only GDPR',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'Which of the following are underwriting considerations when considering CLL cover? ',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Age of the company and recent or planned redundancies',
            body: '',
            score: 1.0
          },
          {
            title: 'How many vans the company own',
            body: '',
            score: 0
          },
          {
            title: 'If the company is in a flood area',
            body: '',
            score: 0
          },
          {
            title: 'Only age of the company',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const MlpCompanyLegalLiabilityCover = {
  questionnaire
};
