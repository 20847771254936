const module = [
  {
    id: '14f25a91-3631-499b-b22b-1cf5519938f8',
    slug: 'reviewing-contracts-for-insurance-implications',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '14f25a91-3631-499b-b22b-1cf5519938f8|6bf4d444-daf6-4a42-b9bc-3e6e7a8d3d44',
    template: 'video-template',
    title: 'Reviewing contracts for insurance implications',
    description:
      'Prepared and delivered by Nick Thomas and Diane Jenkins this learning module is one of our advanced training modules designed for more experienced and technically advanced delegates',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '6bf4d444-daf6-4a42-b9bc-3e6e7a8d3d44',
    questionnaireId: '10f5df48-8f96-4bbd-8b61-b5932e6104e8',
    contents: {
      id: '7f00a76d-63a2-44f7-a77d-81b6a1bada35',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Prepared and delivered by Nick Thomas and Diane Jenkins this learning module ‘Reviewing contracts for insurance implications’ is one of our advanced training modules designed for more experienced and technically advanced delegates' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Improved understanding of the client’s liabilities associated with key contracts</li>' +
        '<li>Able to identify key areas of risk in commercial contracts</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Reviewing contracts for insurance implications',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730953723',
          caption: {
            title: 'Reviewing contracts for insurance implications',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>',
          },
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Reviewing contracts for insurance implications',
          button: 'Download Slides',
          fileSize: '734284',
          url: 'reviewing-contracts-for-insurance-implications.pdf',
          caption: {
            title: 'Reviewing contracts for insurance implications',
            description: '',
          },
          axaUrl: '',
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Contract review case study',
          button: 'Download Case Study',
          fileSize: '800574',
          url: 'contract-review-case-study.pdf',
          caption: {
            title: 'Contract review case study',
            description: '',
          },
          axaUrl: '',
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 1,
    },
    statistics: {
      level: 2,
      timeEstimate: '28',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['sales', 'legal', 'advanced'],
  },
];

export const ReviewingContractsForInsuranceImplications = {
  module,
};
