const module = [
  {
    id: '13ee4680-745b-4aa3-97e9-c9ca776cf321',
    slug: 'fca-introduction-to-regulation-of-general-insurance-distribution',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: false,
    concatenatedID: '13ee4680-745b-4aa3-97e9-c9ca776cf321|f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    template: 'zing365-iframed-template',
    title: 'FCA introduction to regulation of general insurance distribution',
    description:
      'This course provides an overview of UK insurance intermediary regulation. It does not cover minute FCA rules; Not for compliance decisions',
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: 'f509e87d-97bb-411d-a145-a0dda3f0a5d4',
    questionnaireId: '',
    contents: {
      id: '6778b6bd-1e52-4f45-88e6-4fca99e1f232',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This course is designed as an introduction or a refresher to the regulation of general insurance intermediaries in the UK. As such, it does not cover the minute details of the rules of the FCA and the course should not be relied upon on when making compliance decisions for your organisation.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how Financial Services regulation evolved</li>' +
        '<li>Describe how the general insurance industry is regulated.</li>' +
        '<li>Demonstrate an understanding of who the rules apply to</li>' +
        '<li>Outline the main FCA requirements for insurance intermediaries.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url: '/modules/13ee4680-745b-4aa3-97e9-c9ca776cf321/media/fca-introduction-to-regulation-of-general-insurance-distribution/index.html',
          caption: {
            title: '',
            description: '',
          },
        },
      ],
    },
    progress: {
      value: 1,
      maximum: 1,
    },
    statistics: {
      level: 1,
      timeEstimate: '30',
    },
    status: {
      state: 'not-started',
      completedDate: '',
    },
    tags: ['intermediate', 'esg', 'fca', 'regulation', 'compliance'],
  },
];

export const FcaIntroductionToRegulationOfGeneralInsuranceDistribution = {
  module,
};
