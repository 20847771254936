const module = [
  {
    id: '91ed9620-2114-4f6d-ac2a-89e0469545e4',
    slug: 'sales-needs-discovery-two',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '91ed9620-2114-4f6d-ac2a-89e0469545e4|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Needs Discovery - Two',
    description:
      'In the second of two modules on needs discovery you’ll learn how to uncover clients needs to allow tailored solutions that create a strong case to buy.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: '36299bfa-31dc-4b20-9ca5-cc34f46959dd',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In order to uncover your prospects needs and tailor solutions to match them, the art of questioning plays a big part.  This module looks at the SPIN questioning model to help you move closer to the sale.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understanding the power of questions and listening when discovering your prospect’s needs</li>' +
        '<li>How to use the SPIN model for sales questioning</li>' +
        '<li>How to build client perception of current problems and future benefits</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Needs Discovery Two',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755031254',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Needs Discovery Two',
          button: 'Download Slides',
          fileSize: '240557',
          url: 'introduction-to-sales-needs-discovery-two.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 7,
      maximum: 10
    },
    statistics: {
      level: 1,
      timeEstimate: '6'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const IntroductionToSalesNeedsDiscoveryTwo = {
  module
};
