import Crypto from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

export default function (Vue) {
  Vue.localStorage = {
    getOrCreateEncryptionToken(tokenCookieName = 'token', expires = '365d', secure = "true") {
      let token = {
        value: '',
        isNew: false
      };
      try {
        if (!$cookies.isKey(tokenCookieName) || $cookies.get(tokenCookieName) === 'null' || $cookies.get(tokenCookieName) === '') {
          // Generate new token
          token.value = uuidv4();
          token.isNew = true;
        } else {
          // Get and refresh existing token
          token.value = $cookies.get(tokenCookieName);
        }

        $cookies.set(tokenCookieName, token.value, expires);

        return token;
      } catch (err) {

        return null;
      }
    },
    storeEncrypted(key, data, tokenCookieName = 'token') {
      let token = this.getOrCreateEncryptionToken(tokenCookieName);
      if (token === null) {
        return false;
      }

      if (!token.isNew) {
        let encrypted = Crypto.AES.encrypt(JSON.stringify(data), token.value).toString()
        try {
          window.localStorage.setItem(
            key,
            encrypted
          );

          return true;
        }
        catch (err) {
          return false;
        }
      }
    },
    retrieveDecrypted(key, tokenCookieName = 'token') {
      let token = this.getOrCreateEncryptionToken(tokenCookieName);
      if (token === null || localStorage.getItem(key) === null) {
        return false;
      }

      try {
        let decryptedRaw = Crypto.AES.decrypt(window.localStorage.getItem(key), token.value);

        let decrypted = JSON.parse(decryptedRaw.toString(Crypto.enc.Utf8));

        return decrypted;
      }
      catch (err) {
        return false;
      }
    },
    deleteItem(key) {
      localStorage.removeItem(key);
    }
  }

  Object.defineProperties(Vue.prototype, {
    $localStorage: {
      get() {
        return Vue.localStorage
      },
    }
  });
}