const module = [
  {
    id: '79d9ba9d-b1f8-4ab7-bf84-dff0e66a4003',
    slug: 'court-operations-during-covid-19',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '79d9ba9d-b1f8-4ab7-bf84-dff0e66a4003|586e1d86-9cd3-4040-8fa1-c7ed3e93d708',
    template: 'video-template',
    title: 'Court Operations During Covid-19',
    description:
      'You’ll learn about the key information you need to be aware of regarding changes in Court Operations During Covid-19',
    completedDescription: 'TODO',
    supplier: 'Rradar',
    categoryId: '586e1d86-9cd3-4040-8fa1-c7ed3e93d708',
    questionnaireId: 'c03d6b29-16d3-4a7f-829e-9c7dee72a775',
    contents: {
      id: 'd6eedc22-e2d6-4821-9787-c4243cf31165',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module you’ll learn about the key information you need to be aware of regarding changes in Court Operations During Covid-19' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Northern Ireland</li>' +
        '<ul>' +
        '<li>County Court</li>' +
        '<li>Queens Bench</li>' +
        '<li>Chancery</li>' +
        '</ul>' +
        '<li>Republic of Ireland</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Court Operations During Covid-19',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730348445',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Catherine Carton & Meg Burke BL</p>' +
              '<p>Brought to you by: Carson McDowell and Rradar on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Court Operations During Covid-19',
          button: 'Download Slides',
          fileSize: '837125',
          url: 'court-operations-during-covid-slides.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 2,
      timeEstimate: '34'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['legal', 'advanced']
  }
];

export const CourtOperationsDuringCovid19 = {
  module
};
