const module = [
  {
    id: 'b57fc6f1-9b65-4279-9fc0-bf7cd5f5af9d',
    slug: 'key-ingredients-of-building-your-team',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'b57fc6f1-9b65-4279-9fc0-bf7cd5f5af9d|f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    template: 'video-template',
    title: 'Key ingredients of building your team',
    description:
      'Learn what are the key ingredient for building a successful, engaged and motivated team.  Explore how the 4 habits of leadership will support you in achieving your aim.',
    completedDescription: 'TODO',
    supplier: 'Fiona Dewar - Pragmatic',
    categoryId: 'f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    questionnaireId: '',
    contents: {
      id: 'ead69aa0-a54d-4a85-9e86-65aaadd1a033',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn what are the key ingredient for building a successful, engaged and motivated team.  Explore how the 4 habits of leadership will support you in achieving your aim.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to build great teams and why</li>' +
        '<li>Why it’s important to set a common purpose or goals</li>' +
        '<li>Adopting some quick wins to get you started</li>' +
        '<li>How to use the 4 habits of leadership to support you</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Key ingredients of building your team',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730291186',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Fiona Dewar</p>'
          }
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 4
    },
    statistics: {
      level: 2,
      timeEstimate: '18'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'expert']
  }
];

export const KeyIngredientsOfBuildingYourTeam = {
  module
};
