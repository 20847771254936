const questionnaire = [
  {
    id: '7132f93c-7a26-48e8-8895-dfba175a01cd',
    title: 'Introduction to Hospitality',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title:
          'Why should clients check the insurance arranged by the bona fide sub-contractors working in the hotel spa? ',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title:
              'To ensure the treatment/services carried out by the sub-contractors are fully covered',
            body: '',
            score: 1.0,
          },
          {
            title: "To ensure the sub-contractors' insurer is different to their own insurer",
            body: '',
            score: 0,
          },
          {
            title: "To ensure that the cost of the sub-contractors' insurance is reasonable",
            body: '',
            score: 0,
          },
          {
            title: 'To ensure that any excess carried by the sub-contractors is adequate',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 2,
        title:
          'Why should insurers be made aware of the inclusion of electric vehicles in a fleet?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'Electric vehicles cost more than conventional vehicles to repair',
            body: '',
            score: 1.0,
          },
          {
            title: 'So the insured can benefit from a discount',
            body: '',
            score: 0,
          },
          {
            title: 'So insurers can ensure they have suitable repairers in their repair network',
            body: '',
            score: 0,
          },
          {
            title: 'So insurers can qualify for a green client grant from the government',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 3,
        title:
          "Which type of third party liability cover will be affected by the introduction of Martyn's Law (Duty to Protect)",
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: "Terrorism - Martyn's Law is the Terrorism (Protection of Premises) Bill",
            body: '',
            score: 1.0,
          },
          {
            title: "Pollution - Martyn's Law is the Pollution (Protection of Premises) Bill",
            body: '',
            score: 0,
          },
          {
            title: "Financial Loss- Martyn's Law is the Protection of Adjacent Premises Bill",
            body: '',
            score: 0,
          },
          {
            title: "Defective Premises - Martyn's Law is the Protection of Defective Premises Bill",
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 4,
        title: 'Why might the undamaged stock extension be required?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title:
              'Stock might be undamaged but condemned due to insured loss or damage or as a requirement under the Food Safety Act',
            body: '',
            score: 1.0,
          },
          {
            title: 'Damaged stock might be condemned due to insured loss or damage ',
            body: '',
            score: 0,
          },
          {
            title:
              'Stock might be undamaged but insured wants to include cost of replacement in their claim',
            body: '',
            score: 0,
          },
          {
            title: 'Stock is insured for limited perils only',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 5,
        title:
          'Why do composite panels in a food preparation area have to be declared to insurers?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'Their presence may make the fire risk worse',
            body: '',
            score: 1.0,
          },
          {
            title: 'Their presence may make the fire risk better',
            body: '',
            score: 0,
          },
          {
            title: 'To ensure the sum insured is adequate',
            body: '',
            score: 0,
          },
          {
            title: 'They increase the reinstatement costs',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 6,
        title: 'Why does cover need to be extended to include liability for Legionella?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Legionella is caused by gradual pollution which is a standard exclusion',
            body: '',
            score: 1.0,
          },
          {
            title: 'Legionella is a specific exclusion',
            body: '',
            score: 0,
          },
          {
            title:
              'Liability for legionella will be excluded via the hazardous substance exclusion',
            body: '',
            score: 0,
          },
          {
            title:
              'Liability for legionella will be excluded via the contractual liability exclusion',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 7,
        title:
          "Which of the following is NOT a key employers' liability risk in the hospitality sector?",
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Falling from a moving vehicle',
            body: '',
            score: 1.0,
          },
          {
            title: 'Slips and trips',
            body: '',
            score: 0,
          },
          {
            title: 'Dermatitis from cleaning materials',
            body: '',
            score: 0,
          },
          {
            title: 'Harassment from customers',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 8,
        title: 'Why do insurers require regular cleaning of kitchen air extraction systems? ',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Failure to clean kitchen air extraction systems regularly leads to a build up of fats and debris that can cause fires to spread via the extraction system',
            body: '',
            score: 1.0,
          },
          {
            title:
              'Failure to clean kitchen air extraction systems regularly leads to unclean air in the kitchen',
            body: '',
            score: 0,
          },
          {
            title:
              'Failure to clean kitchen air extraction systems  regularly can lead to food poisoning claims',
            body: '',
            score: 0,
          },
          {
            title:
              "Failure to clean kitchen air extraction systems  regularly can lead to employer's liability claims",
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 9,
        title:
          'What are the main benefits for a client of adding a Declaration Link Clause to their BI section?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'The clause automatically allows for increases in the estimated sum insured (up to a maximum of 133.33%) and deletes the condition of average',
            body: '',
            score: 1.0,
          },
          {
            title: 'The clause just deletes the condition of average',
            body: '',
            score: 0,
          },
          {
            title:
              'The clause only automatically allows for increases in the estimated sum insured (up to a maximum of 133.33%)  ',
            body: '',
            score: 0,
          },
          {
            title: 'The clause deletes the adjustment condition',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 10,
        title: 'Why is the cost of living crisis a key leisure/hospitality sector issue?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Customers tend to spend less on leisure/hospitality activities, whilst business costs increase,  reducing profits',
            body: '',
            score: 1.0,
          },
          {
            title: 'Customers tend to spend more on overseas leisure/hospitality activities',
            body: '',
            score: 0,
          },
          {
            title:
              'Customers tend to spend more on leisure/hospitality activities, creating higher demand',
            body: '',
            score: 0,
          },
          {
            title: 'There are less people available to work in the leisure/ hospitality sector',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 11,
        title:
          'Which of the following is a key factor in assessing the required Public Liability limit for a hospitality sector risk?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'The type and number of customers/guests on the premises at any one time',
            body: '',
            score: 1.0,
          },
          {
            title: 'The wageroll and number of employees',
            body: '',
            score: 0,
          },
          {
            title: 'The value of the business',
            body: '',
            score: 0,
          },
          {
            title: 'The number of cars parked in the on-site car park',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 12,
        title: 'What additional cover does the Deterioration in Stock section provide?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'Food spoilage caused by a freezer breakdown or a power failure',
            body: '',
            score: 1.0,
          },
          {
            title: 'Food spoilage caused by an insured peril only',
            body: '',
            score: 0,
          },
          {
            title: 'Food spoilage caused by a power failure only',
            body: '',
            score: 0,
          },
          {
            title: 'Food spoilage caused by an old freezer breaking down only',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 13,
        title: 'What extra cover does the Pairs and Sets clause provide?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title:
              'If only part of a set or pair is damaged, the cost of replacing the entire pair or set will be covered',
            body: '',
            score: 1.0,
          },
          {
            title:
              'If a pair or set is damaged it allows the insured to replace with a better version of the pair or set',
            body: '',
            score: 0,
          },
          {
            title:
              'None - it just ensures that the sum insured takes into account the higher valuation of a pair or set',
            body: '',
            score: 0,
          },
          {
            title: 'None - it just notes that pairs and sets exist',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 14,
        title: 'Which of the following is NOT an ESG risk to the hospitality sector?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'An outbreak of bed bugs at the hotel',
            body: '',
            score: 1.0,
          },
          {
            title: 'Bad weather causing customers to stay at home',
            body: '',
            score: 0,
          },
          {
            title: 'Accidently employing illegal workers',
            body: '',
            score: 0,
          },
          {
            title: 'A natural disaster forcing closure of the business',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 15,
        title: 'Which of the following is a key risk of the hospitality sector?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'Staff shortages',
            body: '',
            score: 1.0,
          },
          {
            title: 'Too many staff',
            body: '',
            score: 0,
          },
          {
            title: 'Lack of staff training',
            body: '',
            score: 0,
          },
          {
            title: 'Unavailability of suitable premises',
            body: '',
            score: 0,
          },
        ],
      },
    ],
  },
];

export const IntroductionToHospitality = {
  questionnaire,
};
