// Questionnaire
const questionnaire = [
    {
        id: 'c03d6b29-16d3-4a7f-829e-9c7dee72a775',
        title: 'Court Operations During Covid-19',
        body: '',
        keyLearning: 'Learning',
        passScore: 4,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'In N Ireland what is the name of the form to be submitted to request a hearing date',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'HR1',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'HR2',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'HR3',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 2,
                title: 'How many weeks’ notice do you require to give the court if you want a hearing in person in N Ireland?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: '2 Weeks',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '1 Week',
                        body: '',
                        score: 0
                    },
                    {
                        title: '3 Weeks',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 3,
                title: 'What form can a hearing take in N Ireland?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Remote, In Person and Hybrid',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Remote',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'In Person',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Hybrid',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'Which party must submit the QBCI2 form before an interlocutory summons is dealt with by the Master in the High Court in N Ireland',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The Moving Party',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Plaintiff',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Defendant',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 5,
                title: 'Which party’s solicitor prepares the trial bundle in N Ireland?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Plaintiff\'s solicitor',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Defendant\'s  solicitor',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 6,
                title: 'In the High Court in N Ireland on what day of the week is a callover held for all cases listed for hearing the following week?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Friday',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Monday',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Wednesday',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Thursday',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'In ROI booklets of pleadings and evidence should be prepared...',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Jointly agreed and prepared by both parties’ solicitors',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'By the Plaintiff',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'By the Defendant',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 8,
                title: 'True or false: In N Ireland and ROI visitors to the courthouse and occupants of bar consultation rooms/areas should still comply with all advised Covid-19 safety measures',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'True',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'False',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 9,
                title: 'True or false: In N Ireland and ROI anyone with symptoms or a positive lateral flow test should not attend at court?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'True',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'False',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 10,
                title: 'In ROI on what date at the start of the pandemic was it announced that no new cases or trials were to commence in the High Court for the remainder of the legal term?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: '16th March 2020',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '16th September 2020',
                        body: '',
                        score: 0
                    },
                    {
                        title: '16th December 2020',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 11,
                title: 'In ROI can new proceedings be issued currently?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Yes',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 12,
                title: 'How many days in advance of a motion date do circuit judges require motion papers to be  filed ?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: '3-5 days',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '21-28 days',
                        body: '',
                        score: 0
                    },
                    {
                        title: '60 days',
                        body: '',
                        score: 0
                    },
                ]
            },
        ]
    }
]

export const CourtOperationsDuringCovid19 = {
    questionnaire
};