<template>
  <div
    v-if="this.userHasCompletedThisModule(this.module)"
    class="row accreditation-certification-background"
    style="margin-top: 10px; margin-bottom: 20px"
  >
    <div class="col" style="margin-top: 10px">
      <h4>Request accredited module completion certificate</h4>
      <div v-if="showForm">
        <p>
          Please complete the form below if you would like a completion certificate for this module
          emailed to you.
        </p>
        <form>
          <validation-provider
            class="form-group"
            tag="div"
            rules="required"
            v-slot="{ errors }"
            mode="lazy"
          >
            <label for="InputFirstName" style="margin-top: 0px; margin-bottom: 2px" class="required"
              >First Name</label
            >
            <input
              aria-describedby="firstNameHelp"
              class="form-control"
              id="InputFirstName"
              name="First Name"
              placeholder="Enter First Name"
              type="text"
              v-model="certificate.first_name"
              v-on:click="onClickClearError"
            />
            <div v-if="errors[0]" class="validation-error">{{ errors[0] }}</div>
          </validation-provider>
          <validation-provider
            class="form-group"
            tag="div"
            rules="required"
            v-slot="{ errors }"
            mode="lazy"
          >
            <label for="InputLastName" style="margin-top: 10px; margin-bottom: 2px"
              >Last Name</label
            >
            <input
              aria-describedby="lastNameHelp"
              class="form-control"
              id="InputLastName"
              name="Last Name"
              placeholder="Enter Last Name"
              type="text"
              v-model="certificate.last_name"
              v-on:click="onClickClearError"
            />
            <div v-if="errors[0]" class="validation-error">{{ errors[0] }}</div>
          </validation-provider>
          <validation-provider
            class="form-group"
            tag="div"
            rules="required|email"
            v-slot="{ errors }"
            mode="lazy"
          >
            <label for="InputEmail" style="margin-top: 10px; margin-bottom: 2px"
              >Email Address</label
            >
            <input
              aria-describedby="emailHelp"
              class="form-control"
              id="InputEmail"
              name="Email Address"
              placeholder="Enter Email"
              type="email"
              v-model="certificate.email"
              v-on:click="onClickClearError"
            />
            <div v-if="errors[0]" class="validation-error">{{ errors[0] }}</div>
          </validation-provider>
          <button
            type="button"
            class="btn btn-request-certificate btn-block"
            style="margin-bottom: 20px"
            v-on:click="onClickCertificateRequest"
          >
            Request Certificate
          </button>
        </form>
      </div>
      <div v-else class="col-12" style="padding: 10px 0 10px 0; text-align: center">
        Your certificate of completion has been sent to <strong>{{ this.certificate.email }}</strong
        >, if you don't receive it in the next 5 minutes please check your spam folder.
      </div>
      <div
        v-if="showError"
        class="col-12"
        style="padding: 8px; text-align: center; background-color: #e7c7c7"
      >
        Sorry, we couldn't send your certificate, please check the details above and try again.
      </div>
    </div>
  </div>
</template>

<script>
import Axios from '@/packages/axios/Axios';
import { MetricsMixin } from '@/mixins/metrics.mixin';
import { HelpersMixin } from '@/mixins/helpers.mixin';

export default {
  props: ['module'],
  mixins: [MetricsMixin, HelpersMixin],
  data() {
    return {
      certificate: {
        certificate_design_id: '',
        certified_at: '',
        description: '',
        email: '',
        first_name: '',
        last_name: '',
        reference: '',
        signature: '',
        title: '',
      },
      showForm: true,
      showError: false,
    };
  },
  methods: {
    userHasCompletedThisModule(module) {
      if (this.$user.findInCollection('module', module.id)) {
        return true;
      }

      return false;
    },
    onClickClearError() {
      this.showError = false;
    },
    onClickCertificateRequest() {
      this.certificate.description =
        'For the completion of <strong>"' +
        this.module.accreditation.title +
        '"</strong> eLearning module on ie.brokerwise.pro';
      this.certificate.certified_at = this.formatDateTime(new Date(), 'YmdHis');
      this.certificate.certificate_design_id = '2aa55283-bc0a-4cd3-a9ce-4c457e65ce36';
      this.certificate.reference = this.metricFetchReference();
      this.certificate.title = 'CERTIFICATE<br/> OF COMPLETION';
      this.certificate.signature = 'ie.brokerwise.pro';
      if (this.$user.findInCollection('module', this.module.id)) {
        Axios.post('api/v1/' + this.$tenant.slug + '/certificates', this.certificate)
          .then((response) => {
            this.showForm = false;
            this.showError = false;
            this.metricWrite('certificate', {
              event: 'generated',
              info: this.certificate,
            });
          })
          .catch((error) => {
            this.showForm = true;
            this.showError = true;
            this.metricWrite('certificate', {
              event: 'failed',
              info: this.certificate,
            });
          });
      }
    },
  },
};
</script>

<style>
.all-ready-finished {
  margin-top: 20px;
}

.all-ready-finished .icon-label {
  padding-left: 40px;
  font-size: 1.2em;
  text-align: left;
  white-space: normal;
  height: 60px;
  margin-bottom: 10px;
}

.icon-finished {
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 630 1000' style='enable-background:new 0 0 630 1000;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EB5C3C;%7D .st1%7Bfill:%23FABC78;%7D .st2%7Bfill:%23FFE67A;%7D%0A%3C/style%3E%3Cg%3E%3Cpolygon class='st0' points='169.84,489.26 169.84,1000 313.26,888.02 464.81,994.54 467.54,489.26 '/%3E%3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -130.3268 314.6352)' class='st1' cx='314.63' cy='314.64' rx='314.63' ry='314.64'/%3E%3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -130.3268 314.6352)' class='st2' cx='314.63' cy='314.64' rx='254.96' ry='254.96'/%3E%3Cpolygon class='st1' points='318.81,154.56 367.88,253.99 477.6,269.93 398.21,347.32 416.95,456.6 318.81,405.01 220.68,456.6 239.42,347.32 160.02,269.93 269.74,253.99 '/%3E%3C/g%3E%3C/svg%3E%0A");
}
</style>
