const module = [
  {
    id: '8b7c404d-fc76-42a5-adcc-60e2a50cb762',
    slug: 'nurturing-your-mental-health',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '8b7c404d-fc76-42a5-adcc-60e2a50cb762|067d1298-cbed-4fa4-b3da-de09893da510',
    template: 'video-template',
    title: 'Nurturing your mental health',
    description:
      'In this webinar we’re joined by the physiologists from AXA Health as they discuss how the importance of nurturing your mental health',
    completedDescription: 'TODO',
    supplier: 'AXA Health',
    categoryId: '067d1298-cbed-4fa4-b3da-de09893da510',
    questionnaireId: '',
    contents: {
      id: 'df298fea-27b4-4c6c-a366-524438be38f8',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this webinar we’re joined by the physiologists from AXA Health as they discuss how the importance of nurturing your mental health.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to practice self compassion</li>' +
        '<li>Using your network of relationships to support our resilience</li>' +
        '<li>Tips for taking action to support your mental health</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Nurturing your mental health',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/689312171',
          caption: {
            title: '',
            description: '<p>Brought to you by: AXA Health.</p>'
          }
        }
        // {
        //   type: 'file',
        //   subType: 'pdf',
        //   title:
        //     'Thriving in a Hard Market - Webinar 3 - You personal Effectiveness in a Hard Market',
        //   button: 'Download Slides',
        //   fileSize: 7511134,
        //   url: 'your-personal-effectiveness-in-a-hard-market.pdf',
        //   caption: {
        //     title: '',
        //     description: ''
        //   },
        //   axaUrl:
        //     'https://www2.axa-insurance.co.uk/e/471271/lides-for-Webinar-3-final-pptx/3l2ppz/415253605?h=6V72L71As0d_1j3_ZPWdqBl-4UoIACNbwltRT3kldcA'
        // }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation']
  }
];

export const NurturingYourMentalHealth = {
  module
};
