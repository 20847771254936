const module = [
  // your-behaviours-matter
  {
    id: '88d0379d-f21a-4fd4-a232-90e59d778a80',
    slug: 'flexing-your-approach-to-drive-engagement',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '88d0379d-f21a-4fd4-a232-90e59d778a80|550971f5-d422-4a1a-9b61-6b167e40b959',
    template: 'itd-course-your-behaviour-matters',
    title: 'Flexing your approach to drive engagement',
    description:
      'Learn how to flex your approach when managing complex situations and different types of people in order to drive engagement and improve productivity',
    completedDescription: 'TODO',
    supplier: 'Pragmatic Leadership',
    categoryId: '550971f5-d422-4a1a-9b61-6b167e40b959',
    questionnaireId: '',
    contents: {
      id: '3d306a9f-cf60-4cb7-bc9b-be12b46de89b',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn how to flex your approach when managing complex situations and different types of people in order to drive engagement and improve productivity' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Learn how to adapt your approach to manage different situations</li>' +
        '<li>Understand when to maintain decision-making control and when to delegate</li>' +
        '<li>Learn how to manage employees as individuals and adapt your style in line with their capability</li>' +
        '<li>Understand what factors can impact motivation and productivity</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {},
        {
          type: 'file',
          subType: 'pdf',
          title: 'The Five Key Steps To Delegation',
          button: 'Download Key Steps',
          fileSize: 542679,
          url: 'module-2-handout-the-five-key-steps-to-delegation.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '45'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];
export const FlexingYourApproachToDriveEngagement = {
  module
};
