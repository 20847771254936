// Questionnaire
// {{ Course }} - {{Module Name }}
const questionnaire = [
    {
        id: '49dbb0f0-b453-47ab-8870-19322d57bd20',
        title: 'Other cover options for the construction industry',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Which of the following is an example of a directors\' and officers\' liability claim in the construction industry?',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Directors of an insolvent contracting company who told suppliers, “when we get paid, you get paid,” were held personally liable for the money owed to the suppliers',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A contracting company was sued after a director dropped a steel bar onto the head of a labour only sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A contracting company was sued by a third party after a director driving a mechanical digger damaged a parked van',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A contracting company was sued by an employee for age discrimination after all employees over the age of 50 were made redundant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'Which of the following is a major disadvantage of a bank-issued bond?',
                body: '',
                keyLearning: 'The types of bonds found in the construction industry',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Often the bank wants 100% of the bond secured by the contractor as cash or a secured overdraft',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A personal meeting between the bank and contractor is needed ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Banks are not prepared to issue bonds for large projects',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Bonds issued by banks take a long time to arrange',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'Why does demand for bonds increase in a recession?',
                body: '',
                keyLearning: 'The types of bonds found in the construction industry',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The risks for employers of loss caused by contractor insolvency increases',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The risks for employers of loss caused by bank insolvency increases',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The risk for contractors of loss caused by sub-contractors insolvency increases',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The risks for insurers of loss caused by contractor insolvency increases',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'Which of the following is <strong>NOT</strong> a benefit of latent defects insurance for a property owner? ',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities ',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Latent defect insurance means there\'s no need to ask the contractor to carry public liability insurance ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Latent defect insurance means there\'s no need to establish who’s at fault or to prove negligence ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Latent defect insurance means the property will be sold more easily',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Latent defect insurance means that it is easier to get funding for the project',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'In what type of contract is it most likely that there will be a condition requiring the contractor to arrange cyber insurance',
                body: '',
                keyLearning: 'Cyber exposures faced by construction companies',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Contracts used in a Private Finance Initiative project',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Contracts used in a tenant\'s refurbishment project',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Contracts where project managers use a computer system ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Contracts used in a private house building project',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'Which type of bond is usually arranged with a bank?',
                body: '',
                keyLearning: 'The types of bonds found in the construction industry',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'An on-demand bond',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A conditional bond',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An unconditional bond',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A loan bond',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'To whom is a  latent defects policy usually assigned? ',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Future owners',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Sub-contractors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Designers\' professional indemnity insurers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Main contractor',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Which of the following statements is <strong>incorrect</strong>?',
                body: '',
                keyLearning: 'The types of bonds found in the construction industry',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Advance Payment Bonds are only available from banks',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Advance Payment Bonds provide security for the repayment of advance payments or payments made on account',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Advance Payment Bonds will usually be for 100% of the advance payment made',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Advance Payment Bonds protect the employer against the insolvency of the contractor',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'What is the usual length of a legal indemnity policy period',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'A legal indemnity policy is usually issued with no set policy period',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A legal indemnity policy is usually issued with a 12 -year policy period',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A legal indemnity policy is usually issued with a 50 -year policy period',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A legal indemnity policy is usually issued with a 100 -year policy perioda4',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What is the main benefit for the buyer of arranging a latent defects policy at the start of the project?',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities ',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'It is cheaper',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'There will be a greater choice of insurers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It is easier to provide insurers with required information',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The policy will last longer',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Who is usually the beneficiary of a construction bond?',
                body: '',
                keyLearning: 'The types of bonds found in the construction industry',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'The employer',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The lender',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'What is a restrictive covenant legal indemnity?',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'An insurance policy that pays the insured\'s losses if a restriction on the use of the property is enforced',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'An insurance policy that pays the insured\'s losses if a right of way is enforced',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An insurance policy that pays the insured\'s losses if title deeds are lost',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An insurance policy that pays the insured\'s losses if there is lack of legal rights of access or services',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Which of the following statements is <strong>incorrect</strong>?',
                body: '',
                keyLearning: 'Cyber exposures faced by construction companies',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Contractors only need cyber insurance if it is a contract condition',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Contractors that store employees personal data need cyber insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Contractors that depend on computer designs need cyber insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Contractors that use computers for ordering materials need cyber insurance',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'How long is a latent defect policy period?',
                body: '',
                keyLearning: 'What other cover may a contractor need, including D&O, latent defects and legal indemnities',
                difficulty: 'easy',
                answers: [
                    {
                        title: '10-12 years',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '1-2 years',
                        body: '',
                        score: 0
                    },
                    {
                        title: '20-30 years',
                        body: '',
                        score: 0
                    },
                    {
                        title: '5-10 years',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'Which of the following is a type of bond found in the construction industry?',
                body: '',
                keyLearning: 'The types of bonds found in the construction industry',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Performance bond',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Goods and Services Bond',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Waterproofing Guarantee Bond',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Bank Bond',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ConstructionOtherCoverOptionsForTheConstructionIndustry = {
    questionnaire
};