// real estate module 2
const module = [
  {
    id: '828ff90e-02c5-4373-87a7-25be0989ab4e',
    slug: 'real-estate-construction-leases-and-lenders-in-the-real-estate-sector',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '828ff90e-02c5-4373-87a7-25be0989ab4e|f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    template: 'video-template',
    title: 'Construction, leases and lenders in the real estate sector',
    description:
      'Understand the differing methods of construction that cause issues for brokers, insurers and lenders along with the issues around subrogation',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    questionnaireId: '4515dc3b-0496-4717-8488-629f79311885',
    contents: {
      id: '6b30ab98-6772-4f16-b942-6a3e39d9a3f5',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the differing methods of construction that cause issues for brokers, insurers and lenders along with the issues around subrogation' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Be aware of differing methods of construction that cause issues for insurers and lenders and the need for brokers to provide information on these</li>' +
        '<li>Understand the issues around lender’s insurance requirements</li>' +
        '<li>Understand subrogation issues for landlords and tenants</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Real Estate - Construction, leases and lenders in the real estate sector',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/582141672',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Real Estate - Construction, leases and lenders in the real estate sector',
          button: 'Download Slides',
          fileSize: '1079664',
          url: 'real-estate-construction-leases-and-lenders-in-the-real-estate-sector.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '33'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['real estate', 'products', 'legal', 'intermediate']
  }
];

export const RealEstateConstructionLeasesAndLendersInTheRealEstateSector = {
  module
};
