// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '19f44ad8-4574-4b68-afdf-57d4fa517011',
    title: 'Introduction to Retail',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'What contractual liability for a breach of credit card data might a retailer have?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title:
              'All retailers that process card payments will need to comply with the Payment Card Industry Data Security Standard (PCI DSS) within their PCI contract',
            body: '',
            score: 1.0,
          },
          {
            title:
              'On-line retailers only will have to comply with the Payment Card Industry Data Security Standard (PCI DSS) within their PCI contract',
            body: '',
            score: 0,
          },
          {
            title:
              'Only retailers that pay for stock using a credit card will need to comply with the Payment Card Industry Data Security Standard (PCI DSS) within the PCI contracts',
            body: '',
            score: 0,
          },
          {
            title:
              'Retailers that issue their own credit cards will have to comply with the Payment Card Industry Data Security Standard (PCI DSS) ',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 2,
        title:
          'Why should insurers be made aware of the inclusion of electric vehicles in a fleet?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'Electric vehicles cost more than conventional vehicles to repair',
            body: '',
            score: 1.0,
          },
          {
            title: 'So the insured can benefit from a discount',
            body: '',
            score: 0,
          },
          {
            title: 'So insurers can ensure they have suitable repairers in their repair network',
            body: '',
            score: 0,
          },
          {
            title: 'So insurers can qualify for a green client grant from the government',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 3,
        title:
          'Why does a retailer need products liability insurance, even if they do not make the product',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title:
              'A retailer may be liable for loss/damage caused by a faulty product they have sold',
            body: '',
            score: 1.0,
          },
          {
            title: 'Only a retailer is liable for a faulty product sold',
            body: '',
            score: 0,
          },
          {
            title:
              'A retailer is always liable for loss/damage caused by a faulty product they have sold',
            body: '',
            score: 0,
          },
          {
            title:
              'Retailers cannot recover costs for loss or damage caused by a defective product from the product manufacturers',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 4,
        title:
          'Which of the following is NOT  a reason why the shop being part of a multi-tenured building increases the property risk?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'The landlord may be unknown to the insurers',
            body: '',
            score: 1.0,
          },
          {
            title: 'The shop might be in a building where high risk activities are undertaken',
            body: '',
            score: 0,
          },
          {
            title: 'The insured has less control over the risks faced',
            body: '',
            score: 0,
          },
          {
            title: 'The shop might be accessed by other tenants  at the premises ',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 5,
        title: 'Why do composite panels in a warehouse area have to be declared to insurers?',
        body: '',
        keyLearning: '',
        difficulty: 'hard',
        answers: [
          {
            title: 'Their presence may make the fire risk worse',
            body: '',
            score: 1.0,
          },
          {
            title: 'Their presence may make the fire risk better',
            body: '',
            score: 0,
          },
          {
            title: 'To ensure the sum insured is adequate',
            body: '',
            score: 0,
          },
          {
            title: 'They increase the reinstatement costs',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 6,
        title: 'Why does cover need to be extended to include liability for Legionella?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Legionella is caused by gradual pollution which is a standard exclusion',
            body: '',
            score: 1.0,
          },
          {
            title: 'Legionella is a specific exclusion',
            body: '',
            score: 0,
          },
          {
            title:
              'Liability for legionella will be excluded via the hazardous substance exclusion',
            body: '',
            score: 0,
          },
          {
            title:
              'Liability for legionella will be excluded via the contractual liability exclusion',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 7,
        title:
          "Which of the following is NOT a key employers' liability risk in the retail sector?",
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Noise from machinery',
            body: '',
            score: 1.0,
          },
          {
            title: 'Slips and trips',
            body: '',
            score: 0,
          },
          {
            title: 'Dermatitis from cleaning materials',
            body: '',
            score: 0,
          },
          {
            title: 'Harassment / violence from customers',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 8,
        title: 'Why do insurers want shop fronts to be protected by roller shutters',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'Roller shutters can prevent damage to glass and shopfronts',
            body: '',
            score: 1.0,
          },
          {
            title: 'Roller shutters can prevent shoplifting',
            body: '',
            score: 0,
          },
          {
            title: 'Roller shutters can help protect employees against workplace violence',
            body: '',
            score: 0,
          },
          {
            title: 'Roller shutters can protect a shop from water damage',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 9,
        title:
          'What are the main benefits for a client of adding a Declaration Link Clause to their BI section?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'The clause automatically allows for increases in the estimated sum insured (up to a maximum of 133.33%) and deletes the condition of average',
            body: '',
            score: 1.0,
          },
          {
            title: 'The clause just deletes the condition of average',
            body: '',
            score: 0,
          },
          {
            title:
              'The clause only automatically allows for increases in the estimated sum insured (up to a maximum of 133.33%)  ',
            body: '',
            score: 0,
          },
          {
            title: 'The clause deletes the adjustment condition',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 10,
        title: 'Why is the cost of living crisis a key retail sector issue?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Customers tend to spend less , whilst business costs increase,  reducing profits',
            body: '',
            score: 1.0,
          },
          {
            title: 'Customers tend to spend more increasing the money risk',
            body: '',
            score: 0,
          },
          {
            title: 'Customers tend to spend more creating a need for higher stock levels',
            body: '',
            score: 0,
          },
          {
            title: 'There are less people available to work in the retail sector',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 11,
        title:
          'Which of the following is a key factor in assessing the required Public Liability limit for a retail sector risk?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'The type and number of customers on the premises at any one time',
            body: '',
            score: 1.0,
          },
          {
            title: 'The wageroll and number of employees',
            body: '',
            score: 0,
          },
          {
            title: 'The value of the business',
            body: '',
            score: 0,
          },
          {
            title: 'The number of cars parked in the on-site car park',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 12,
        title: 'What additional cover does the Deterioration in Stock section provide?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'Food spoilage caused by a freezer breakdown or a power failure',
            body: '',
            score: 1.0,
          },
          {
            title: 'Food spoilage caused by an insured peril only',
            body: '',
            score: 0,
          },
          {
            title: 'Food spoilage caused by a power failure only',
            body: '',
            score: 0,
          },
          {
            title: 'Food spoilage caused by an old freezer breaking down only',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 13,
        title: 'What extra cover does the Exhibitions clause provide?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title:
              'It provides cover to stock and contents whilst at an exhibition  and whilst in transit to and from the exhibition ',
            body: '',
            score: 1.0,
          },
          {
            title:
              'It provides cover to stock and contents only whilst in transit to and from the exhibition ',
            body: '',
            score: 0,
          },
          {
            title: 'It provides cover for stock bought at an exhibition ',
            body: '',
            score: 0,
          },
          {
            title: 'It sets an exhibition price valuation for stock',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 14,
        title: 'Which of the following is an ESG risk to the retail sector?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'Activists leading a product boycott',
            body: '',
            score: 1.0,
          },
          {
            title: 'Shoplifting',
            body: '',
            score: 0,
          },
          {
            title: 'Data breach',
            body: '',
            score: 0,
          },
          {
            title: 'A fire forcing closure of the business',
            body: '',
            score: 0,
          },
        ],
      },
      {
        id: 15,
        title: 'Which of the following is a key risk of the retail sector?',
        body: '',
        keyLearning: '',
        difficulty: 'easy',
        answers: [
          {
            title: 'An aging workforce',
            body: '',
            score: 1.0,
          },
          {
            title: 'Too many staff',
            body: '',
            score: 0,
          },
          {
            title: 'Lack of staff training',
            body: '',
            score: 0,
          },
          {
            title: 'Unavailability of suitable premises',
            body: '',
            score: 0,
          },
        ],
      },
    ],
  },
];

export const IntroductionToRetail = {
  questionnaire,
};
