const module = [
  {
    id: 'dea0e533-61c3-4fee-89a9-eda7f3c97b20',
    slug: 'networking-for-success',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'dea0e533-61c3-4fee-89a9-eda7f3c97b20|d68963e3-4b1b-4af9-9d70-c9399c58d809',
    template: 'video-template',
    title: 'Networking For Success',
    description:
      'Nick Thomas discusses how to achieve success at networking events.  He provides top tips for how to plan for events, what to do at the event and how to maximise the post event follow up.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
    questionnaireId: '',
    contents: {
      id: 'ae2296cb-4b39-46ba-8723-3ab20feb8cd7',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Nick Thomas discusses how to achieve success at networking events.  He provides top tips for how to plan for events, what to do at the event and how to maximise the post event follow up.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to plan pre-event for networking success</li>' +
        '<li>What to consider when you’re at the event</li>' +
        '<li>How to do post event follow up</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Networking For Success',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/764190429',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas & Aidan Boyles</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '35'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const NetworkingForSuccess = {
  module
};
