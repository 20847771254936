const module = [
  {
    id: '4a5cea33-a20c-4bcf-a987-702eee24d306',
    slug: 'construction-contractors-all-risks-erection-all-risks-and-bi-issues',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '4a5cea33-a20c-4bcf-a987-702eee24d306|07ceadc8-32da-4dab-9bef-da023c3388eb',
    template: 'video-template',
    title: 'Contractors All Risks, Erection All Risks and BI issues',
    description:
      'Learn what is covered in CAR and DSU policies, how JCT contract options apply and understand joint names vs composite insureds',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '07ceadc8-32da-4dab-9bef-da023c3388eb',
    questionnaireId: 'c75c1cfc-089c-44c5-ab91-3dd8c2a88224',
    contents: {
      id: '63cafd8b-a3d8-4a5a-ba15-a89180d0eb0f',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn what is covered in CAR and DSU policies, how JCT contract options apply and understand joint names vs composite insureds' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Learn what is covered in a Contractors All Risks policy </li>' +
        '<li>Understand how the JCT contract options apply</li>' +
        '<li>Identify the difference between joint names and composite insured status and why it matters</li>' +
        '<li>Understand what a Delay in Start-Up (DSU) policy covers</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Contractors All Risks, Erection All Risks and BI issues',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/545206601',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Contractors All Risks, Erection All Risks and BI issues',
          button: 'Download Slides',
          fileSize: 635395,
          url: 'construction-contractors-all-risks-erection-all-risks-and-bi-issues.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 5
    },
    statistics: {
      level: 2,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['construction', 'products', 'sales', 'advanced']
  }
];

export const ConstructionContractorsAllRisksErectionAllRisksAndBiIssues = {
  module
};
