const module = [
  {
    id: '4b0fe2b8-6145-4200-bfd9-6dbca2bf8fda',
    slug: 'sales-introduction-to-the-sales-process',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '4b0fe2b8-6145-4200-bfd9-6dbca2bf8fda|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Introduction to the sales process',
    description:
      'You’ll learn a series of repeatable steps a sales team takes to move a prospect from an early-stage lead to a new client.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: '08f0e9bd-12e1-40d4-b4a3-c11623fa16b9',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understanding the steps in the sales process is critical for any successful sales strategy.  You’ll identify the steps you need to take through the journey.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>An overview of the six steps taken to move a prospect from early stage lead to new client</li>' +
        '<li>Understanding the terminology used in each part of the process</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Introduction to the sales process',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/731373441',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Introduction to the sales process',
          button: 'Download Slides',
          fileSize: '125559',
          url: 'introduction-to-the-sales-process.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 10
    },
    statistics: {
      level: 0,
      timeEstimate: '4'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'sales', 'foundation']
  }
];

export const IntroductionToTheSalesProcess = {
  module
};
