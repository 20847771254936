<template>
  <div>
    <template v-if="this.module.accreditation.board == 'cii-ireland'">
      <div class="row">
        <div class="col-3 text-center" style="padding-top: 30px; padding-right: 0px">
          <img
            :src="getImageUrl('tenant', this.$tenant.id, 'cii-cpd-logo.png')"
            class=""
            style="width: 180px"
          />
        </div>
        <div class="col-9">
          <h2 class="subtitle" style="margin-bottom: 10px">Accreditation</h2>
          <p>
            The Insurance Institute has awarded the e-learning course titled
            <strong
              ><em>
                {{ this.module.accreditation.title }}, {{ this.module.accreditation.cpdTime }} CPD
              </em></strong
            >
            for the undernoted Central Bank of Ireland MCC Retail Product Category:
          </p>
          <p style="margin-left: 10px"><strong>(5) Commercial General Insurance</strong></p>
          <p>
            This event can also be used by ACII/FCII holders, as part of their Chartered Insurance
            Institute (CII) CPD requirement should they consider the content relevant to their
            development needs. Please refer to the CII website for further details
            <a href="http://www.cii.co.uk/" target="_blank">http://www.cii.co.uk/</a>.
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { HelpersMixin } from '@/mixins/helpers.mixin';

export default {
  props: ['module'],
  mixins: [HelpersMixin],
};
</script>