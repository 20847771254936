const module = [
  {
    id: '17dfae1f-d43c-479d-839c-a9c7a49c6459',
    slug: 'managing-sickness-absence',
    type: 'playlist',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '17dfae1f-d43c-479d-839c-a9c7a49c6459|9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    template: 'rradar-iframed-template',
    title: "A manager's guide to managing sickness absence",
    description:
      'Learn how to have meaningful conversations and manage absence effectively and understand the common pitfalls associated with absence management processes',
    completedDescription: 'TODO',
    supplier: 'Rradar',
    categoryId: '9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    questionnaireId: '',
    contents: {
      id: '9a3cd53a-644b-40ba-b115-9038b54e995f',
      longDescription:
        '<div class="row">' +
        '<div class="col-12 col-md-6">' +
        '<h2 class="subtitle">Description</h2>' +
        'Learn how to have meaningful conversations and manage absence effectively and understand the common pitfalls associated with absence management processes.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the impact of sickness absence on a business</li>' +
        '<li>Effectively keep in touch with employees who are off sick</li>' +
        '<li>Confidently carry out a return to work or welfare meeting</li>' +
        '<li>Get the return-to-work process right</li>' +
        '<li>Spot potential disability discrimination issues linked to absences</li>' +
        '</ul>' +
        '<h2 class="subtitle">Additional Resources</h2>' +
        '<ul style="margin-top:10px">' +
        '<li><a href="/modules/17dfae1f-d43c-479d-839c-a9c7a49c6459/media/documents/managing-long-term-sickness-absence.pdf" target="_blank">Managing long-term sickness absence</a></li>' +
        '<li><a href="/modules/17dfae1f-d43c-479d-839c-a9c7a49c6459/media/documents/sickness-absences-glossary-of-terms.pdf" target="_blank">Sickness absences glossary of terms</a></li>' +
        '<li><a href="/modules/17dfae1f-d43c-479d-839c-a9c7a49c6459/media/documents/sickness-absence-manager-checklist.pdf" target="_blank">Manager\'s Return to Work Checklist</a></li>' +
        '</ul>' +
        '</div>' +
        '<div class="col-12 col-md-6">' +
        '<h2 class="subtitle">Chapters</h2>' +
        "<p>This module is made up of a series of chapters which you will complete them in the order shown below but as your progress will be saved as you go you don't have to do them all in one sitting, just leave the module and the chapter you are on will be shown when you return.</p>" +
        '<ul style="margin-top:10px">' +
        '<li>Chapter 1: Introduction and Absence Management Overview</li>' +
        '<li>Chapter 2.1: Certifying Sickness</li>' +
        '<li>Chapter 2.2: Short Term Absence' +
        '<li>Chapter 3: Role Play: Scenario 1 & 2</li>' +
        '<li>Chapter 4: Long Term Absence and Disability Discrimination</li>' +
        '<li>Chapter 5: Role Play: Welfare Meetings</li>' +
        '<li>Chapter 6: Disability discrimination and mental health</li>' +
        '<li>Chapter 7: Quiz</li>' +
        '</ul>' +
        '</div>' +
        '</div>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/17dfae1f-d43c-479d-839c-a9c7a49c6459/media/managing-sickness-absence-chapter-one/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ],
      playlist: {
        id: 'b4c29887-4a23-42ba-8e89-d9e0ecfcb020',
        title: 'Absence Managment',
        author: 'Rradar',
        tracks: [
          {
            id: 'f7a41ee3-059b-4bd1-a5c3-208c7852256f',
            title: 'Chapter 1: Introduction and Absence Management Overview',
            slug: 'chapter-1-introduction-and-absence-management-overview',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: 'c46b0106-c90f-481b-8320-69a828c37dc6',
            title: 'Chapter 2.1: Certifying Sickness',
            slug: 'chapter-2-1-certifying-sickness',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: 'eef11488-5e71-4be3-8f5d-84f8421c25a1',
            title: 'Chapter 2.2: Short Term Absence',
            slug: 'chapter-2-2-short-term-absence',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '281ecb1f-bd3f-4f0c-ac6b-78413105ce61',
            title: 'Chapter 3: Role Play: Scenario 1 & 2',
            slug: 'chapter-3-role-play-1-2',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '7290961f-ea06-4851-8e48-8679c692c554',
            title: 'Chapter 4: Long Term Absence and Disability Discrimination',
            slug: 'chapter-4-long-term-absence-disability-discrimination',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: 'be76a537-1e18-4f0d-ad82-20e1cff3bf75',
            title: 'Chapter 5: Role Play: Welfare Meetings',
            slug: 'chapter-5-role-play-welfare-meeting',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '1635483f-b854-439b-9c56-7f3f3aece886',
            title: 'Chapter 6: Disability discrimination and mental health',
            slug: 'chapter-6-disability-discrimination-and-mental-health',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '76c8ec79-f915-491f-94dd-e0d6a6af80d2',
            title: 'Chapter 7: Quiz',
            slug: 'chapter-7-quiz',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          }
        ]
      }
    },
    progress: {
      value: 1,
      maximum: 0
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management', 'legal', 'wellbeing', 'intermediate']
  }
];

export const ManagingSicknessAbsence = {
  module
};
