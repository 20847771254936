const module = [
  // motivating-your-team-for-improved-performance
  {
    id: '40d68ddd-5ab4-42dd-9710-35d67ecdbd8e',
    slug: 'motivating-your-team-for-improved-performance',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '40d68ddd-5ab4-42dd-9710-35d67ecdbd8e|550971f5-d422-4a1a-9b61-6b167e40b959',
    template: 'itd-course-your-behaviour-matters',
    title: 'Motivating your team for improved performance',
    description:
      'Understand the factors affecting your team’s motivation and learn how to use coaching and feedback to manage and develop performance',
    completedDescription: 'TODO',
    supplier: 'Pragmatic Leadership',
    categoryId: '550971f5-d422-4a1a-9b61-6b167e40b959',
    questionnaireId: '',
    contents: {
      id: '4a318b67-85be-4aa9-b09a-26aaf4da4c4a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the factors affecting your team’s motivation and learn how to use coaching and feedback to manage and develop performance' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the factors that impact motivation and how to audit your team’s motivation levels</li>' +
        '<li>Understand how your actions can impact the motivation of your team</li>' +
        '<li>Learn how to give regular, informal feedback and embed it into your routine</li>' +
        '<li>Understand how to use coaching to improve performance</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {},
        {
          type: 'file',
          subType: 'pdf',
          title: 'The Motivation Audit – HOT&COLD',
          button: 'Download The Motivation Audit',
          fileSize: 528384,
          url: 'module-3-handout-the-motivation-audit.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '45'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];
export const MotivatingYourTeamForImprovedPerformance = {
  module
};
