const module = [
  {
    id: '215cfe29-0599-4e3d-9bb6-60e4b90abebc',
    slug: 'a-guide-to-workplace-integrity',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '215cfe29-0599-4e3d-9bb6-60e4b90abebc|349708b6-4bdc-49a5-a851-fbd4d574b56d',
    template: 'zing365-iframed-template',
    title: 'A Guide to Workplace Integrity',
    description:
      'In this module we will look at what it means to have integrity. And what role does it play in career advancement and job satisfaction? ',
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
    questionnaireId: '',
    contents: {
      id: '19176ea5-7eb1-4345-88c5-a2f0d17a9b31',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module we will look at what it means to have integrity. And what role does it play in career advancement and job satisfaction? ' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand what integrity is</li>' +
        '<li>Identify examples of workplace integrity</li>' +
        '<li>Be aware of the benefits and challenges of integrity</li>' +
        '<li>Define your moral values</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/215cfe29-0599-4e3d-9bb6-60e4b90abebc/media/zing365-soft-skills-a-guide-to-workplace-integrity/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['skills', 'foundation']
  }
];

export const AGuideToWorkplaceIntegrity = {
  module
};
