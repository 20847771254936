const module = [
  // your-behaviours-matter
  {
    id: '387a74bf-36de-4a0d-afad-5c0beccc6210',
    slug: 'your-behaviours-matter',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '387a74bf-36de-4a0d-afad-5c0beccc6210|550971f5-d422-4a1a-9b61-6b167e40b959',
    template: 'itd-course-your-behaviour-matters',
    title: 'Your behaviours matter',
    description:
      'This module explores the four key habits you can adopt to develop your leadership skills and increase your team’s engagement',
    completedDescription: 'TODO',
    supplier: 'Pragmatic Leadership',
    categoryId: '550971f5-d422-4a1a-9b61-6b167e40b959',
    questionnaireId: '',
    contents: {
      id: 'aa12b5d8-ddb7-4c15-95e1-e9664a33dca4',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'This module explores the four key habits you can adopt to develop your leadership skills ' +
        'and increase your team’s engagement' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how your behaviours as a leader can affect team performance</li>' +
        '<li>Understand the link between employee engagement and organisational success</li>' +
        '<li>Adopt 4 key habits to develop your leadership skills and get the best out of your team</li>' +
        '<li>To understand the difference between Company Pages and Personal Profiles</li>' +
        '<li>Learn how to use the 3-step process to address performance issues</li>' +
        '</ul>',
      heroImage: false,
      media: [{}, {}]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '45'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'foundation']
  }
];
export const YourBehavioursMatter = {
  module
};
