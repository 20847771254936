<template>
  <div>
    <div class="module-page module">
      <course-title :course="course" />
      <div class="row">
        <div class="col-12">
          <module-title-description :module="module" />
        </div>
      </div>
      <div v-for="track in module.contents.playlist.tracks" :key="track.id">
        <div v-if="isTrackVisible(track.id)" class="row">
          <div class="col">
            <h3>{{ track.title }}</h3>
            <template v-if="!hasTrackStarted(track.id)">
              <div class="row startModule">
                <div class="col">
                  <button v-on:click="startTrack(track.id)" class="btn-lg btn-success">
                    {{ track.startButtonText }}
                  </button>
                </div>
              </div>
            </template>
            <transition name="fade">
              <template v-if="hasTrackStarted(track.id)">
                <iframe
                  :src="
                    'https://media.brokerwise.pro/modules/' +
                    module.id +
                    '/media/' +
                    track.slug +
                    '/index.html'
                  "
                  width="100%"
                  height="800px"
                  onload="this.style.opacity = '1';"
                  border="0"
                  style="
                    opacity: 0;
                    transition-duration: 1000ms;
                    transition-property: opacity;
                    transition-timing-function: ease-in-out;
                  "
                ></iframe>
              </template>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ModuleMixin } from '@/mixins/module.mixin';
import CourseTitle from '@/components/module/CourseTitle';
import ModuleTitleDescription from '@/components/module/TitleDescription';

export default {
  props: ['module', 'course'],
  mixins: [ModuleMixin],
  components: {
    CourseTitle,
    ModuleTitleDescription,
  },
  data() {
    return {
      showStartModuleCard: true,
      showIframe: false,
      player: null,
      currentTrackId: 'f7a41ee3-059b-4bd1-a5c3-208c7852256f',
      // currentTrackId: 'eb82d210-a53a-4bb6-a687-a35783d1be0f',
    };
  },
  methods: {
    receiveMessage(event) {
      if (event.data.message === 'savePlaylistProgress') {
        this.savePlaylistProgress(this.currentTrackId);
      }
      if (event.data.message === 'savePlaylistProgressAndLeave') {
        this.savePlaylistProgress(this.currentTrackId);
        this.$router.push({ name: 'Home' });
      }
      if (event.data.message === 'nextTrack') {
        this.nextTrack(this.currentTrackId);
        this.savePlaylistProgress(this.currentTrackId);
      }
      if (event.data.message === 'previousTrack') {
        this.previousTrack(this.currentTrackId);
        this.savePlaylistProgress(this.currentTrackId);
      }
      if (event.data.message === 'markModuleComplete') {
        this.module.status.state = 'finished';
        this.showIframe = false;
        this.$eventBus.$emit('set-module-finished');
      }
      if (event.data.message === 'resetPlaylistProgress') {
        this.$localStorage.deleteItem('pl_progress:' + this.module.contents.playlist.id);
      }
      if (event.data.message === 'leaveModule') {
        this.$router.push({ name: 'Home' });
      }

      this.$eventBus.$emit('set-module-started');
    },
    isTrackVisible(id) {
      if (id == this.currentTrackId) {
        return true;
      }
      return false;
    },
    hasTrackStarted(id) {
      if (id == this.currentTrackId) {
        return true;
      }
      return false;
    },
    loadPlaylistProgress() {
      if (this.$localStorage.retrieveDecrypted('pl_progress:' + this.module.contents.playlist.id)) {
        this.currentTrackId = this.$localStorage.retrieveDecrypted(
          'pl_progress:' + this.module.contents.playlist.id
        );
      } else {
        this.currentTrackId = this.module.contents.playlist.tracks[0].id;
        if (this.module.status.state != 'finished') {
          this.$eventBus.$emit('set-module-started');
        }
      }
    },
    savePlaylistProgress(trackId) {
      this.$localStorage.storeEncrypted('pl_progress:' + this.module.contents.playlist.id, trackId);
    },
    nextTrack() {
      for (let i = 0; i < this.module.contents.playlist.tracks.length; i++) {
        if (this.module.contents.playlist.tracks[i].id === this.currentTrackId) {
          this.currentTrackId = this.module.contents.playlist.tracks[i + 1].id;
          break;
        }
      }
    },
    previousTrack() {
      for (let i = 0; i < this.module.contents.playlist.tracks.length; i++) {
        if (this.module.contents.playlist.tracks[i].id === this.currentTrackId) {
          this.currentTrackId = this.module.contents.playlist.tracks[i - 1].id;
          break;
        }
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
    this.loadPlaylistProgress();
    this.$eventBus.$emit('disable-feedback');
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  },
};
</script>

<style>
.startModule {
  text-align: center;
  background-color: #dff0de;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}

.slide-navigation {
  color: green;
  font-size: 14px;
  font-weight: 800;
}

iframe {
  /* border-right: 2px solid black;
  line-height: 4em;
  display: inline-block;
  /* box-shadow: 11px 10px 14px -2px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 11px 10px 14px -2px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 11px 10px 14px -2px rgba(0, 0, 0, 0.41); */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.progress {
  background-color: #fff;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.module .icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.module .icon-label {
  padding-left: 25px;
  white-space: nowrap;
}

.module .fileSize {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}

.module .btn-powerpoint {
  background-color: #304a85;
  color: #fff;
}

.module .btn-powerpoint:hover {
  background-color: #214baf;
  color: #fff;
}

.powerpoint {
  margin-bottom: 20px;
}

.caption {
  margin-top: 20px;
}

.caption h6 {
  margin-bottom: 5px;
  font-weight: 800;
}

.caption p {
  text-align: justify;
  margin-bottom: 2px;
  font-size: 12px;
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 20px;
}

.player {
  max-width: 100% !important;
}

.all-ready-finished {
  margin-top: 20px;
}

.all-ready-finished .icon-label {
  padding-left: 40px;
  font-size: 1.2em;
  text-align: left;
  white-space: normal;
  height: 60px;
}

.icon-finished {
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 630 1000' style='enable-background:new 0 0 630 1000;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EB5C3C;%7D .st1%7Bfill:%23FABC78;%7D .st2%7Bfill:%23FFE67A;%7D%0A%3C/style%3E%3Cg%3E%3Cpolygon class='st0' points='169.84,489.26 169.84,1000 313.26,888.02 464.81,994.54 467.54,489.26 '/%3E%3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -130.3268 314.6352)' class='st1' cx='314.63' cy='314.64' rx='314.63' ry='314.64'/%3E%3Cellipse transform='matrix(0.7071 -0.7071 0.7071 0.7071 -130.3268 314.6352)' class='st2' cx='314.63' cy='314.64' rx='254.96' ry='254.96'/%3E%3Cpolygon class='st1' points='318.81,154.56 367.88,253.99 477.6,269.93 398.21,347.32 416.95,456.6 318.81,405.01 220.68,456.6 239.42,347.32 160.02,269.93 269.74,253.99 '/%3E%3C/g%3E%3C/svg%3E%0A");
}
</style>
