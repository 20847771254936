const module = [
  {
    id: '966058dd-cf46-4546-a6a3-4b1408e93412',
    slug: 'financial-wellbeing-the-impact-on-mental-health',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: false,
    concatenatedID: '7e9e8508-7258-4b00-a543-074414cb5f36|80ea6f13-2d75-4b83-9c42-02ef20d57166',
    template: 'video-template',
    title: 'Financial wellbeing: the impact on mental health',
    description:
      'The rising cost of living is having a significant impact on financial wellbeing.  We look at the link between financial health and mental health',
    completedDescription: 'TODO',
    supplier: 'AXA Health',
    categoryId: '7e9e8508-7258-4b00-a543-074414cb5f36',
    questionnaireId: '',
    contents: {
      id: '85f144e9-191d-4f7b-9c76-d6b7baaa8ded',
      longDescription:
        '<h2 class="subtitle">Introduction</h2>' +
        '<p>Financial wellbeing in turn has a significant impact on mental health. 86% of people with experience of mental health problems say their financial situation made their mental health problems worse.  We look at what you can do to improve your financial wellbeing.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes from this module will include:</p>' +
        '<div class="row"><div class="col">' +
        '<ul style="margin-top:10px">' +
        '<li>The link between financial wellbeing and mental health </li>' +
        '<li>Preventative actions that can support financial wellbeing </li>' +
        '<li>How you, your teams and your clients can maintain mental resilience</li>' +
        '</ul>' +
        '</div>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Financial wellbeing: the impact on mental health',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/818788801',
          caption: {
            title: '',
            description:
              '<p>Delivered by: AXA Health</p>' + '<p>Brought to you by: AXA Insurance</p>'
          }
        }
        // {
        //   type: 'file',
        //   subType: 'pdf',
        //   title: 'Lithium-ion Batteries',
        //   button: 'Download Slides',
        //   fileSize: '1368532',
        //   url: 'axa-technical-training-lithium-ion-batteries.pdf',
        //   caption: {
        //     title: '',
        //     description: ''
        //   },
        //   axaUrl: ''
        // }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation', 'skills']
  }
];

export const FinancialWellbeingTheImpactOnMentalHealth = {
  module
};
