// Questionnaire
// {{ Course }} - {{Module Name }}
const questionnaire = [
    {
        id: 'c416f322-4404-429d-9450-8c66a66edab1',
        title: 'Liability issues in the construction sector – part 2',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Which of the following is NOT an exclusion/condition found in a collateral warranty extension',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Cover will exclude any liability assumed under a contract',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Cover will not extend for longer than is applicable under the Limitation Act',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cover for assignments will be limited to two assignments only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Cover will exclude fitness for purpose conditions',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'Which of the following is an example of a contractors\' design and build professional indemnity claim?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'A restaurant\'s air conditioning system designed by the contractor failed to work properly. Restaurant had to close for repairs.',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A restaurant\'s air conditioning system, installed by the contractor in accordance with the employer\'s agreed specification, failed to work properly.Restaurant had to close for repairs.',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A restaurant\'s air conditioning system designed by the contractor failed to work properly because it had not been installed by the contractor properly. Restaurant had to close for repairs.',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A restaurant\'s air conditioning system, installed by a bona fide sub-contractor in accordance with the employer\'s agreed specification, failed to work properly. Restaurant had to close for repairs. ',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'What is the contractors \'duty to warn\' in a professional indemnity context?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'A duty to warn the employer of any problems or errors that the contractor might become aware of',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A duty to warn their employees of any problems or errors that the contractor might become aware of',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A duty to warn the site visitors of any problems or errors that the contractor might become aware of',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A duty to warn the client that they have chosen the wrong insurance broker',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'Which of the following is an example of a financial loss claim',
                body: '',
                keyLearning: 'When is financial loss cover needed and what to look for in the cover',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Floor is laid in garage using wrong concrete mix so has to be replaced. Employer sues contractors for the costs of this',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Floor is laid in garage using wrong concrete mix, and this causes damage to a third-party\'s vehicles, who sues contractors for the costs of this ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Floor is laid in garage using wrong concrete mix that gives off poisonous fumes when wet, causing injury to the contractor\'s employees. The contractor is sued by its employees',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Floor is laid in garage using wrong concrete mix, which means the walls and roof are not properly supported, causing the garage to collapse',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Which of these perils is <strong>NOT</strong> included in a non-negligence JCT 5.6.1 insurance?',
                body: '',
                keyLearning: 'When is cover for JCT 6.5.1 needed',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Fire',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Collapse',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Subsidence',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Heave',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'What benefit  does Building Information Modelling provide to the construction industry and insurers?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'It reduces need for on-site design changes, reducing contractors\' professional indemnity exposure',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It provides cost savings, reducing the size of claims',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It avoids need for specifications of work reducing contractors\' professional indemnity exposure',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It enables information sharing with insurers, reducing costs for brokers',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'For which of the following types of work is a JCT6.5.1 non-negligence policy most likely to be needed?',
                body: '',
                keyLearning: 'When is cover for JCT 6.5.1 needed',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Construction of a sub-basement extension in a residential area',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Construction of a factory extension in an industrial area',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Construction of a new factory on a greenfield site ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Construction of a mezzanine floor in an exiting factory',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'What does the rectification/ mitigation costs extension in a contractor\'s design and build PI policy cover?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Costs  incurred in remediation work or mitigating a loss or potential loss that may otherwise result in a claim',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Costs incurred in proving a remediation service to a third party',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Costs incurred in mitigating a claim via a mediation service',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Costs incurred by the insured in making a claim',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'Why is financial loss usually not covered under a contractors\' public liability policy?',
                body: '',
                keyLearning: 'When is financial loss cover needed and what to look for in the cover',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Policy usually includes a contractual liability exclusion',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Policy usually includes a negligence exclusion',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Policy usually includes a collateral warranty exclusion',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Policy usually includes a professional liability exclusion',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'Who usually pays for the non-negligence JCT 5.6.1 insurance?',
                body: '',
                keyLearning: 'When is cover for JCT 6.5.1 needed',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The employer',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The sub-contractors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The professional consultant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Which of the following is <strong>NOT</strong> a common extension in a contractors\' design and build PI policy?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Product recall cover',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Lost documents cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Rectification and mitigation costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Fraud and dishonesty of employees',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Which of the following statements is true?',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'A contractors design and build professional indemnity policy only covers the contractors\' professional liability',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A contractors design and build professional indemnity policy covers the contractors\' public liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A contractors design and build professional indemnity policy covers the contractors\' employers\' liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A contractors design and build professional indemnity policy only covers the employer\'s\' professional liability',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Which of these is a reason why contractors need to buy professional indemnity insurance',
                body: '',
                keyLearning: 'Key cover extensions and exclusions for PI',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'They are responsible for designing the staircase',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'They are responsible for building the staircase',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'They are responsible for painting the staircase',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'They are responsible for appointing sub-contractors to build the staircase',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'What is another name for financial loss?',
                body: '',
                keyLearning: 'When is financial loss cover needed and what to look for in the cover',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Pure economic loss',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Civil liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Negligence',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Nuisance',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'What is party wall insurance?',
                body: '',
                keyLearning: 'When is cover for JCT 6.5.1 needed',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Another name for non-negligence or JCT 6.5.1 insurance',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Another name for contractors all risks insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A type of insurance that covers jointly owned garden walls',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurance for walls where more than one party is insured',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ConstructionLiabilityIssuesInTheConstructionSectorPart_2 = {
    questionnaire
};