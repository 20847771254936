const module = [
  {
    id: 'd5947c7d-fad0-4377-9bc2-27846d6f3f3c',
    slug: 'sales-connecting-one',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'd5947c7d-fad0-4377-9bc2-27846d6f3f3c|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Connecting - One',
    description:
      'In the first of two modules on connecting with your prospects you’ll learn how to gain the attention of new leads and differentiate yourself from the competition.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: '7a96617c-cb1c-43e2-9c21-3326dc9f3c71',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Now that you have identified your ideal prospect, you need to grab their attention, but how?  In this module you’ll learn how to create a contact strategy that’s designed to help you stand out from the crowd.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to gain your targets attention</li>' +
        '<li>How to create a personalised, multi-channel contact strategy maximise your sales activities</li>' +
        '<li>How to use social media as channel to support the sales process.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Connecting - One',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/742561232',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Connecting - One',
          button: 'Download Slides',
          fileSize: '232395',
          url: 'introduction-to-sales-connecting-one.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 10
    },
    statistics: {
      level: 0,
      timeEstimate: '6'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'foundation']
  }
];

export const IntroductionToSalesConnectingOne = {
  module
};
