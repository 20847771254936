const module = [
  {
    id: 'd149e364-011a-4e68-b617-23c1f9677c5b',
    slug: 'trading-in-a-hard-market',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b|d149e364-011a-4e68-b617-23c1f9677c5b',
    template: 'video-template',
    title: 'Trading in a hard market',
    description:
      'Develop the trading skills and strategies needed to thrive in a hard market and build your effectiveness in terms of both client and market interaction',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '3f0b220a-d4d9-49c0-a49c-fc90c26de19b',
    questionnaireId: '',
    contents: {
      id: '0fc237fa-698e-4932-aae0-c526337812ad',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Develop the trading skills and strategies needed to thrive in a hard market and build your effectiveness in terms of both client and market interaction' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Enhance your understanding of the effect of a hard market on clients</li>' +
        '<li>Provide you with the trading skills and strategies needed to thrive in a hard market</li>' +
        '<li>Help you to build your effectiveness in terms of both client and market interaction</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Thriving in a Hard Market - Module Two: Trading in a Hard Market',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505200208',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas & Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Thriving in a Hard Market - Module Two: Trading in a Hard Market',
          button: 'Download Slides',
          fileSize: 4544013,
          url: 'trading-in-a-hard-market.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www2.axa-insurance.co.uk/e/471271/lides-for-Webinar-2-final-pptx/3l2pq8/415253605?h=6V72L71As0d_1j3_ZPWdqBl-4UoIACNbwltRT3kldcA'
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const TradingInAHardMarket = {
  module
};
