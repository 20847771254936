<template>
  <div class="complete-message">
    <div v-if="showModal">
      <div class="modal-mask" @click="onClickCloseModal">
        <div class="modal-wrapper">
          <transition name="bounce">
            <div v-if="showModalBody" class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body body">
                  <div class="row">
                    <div class="col">
                      <h1>{{ this.$content.moduleComplete.title }}</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3 col-md-3">
                      <div class="icon icon-finished">
                        <div class="icon-label"></div>
                      </div>
                    </div>
                    <div class="col-9 col-md-9">
                      <p v-html="this.$content.moduleComplete.body[0].body"></p>
                    </div>
                    <div class="col-lg-2 text-right"></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="small-print" v-html="this.$content.moduleComplete.body[1].body"></p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="onClickCloseModal">
                    {{ this.$content.moduleComplete.button }}
                  </button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      showModalBody: false,
    };
  },
  methods: {
    onClickCloseModal() {
      this.showModalBody = false;
      window.setTimeout(this.hideModal, 800);
    },
    showCompleted() {
      this.showModal = true;
      window.setTimeout(this.showBody, 300);
    },
    showBody() {
      this.showModalBody = true;
    },
    hideModal() {
      this.showModal = false;
    },
  },
  created() {
    this.$eventBus.$on('show-module-complete-message', this.showCompleted);
  },
  beforeDestroy() {
    this.$eventBus.$off('show-module-complete-message', this.showCompleted);
  },
};
</script>

<style scoped>
</style>
