// Questionnaire
// {{ Course }} - {{Module Name }}
const questionnaire = [
    {
        id: 'c75c1cfc-089c-44c5-ab91-3dd8c2a88224',
        title: 'Contractors All Risks, Erection All Risks and BI issue',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Why does DSU cover have to bought alongside the contract works cover?',
                body: '',
                keyLearning: 'What is Delay in Start-Up (DSU) insurance',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Because the DSU policy responds to delays caused by damage  insured under the contract works policy only',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Because the insurers need to obtain the same information for the DSU cover as for the contract works cover',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Because insurers do not want to have two loss adjusters dealing with the claim',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Because brokers can obtain better terms by packaging the covers',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'When are contract works insurers likely to make compliance with the Joint Code of Practice, Fire Prevention on Construction Sites requirements a policy condition?',
                body: '',
                keyLearning: 'What is covered in a Contractors All Risks policy, including defective design',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'For insurance of works where original contract value exceeds £2.5m, or works include specific hazards such as timber frame construction',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'For insurance of works where original contract value exceeds £2.5m only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'For insurance of works taking place outside the UK only',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'For insurance of contract works on a high-rise building only',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'Why was the JCT – Option C1 Replacement added to the contract options?',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'It can be difficult for an employer to fulfil the joint names requirement for existing structures ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It can be difficult for an employer to obtain details of the insurance for the existing structure',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It can be difficult for an employer to obtain to get the agreement of the contractor to arrange the jont names insurance of the existing structure',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It can be difficult for an employer to obtain to get the agreement of the sub-contractor to arrange the jont names insurance of the existing structure',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'If the insured is a \'composite insured\', which of the following is <strong>incorrect</strong>? ',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'All composite insureds on the policy are treated as though they are the same insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The insured is covered for its own individual interest',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured is unable to subrogate against other composite insureds',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured can make a claim directly against the insurers of the policy',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Why do the JCT contracts have options for clause 6.7?',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'To allow either the employer or contractor to control the insurance arrangements',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'To allow either the  contractor or sub-contractors to control the insurance arrangements',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To provide an option where joint names insurance is not required',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To allow different insurers to become involved',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'What type of damage triggers cover under the DSU policy?',
                body: '',
                keyLearning: 'What is Delay in Start-Up (DSU) insurance',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Damage to the contract works as insured under the contract works policy',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Damage to the contract works as insured under the employer\'s own property policy',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Any damage to the contract works caused by an event that is outside the control of the contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Any damage to the contract works caused by the contractor',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'Which of the following is an extension usually found on a contract works policy?',
                body: '',
                keyLearning: 'What is covered in a Contractors All Risks policy, including defective design ',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Tools belonging to employees',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Property being work on',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Escape of molten materials',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Theft of existing structures',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'Which contract works policy defects exclusion, completely excludes all claims arising from property defects of any kind? ',
                body: '',
                keyLearning: 'What is covered in a Contractors All Risks policy, including defective design ',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'DE1',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'DE2',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'DE3',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'DE4',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'Why do the JCT standard building contract clauses insist on a joint names insurance policy?',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The joint names basis ensures that the insurers have no right of subrogation against any party named as a joint insured',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The joint names basis ensures that the insurers have full rights of subrogation against any party named as a joint insured',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The joint names basis ensures that insurers are aware of all parties involved in the contract works',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The joint names basis makes it easier for parties to make a claim',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'When would the JCT Minor Works contract be used?',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'For simple contracts with a value of up to £250,000',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'For simple contracts with a value up to £1m',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'For contracts where there are no sub-contractors',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'For refurbishment contracts only',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Who arranges delay in start-up insurance?',
                body: '',
                keyLearning: 'What is Delay in Start-Up (DSU) insurance',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'The employer',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The sub-contractor',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The architect',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The tenant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Which of the following statements is true?',
                body: '',
                keyLearning: 'What is covered in a Contractors All Risks policy, including defective design ',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'All contract works cover has some kind of exclusion relating to defective design, workmanship, or materials',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No contract works cover has exclusions relating to defective design, workmanship, or materials',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'All contract works cover has some kind of exclusion relating to defective design, but always covers defective workmanship or materials',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'All contract works cover has some kind of exclusion relating to defective design and defective workmanship but always covers defective materials',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Which of the following covers would not usually be found in a contractors\' all risks policy?',
                body: '',
                keyLearning: 'What is covered in a Contractors All Risks policy, including defective design ',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Directors\' and officers\' liability',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Public liability',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Theft of tools',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Damage to hired in plant',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'How do the JCT contract wordings define \'joint names\'?',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'As composite insureds',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'As joint insureds',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'As co-insureds',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'There is no joint names definition in the contract',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'What is the main contract used for building work in the UK?',
                body: '',
                keyLearning: 'Difference between joint names and composite insured status, and why it matters',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'The Joint Contracts Tribunal design and build contract',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The Joint Conditions and Terms design and build contract',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The International Federation of Consulting Engineers produces Conditions of Contract',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Association of Consultant Architects form of Building Agreement',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const ConstructionContractorsAllRisksErectionAllRisksAndBiIssues = {
    questionnaire
};