const module = [
  {
    id: '2ce14dc2-9690-47b2-9960-a22a95afde42',
    slug: 'discrimination',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '2ce14dc2-9690-47b2-9960-a22a95afde42|9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    template: 'rradar-iframed-template',
    title: 'An introduction to discrimination awareness',
    description:
      'The third in the series of Rradars Employment Law modules, we’re discussing discrimination, what is it, the types of discrimination and the key legislation covering it.',
    completedDescription: 'TODO',
    supplier: 'Rradar',
    categoryId: '9ecd64a0-66a5-4ef7-8ba6-f0a39841849f',
    questionnaireId: '',
    contents: {
      id: '0f52ba65-2e97-4ae0-b64d-32de6e605ad3',
      longDescription:
        '<div class="row">' +
        '<div class="col-12 col-md-6">' +
        '<h2 class="subtitle">Description</h2>' +
        'You’ll learn what is discrimination and the types of discrimination and what you need to consider when recruiting and as an employer.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>What is discrimination</li>' +
        '<li>Key legislation covering discrimination</li>' +
        '<li>Direct and indirect discrimination</li>' +
        '<li>Discrimination in recruitment</li>' +
        '<li>Discrimination in employment</li>' +
        '<li>Introduction to reasonable adjustments</li>' +
        '</ul>' +
        '</div>' +
        '<div class="col-12 col-md-6">' +
        '<h2 class="subtitle">Chapters</h2>' +
        "<p>This module is made up of a series of chapters which you will complete them in the order shown below but as your progress will be saved as you go you don't have to do them all in one sitting, just leave the module and the chapter you are on will be shown when you return.</p>" +
        '<ul style="margin-top:10px">' +
        '<li>Chapter 1 - What is discrimination</li>' +
        '<li>Chapter 2 - Introduction to Discrimination Quiz</li>' +
        '<li>Chapter 3 - Case Studies</li>' +
        '<li>Chapter 4 - Different Types of Discrimination Quiz</li>' +
        '</ul>' +
        '</div>' +
        '</div>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/17dfae1f-d43c-479d-839c-a9c7a49c6459/media/managing-sickness-absence-chapter-one/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ],
      playlist: {
        id: 'f3f909e1-3afc-427f-9fd8-91ab3222c28a',
        title: 'Discrimination',
        author: 'Rradar',
        tracks: [
          {
            id: 'd4d9ed58-940c-4ccf-9de0-3acb49a6f654',
            title: 'Chapter 1 - What is discrimination',
            slug: 'chapter-1-what-is-discrimination',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '820b900e-43da-4500-9bab-936b3c247fe1',
            title: 'Chapter 2 - Introduction to Discrimination Quiz',
            slug: 'chapter-2-introduction-to-discrimination-quiz',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: '853bfdef-3052-4cf6-a70a-734c973b3e2f',
            title: 'Chapter 3 - Case Studies',
            slug: 'chapter-3-case-studies',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          },
          {
            id: 'f736f0e6-e376-4afc-b4e8-a36ad6682773',
            title: 'Chapter 4 - Different Types of Discrimination Quiz',
            slug: 'chapter-4-different-types-of-discrimination-quiz',
            type: 'track',
            startButtonText: 'START THIS CHAPTER'
          }
        ]
      }
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['management', 'legal', 'intermediate']
  }
];

export const Discrimination = {
  module
};
