const module = [
  {
    id: '7be7047e-5c33-4d6f-b6ad-f15620195b92',
    slug: 'change-management-dealing-with-change',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '7be7047e-5c33-4d6f-b6ad-f15620195b92|76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    template: 'actus-iframed-template',
    title: 'Dealing with change',
    description:
      'Change can be unsettling for some or energising for others.  You’ll discover how to navigate our natural responses to change to become more resilient.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '76c64d1c-38a4-4ac5-824c-31ef12b91ac7',
    questionnaireId: '',
    contents: {
      id: '8e7b01cb-0850-4785-854c-9cc6810573e0',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Dealing with change in an organisation can be unsettling for some and positive and energising for others.  You’ll discover our natural responses to change and how to navigate them and how we can challenge our mindset to become resilient to change.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your learning outcomes for this module include</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how neuroscience and personality preferences affect our view of change</li>' +
        '<li>Discover our natural emotional responses to change and how to navigate them</li>' +
        '<li>Understand the ways in which we can challenge our mindset to make us feel more resilient about change</li>' +
        '<li>Recognise the skills and mindsets that will help yourself and others adapt to change</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/7be7047e-5c33-4d6f-b6ad-f15620195b92/media/actus-change-management-series-dealing-with-change-web-v1/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const DealingWithChange = {
  module
};
