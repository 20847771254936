const module = [
  {
    id: 'e3b95373-1c4c-45ac-bc64-46edf2df64e5',
    slug: 'manufacturing-liability-and-motor-insurance-issues-and-implications',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'e3b95373-1c4c-45ac-bc64-46edf2df64e5|31e36ecf-9b1e-4831-9193-665e8534fe0c',
    template: 'video-template',
    title: 'Liability and motor insurance issues and implications',
    description:
      'Understand the main issues affecting liability, cyber and motor cover for manufacturers and the importance of detailed presentation information.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '31e36ecf-9b1e-4831-9193-665e8534fe0c',
    questionnaireId: 'a8240431-030e-4642-a657-d64d74fd2d60',
    contents: {
      id: 'ffb731c2-497f-476d-9ba0-1f0ab8a363e3',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the main issues affecting liability, cyber and motor cover for manufacturers and the importance of detailed presentation information.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand risk and need to put detailed, clear and up-to-date information in a presentation</li>' +
        '<li>Understand the key liability exclusions and which may be bought back to infill gaps in cover</li>' +
        '<li>Understand the main issues affecting liability, cyber and motor cover for manufacturers</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Manufacturing 4 - Liability and motor insurance issues and implications',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/541618109',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Manufacturing 4 - Liability and motor insurance issues and implications',
          button: 'Download Slides',
          fileSize: 4613451,
          url: 'manufacturing-liability-and-motor-insurance-issues-and-implications.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 4
    },
    statistics: {
      level: 1,
      timeEstimate: '28'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['manufacturing', 'products', 'motor', 'sales', 'intermediate']
  }
];

export const ManufacturingLiabilityAndMotorInsuranceIssuesAndImplications = {
  module
};
