const module = [
  {
    id: '1877c0ed-e65d-485a-847e-c6e22734b1eb',
    slug: 'myths-about-burnout',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1877c0ed-e65d-485a-847e-c6e22734b1eb|32f67180-3d5b-4522-82d8-aec3c6be0971',
    template: 'broker-dev-work-burnout',
    title: 'Myths About Burnout',
    description:
      'Burnout describes a condition of emotional, physical, and mental exhaustion' +
      ' but there are many myths. ' +
      'These misunderstandings and assumptions are not helpful to individuals who experience this challenging condition',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: '32f67180-3d5b-4522-82d8-aec3c6be0971',
    questionnaireId: '',
    contents: {
      id: 'd91511ca-5453-4b11-ad9e-f1938809177f',
      longDescription: '',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: '??',
          button: '',
          fileSize: 0,
          url: '???',
          caption: {
            title: '',
            description: '<p>Delivered by: ??</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: '??',
          button: 'Download Slides',
          fileSize: 0,
          url: '???.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '15'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['wellbeing', 'foundation']
  }
];

export const MythsAboutBurnout = {
  module
};
