const module = [
  {
    id: '5b5efa46-a19a-4418-87d3-104ea23c6b6e',
    slug: 'manufacturing-property-insurance-issues-and-implications',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '5b5efa46-a19a-4418-87d3-104ea23c6b6e|31e36ecf-9b1e-4831-9193-665e8534fe0c',
    template: 'video-template',
    title: 'Property insurance issues and implications',
    description:
      'Understand the issues facing manufacturers that impact property insurance, including key cover extensions to consider and calculating the sum insured.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '31e36ecf-9b1e-4831-9193-665e8534fe0c',
    questionnaireId: 'a517af35-40df-4130-aa31-c02183d71cd4',
    contents: {
      id: 'f5ff17d9-bc3c-4b31-8998-d63b5108157c',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the key issues that impact property insurance for manufacturers, along with specific considerations for cover/extensions and calculation of the sum insured.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand what factors to consider when advising on calculation of the correct sum insured.</li>' +
        '<li>Be aware of the issues that are facing manufacturers and how they impact the insurance cover.</li>' +
        '<li>Enhance understanding of the key extensions/cover to consider for manufacturing risks.</li>' +
        '<li>Understand the key challenges for brokers and how to present the risk to insurers.</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Manufacturing 1 - Property insurance issues and implications',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/515764625',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Manufacturing 1 - Property insurance issues and implications',
          button: 'Download Slides',
          fileSize: 4022213,
          url: 'manufacturing-property-insurance-issues-and-implications.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 4
    },
    statistics: {
      level: 1,
      timeEstimate: '37'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['manufacturing', 'products', 'sales', 'intermediate']
  }
];

export const ManufacturingPropertyInsuranceIssuesAndImplications = {
  module
};
