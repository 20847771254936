const module = [
  {
    id: 'ab5edfa3-1e1d-4490-9f81-4dc7265fad89',
    slug: 'sales-prospecting-two',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'ab5edfa3-1e1d-4490-9f81-4dc7265fad89|c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    template: 'video-template',
    title: 'Prospecting - Two',
    description:
      'In the second of two modules on prospecting you’ll learn about how you can use the Ideal Client Profile to identify and generate new leads.',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: 'c306cd2c-4df9-4206-85cc-a33d0bcf6762',
    questionnaireId: '',
    contents: {
      id: 'd3043a7f-77c4-4a9c-a567-b7cbdd6dc111',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Determining what makes your Ideal Client Profile will set you up to identify and generate new leads.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Using a research-based approach for identifying leads.</li>' +
        '<li>Identifying where you can find or create ‘warm’ leads to maximise your sales activities</li>' +
        '<li>The importance of referrals</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Prospecting - Two',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/731359561',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Prospecting - Two',
          button: 'Download Slides',
          fileSize: '414817',
          url: 'prospecting-two.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 3,
      maximum: 10
    },
    statistics: {
      level: 0,
      timeEstimate: '6'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'foundation']
  }
];

export const IntroductionToSalesProspectingTwo = {
  module
};
