<template>
  <div>
    <div v-if="module.progress.maximum > 1" class="row">
      <div class="col">
        <module-progress :progress="module.progress" class="progress-steps" />
      </div>
    </div>
  </div>
</template>

<script>
import ModuleProgress from '@/components/ModuleProgress.vue';

export default {
  props: ['module'],
  components: {
    ModuleProgress,
  },
};
</script>
