const module = [
  {
    id: 'fa94d80f-8bad-46b6-a0c5-b2646f69e817',
    slug: 'the-extra-one-percent-performance-edge',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'fa94d80f-8bad-46b6-a0c5-b2646f69e817|d68963e3-4b1b-4af9-9d70-c9399c58d809',
    template: 'video-template',
    title: 'The Extra 1% Performance Edge',
    description:
      'Hear from Emma Matthews how to get the extra 1% edge on your performance, including how to free up time to achieve more and why keeping fit give you an edge.',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
    questionnaireId: '',
    contents: {
      id: 'a1f54813-2912-443f-8379-4d5c2c28ca85',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Hear from Emma Matthews how to get the extra 1% edge on your performance, including how to free up time to achieve more and why keeping fit give you an edge.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to get the extra edge on your performance</li>' +
        '<li>How to uplevel your self belief</li>' +
        '<li>How to free up time to achieve more</li>' +
        '<li>Understand the connection between physical health and achieving a performance edge</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'The Extra 1% Performance Edge',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/742197870',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Emma Matthews</p>'
          }
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '20'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'intermediate']
  }
];

export const TheExtraOnePerformanceEdge = {
  module
};
