// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
  {
    id: '0c624416-354e-4179-86e9-1081192bbbbc',
    title: 'MLP - Directors and Officers cover',
    body: '',
    keyLearning: 'Learning',
    passScore: 2,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false
    },
    questions: [
      {
        id: 1,
        title: 'How many regulatory bodies are there in the United Kingdom?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title: 'More than 100',
            body: '',
            score: 1.0
          },
          {
            title: 'More than 3',
            body: '',
            score: 0
          },
          {
            title: 'More than 20',
            body: '',
            score: 0
          },
          {
            title: 'More than 50',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title:
          'Being a director, partner or officer of a company exposes the individuals holding these positions to be personally liable for the acts or omissions they may make in the performance of their company duties. Which of these could be potential allegations for breach of duties?',
        body: '',
        keyLearning: '',
        difficulty: 'medium',
        answers: [
          {
            title:
              'Shareholder derivative action, Unfair competition laws and Unfair trade practices',
            body: '',
            score: 1.0
          },
          {
            title: 'Only shareholder derivative action',
            body: '',
            score: 0
          },
          {
            title: 'Only unfair competition laws',
            body: '',
            score: 0
          },
          {
            title: 'Only unfair trade practices',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
];

export const MlpDirectorsOfficersCover = {
  questionnaire
};
