const module = [
  {
    id: 'e9cd94d5-31eb-4792-acfc-548ccd72390e',
    slug: 'keeping-your-high-performers-performing',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'e9cd94d5-31eb-4792-acfc-548ccd72390e|d68963e3-4b1b-4af9-9d70-c9399c58d809',
    template: 'video-template',
    title: 'Keeping your high performers performing',
    description:
      'Learn how to sustain performance for the long term, how to gain an advantage, what to do if performance dips and how to how recognition plays an important role in maintaining performance.',
    completedDescription: 'TODO',
    supplier: 'Emma Matthews',
    categoryId: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
    questionnaireId: '',
    contents: {
      id: 'd68963e3-4b1b-4af9-9d70-c9399c58d809',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this podcast we’re joined by Emma Matthews, performance coach, author and expert on burnout prevention. We’ll discuss how to sustain performance for the long term, how gain an advantage and what to do if performance dips.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>How to sustain performance</li>' +
        '<li>Use visualisation to achieve success</li>' +
        '<li>Understand the ‘rules of the mind’</li>' +
        '<li>How to get out of a dip in performance</li>' +
        '<li>Understanding reward and recognition</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Keeping your high performers performing',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/691442023',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Emma Matthews</p>'
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 3
    },
    statistics: {
      level: 1,
      timeEstimate: '20'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'intermediate']
  }
];

export const KeepingYourHighPerformersPerforming = {
  module
};
