const module = [
  {
    id: 'f9ea0233-4e64-4e3f-a3cd-dec4a59b62d9',
    slug: 'creating-and-delivering-business-presentations',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'f9ea0233-4e64-4e3f-a3cd-dec4a59b62d9|349708b6-4bdc-49a5-a851-fbd4d574b56d',
    template: 'zing365-iframed-template',
    title: 'Creating and Delivering Business Presentations',
    description:
      'In this module, you will learn how to create and deliver winning business presentations.',
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
    questionnaireId: '',
    contents: {
      id: '5aefc352-c3d6-49f5-bc3d-cfba9be1368a',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module, you will learn how to create and deliver winning business presentations. First, you will learn how to define presentation objectives, organise content, and bring it to life with visual aids. Then, you will discover the best ways to rehearse, identify areas for improvement, and cope with nerves. Finally, you will learn how to deal with difficult questions from your audience.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand the importance of structuring a presentation</li>' +
        '<li>Review tips to maximise the impact of your presentation</li>' +
        '<li>Tips for soliciting meaningful feedback</li>' +
        '<li>Identify 6 steps to calm your presentation nerves</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/f9ea0233-4e64-4e3f-a3cd-dec4a59b62d9/media/zing365-soft-skills-creating-and-delivering-business-presentations/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '40'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'skills', 'foundation']
  }
];

export const CreatingAndDeliveringBusinessPresentations = {
  module
};
