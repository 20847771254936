const module = [
  {
    id: 'fc76de0b-07e5-480a-b872-f7a399177e39',
    slug: 'introduction-to-linkedin',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'fc76de0b-07e5-480a-b872-f7a399177e39|8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    template: 'video-template',
    title: 'An Introduction to LinkedIn',
    description:
      ' The first in a series dedicated to LinkedIn. This module discusses what LinkedIn is, the difference between Company Pages and Personal Profiles, and asks Why are you using LinkedIn?',
    completedDescription: 'TODO',
    supplier: 'Ignition NBS Ltd',
    categoryId: '8564b263-b8f3-47d5-b2d8-680bb2f38ea6',
    questionnaireId: '',
    contents: {
      id: '6eabe67d-1fc0-48dc-9da7-be3a043e0764',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'The first in a series dedicated to LinkedIn. This module discusses what LinkedIn is, ' +
        'the difference between Company Pages and Personal Profiles, and asks Why are you using LinkedIn?' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>To understand what LinkedIn is and how much it is used</li>' +
        '<li>To bust the myth that it is more than a recruiting tool and online CV</li>' +
        '<li>To provide an overview of LinkedIn’s features and where you should concentrate your time and effort</li>' +
        '<li>To understand the difference between Company Pages and Personal Profiles</li>' +
        '<li>To pose the question WHY are you using LinkedIn and what does success look like for me</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Marketing - An Introduction to LinkedIn',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/531654615',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Mike McNabb - Broker Marketing Manager.</p>' +
              '<p>Brought to you by: Ignition NBS Ltd.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Marketing - An Introduction to LinkedIn',
          button: 'Download Slides',
          fileSize: 1904211,
          url: 'an-introduction-to-linkedin.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 2,
      maximum: 3
    },
    statistics: {
      level: 0,
      timeEstimate: '14'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'foundation']
  }
];

export const IntroductionToLinkedin = {
  module
};
