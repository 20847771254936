const module = [
  {
    id: '9606c79a-0404-4d16-b68d-0628ad7ce4f7',
    slug: 'social-selling-and-virtual-networking',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '1d903ecd-b454-426a-8d43-a44307fd4ab7|9606c79a-0404-4d16-b68d-0628ad7ce4f7',
    template: 'video-template',
    title: 'Social selling and virtual networking',
    description:
      'Understand how to maximise the potential of your network, develop "client intimacy" and build prospect relationships in a remote environment',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: '1d903ecd-b454-426a-8d43-a44307fd4ab7',
    questionnaireId: '',
    contents: {
      id: '7bebde7d-1e9a-40d5-9143-705a93b30f99',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand how to maximise the potential of your network, develop "client intimacy" and build prospect relationships in a remote environment' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Build an understanding of the effects of Covid-19 on working practices and the sales process</li>' +
        '<li>Understand how to maximise the potential of your network</li>' +
        '<li>Learn how to develop "client intimacy" remotely</li>' +
        '<li>Understand how to "prospect" and build prospect relationships in a remote environment</li>' +
        '<li>Social selling best practice and when to use it</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title:
            'Broker Sales in a Changing Environment - Session Four: Social Selling and Virtual Networking',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505196982',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title:
            'Broker Sales in a Changing Environment - Session Four: Social Selling and Virtual Networking',
          button: 'Download Slides',
          fileSize: 1527335,
          url: 'social-selling-and-virtual-networking.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https://www.axaconnect.co.uk/siteassets/coronavirus-hub/business-support/sales-in-a-changing-environment/sales-in-a-changing-environment-week-4/'
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 6
    },
    statistics: {
      level: 1,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'marketing', 'intermediate']
  }
];

export const SocialSellingAndVirtualNetworking = {
  module
};
