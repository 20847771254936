// Manufacturing - Property insurance issues and implications
const questionnaire = [
    {
        id: 'a517af35-40df-4130-aa31-c02183d71cd4',
        title: 'Manufacturing - Property insurance issues and implications',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title:
                    'Which of the following statements is false regarding the EC and Public Authorities Clause?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'The clause pays for any upgrade required by a local authority',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Sprinkler upgrades required by an insurer following a loss can be covered',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Undamaged property can be included in some wordings',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The work must be completed within 12 months of the date of the damage',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'Where would you NOT expect to find steam plant?',
                body: '',
                keyLearning: 'Key extensions/cover to consider for manufacturing risks.',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'A retail outlet',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'A brick manufacturer',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A brewery',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A paper manufacturing factory',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'What is the purpose of an obsolete buildings clause?',
                body: '',
                keyLearning:
                    'Key extensions/cover to consider for manufacturing risks.   Issues facing manufacturers that impact property insurance and the changes to policies that may be needed',
                difficulty: 'hard',
                answers: [
                    {
                        title:
                            'To allow a building to be insured for less than its full reinstatement value without penalty',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'To exclude any building which is no longer in use',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To restrict the perils covered for unoccupied old buildings',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'To allow buildings to be rebuilt with original materials even though the cost may exceed the sum insured',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'When would a client need to insure his motor vehicles under a property policy?',
                body: '',
                keyLearning: 'Key extensions/cover to consider for manufacturing risks.',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'As an accumulation risk in the car park',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Never',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Whilst being serviced at the garage',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'When on a transporter',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'Which of the following statements is true regarding the Brands and Labels clause?',
                body: '',
                keyLearning: 'Key extensions/cover to consider for manufacturing risks.',
                difficulty: 'hard',
                answers: [
                    {
                        title:
                            "Salvaged stock bearing a brand or label cannot be sold without the insured's consent",
                        body: '',
                        score: 1
                    },
                    {
                        title: 'No liability will attach to the manufacturer if the label is missing',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The insured has to pay for the removal of the labels or brand marks',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'The insured cannot be paid for salvaged stock still bearing a brand mark or label',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title:
                    'Which clause is added to a Property policy to cover the full cost of rebuilding as new?',
                body: '',
                keyLearning: 'Ensuring that the sum insured is correct',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'A reinstatement memorandum',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'An excess clause',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'An average condition',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A betterment clause',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'What is an indemnity settlement?',
                body: '',
                keyLearning: 'Ensuring that the sum insured is correct',
                difficulty: 'medium',
                answers: [
                    {
                        title:
                            'A financial settlement that places the insured in the same financial position after the loss as they enjoyed immediately prior to it',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Another term for subsidence, heave or landslip',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Claims payments made in a staged or interim basis',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'A type of claim settlement designed to enable the insured to comply with the indemnities in his finance agreement',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title:
                    'Which type of property is not usually included within the definition of "contents" in a standard all risks policy?',
                body: '',
                keyLearning: 'Ensuring that the sum insured is correct.',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Livestock',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Computers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Valuable stock',
                        body: '',
                        score: 0
                    },
                    {
                        title: ' Visitors personnel effects',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title:
                    'If a property policy is arranged on a reinstatement basis, where may the reinstatement of damaged property take place?',
                body: '',
                keyLearning: 'Ensuring that the sum insured is correct',
                difficulty: 'medium',
                answers: [
                    {
                        title:
                            'On any site the insured chooses, subject to not increasing the cost to insurers',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Only on the existing site',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'On an adjacent site, if it saves debris removal costs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Nowhere – the insurer will pay the insured the money to spend as he likes',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'What is the purpose of a contract price clause on a Property policy?',
                body: '',
                keyLearning:
                    'Ensuring that the sum insured is correct. Issues facing manufacturers that impact property insurance and the changes to policies that may be needed',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'To specify the basis of settlement for goods sold but not delivered',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'To permit the insured to accept the best estimate for repairs following damage',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            'To limit the cover in respect of contracts for which the insured is liable following a loss',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'To guarantee the premium for a three year period',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'What is the normal penalty for underinsurance?',
                body: '',
                keyLearning: 'Ensuring that the sum insured is correct',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Application of the condition of average',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Insurers decline a claim',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insured has to pay additional premium ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'None',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'Why might replacement costs of machinery increase after Brexit?',
                body: '',
                keyLearning:
                    'Ensuring that the sum insured is correct. Issues facing manufacturers that impact property insurance and the changes to policies that may be needed',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Imposition of additional taxes and costs',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Shortage of parts',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insolvency of machinery manufacturers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Most machinery is manufactured overseas',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'Are foundations included in a policy definition of  buildings?',
                body: '',
                keyLearning: 'Ensuring that the sum insured is correct',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Sometimes - depends on the wording',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'Never',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'No, Included in contents sum insured',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Yes, always',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'Why must the existence of composite panels in a building be declared to insurers?',
                body: '',
                keyLearning:
                    'Issues facing manufacturers that impact property insurance and the changes to policies that may be needed',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'They increase the fire risk',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'They decrease the fire risk',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'So insurers can impose special conditions',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'So insurers can increase the price',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'Why is it important to include an executive summary in a broker risk presentation?',
                body: '',
                keyLearning:
                    'Issues facing manufacturers that impact property insurance and the changes to policies that may be needed',
                difficulty: 'easy',
                answers: [
                    {
                        title:
                            'To enable insurers to see quickly if they are interested in underwriting the risk',
                        body: '',
                        score: 1
                    },
                    {
                        title: 'So that insurers do not need to read the whole presentation',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'So that placing brokers do not need to read the whole presentation',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'So insurers know where to file the presentation',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    },
]

export const ManufacturingPropertyInsuranceIssuesAndImplications = {
    questionnaire
};