// real estate module 4
const module = [
  {
    id: 'c2df1d8c-fbde-4b74-864d-7fe56f83944c',
    slug: 'real-estate-income-protection-loss-of-rent-and-alternative-accommodation',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'c2df1d8c-fbde-4b74-864d-7fe56f83944c|f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    template: 'video-template',
    title: 'Income protection: loss of rent and alternative accommodation',
    description:
      'Understand the issues to consider when arranging loss of rent cover, key cover extensions available and considerations when assessing the indemnity period',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas & Associates',
    categoryId: 'f0a2b81c-6127-4e34-a22d-e24cd5f58c5f',
    questionnaireId: '130e320d-eb1b-46b7-9b0e-2834bce3a359',
    contents: {
      id: '{{content-uuid}}',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Understand the issues to consider when arranging loss of rent cover, key cover extensions available and considerations when assessing the indemnity period' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand how to insure rent and the issues to consider when arranging loss of rent cover</li>' +
        '<li>Enhance your understanding of the key rent cover extensions and when they are needed</li>' +
        '<li>Learn the factors to consider when advising a client on the suitable length of the indemnity period</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Income protection: loss of rent and alternative accommodation',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/592690916',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Income protection: loss of rent and alternative accommodation',
          button: 'Download Slides',
          fileSize: '1351258',
          url: 'real-estate-income-protection-loss-of-rent-and-alternative-accommodation.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 4,
      maximum: 5
    },
    statistics: {
      level: 1,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['real estate', 'products', 'legal', 'intermediate']
  }
];

export const RealEstateIncomeProtectionLossOfRentAndAlternativeAccommodation = {
  module
};
