const module = [
  {
    id: '7d4a8051-1db0-43be-905c-70c5a8ba7921',
    slug: 'adopting-a-sales-mindset',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '7d4a8051-1db0-43be-905c-70c5a8ba7921|4c2ac334-4d5d-4213-b2ad-6189b7c63761',
    template: 'video-template',
    title: 'Adopting a Sales Mindset',
    description:
      'Hear from Nick Thomas as he discussed how to adopt a sales mindset; what they key attributes of a high performing salesperson and how to overcome the fear of rejection',
    completedDescription: 'TODO',
    supplier: 'Nick Thomas Associates',
    categoryId: '4c2ac334-4d5d-4213-b2ad-6189b7c63761',
    questionnaireId: '',
    contents: {
      id: 'e29a2302-219d-445e-8f15-7a3578096a83',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Hear from Nick Thomas as he discussed how to adopt a sales mindset; what they key attributes of a high performing salesperson and how to overcome the fear of rejection' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Debunking the myths about being a salesperson</li>' +
        '<li>What are the key attributes of a successful salesperson?</li>' +
        '<li>How you can overcome the fear of rejection to thrive</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Adopting a sales mindset',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/755045750',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Nick Thomas & Aidan Boyles</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        }
        // {
        //   type: 'file',
        //   subType: 'pdf',
        //   title: 'Adopting a sales mindset',
        //   button: 'Download Slides',
        //   fileSize: '{{bytes}}',
        //   url: '{{ module slig as filename }}.pdf',
        //   caption: {
        //     title: '',
        //     description: ''
        //   },
        //   axaUrl: ''
        // }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '30'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['sales', 'foundation']
  }
];

export const AdoptingASalesMindset = {
  module
};
