<template>
  <div id="app" class="bw-ie" v-bind:class="{ elevate: isIEElevate }">
    <navbar
      :accessCode="this.$localStorage.retrieveDecrypted('access-code')"
      :isIEElevate="isIEElevate"
    ></navbar>
    <div class="container-fluid" :style="topMargin">
      <div v-cloak>
        <router-view :isIEElevate="isIEElevate"></router-view>
      </div>
    </div>
    <footer-panel :isIEElevate="isIEElevate"></footer-panel>
    <cookie-notice></cookie-notice>
  </div>
</template>

<script>
import CookieNotice from '@/components/CookieNotice';
import CssAuth from '@/content/tenants/ie-brokerwise-pro/design/CssAuth';
import CssCurriculum from '@/content/tenants/ie-brokerwise-pro/design/CssCurriculum';
import CssCookieNotice from '@/content/tenants/ie-brokerwise-pro/design/CssCookieNotice';
import CssFooter from '@/content/tenants/ie-brokerwise-pro/design/CssFooter';
import CssHomePage from '@/content/tenants/ie-brokerwise-pro/design/CssHomePage';
import CssIcons from '@/content/tenants/ie-brokerwise-pro/design/CssIcons';
import CssModuleCard from '@/content/tenants/ie-brokerwise-pro/design/CssModuleCard';
import CssModulesCore from '@/content/tenants/ie-brokerwise-pro/design/CssModulesCore';
import CssNavbar from '@/content/tenants/ie-brokerwise-pro/design/CssNavbar';
import CssSiteCore from '@/content/tenants/ie-brokerwise-pro/design/CssSiteCore';
import CssTags from '@/content/tenants/ie-brokerwise-pro/design/CssTags';
import CssSpotlight from '@/content/tenants/ie-brokerwise-pro/design/CssSpotlight';

import { AuthMixin } from '@/mixins/auth.mixin';

import Navbar from '@/content/tenants/ni-brokerwise-pro/Navbar';

import FooterPanel from '@/content/tenants/ni-brokerwise-pro/FooterPanel';

export default {
  beforeUpdate() {
    this.checkAndAllowAccess();
    window.scrollTo(0, 0);
  },
  components: {
    CookieNotice,
    CssAuth,
    CssCurriculum,
    CssCookieNotice,
    CssFooter,
    CssHomePage,
    CssIcons,
    CssModuleCard,
    CssModulesCore,
    CssNavbar,
    CssSiteCore,
    CssTags,
    CssSpotlight,
    FooterPanel,
    Navbar,
  },
  computed: {
    isIEElevate() {
      if (
        this.$route.name === 'AccessCodeEntryElevate' ||
        this.$localStorage.retrieveDecrypted('view') === 'elevate' ||
        this.$localStorage.retrieveDecrypted('access-code').type === 'ELE'
      ) {
        return true;
      }
      return false;
    },
    topMargin() {
      if (this.isIEElevate) {
        return 'margin-top:150px';
      }

      return 'margin-top:100px';
    },
  },
  mounted() {
    this.checkAndAllowAccess();
    window.scrollTo(0, 0);
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Brokerwise Bite-sized knowledge for our Brokers ',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Brokerwise is an online personal development platform for Brokers. Work your way through self-contained training modules',
      },
      {
        name: 'msapplication-config',
        content: '/icons/ni.brokerwise-pro/tiles.xml',
      },
      {
        name: 'application-name',
        content: 'Brokerwise',
      },
    ],
    link: [
      {
        rel: 'shortcut icon',
        href: '/icons/ni.brokerwise-pro/favicon.ico',
      },
      {
        rel: 'manifest',
        href: '/icons/ni.brokerwise-pro/manifest.json',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/icons/ni.brokerwise-pro/apple-icon-57x57.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60x',
        href: '/icons/ni.brokerwise-pro/apple-icon-60x60.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/icons/ni.brokerwise-pro/apple-icon-72x72.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/icons/ni.brokerwise-pro/apple-icon-76x76.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/icons/ni.brokerwise-pro/apple-icon-114x114.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/icons/ni.brokerwise-pro/apple-icon-120x120.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/icons/ni.brokerwise-pro/apple-icon-144x144.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/icons/ni.brokerwise-pro/apple-icon-152x152.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/icons/ni.brokerwise-pro/apple-icon-180x180.png',
      },
      {
        rel: 'icon',
        sizes: '192x192',
        href: '/icons/ni.brokerwise-pro/android-icon-192x192.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        href: '/icons/ni.brokerwise-pro/favicon-16x16.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        href: '/icons/ni.brokerwise-pro/favicon-32x32.png',
      },
      {
        rel: 'icon',
        sizes: '96x96',
        href: '/icons/ni.brokerwise-pro/favicon-96x96.png',
      },
    ],
  },
  mixins: [AuthMixin],
};
</script>
