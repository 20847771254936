const module = [
  {
    id: 'b55e0a61-ed38-4904-a78e-8af63f6a58ab',
    slug: 'hybrid-management-key-communication-skills-and-self-awareness',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'b55e0a61-ed38-4904-a78e-8af63f6a58ab|9ddda366-d724-4ef0-8a75-248eda696817',
    template: 'actus-iframed-template',
    title: 'Key communication skills & self awareness',
    description:
      'Understand what effective hybrid communication is and how to develop flexibility in your communication style.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '9ddda366-d724-4ef0-8a75-248eda696817',
    questionnaireId: '',
    contents: {
      id: '0734fd93-949e-4b61-a401-ee5775ea6637',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Develop your communication skills by understanding about the benefits of hybrid communication and how to develop flexibility in your communication to work remotely more effectively.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes for this learning module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>Understand what effective hybrid communication is and what the benefits are</li>' +
        '<li>Explore the behaviours and skills involved when communicating remotely</li>' +
        '<li>Develop an awareness of your own communication style</li>' +
        '<li>Understand how to develop flexibility of communication style to improve relationships and key results</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/b55e0a61-ed38-4904-a78e-8af63f6a58ab/media/key-communication-skills-and-self-awareness-web-v2/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'foundation']
  }
];

export const KeyCommunicationSkillsSelfAwareness = {
  module
};
