const module = [
  {
    id: 'd5c0362c-0b8c-480b-b342-aae3a46a8ba5',
    slug: 'pre-action-protocols-in-northern-ireland',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'd5c0362c-0b8c-480b-b342-aae3a46a8ba5|b7ff23a6-9aaf-4b4a-9f8d-e7b6e73c3576',
    template: 'video-template',
    title: 'Pre-action Protocols in Northern Ireland',
    description:
      'You’ll learn about the key information you need to be aware of Pre-action Protocols in Northern Ireland',
    completedDescription: 'TODO',
    supplier: 'Rradar',
    categoryId: 'b7ff23a6-9aaf-4b4a-9f8d-e7b6e73c3576',
    questionnaireId: 'e030abd2-16d0-48ea-9665-7f25037caa0e',
    contents: {
      id: 'fed9fdd6-75d7-4305-8e92-d65d52c16343',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'In this module you’ll learn about the key information you need to be aware of regarding Pre-action Protocols in Northern Ireland' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Pre-action Protocols in NI</li>' +
        '<li>Aims/Purpose</li>' +
        '<li>Obligations</li>' +
        '<li>Civil Justice</li>' +
        '<li>Developments</li>' +
        '<li>Future</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Pre-action Protocols in Northern Ireland',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/765644945',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Enya McKenna, Associate – Carson McDowell</p>' +
              '<p>Brought to you by: Rradar and Carson McDowel</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Pre-action Protocols in Northern Ireland',
          button: 'Download Slides',
          fileSize: '4513887 bytes',
          url: 'pre_action_protocol_webinar.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl: ''
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 2,
      timeEstimate: '15'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['legal', 'advanced'],
    questionnaireOverride: {
      passScore: 4,
      mix: {
        easy: 0,
        medium: 4,
        hard: 0
      }
    }
  }
];

export const PreActionProtocolsInNorthernIreland = {
  module
};
