const module = [
  {
    id: '85e0fe37-02dc-48e7-9f2a-b8f90e5ef183',
    slug: 'motor-claims-inflation',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '85e0fe37-02dc-48e7-9f2a-b8f90e5ef183|56835389-6712-4c05-b212-f19d6095067b',
    template: 'video-template',
    title: 'Motor Claims Inflation ',
    description: '',
    completedDescription: 'TODO',
    supplier: 'AXA Commercial',
    categoryId: '56835389-6712-4c05-b212-f19d6095067b',
    questionnaireId: '',
    contents: {
      id: 'dd15a8ca-5fb7-45ae-b174-14eb3c2fa82f',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Thing_1</li>' +
        '<li>Thing_2</li>' +
        '<li>Thing_3</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: '{{ module titile }}',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/{{video id}}',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Diane Jenkins</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        }
        //   {
        //     type: 'file',
        //     subType: 'pdf',
        //     title: '{{ module titile }}',
        //     button: 'Download Slides',
        //     fileSize: '{{bytes}}',
        //     url: '{{ module slig as filename }}.pdf',
        //     caption: {
        //       title: '',
        //       description: ''
        //     },
        //     axaUrl: ''
        //   }
      ]
    },
    progress: {
      value: 1,
      maximum: 1
    },
    statistics: {
      level: 0,
      timeEstimate: '28'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['motor', 'products']
  }
];

export const MotorClaimsInflation = {
  module
};
