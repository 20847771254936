const module = [
  {
    id: '059e56f7-61d5-4c74-b1e0-140dd0cae6c1',
    slug: 'be-the-best-version-of-yourself',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '059e56f7-61d5-4c74-b1e0-140dd0cae6c1|349708b6-4bdc-49a5-a851-fbd4d574b56d',
    template: 'zing365-iframed-template',
    title: 'Be the Best Version of Yourself',
    description:
      'What does it mean to be the best version of yourself? In this module we will look at some of the techniques and tips you can use to work towards the best version of yourself.',
    completedDescription: 'TODO',
    supplier: 'Zing365',
    categoryId: '349708b6-4bdc-49a5-a851-fbd4d574b56d',
    questionnaireId: '',
    contents: {
      id: '8a2ab43d-a06f-411f-9c1d-99cc38695575',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'What does it mean to be the best version of yourself? In this module we will look at some of the techniques and tips you can use to work towards the best version of yourself. We will also investigate emotional intelligence and what impact that has on your working and personal life.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Review the 7 practices of successful professionals</li>' +
        '<li>Understand how to be an effective teammate</li>' +
        '<li>Identify the importance and characteristics of emotional intelligence</li>' +
        '<li>Enhance your social skills</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/059e56f7-61d5-4c74-b1e0-140dd0cae6c1/media/zing365-soft-skills-be-the-best-version-of-yourself/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 5,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '40'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['skills', 'foundation']
  }
];

export const BeTheBestVersionOfYourself = {
  module
};
