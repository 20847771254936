const module = [
  {
    id: 'ac79b1d4-70e8-42f0-a0f8-440d4d0daa96',
    slug: 'what-makes-a-great-leader',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'ac79b1d4-70e8-42f0-a0f8-440d4d0daa96|f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    template: 'video-template',
    title: 'What makes a great leader?',
    description:
      'Learn why great leaders can be made rather than born.  Identify traits great leaders display and how you can transition from a good leader to a great one.',
    completedDescription: 'TODO',
    supplier: 'Fiona Dewar - Pragmatic',
    categoryId: 'f2aabd5b-8118-4cbb-aa4a-c0e719f8d459',
    questionnaireId: '',
    contents: {
      id: '3045c242-a3e9-4ec8-b634-60733f560368',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Learn why great leaders can be made rather than born.  Identify traits great leaders display and how you can transition from a good leader to a great one.' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Understanding if leaders can be born or made</li>' +
        '<li>Who are great leaders?</li>' +
        '<li>What’s the difference between good and great leaders?</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'What makes a great leader?',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/730282313',
          caption: {
            title: '',
            description: '<p>Delivered by: Aidan Boyles and Fiona Dewar</p>'
          }
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 4
    },
    statistics: {
      level: 1,
      timeEstimate: '14'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const WhatMakesAGreatLeader = {
  module
};
