// Questionnaire
// {{ Course }} - {{ Module Name }}
const questionnaire = [
    {
        id: '0ce99d11-b025-4d3e-bf86-22411cfefc8b',
        title: 'Introduction to Terrorism cover - Session Two',
        body: '',
        keyLearning: 'Learning',
        passScore: 5,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'Which of the following statements is correct?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Insureds need to consider all primary policy extension sub-limits and indemnity periods in light of a terrorism event  occurring as the terrorism cover mirrors the limits of that policy',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Pool Re will set the policy extension sub-limits and indemnity periods that apply to a terrorism event',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insureds need to consider the terrorism cover extension sub-limits and indemnity periods in light of a terrorism event  occurring as the terrorism cover limits need to be adequate',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Insurers will set the policy extension sub-limits and indemnity periods that apply to a terrorism event',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'What type of premises are affected by the  Terrorism Protection of Premises Act',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Premises where at least 100 people have access to the premises or part of the premises',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'All business premises',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Premises where at least 800 people have access to the premises or part of the premises ',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'All local authority or government premises',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'Which of the following is NOT risk management provided by Pool Re',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'National Protective Security Authority',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Vulnerability Self-Assessment Tool',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Terrorism risk assessment for SMEs',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Threat analysis and information',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'Which of the following is NOT a benefit of a business using the Vulnerability Self-Assessment Tool ',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Insurers only offer Terrorism cover to a business that has used the Tool',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'May qualify for a terrorism premium discount',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Assists with business continuity planning',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It helps a business identify potential terrorism vulnerabilities',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'What is the significance of the QDIME Ltd v (1) Bath Building (Swindon) Management Company Ltd 2014 case?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'It established that if there is a need to insure against ‘explosion’  in a lease, explosion caused by terrorism must be included',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It established that if there is a need to insure against ‘explosion’  in a lease, explosion caused by terrorism need not be included',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It established that Pool Re must offer insurance to members anywhere in the UK',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It established that Pool Re must offer insurance to residential blocks of flats',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'What does the Terrorism Protection of Premises Act require a business to do?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Have in place procedures to reduce harm to the public and staff at the premises in the event of a terrorist attack',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Have in place procedures to identify all possible terrorism threats',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Have in place Terrorism insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Have in place procedures to implement a lockdown in the event of a terrorism threat',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 7,
                title: 'Why do businesses need to reconsider general liability policy terrorism limits in the light of Martyn\'s Law',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The law increases the responsibilities of businesses',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The law requires minimum levels of public liability insurance depending on the size of the business',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The law requires at least £10m of public liability insurance',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The law requires all businesses buy excess public liability insurance',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 8,
                title: 'What is also known as Martyn’s Law? ',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'The Terrorism Protection of Premises Act',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Counter Terrorism and Security Act',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The Terrorism Act',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Terrorism Prevention and Investigation Measures Act',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 9,
                title: 'What is the Pool Re Vulnerability Self-Assessment Tool? ',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Terrorism risk assessment tool for Insureds',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Terrorism risk assessment tool for Insurers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Terrorism risk assessment tool for Pool Re to assess risks before offering terms',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Terrorism risk assessment tool for Insurers to assess risks before offering terms',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 10,
                title: 'In the 2017 Borough Market incident, why were businesses affected unable to claim on their business interruption Terrorism insurance?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'There was no physical damage to property',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'The excesses were too high',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'The incident did not meet the terrorism definition in their policies',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pool Re cover does not include business interruption',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 11,
                title: 'Do liability policies have sub-limits for terrorism?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Yes many do, but some exclude terrorism completely',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No - all liability policies offer terrorism cover to the full policy limit',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Sub limits are found only in Public Liability policies',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Sub limits are found only in Professional Indemnity policies',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 12,
                title: 'How much would an insured pay Pool Re for risk management advice?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Pool Re provides free risk management advice to insureds',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'It would depend on the size of the business',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'It would depend on the size of the terrorism premium',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Pool Re charges insurers not insureds',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 13,
                title: 'What is ProtectUK?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'A hub for counter terrorism and security advice',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'A business that provides terrorism prevention trained security guards',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Representative body for Terrorism security professionals',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Representative body for Terrorism Insurers',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 14,
                title: 'How could a business suffer a liability terrorism claim?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Liability for terrorism could arise out of a breach in the policyholder’s duty to protect the safety of employees or third parties ',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Liability for terrorism could arise from an employee carrying out a terrorism act',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Liability for terrorism could arise from where the business chooses to locate',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'A business could not be liable for a terrorism act',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 15,
                title: 'Which of the following is a factor that clients should consider when deciding whether or not to buy Terrorism cover?',
                body: '',
                keyLearning: '',
                difficulty: 'easy',
                answers: [
                    {
                        title: 'Location – proximity to potential targets',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Size of business',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Type of customers',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Business sector',
                        body: '',
                        score: 0
                    }
                ]
            }
        ]
    }
]

export const IntroductionToTerrorismCoverSessionTwo  = {
    questionnaire
};