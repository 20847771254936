// Questionnaire
// axa-motor-fleet - electric-vehicles-technical-training
const questionnaire = [
    {
        id: 'dab36884-a2ec-4f9f-a3af-4456df7b026d',
        title: 'Electric Vehicles technical training',
        body: '',
        keyLearning: 'Learning',
        passScore: 3,
        debug: {
            showAllQuestions: false,
            showQuestionId: false,
            showAnswerInfo: false,
            showJson: false,
        },
        questions: [
            {
                id: 1,
                title: 'What are the main types of electric vehicle?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Plug in hybrid, self charging hybrid, battery electric vehicle',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Fuel cell hybrid, battery hybrid, plug in hybrid',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Fuel cell electric, battery hybrid, plug in electric',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Plug in hybrid, fuel cell electric, battery hybrid',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 2,
                title: 'What four things can affect the range of an EV?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>How efficiently you drive</li>' +
                            '<li>How you heat or cool the car before a journey (preconditioning)</li>' +
                            '<li>How fast you drive</li>' +
                            '<li>the vehicle’s payload </li>' +
                            '</ul>',
                        body: '',
                        score: 1.0
                    },
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>How erratic you drive</li>' +
                            '<li>How you heat or cool the car before a journey (preconditioning)</li>' +
                            '<li>How you use the infotainment system </li>' +
                            '<li>If you drive at night</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>How efficiently you drive </li>' +
                            '<li>How you heat or cool the car during a journey</li>' +
                            '<li>How fast you drive </li>' +
                            '<li>If you turn off rejenerative braking</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>How efficiently you drive </li>' +
                            '<li>How clean your car is</li>' +
                            '<li>How fast you drive</li>' +
                            '<li>The vehicle’s payload</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 3,
                title: 'What are the three types of chargers?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>Rapid</li>' +
                            '<li>Fast</li>' +
                            '<li>Slow</li>' +
                            '</ol>',
                        body: '',
                        score: 1.0
                    },
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>Speedy</li>' +
                            '<li>Quick</li>' +
                            '<li>Slow</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>Rapid</li>' +
                            '<li>Quick</li>' +
                            '<li>Slow</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    },
                    {
                        title:
                            '<ol style="margin-bottom:20px">' +
                            '<li>Really fast</li>' +
                            '<li>Rapid</li>' +
                            '<li>Fast</li>' +
                            '</ol>',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 4,
                title: 'What is the typical range for a pure electric vehicle?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: '100 - 370 miles',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: '50 - 200 miles',
                        body: '',
                        score: 0
                    },
                    {
                        title: '200 - 500 miles',
                        body: '',
                        score: 0
                    },
                    {
                        title: '80 - 350 miles',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 5,
                title: 'What type of braking can affect the range of an EV?',
                body: '',
                keyLearning: '',
                difficulty: 'hard',
                answers: [
                    {
                        title: 'Regenerative',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Hard braking',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Short, light braking',
                        body: '',
                        score: 0
                    },
                    {
                        title: 'Hydraulic braking',
                        body: '',
                        score: 0
                    }
                ]
            },
            {
                id: 6,
                title: 'Can an EV be towed?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Yes',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 7,
                title: 'Is an EV battery range longer in the summer or winter?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Summer',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Winter',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 8,
                title: 'Are all charging cables the same?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'No',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Yes',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 9,
                title: 'Charging an EV costs the same wherever you charge it?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'No',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'Yes',
                        body: '',
                        score: 0
                    },
                ]
            },
            {
                id: 10,
                title: 'Can you charge an EV using a standard three pin plug in a domestic house?',
                body: '',
                keyLearning: '',
                difficulty: 'medium',
                answers: [
                    {
                        title: 'Yes',
                        body: '',
                        score: 1.0
                    },
                    {
                        title: 'No',
                        body: '',
                        score: 0
                    },
                ]
            },
        ]
    }
]

export const MotorFleetElectricVehiclesTechnicalTraining = {
    questionnaire
};