const module = [
  {
    id: 'b6287211-c150-469c-9db2-1d260c4dd260',
    slug: 'virtual-meetings-setting-yourself-up-for-success',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: '0634f0ad-b1be-4a8d-8975-dd46dee3742d|b6287211-c150-469c-9db2-1d260c4dd260',
    template: 'video-template',
    title: 'Virtual meetings - setting yourself up for success',
    description:
      'Develop the skills to work effectively across multiple platforms and understand best practice in virtual meeting planning and set-up',
    completedDescription: 'TODO',
    categoryId: '0634f0ad-b1be-4a8d-8975-dd46dee3742d',
    questionnaireId: '',
    contents: {
      id: '394e206a-dab8-4f4e-ba3d-7430b80ca770',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        'Develop the skills to work effectively across multiple platforms and understand best practice in virtual meeting planning and set-up' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<ul style="margin-top:10px">' +
        '<li>Build an understanding of the different meeting platforms available and their strengths and weaknesses</li>' +
        '<li>Provide you with the skills to work effectively across multiple platforms</li>' +
        '<li>Understand best practice in session planning and set up to maximise effectiveness of the meeting</li>' +
        '<li>Understand how to overcome some of the challenges to wellbeing of working with virtual meeting technology</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'video',
          subType: 'vimeo',
          title: 'Impactful communication in a virtual trading environment - Session One',
          button: '',
          fileSize: 0,
          url: 'https://player.vimeo.com/video/505202128',
          caption: {
            title: '',
            description:
              '<p>Delivered by: Lorna Reeves & Nick Thomas</p>' +
              '<p>Brought to you by: Nick Thomas & Associates (specialist coaching, training and consulting to the UK insurance sector), on behalf of AXA Commercial.</p>'
          }
        },
        {
          type: 'file',
          subType: 'pdf',
          title: 'Impactful communication in a virtual trading environment - Session One',
          button: 'Download Slides',
          fileSize: 2345491,
          url: 'virtual-meetings-setting-yourself-up-for-success.pdf',
          caption: {
            title: '',
            description: ''
          },
          axaUrl:
            'https*3A*2F*2Fwww2.axa-insurance.co.uk*2Fe*2F471271*2Fual-Impact---Module-1---V4-pdf*2F3l5tv8*2F427588571*3Fh*3DrQ1a164e3AmUFjXog2TZNGDpcVwMrxcitJXPSAAdVAA&data=04*7C01*7C*7C78df0081bac94b0ab47708d88d4acce7*7C84df9e7fe9f640afb435aaaaaaaaaaaa*7C1*7C0*7C637414699684336208*7CUnknown*7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0*3D*7C1000&sdata=7RDqAzcHxR94Y*2BUwMPSrEsNBN7enO3zOvKsYyO3o9RQ*3D&reserved=0__;JSUlJSUlJSUlJSUlJSUlJSUlJSUlJSU!!BnkV9pdh5V0!TiHdGbe9C2paBi8pDUp7B_5JFCKsmFIyL2jtXgvw0SXN2P_Ei3Zk8OSxymfH4DPnqG4QgSQWwp9g$'
        }
      ]
    },
    progress: {
      value: 1,
      maximum: 2
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['marketing', 'sales', 'foundation']
  }
];

export const VirtualMeetingsSettingYourselfUpForSuccess = {
  module
};
