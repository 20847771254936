const module = [
  {
    id: 'daceba8f-82ae-4db6-a7d8-39597b13e0d2',
    slug: 'hybrid-management-manage-underperformance',
    visible: true,
    requiresAuth: false,
    showFeedbackForm: true,
    concatenatedID: 'daceba8f-82ae-4db6-a7d8-39597b13e0d2|9ddda366-d724-4ef0-8a75-248eda696817',
    template: 'actus-iframed-template',
    title: 'Manage underperformance in a hybrid setting',
    description:
      'You’ll discover the potential causes of underperformance in a remote environment and how to communicate expectations clearly.',
    completedDescription: 'TODO',
    supplier: 'Actus',
    categoryId: '9ddda366-d724-4ef0-8a75-248eda696817',
    questionnaireId: '',
    contents: {
      id: 'd16402a3-372f-4c69-93f7-4bd3470dfc02',
      longDescription:
        '<h2 class="subtitle">Description</h2>' +
        '<p>Managing underperformance can always be a thorny subject especially if left unattended.  In this module you’ll discover the potential causes of underperformance in a remote environment and how to communicate expectations clearly.</p>' +
        '<h2 class="subtitle">Objectives</h2>' +
        '<p>Your key learning outcomes for this learning module will include:</p>' +
        '<ul style="margin-top:10px">' +
        '<li>What underperformance is and the potential consequences</li>' +
        '<li>Potential causes of underperformance, particularly when working remotely</li>' +
        '<li>The role of the line manager</li>' +
        '<li>The importance of ‘grasping the nettle ’How to communicate expectations clearly</li>' +
        '</ul>',
      heroImage: false,
      media: [
        {
          type: 'iframe',
          subType: 'module',
          title: '',
          button: '',
          fileSize: 0,
          url:
            '/modules/daceba8f-82ae-4db6-a7d8-39597b13e0d2/media/actus-hybrid-management-series-managing-underperformance-web-v1/index.html',
          caption: {
            title: '',
            description: ''
          }
        }
      ]
    },
    progress: {
      value: 5,
      maximum: 5
    },
    statistics: {
      level: 0,
      timeEstimate: '60'
    },
    status: {
      state: 'not-started',
      completedDate: ''
    },
    tags: ['leadership', 'management', 'intermediate']
  }
];

export const ManageUnderperformanceInAHybridSetting = {
  module
};
