// Questionnaire
const questionnaire = [
  {
    id: 'bbdc75f3-df2e-4d9b-a6fc-8b1176b0fb8e',
    title: 'Additional policies to consider for the real estate sector',
    body: '',
    keyLearning: 'Learning',
    passScore: 5,
    debug: {
      showAllQuestions: false,
      showQuestionId: false,
      showAnswerInfo: false,
      showJson: false,
    },
    questions: [
      {
        id: 1,
        title: 'Which of the following is a cyber risk faced by a property owner?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'hard',
        answers: [
          {
            title: 'All of them',
            body: '',
            score: 1.0
          },
          {
            title: 'Privacy breach of confidential data',
            body: '',
            score: 0
          },
          {
            title: 'Ransomware/extortion',
            body: '',
            score: 0
          },
          {
            title: 'Liability for transmitting a virus to a managing agent',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 2,
        title: 'When should a broker recommend the insured considers buying engineering breakdown cover?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'hard',
        answers: [
          {
            title: 'Whenever there is a key item of plant in the building e.g. a lift in a tall building',
            body: '',
            score: 1.0
          },
          {
            title: 'Whenever the tenant has key items of plant in their unit',
            body: '',
            score: 0
          },
          {
            title: 'Never',
            body: '',
            score: 0
          },
          {
            title: 'Only if lease requires such cover',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 3,
        title: 'Which type of additional policy is assignable by the insured?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'hard',
        answers: [
          {
            title: 'Latent Defects',
            body: '',
            score: 1.0
          },
          {
            title: 'Rent guarantee',
            body: '',
            score: 0
          },
          {
            title: 'Resident\'s Associations D&O',
            body: '',
            score: 0
          },
          {
            title: 'Legal Expenses',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 4,
        title: 'Which of the following is an example of a valid claim under a residents D&O policy?',
        body: '',
        keyLearning: 'Why residents\’ associations need D&O cover',
        difficulty: 'hard',
        answers: [
          {
            title: 'Members of a Residents\’ Association committee were sued because a resident alleged that the association had failed to manage a disruptive tenant in a neighbouring flat, causing their property to reduce in value',
            body: '',
            score: 1.0
          },
          {
            title: 'Members of a Residents\’ Association committee were sued because a tenant in a flat caused water damage to a neighbouring flat',
            body: '',
            score: 0
          },
          {
            title: 'Members of a Residents\’ Association committee were sued because an employee of the managing agent crashed their car into a gate  ',
            body: '',
            score: 0
          },
          {
            title: 'Members of a Residents\’ Association committee were sued after a tenant tripped over a table and injured themselves in their flat',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 5,
        title: 'To whom do property owners owe a duty of care?',
        body: '',
        keyLearning: 'Liability exposures for property owners',
        difficulty: 'hard',
        answers: [
          {
            title: 'Anyone who suffers bodily injury or damage to their property caused by negligence of the property owner',
            body: '',
            score: 1.0
          },
          {
            title: 'Only tenants at the premises that suffer bodily injury or damage to their property caused by negligence of the property owner',
            body: '',
            score: 0
          },
          {
            title: 'Only third parties in neighbouring premises that suffer bodily injury or damage to their property caused by negligence of the property owner',
            body: '',
            score: 0
          },
          {
            title: 'Only visitors to the premises owned by the property owner that suffer bodily injury or damage to their property caused by negligence of the property owner',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 6,
        title: 'Which additional policy usually provides a legal help line for the insured?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'medium',
        answers: [
          {
            title: 'A legal Expenses Policy',
            body: '',
            score: 1.0
          },
          {
            title: 'A property owners liability policy',
            body: '',
            score: 0
          },
          {
            title: 'An employers\' liability policy',
            body: '',
            score: 0
          },
          {
            title: 'An emergency assistance policy',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 7,
        title: 'Is it correct to advise property owners that tenants are always responsible for arranging all statutory plant inspections in a building?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'medium',
        answers: [
          {
            title: 'No, the lease may make the landlord responsible for arranging statutory inspections of equipment provided as part of the building such as lifts',
            body: '',
            score: 1.0
          },
          {
            title: 'No, the tenants are never responsible for arranging statutory inspections of equipment provided as part of the building such as lifts',
            body: '',
            score: 0
          },
          {
            title: 'Yes, the tenants are always responsible for arranging statutory  inspections of all plant at the premises',
            body: '',
            score: 0
          },
          {
            title: 'No, no statutory inspections are required for equipment  provided as part of the building such as lifts',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 8,
        title: 'Which of the following would be covered by a residents\' association\'s D&O policy?',
        body: '',
        keyLearning: 'Why residents\’ associations need D&O cover',
        difficulty: 'medium',
        answers: [
          {
            title: 'Costs incurred by a residents\' association\'s director during a regulatory investigation into activities they had carried out as a director of the residents\' association',
            body: '',
            score: 1.0
          },
          {
            title: ' A regulatory fine imposed on a residents\' association\'s director for activities they had carried out as a director of the residents\' association',
            body: '',
            score: 0
          },
          {
            title: 'The wages lost by a  residents\' association\'s director whilst the director was volunteering for the association',
            body: '',
            score: 0
          },
          {
            title: 'A criminal fine imposed on a residents\' association\'s director for activities they had carried out as a director of the residents\' association',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 9,
        title: 'What does the Defective premises clause cover?',
        body: '',
        keyLearning: 'Liability exposures for property owners',
        difficulty: 'medium',
        answers: [
          {
            title: 'Legal liability of insured for bodily injury or property damage arising out of premises that insured has disposed of, but had previously owned ',
            body: '',
            score: 1.0
          },
          {
            title: 'Legal liability of insured for repairing defects in premises that insured has disposed of, but had previously owned ',
            body: '',
            score: 0
          },
          {
            title: 'Legal liability of insured for bodily injury or property damage arising out of defective premises ',
            body: '',
            score: 0
          },
          {
            title: 'Costs of defending insured against allegations that premises are defective',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 10,
        title: 'Why is liability for legionella not included in Property Owners\' Liability policy unless cover has been extended?',
        body: '',
        keyLearning: 'Liability exposures for property owners',
        difficulty: 'medium',
        answers: [
          {
            title: 'Legionella is caused by gradual pollution, which is excluded',
            body: '',
            score: 1.0
          },
          {
            title: 'Legionella is not a notifiable disease in the UK',
            body: '',
            score: 0
          },
          {
            title: 'Legionella can only be caused by tenants',
            body: '',
            score: 0
          },
          {
            title: 'There is no legal liability for causing legionella in the UK',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 11,
        title: 'What do emergency assistance policies provide?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'easy',
        answers: [
          {
            title: 'Emergency assistance policies provide cover for the cost of call-out charges, labour, parts, and materials, and gives access to specialist contractors',
            body: '',
            score: 1.0
          },
          {
            title: 'Emergency assistance policies provide cover for the cost of loss adjustors',
            body: '',
            score: 0
          },
          {
            title: 'Emergency assistance policies provide cover for the cost of legal advisors',
            body: '',
            score: 0
          },
          {
            title: 'Emergency assistance policies provide cover against tenants\' rent arrears',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 12,
        title: 'Which of the following is <strong>always</strong> a condition of a rent guarantee policy?',
        body: '',
        keyLearning: 'Additional policies that meet the needs of real estate sector clients',
        difficulty: 'easy',
        answers: [
          {
            title: 'Credit references must be obtained for all tenants prior to signing the lease',
            body: '',
            score: 1.0
          },
          {
            title: 'All tenants insured must provide a bond',
            body: '',
            score: 0
          },
          {
            title: 'Landlords must not start legal proceedings against a tenant if they want to make a claim',
            body: '',
            score: 0
          },
          {
            title: 'Tenants paying in cash will not be covered',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 13,
        title: 'Why do directors and officers of residents\' associations need D&O insurance?',
        body: '',
        keyLearning: 'Why residents\’ associations need D&O cover',
        difficulty: 'easy',
        answers: [
          {
            title: 'Directors/officers of residents\' associations have same liability risk as directors of other companies ',
            body: '',
            score: 1.0
          },
          {
            title: 'Directors/officers of residents\' associations have a greater liability risk than directors of other companies ',
            body: '',
            score: 0
          },
          {
            title: 'Directors/officers of residents\' associations are legally required to have D&O insurance',
            body: '',
            score: 0
          },
          {
            title: 'The lease conditions require all directors/officers of residents\' associations to arrange D&O insurance',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 14,
        title: 'Is £5m is the maximum limit needed for property owners liability?',
        body: '',
        keyLearning: 'Liability exposures for property owners',
        difficulty: 'easy',
        answers: [
          {
            title: 'No, each risk should be assessed to decide the limit of indemnity required',
            body: '',
            score: 1.0
          },
          {
            title: 'No, the legally required minimum is £10m',
            body: '',
            score: 0
          },
          {
            title: 'Yes',
            body: '',
            score: 0
          },
          {
            title: 'No, the legally required minimum is £20m',
            body: '',
            score: 0
          }
        ]
      },
      {
        id: 15,
        title: 'Is property owners liability insurance compulsory in the UK?',
        body: '',
        keyLearning: 'Liability exposures for property owners',
        difficulty: 'easy',
        answers: [
          {
            title: 'No, but it is advisable',
            body: '',
            score: 1.0
          },
          {
            title: 'No, only the tenants are required to have liability insurance',
            body: '',
            score: 0
          },
          {
            title: 'Yes',
            body: '',
            score: 0
          },
          {
            title: 'Yes for a minimum of £1m',
            body: '',
            score: 0
          }
        ]
      }
    ]
  }
]

export const RealEstateAdditionalPoliciesToConsiderForTheRealEstateSector = {
  questionnaire
};